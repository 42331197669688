export default `
<section id="deregistration" *ngIf="!isEmpty(deregistration)">          
            <h3>Deregistration</h3>
            <br />
            <div class="form-group row">
              <label
                for="commencementdatederegistration"
                class="col-sm-3 col-form-label"
                >Commencement date of Deregistration: (dd/mm/yy):</label
              >
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  id="commencementdatederegistration"
                  [(ngModel)]="deregistration.DeregistrationCommencementDate"
                  placeholder="Commencement date of Deregistration: (dd/mm/yy)"
                  readonly
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="deregistrationreason" class="col-sm-3 col-form-label"
                >Reason for Deregistration:</label
              >
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="deregistration.DeregistrationReason"
                  id="deregistrationreason"
                  placeholder="Reason for Deregistration"
                  readonly
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-3">
                <label for="outstandingpenalties">Outstanding Taxes:</label>
              </div>
              <div class="col-sm-3">
                <input
                  type="text"
                  [(ngModel)]="deregistration.OutstandingTaxAmount"
                  class="form-control"
                  id="outstandingtaxes"
                  readonly
                />
              </div>

              <div class="col-sm-2">
                <label for="outstandingpenalties">Penalties:</label>
              </div>
              <div class="col-sm-4">
                <input
                  type="text"
                  [(ngModel)]="deregistration.OutstandingPenaltyAmount"
                  class="form-control"
                  id="outstandingpenalties"
                  readonly
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="outstandingperiods" class="col-sm-3"
                >Specify outstanding period(s):</label
              >
              <div class="col-sm-3">
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="deregistration.OutstandingTaxMonth"
                  id="outstandingperiods"
                  readonly
                />
              </div>
              <label for="outstandingyear" class="col-sm-2 col-form-label"
                >Year:</label
              >
              <div class="col-sm-4">
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="deregistration.OutstandingTaxYear"
                  id="outstandingyear"
                  readonly
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="comments" class="col-sm-3 col-form-label"
                >Comments:</label
              >
              <div class="col-sm-9">
                <input
                  type="text"
                  [(ngModel)]="deregistration.DeregistrationComments"
                  class="form-control"
                  id="comments"
                  readonly
                />
              </div>
            </div>
          </section>

`