import {Component, OnInit} from '@angular/core';
import {UserStore} from '../../../stores/store.user';
import FormAction from '../../../actions/action.form';
import {RealTimeStore} from "../../../stores/store.realtime";
import {TrackingStore} from "../../../stores/store.tracking";


@Component({
    selector: 'sidebar-component',

    template: `
        <div ref="sidebar" class="sidebar">
            <ul>

                <li [ngClass]="getActiveState(menu)" *ngFor="let menu of MenuSystem" (click)="launchMenuItem(menu)">
                    <div>
                        <i class="{{menu.icon}}">

                        </i><a>
                        {{menu.name}}</a>
                    </div>
                </li>
            </ul>

            
            
            
            
        </div>`
})
export class SideBarComponent implements OnInit {

    MenuSystem = [];

    lastDashboard = null;

    ngOnInit() {
        this.MenuSystem = UserStore.getMenuSystem();
        if (this.MenuSystem.length > 0) {
            UserStore.setCurrentItem(this.MenuSystem[0].resource);
        }
    }

    getActiveState(item) {
        if (item.resource === UserStore.getCurrentItem()) {
            return "sideactive";
        }
        return "";
    }

    launchMenuItem(item) {


        TrackingStore.closeRecord((state) => {

            if (state === true) {
                if (item.resource === UserStore.getCurrentItem()) {
                    return;
                }

                RealTimeStore.setCurrentMessageStack([]);

                UserStore.setCurrentItem(item.resource);
                $("#mainloader").css("display", "block");
                FormAction.loadForm(item.resource);
            }

        }, true);

    }



}

