export default `
<div class="row">

<div class="col-md-6">
<div class="form-group">
  <label>Primary Language</label>
  <div class="input-group">
    <input
    [style.background-color]="compare(props.currentSelectedModification.NewPrimaryLanguageId,props.currentSelectedModification.OldPrimaryLanguageId) ? '#AEF0B7' : '#fff'"
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedModification.NewPrimaryLanguageId"
      class="form-control"
    />
  </div>
</div>
</div>

<div class="col-md-6">
<div class="form-group">
  <label>Secondary Language</label>
  <div class="input-group">
    <input
    [style.background-color]="compare(props.currentSelectedModification.NewSecondaryLanguageId,props.currentSelectedModification.OldSecondaryLanguageId) ? '#AEF0B7' : '#fff'"
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedModification.NewSecondaryLanguageId"
      class="form-control"
    />
  </div>
</div>
</div>

</div>
`;
