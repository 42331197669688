import {UserStore} from "../stores/store.user";

class RESTServiceStore2 {

    private processing = [];


    private getCookie(name) {
        let value = "; " + document.cookie;
        let parts = value.split("; " + name + "=");
        if (parts.length == 2) return parts.pop().split(";").shift();
    }

    processRequest(cancelExisting: boolean, url: string, data: any, method: string, callback: Function) {

        if (cancelExisting) {
            this.processing.map(function (xhr1) {
                xhr1.abort();
            });
            this.processing = [];
        }
        let me = this;
        if (method !== 'GET') {
            let xhr = new XMLHttpRequest();   // new HttpRequest instance
            xhr.open('POST', '/api/' + url);
            xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
            xhr.setRequestHeader('user-token', UserStore.getUserDetails().token);
            xhr.setRequestHeader("site-token", '7pdvAXwKchuAQGw7QBLnSHS');
            xhr.timeout = 0;
            xhr.onreadystatechange = (oEvent) => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let response = JSON.parse(xhr.response);
                        let index = me.processing.indexOf(xhr);
                        me.processing[index] = null;
                        delete me.processing[index];
                        me.processing.splice(index, 1);
                        callback(response);
                    } else {
                        callback([]);
                    }
                }


            };
            xhr.onerror = () => {
                callback([]);
            };
            this.processing.push(xhr);
            xhr.send(JSON.stringify(data));
        } else {
            let xhr = new XMLHttpRequest();
            xhr.timeout = 0;
          
            xhr.onreadystatechange = (oEvent) => {

                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let response = JSON.parse(xhr.response);

                        let index = me.processing.indexOf(xhr);
                        me.processing[index] = null;
                        delete me.processing[index];
                        me.processing.splice(index, 1);
                        callback(response);
                    } else {


                        callback([]);

                    }

                }
            };
            xhr.onerror = () => {
                callback([]);
            };
            this.processing.push(xhr);
            xhr.open('GET', '/api/' + url);
            xhr.setRequestHeader('user-token', UserStore.getUserDetails().token);
      xhr.setRequestHeader("site-token", '7pdvAXwKchuAQGw7QBLnSHS');
            xhr.send();
        }
    }
}

export var RESTLoaderAuthOnly = new RESTServiceStore2();