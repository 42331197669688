export default {
  MENUS: [
    {
      name: 'Online Users',
      groups: [
        {
          name: 'Start Up',
          menuItems: [
            {
              id: '1a',
              name: 'Manage Users',
              type: 'form',
              resource: 'startup/startuppage',
              icon: 'mdi mdi-account-location'
            }
          ]
        },
        {
          name: 'Users',
          menuItems: [
            {
              id: '1a',
              name: 'Change Password',
              type: 'form',
              resource: 'startup/changepassword',
              icon: 'mdi mdi-textbox-password'
            },
            {
              id: '1a',
              name: 'Set Status',
              type: 'form',
              resource: 'startup/setstatus',
              icon: 'mdi mdi-toggle-switch'
            }
          ]
        }
      ]
    },
    {
      name: 'Hotel',
      groups: [
        {
          name: 'Hotels',
          menuItems: [
            {
              id: '1a',
              name: 'Hotel Applications',
              type: 'form',
              resource: 'hotelapplication',
              icon: 'mdi mdi-format-list-checks'
            },
            {
              id: '1a',
              name: 'Hotel Modifications',
              type: 'form',
              resource: 'hotelmodification',
              icon: 'mdi mdi-domain'
            },
            {
              id: '1a',
              name: 'Accommodation Tax Returns',
              type: 'form',
              resource: 'hotelaccommodationtaxreturn',
              icon: 'mdi mdi-file-document'
            }
          ]
        }

      ]
    },

    {
      name: 'Tour Operators',
      groups: [
        {
          name: 'Tour Operators',
          menuItems: [
            {
              id: '1ac',
              name: 'Tour Operator Applications',
              type: 'form',
              resource: 'touroperatorapplication',
              icon: 'mdi mdi-format-list-checks'
            },
            {
              id: '1ac',
              name: 'Tour Operator Modifications',
              type: 'form',
              resource: 'touroperatormodification',
              icon: 'mdi mdi-domain'
            }
          ]
        }

      ]
    },

    {
      name: 'Tour Guides',
      groups: [
        {
          name: 'Tour Guides',
          menuItems: [
            {
              id: '1ac23',
              name: 'Tour Guide Applications',
              type: 'form',
              resource: 'tourguideapplication',
              icon: 'mdi mdi-format-list-checks'
            },
            {
              id: '1ac23',
              name: 'Tour Guide Modifications',
              type: 'form',
              resource: 'tourguidemodification',
              icon: 'mdi mdi-domain'
            }
          ]
        }

      ]
    },

    {
      name: 'Qualified Retired Person',
      groups: [
        {
          name: 'Qualified Retired Person',
          menuItems: [
            {
              id: '1ac23a',
              name: 'Qualified Retired Person Applications',
              type: 'form',
              resource: 'qualifiedRetiredPersonApplication',
              icon: 'mdi mdi-format-list-checks'
            },
            {
              id: '1ac23a',
              name: 'Qualified Retired Person Modifications',
              type: 'form',
              resource: 'qualifiedRetiredPersonModification',
              icon: 'mdi mdi-domain'
            }
          ]
        }

      ]
    },

    {
      name: 'Bank',
      groups: [
        {
          name: 'Bank',
          menuItems: [
            {
              id: '1a',
              name: 'File Upload',
              type: 'form',
              resource: 'startup/uploadfile',
              icon: 'mdi mdi-cloud-upload'
            }
          ]
        }
      ]
    },

    {
      name: 'Administrative Users',
      groups: [
        {
          name: 'General',
          menuItems: [
            {
              id: '1a',
              name: 'Manage Users',
              type: 'form',
              resource: 'admin/manageusers',
              icon: 'mdi mdi-account-location'
            },
            {
              id: '1t',
              name: 'Get User Activity Records',
              type: 'form',
              resource: 'admin/getuseractivityrecords',
              icon: 'mdi mdi-account-location'
            }
          ]
        },
        {
          name: 'Users',
          menuItems: [
            {
              id: '1a',
              name: 'Change Password',
              type: 'form',
              resource: 'admin/changepassword',
              icon: 'mdi mdi-textbox-password'
            }
          ]
        }
      ]
    },

    {
      name: 'Utilities',
      groups: [
        {
          name: 'General',
          menuItems: [
            {
              id: '1a',
              name: 'Calculate Interest',
              type: 'form',
              resource: 'util/calcinterest',
              icon: 'mdi mdi-calculator'
            }
          ]
        },
        {
          name: 'General',
          menuItems: [
            {
              id: '1a',
              name: 'Email Statement',
              type: 'form',
              resource: 'util/sendstatement',
              icon: 'mdi mdi-email'
            }
          ]
        },
        {
          name: 'General',
          menuItems: [
            {
              id: '1v',
              name: 'Penalty Exemptions',
              type: 'form',
              resource: 'util/penalty',
              icon: 'mdi mdi-alert-circle'
            }
          ]
        }
      ]
    },
    {
      name: 'Guest Registration',
      groups: [
        {
          name: 'General',
          menuItems: [
            {
              id: '1v',
              name: 'View Guest Registration',
              type: 'form',
              resource: 'util/guestregistration',
              icon: 'mdi mdi-file-document'
            }
          ]
        }
      ]
    },
    {
      name: 'Portal Messages',
      groups: [
        {
          name: 'General',
          menuItems: [
            {
              id: '1v',
              name: 'Online Portal Messages',
              type: 'form',
              resource: 'util/onlineportalmessage',
              icon: 'mdi mdi-message-bulleted'
            }
          ]
        }
      ]
    }
  ]
};
