export const ROLEMANAGEMENT = {
    duplicates: [],
    access: ['admin'],
    track: 'SystemRole',
    formtype: 'dataEntry',
    listresources: [
      {
        id: 'getRoleList',
        type: 'SQL'
      },
      {
        id: 'getpermissions',
        type: 'SQL'
      }
    ],
    caption: 'System Roles',
    editSearchSelector: {
      resource: 'getRoleList',
      resourceId: 'SystemRole',
      resourceValue: 'SystemRole',
      resourceSelectedType: 'SQL',
      resourceSelected: 'getSystemRole',
      resourceDisplayFields: ['Description']
    },
    model: {
      modelid: 'getsystemuser',
      autoload: false
    },
    functionactions: {
      createnew: {
        functionname: 'createSystemRole',
        functiontype: 'SQL',
        validateData: true,
        validationFailedTitle: '',
        validationFailedText: '',
        alertOnComplete: true,
        alertTitle: '',
        alertText: ''
      },
      update: {
        functionname: 'updateSystemRole',
        functiontype: 'SQL',
        validateData: true,
        validationFailedTitle: '',
        validationFailedText: '',
        alertOnComplete: true,
        alertTitle: '',
        alertText: ''
      },
      after: {
        functionname: 'getRoleList',
        functiontype: 'SQL'
      }
    },
    formDefinition: {
      rows: [
        {
          columns: [
            {
              title: 'General Information',
              fields: [
                {
                  key: 'OldSystemRole',
                  type: 'hidden',
                  label: 'UserName',
                  placeholder: 'UserName',
                  required: 'true',
                  isPrimary: 'false'
                },
                {
                  key: 'SystemRole',
                  type: 'text',
                  label: 'User Name',
                  placeholder: 'User Name',
                  required: 'true',
                  validation: {
                    'data-parsley-required': true,
                    'data-parsley-minlength': 3,
                    'data-parsley-maxlength': 100
                  },
                  isPrimary: 'false'
                },
                {
                  key: 'Description',
                  type: 'text',
                  label: 'Full Name',
                  placeholder: 'Full Name',
                  required: 'true',
                  validation: {
                    'data-parsley-required': true,
                    'data-parsley-minlength': 3,
                    'data-parsley-maxlength': 100
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  };