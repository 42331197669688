export default `
<section *ngIf="(props.currentSelectedModification.dependents !==undefined && props.currentSelectedModification.dependents.length>0)">

<h3>Dependent(s)</h3>

      <div class="form-group row">
        <div class="col-sm-12">
          <table class="data-table">
            <thead>
              <th>Name</th>
              <th>Relationship</th>
              <th>Gender</th>
              <th>Date Of Birth</th>
              <th>Cell Phone</th>
              <th>Home Phone</th>
              <th>Email Address</th>
              <th></th>
            </thead>
            <tbody>
              <tr *ngFor="let owner of props.currentSelectedModification.dependents" [style.background-color]="owner.RemoveDependent ? '#FFA6AF' : 'transparent'">
                <td [style.background-color]="compare(owner.OldFullName,owner.NewFullName) ? '#AEF0B7' : 'transparent'">{{ owner.NewFullName }}</td>
                <td [style.background-color]="compare(owner.OldRelationship,owner.NewRelationship) ? '#AEF0B7' : 'transparent'">{{ owner.NewRelationship }}</td>
                <td [style.background-color]="compare(owner.OldGender,owner.NewGender) ? '#AEF0B7' : 'transparent'">{{ owner.NewGender }}</td>
                <td [style.background-color]="compare(owner.OldDateOfBirth,owner.NewDateOfBirth) ? '#AEF0B7' : 'transparent'">{{ owner.NewDateOfBirth }}</td>
                <td [style.background-color]="compare(owner.OldCellPhone,owner.NewCellPhone) ? '#AEF0B7' : 'transparent'">{{ owner.NewCellPhone }}</td>
                <td [style.background-color]="compare(owner.OldHomePhone,owner.NewHomePhone) ? '#AEF0B7' : 'transparent'">{{ owner.NewHomePhone }}</td>
                <td [style.background-color]="compare(owner.OldEmailAddress,owner.NewEmailAddress) ? '#AEF0B7' : 'transparent'">{{ owner.NewEmailAddress }}</td>
                <td><button type="button" title="Details" class="e-lib e-btn e-control e-flat" (click)="showDependentDetails('new',owner)">Details</button></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

</section>
`;
