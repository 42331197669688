import { UserStore } from "../stores/store.user";

import swal from "sweetalert2";
class RESTServiceStore {
  private processing = [];

  private getCookie(name) {
    let value = "; " + document.cookie;
    let parts = value.split("; " + name + "=");
    if (parts.length == 2) return parts.pop().split(";").shift();
  }

  processRequest(
    cancelExisting: boolean,
    url: string,
    data: any,
    method: string,
    callback: Function
  ) {
    if (cancelExisting) {
      this.processing.map(function (xhr1) {
        xhr1.abort();
      });
      this.processing = [];
    }
    let me = this;
    if (method !== "GET") {
      let xhr = new XMLHttpRequest(); // new HttpRequest instance
      xhr.timeout = 600000;
      xhr.open("POST", "/api/" + url);
      xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
      xhr.setRequestHeader("user-token", UserStore.getUserDetails().token);
      xhr.setRequestHeader("site-token", "7pdvAXwKchuAQGw7QBLnSHS");
      xhr.timeout = 0;
      xhr.onreadystatechange = (oEvent) => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            let response = JSON.parse(xhr.response);
            let index = me.processing.indexOf(xhr);
            me.processing[index] = null;
            delete me.processing[index];
            me.processing.splice(index, 1);
            callback(response);
          } else if (xhr.status === 0) {
          } else if (xhr.status === 998) {
            if (xhr.statusText !== "abort") {
              swal
                .fire({
                  title: "Session Has Expired",
                  text:
                    "Your current session has been inactive for too much time. So It has timed out.",
                  icon: "error",
                  showCancelButton: true,
                  confirmButtonColor: "#DD6B55",
                  confirmButtonText: "Refresh Browser",
                  cancelButtonText: "Close",
                })
                .then(
                  (result) => {
                    if (result.value) {
                      location.reload();
                    }
                  },
                  () => {}
                );
            }
          } else {
            if (xhr.statusText !== "abort") {
              if (xhr.statusText !== "abort") {
                let response = JSON.parse(xhr.response);
                swal
                  .fire({
                    title: "Oops...",
                    text:
                      "Something went wrong with your last request. " +
                      response.error,
                    icon: "error",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Retry",
                    cancelButtonText: "Close",
                  })
                  .then(
                    (result) => {
                      if (result.value) {
                        xhr.open("POST", "/api/" + url);
                        xhr.setRequestHeader(
                          "user-token",
                          UserStore.getUserDetails().token
                        );
                        xhr.setRequestHeader(
                          "site-token",
                          "7pdvAXwKchuAQGw7QBLnSHS"
                        );
                        xhr.send(JSON.stringify(data));
                      }
                    },
                    () => {}
                  );
              }
            }
          }
        }
      };
      this.processing.push(xhr);
      xhr.send(JSON.stringify(data));
    } else {
      let xhr = new XMLHttpRequest();
      xhr.timeout = 0;

      xhr.onreadystatechange = (oEvent) => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            let response = JSON.parse(xhr.response);

            let index = me.processing.indexOf(xhr);
            me.processing[index] = null;
            delete me.processing[index];
            me.processing.splice(index, 1);
            callback(response);
          } else if (xhr.status === 0) {
          } else if (xhr.status === 998) {
            if (xhr.statusText !== "abort") {
              swal
                .fire({
                  title: "Session Has Expired",
                  text:
                    "Your current session has been inactive for too much time. So It has timed out.",
                  icon: "error",
                  showCancelButton: true,
                  confirmButtonColor: "#DD6B55",
                  confirmButtonText: "Refresh Browser",
                  cancelButtonText: "Close",
                })
                .then(
                  (result) => {
                    if (result.value) {
                      location.reload();
                    }
                  },
                  () => {}
                );
            }
          } else {
            if (xhr.statusText !== "abort") {
              if (xhr.statusText !== "abort") {
                let response = JSON.parse(xhr.response);
                swal
                  .fire({
                    title: "Oops...",
                    text:
                      "Something went wrong with your last request. " +
                      response.error,
                    icon: "error",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Retry",
                    cancelButtonText: "Close",
                  })
                  .then(
                    (result) => {
                      if (result.value) {
                        xhr.open("GET", "/api/" + url);
                        xhr.setRequestHeader(
                          "user-token",
                          UserStore.getUserDetails().token
                        );
                        xhr.setRequestHeader(
                          "site-token",
                          "7pdvAXwKchuAQGw7QBLnSHS"
                        );
                        xhr.send(JSON.stringify(data));
                      }
                    },
                    () => {}
                  );
              }
            }
          }
        }
      };
      this.processing.push(xhr);
      xhr.open("GET", "/api/" + url);
      xhr.setRequestHeader("user-token", UserStore.getUserDetails().token);
      xhr.setRequestHeader("site-token", "7pdvAXwKchuAQGw7QBLnSHS");
      xhr.send();
    }
  }
}

export var RESTLoader = new RESTServiceStore();
