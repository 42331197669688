import {Component, Input, AfterViewInit} from '@angular/core';
import {FlowControlStore} from '../../../stores/store.flowcontrol';
import {UserStore} from '../../../stores/store.user';
import constants from '../../../constants/constants';
import UserAction from '../../../actions/action.user';


@Component({

    selector: 'authentication-control',
    template: `

<div class="adminstyle">{{getCurrentApp()}}</div>
<div style='width:100%;'>
    <div style='width:100%;text-align: center;' *ngIf='!isAuthenticating'>
       <img src="assets/logo.png"/>
    </div>
                <div style='width:100%;' *ngIf='!isAuthenticating'>
                    <label style='width:100%;'>

                        <input [attr.data-required]="true" [attr.data-minlength]="3"  [(ngModel)]='UserName' (keydown)="checkEnter($event)" type='text' placeholder='User Name' />
                    </label>
                </div>

                <div style='width:100%;' *ngIf='!isAuthenticating'>
                    <label style='width:100%;'>

                        <input [attr.data-required]="true" [attr.data-minlength]="3" [(ngModel)]='Password' (keydown)="checkEnter($event)"  type='password' placeholder='Password' />
                    </label>
                </div>

<div style='width:100%; padding-left: 29%;padding-top: 5px;' *ngIf='!isAuthenticating'>
                   
                </div>

                <div style='width:100%;'>

                <div class='loginbuttonholder' *ngIf='!isAuthenticating'>
                    <button class='loginbutton' (click)='runAuthentication()'>SIGN IN</button>
                </div>
                <div class='loadingsection' *ngIf='isAuthenticating'>
                    <div class='loader'>Loading...</div>
                </div>
                <div class='error' *ngIf='hasError' style="color:#EE000E;text-align: center"><br/>Authentication failed. Please ensure that your user name
                    and/or password is correct.
                </div>
                    </div>
            </div>
            <div id="recaptcha"></div>
`
})
export class AuthenticationComponent implements AfterViewInit {


    @Input() config: any;
    public hasError: boolean = false;
    public isAuthenticating: boolean = false;
    public UserName: string;
    public Password: string;


    getCurrentApp() {
        return this.config.title;
    }

    constructor() {
        UserStore.emitter.subscribe(constants.UserActions.USER_DETAILS_AVAILABLE, () => {

            this.isAuthenticating = true;
            this.hasError = false;
            FlowControlStore.moveNextScene();

        });
        UserStore.emitter.subscribe(constants.UserActions.AUTHENTICATION_FAILED, () => {

            this.isAuthenticating = false;

            this.hasError = true;

        });


    }

    checkEnter(event) {
        if (event.keyCode == 13) {
            this.runAuthentication()
        }
    }


    runAuthentication() {
        UserAction.authenticateUser(this.UserName, this.Password);
    }

    ngAfterViewInit() {


    }


}
