export const QUALIFIEDRETIREDPERSONMODIFCATION = {
  access: ['admin'],
  formtype: 'qualifiedretiredpersonmodification',
  listresources: [
    {
      id: 'controlids',
      type: 'SQL'
    }
  ],
  caption: 'Qualified Retired Person Modifications'
};
