import { Component, AfterViewInit, ViewChild, Input } from "@angular/core";
import * as _ from "underscore";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import ui from './qualifiedRetiredPersonModificationSections';
import constants from './qualifiedRetiredPersonModificationSections/constants';
import {init} from './qualifiedRetiredPersonModificationSections/actions/setup';
import guid from "../../../../app/constants/guid";
import {showModificationsList}  from "./qualifiedRetiredPersonModificationSections/actions/showModifications";
import { processDetails } from "./qualifiedRetiredPersonModificationSections/actions/processdetails";
import {onShowNewDependentDetail} from "./qualifiedRetiredPersonModificationSections/actions/onShowNewDependentDetail";
import {onShowDependentDetail} from "./qualifiedRetiredPersonModificationSections/actions/onShowDependentDetail";
@Component({
  selector: "view-qualified-retired-person-modification-request",
  template: ui
})
export class ViewQualifiedRetiredPersonModificationRequest implements AfterViewInit {
  props:any = null;
  guid:Function=null;
  showModificationsList:Function=null;
  _onChange:Function=null;
  processDetails:Function=null;
  showNewDependentDetails:Function=null;
  showDependentDetails:Function=null;
  @ViewChild("content", {static: false})
  content;
  @ViewChild("process", {static: false})
  process;
  @ViewChild("complete", {static: false})
  complete;
  @ViewChild("image", {static: false})
  image;
  @ViewChild("document", {static: false})
  document;
  @ViewChild("approvecomplete", {static: false})
  approvecomplete;
  @ViewChild("newdependent", {static: false})
  newdependent;
  @ViewChild("olddependent", {static: false})
  olddependent;
  @ViewChild("moddependent", {static: false})
  moddependent;
  @Input() formdata: any;
  ngAfterViewInit() {
    const _init = init.bind(this);
    _init();
  }

  compare(val1, val2) {
    if (val1 !== undefined && val2 !== undefined) {
      return (
        val1
          .toString()
          .trim()
          .toLowerCase() !==
        val2
          .toString()
          .trim()
          .toLowerCase()
      );
    } else {
      return val1 !== val2;
    }
  }

  onChange(changed){
    this.props.postdata.IdNumber = parseInt(changed.IdNumber.toString());
    this.props.postdata.QualifiedRetiredPersonIdNumber = parseInt(changed.IdNumber.toString());
  }
  constructor(private modalService: NgbModal) {
    this.props = _.clone(constants);
    this.guid=guid.create;
    this.showModificationsList=showModificationsList.bind(this);
    this.processDetails=processDetails.bind(this);
    this._onChange = this.onChange.bind(this);
    this.showNewDependentDetails = onShowNewDependentDetail.bind(this);
    this.showDependentDetails = onShowDependentDetail.bind(this);
  }
}
