export default `
<div class="row">

<div class="col-md-10">
  <div class="form-group">
    <label>Physical Address</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedApplication.Address"
      />
    </div>
  </div>
</div>

<div class="col-md-2">
  <div class="form-group">
    <label>City</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedApplication.CityName"
      />
    </div>
  </div>
</div>


<div class="col-md-10">
  <div class="form-group">
    <label>Mailing Address</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedApplication.MailingAddress"
      />
    </div>
  </div>
</div>

<div class="col-md-2">
  <div class="form-group">
    <label>Mailing City</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedApplication.MailingCityName"
      />
    </div>
  </div>
</div>


<div class="col-md-6">
  <div class="form-group">
    <label>Cell Phone</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedApplication.CellPhone"
      />
    </div>
  </div>
</div>


<div class="col-md-6">
  <div class="form-group">
    <label>Home Phone</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedApplication.HomePhone"
      />
    </div>
  </div>
</div>




<div class="col-md-12">
<div class="form-group">
<label>Email Address</label>
<div class="input-group">
<input
readOnly="readOnly"
class="form-control"
[(ngModel)]="props.currentSelectedApplication.EmailAddress"
/>
</div>
</div>

</div>







</div>
`;
