import { Grid } from "@syncfusion/ej2-ng-grids";

export const createTourGuideApplicationLanguageListGrid = function(dataSource){
  const cols = [
    {
      field: "Language",
      headerText: "Language"
    },


  ];
  const ownerGrid = new Grid({
    dataSource,
    width: "100%",
    allowTextWrap: true,
    textWrapSettings: {
      wrapMode: "Content"
    },
    columns: cols
  });
  ownerGrid.appendTo(`#${this.props.referenceid2}`);
}
