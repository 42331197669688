export default
{

    RealTimeActionsActions: {
        CONNECTION_OPEN: "CONNECTION_OPEN",
        CONNECTION_CLOSED: "CONNECTION_CLOSED",
        MESSAGE_RECEIVED: "MESSAGE_RECEIVED"
    },
    UserActions: {
        USER_DETAILS_AVAILABLE: "USER_AUTHENTICATED",
        AUTHENTICATION_FAILED: "AUTHENTICATION_FAILED",
        API_USER_DETAILS: "API_USER_DETAILS",
        GET_USER_DETAILS: "GET USER DETAILS"
    },
    DataActions: {
        DATA_AVAILABLE: "DATA_AVAILABLE",
        READONLY_STATE: "READONLY_STATE",
        EDITABLE_STATE: "EDITABLE_STATE",
        OBTAIN_CURRENT: "OBTAIN_CURRENT",
        RANGE_CHANGED: "RANGE_CHANGED"
    },
    FormsActions: {
        FORM_AVAILABLE: "FORM_AVAILABLE",
        TRIGGER_SEARCHABLE: "TRIGGER_SEARCHABLE",
        TRIGGER_SETTINGS: "TRIGGER_SETTINGS",
        TRIGGER_DELETE_HISTORY: "TRIGGER_DELETE_HISTORY",
        TRIGGER_UPDATE_HISTORY: "TRIGGER_UPDATE_HISTORY"
    },

    GenericActions: {
        EXCEPTION_TRIGGERED: "EXCEPTION_TRIGGERED",
        TRIGGER_WAIT_STATE: "TRIGGER_WAIT_STATE"
    },
    FlowControl: {
        FLOW_STATE_CHANGED: "FLOW_STATE_CHANGED",
        FLOW_STATE_MOVED: "FLOW_STATE_MOVED",
        FLOW_STATE_INITIATED: "FLOW_STATE_INITIATED"
    },


    NetworkActions: {
        NETWORK_CHANGED: "NETWORK CHANGED"
    }


};
