import {Directive, ElementRef, Inject, Input, AfterViewInit} from '@angular/core';


@Directive({
    selector: '[parsley]'
})

export class ParsleyDirective implements AfterViewInit {
    @Input() validation: any;

    private el: HTMLElement;

    constructor(@Inject(ElementRef) elementRef: ElementRef) {
        this.el = elementRef.nativeElement;
    }

    ngAfterViewInit() {
        let stringValue = '';

        if (this.validation !== null && this.validation !== undefined) {
            for (let propertyName in this.validation) {
                if (this.validation.hasOwnProperty(propertyName)) {
                    if (propertyName !== '0') {
                        if ($(this.el).length > 0) {
                            if (typeof(stringValue) === typeof( this.validation[propertyName])) {
                                $(this.el).attr(propertyName, this.validation[propertyName].replace('*', '#'));
                            }
                            else {
                                $(this.el).attr(propertyName, this.validation[propertyName]);
                            }
                        }
                    }
                }
            }
        }

    }
}