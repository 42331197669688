import {AfterViewInit, Component, Input, OnChanges} from '@angular/core';
import DataAction from '../../../actions/action.data';
import * as _ from 'underscore';
import * as toastr from 'toastr';
import {FormStore} from '../../../stores/store.form';
import * as moment from 'moment';

@Component({
    selector: 'btb-interest-calc',
    template: `
        <div class="editorActions" style="right:10px;">

          
        </div>
        <div style="position:absolute;top: 10px;left: 20px;right: 20px;bottom:10px;" class="clientassign">

            <div class="form-group" style="width:300px;margin:20px;">
                <label for="sel1">Source Type:</label>              

                <select class="form-control" [(ngModel)]="postdata.sourcetype">
                    <option value="HOT">Hotels</option>                  
                    <option value="CRU">Cruise</option>                    
                    <option value="TSH">Timeshare</option>
                    <option value="ABD">Live aboards</option>                    
                </select>                           
                
            </div>

            <div class="form-group" style="width:300px;margin:20px;">

                <div style="width:150px;display:inline-block;">
                <select class="form-control" [(ngModel)]="postdata.month">                   
                    <option value="1">JANUARY</option>
                    <option value="2">FEBRUARY</option>
                    <option value="3">MARCH</option>
                    <option value="4">APRIL</option>
                    <option value="5">MAY</option>
                    <option value="6">JUNE</option>
                    <option value="7">JULY</option>
                    <option value="8">AUGUST</option>
                    <option value="9">SEPTEMBER</option>
                    <option value="10">OCTOBER</option>
                    <option value="11">NOVEMBER</option>
                    <option value="12">DECEMBER</option>
                </select>
                </div>
                <div style="width:15px;display:inline-block;margin-left:10px;"><strong>/</strong></div>
                <div style="width:90px;display:inline-block;margin-left:5px;">
                <select class="form-control" [(ngModel)]="postdata.year">
                <option></option>    
                <option *ngFor="let y of years" value={{y}}>{{y}}</option>                    
                </select>
                </div>
                

            </div>          

            <div class="form-group" style="width:300px;margin:20px;">
                <a href="javascript:;"
                   (click)="calculate()"
                   class="btn btn-sm btn-circle btn-success"> Calculate </a>
            </div>

            <div class="form-group" style="width:300px;margin:20px;">
              <span>{{currentMessages}}</span>
            </div>
           
            
            
            
         
        </div>`
})
export class BTBInterestCalc implements AfterViewInit {

    loaded="";
    years=[];
    months=[];
    @Input() formdata: any;
    postdata={
        sourcetype:"",
        interestdate:"",
        month:"",
        year:""
    };
    calculate(){

        $(".fakeloader").show();

        let senddata = JSON.parse(JSON.stringify(this.postdata));

        let realdate = moment(senddata.year+"-"+senddata.month+"-1").toDate();
        realdate.setMonth(realdate.getMonth() + 1);
        realdate.setDate(realdate.getDate() + 13);
        senddata.penaltydate =  moment(realdate).format("YYYY-MM-DD");

        DataAction.runData(senddata, "SQL","CalcInterest", (data) => {
            $(".fakeloader").hide();
            if(data.ErrorString === "")
            {
                this.currentMessages = "Invoices Created Successfully. "+ data.NumberOfInvoicesGenerated.toString()+" Invoices Created.";
            }
            else
            {
                this.currentMessages = data.ErrorString;
            }




        });
    }


    config2={
        "key": "penaltydate",
        "type": "date",
        "label": "Penalty Period Date",
        "placeholder": "Penalty Period Date",
        "required": "true",
        "validation": {
            "data-parsley-required": true,
            "data-parsley-minlength": 3
        },
        "isPrimary": "false",
        "default":"TODAY",
        "readonly":true
    };

    currentMessages = "";
    ngAfterViewInit() {
        let yearstart = (new Date()).getFullYear()-1;
        for(let l=0;l<3;l++){
            this.years.push(yearstart);
            yearstart++;
        }

        for(let f=1;f<13;f++){
            this.months.push(f);
        }


    }




}
