export const fit_modal_body = (modal: any) => {
  const header = $(".modal-header", modal);
  const footer = $(".modal-footer", modal);
  const body = $(".modal-body", modal);

  const modalheight = parseInt(modal.css("height"));
  const headerheight =
    parseInt(header.css("height")) +
    parseInt(header.css("padding-top")) +
    parseInt(header.css("padding-bottom"));
  const bodypaddings =
    parseInt(body.css("padding-top")) + parseInt(body.css("padding-bottom"));
  const footerheight =
    parseInt(footer.css("height")) +
    parseInt(footer.css("padding-top")) +
    parseInt(footer.css("padding-bottom"));
  const height = modalheight - headerheight - footerheight - bodypaddings - 5; // fudge factor

  return body.css("max-height", `${height}px`);
}
