import { Component, AfterViewInit, ViewChild, Input } from "@angular/core";
import * as _ from "underscore";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import ui from './TourOperatorModificationSections';
import constants from './TourOperatorModificationSections/constants';
import {init} from './TourOperatorModificationSections/actions/setup';
import guid from "../../../../app/constants/guid";
import {showModificationsList}  from "./TourOperatorModificationSections/actions/showModifications";
import { processDetails } from "./TourOperatorModificationSections/actions/processdetails";
@Component({
  selector: "view-touroperator-modification-request",
  template: ui
})
export class ViewTourOperatorModificationRequest implements AfterViewInit {
  props:any = null;
  guid:Function=null;
  showModificationsList:Function=null;
  _onChange:Function=null;
  processDetails:Function=null;
  @ViewChild("content", {static: false})
  content;
  @ViewChild("process", {static: false})
  process;
  @ViewChild("complete", {static: false})
  complete;
  @ViewChild("image", {static: false})
  image;
  @ViewChild("document", {static: false})
  document;
  @ViewChild("approvecomplete", {static: false})
  approvecomplete;
  @Input() formdata: any;
  ngAfterViewInit() {
    const _init = init.bind(this);
    _init();
  }

  compare(val1, val2) {
    if (val1 !== undefined && val2 !== undefined) {
      return (
        val1
          .toString()
          .trim()
          .toLowerCase() !==
        val2
          .toString()
          .trim()
          .toLowerCase()
      );
    } else {
      return val1 !== val2;
    }
  }


  constructor(private modalService: NgbModal) {
    this.props = _.clone(constants);
    this.guid=guid.create;
    this.showModificationsList=showModificationsList.bind(this);
    this.processDetails=processDetails.bind(this);
    this._onChange = changed => {

      this.props.postdata.IdNumber = parseInt(changed.IdNumber.toString());


      //this.submitdata(changed.ControlId);
    };

  }
}
