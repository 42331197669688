import { Component, Input, OnInit, OnChanges } from "@angular/core";
import * as _ from "underscore";
import { DataStore } from "../../../stores/store.data";
import constants from "../../../constants/constants";
import DataAction from "../../../actions/action.data";
import * as moment from "moment";
import { TrackingStore } from "../../../stores/store.tracking";
import guid from "../../../constants/guid";
import swal from "sweetalert2";
import validate from "../../../constants/validate";
import * as toastr from "toastr";

@Component({
  selector: "editable-table-view",
  template: `
    <div class="card-full">
      <div
        *ngIf="!addmode && !editable && mode === 'PENDING'"
        style="height:35px;display:block;"
      >
        <a
          class="btn btn-success pull-right"
          href="javascript:void(0);"
          (click)="addNew()"
          >Add New Record</a
        >
      </div>
      <div>
        <div
          class="container-fluid tableheader hidden-xs hidden-sm hidden-md"
          style="margin-top:10px;"
        >
          <div class="row">
            <div
              class="col-md-1"
              style="padding:10px 3px 10px 3px;"
              *ngIf="!editable"
            >
              &nbsp;
            </div>

            <div
              *ngFor="let column of config.fields"
              style="padding:7px 3px 7px 3px;text-align:center;"
              class="{{ getColSize(column) }}"
            >
              <span
                style="float:left;font-weight:400;width:80%;font-size:14px;font-weight:400;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;"
                >{{ column.label }}</span
              >
            </div>
          </div>
        </div>

        <div>
          <div class="container-fluid tablerows" style="margin-top:10px;">
            <div
              class="container-fluid"
              *ngFor="let data of getData(); let idx = index"
            >
              <div id="{{ getFormRowID(idx) }}" parsley-validate>
                <div class="row tablerow">
                  <div
                    class="col-md-1"
                    style="padding:10px 3px 10px 3px;text-align:center;"
                    *ngIf="!editable"
                  >
                    <a
                      style="padding-top: 5px;display: inline-block;color:#4387B7;"
                      *ngIf="!editable && !data.editable && mode === 'PENDING'"
                      href="javascript:void(0)"
                      (click)="editItem(data)"
                      ><i class="mdi mdi-pencil"></i
                    ></a>
                    <a
                      *ngIf="!editable && !data.editable && mode === 'PENDING'"
                      href="javascript:void(0)"
                      style="margin-left:8px;padding-top: 5px;display: inline-block;color:#B50B00;"
                      (click)="deleteItem(data)"
                      ><i class="mdi mdi-delete"></i
                    ></a>
                    <a
                      style="padding-top: 5px;display: inline-block;color:#35B03B;"
                      *ngIf="data.editable && mode !== 'PENDING'"
                      href="javascript:void(0)"
                      (click)="saveItem(data, idx)"
                      ><i class="mdi mdi-content-save"></i
                    ></a>
                    <a
                      *ngIf="data.editable && mode !== 'PENDING'"
                      href="javascript:void(0)"
                      style="margin-left:8px;padding-top: 5px;display: inline-block;color:#B50B00;"
                      (click)="cancelItem(data)"
                      ><i class="mdi mdi-undo-variant"></i
                    ></a>
                  </div>

                  <div
                    *ngFor="let column of config.fields"
                    style="padding:10px 3px 10px 3px;"
                    class="{{ getColSize(column) }}"
                  >
                    <label
                      style="width:100%"
                      class="visible-xs visible-sm visible-md"
                    >
                      <span class="ThemeControlled">{{ column.label }}</span>
                    </label>

                    <div [ngSwitch]="column.type">
                      <div *ngSwitchCase="'text'">
                        <div
                          style="width:100%"
                          *ngIf="getPrimary(column.isPrimary)"
                        >
                          <input
                            parsley
                            [(validation)]="column.validation"
                            [readonly]="!data.editable ? true : null"
                            class="form-control"
                            name="{{ column.key }}"
                            [(ngModel)]="data[column.key]"
                            placeholder="{{ column.placeholder }}"
                          />
                        </div>
                        <div
                          style="width:100%"
                          *ngIf="!getPrimary(column.isPrimary)"
                        >
                          <div class="input-group">
                            <input
                              parsley
                              [(validation)]="column.validation"
                              [readonly]="!data.editable ? true : null"
                              class="form-control"
                              name="{{ column.key }}"
                              [(ngModel)]="data[column.key]"
                              placeholder="{{ column.placeholder }}"
                            />
                            <span
                              class="input-group-addon"
                              style="color:#fff!important;background:#92C2E3!important;"
                              data-balloon-length="large"
                              data-balloon="Please note that this field is both required and cannot be duplicated. Please ensure that you only use the text in this field once per record or you will have issues saving."
                              data-balloon-pos="up"
                              id="basic-addon1"
                              ><i
                                class="mdi mdi-help"
                                style="font-size:14px!important;"
                              ></i
                            ></span>
                          </div>
                        </div>
                      </div>

                      <div *ngSwitchCase="'number'">
                        <div
                          style="width:100%"
                          *ngIf="getPrimary(column.isPrimary)"
                        >
                          <input
                            type="number"
                            parsley
                            [(validation)]="column.validation"
                            [readonly]="!data.editable ? true : null"
                            class="form-control"
                            name="{{ column.key }}"
                            [(ngModel)]="data[column.key]"
                            placeholder="{{ column.placeholder }}"
                          />
                        </div>
                        <div
                          style="width:100%"
                          *ngIf="!getPrimary(column.isPrimary)"
                        >
                          <div class="input-group">
                            <input
                              type="number"
                              parsley
                              [(validation)]="column.validation"
                              [readonly]="!data.editable ? true : null"
                              class="form-control"
                              name="{{ column.key }}"
                              [(ngModel)]="data[column.key]"
                              placeholder="{{ column.placeholder }}"
                            />
                            <span
                              class="input-group-addon"
                              style="color:#fff!important;background:#92C2E3!important;"
                              data-balloon-length="large"
                              data-balloon="Please note that this field is both required and cannot be duplicated. Please ensure that you only use the text in this field once per record or you will have issues saving."
                              data-balloon-pos="up"
                              id="basic-addon1"
                              ><i
                                class="mdi mdi-help"
                                style="font-size:14px!important;"
                              ></i
                            ></span>
                          </div>
                        </div>
                      </div>

                      <div *ngSwitchCase="'password'">
                        <div style="width:100%">
                          <input
                            parsley
                            [(validation)]="column.validation"
                            [readonly]="data.editable ? false : null"
                            type="password"
                            class="form-control"
                            name="{{ column.key }}"
                            id="{{ getIdentity(column) }}"
                            [(ngModel)]="data[column.key]"
                            placeholder="{{ column.placeholder }}"
                          />
                        </div>
                      </div>

                      <div *ngSwitchCase="'spacer'">
                        <div style="height:67px;"></div>
                      </div>

                      <div *ngSwitchCase="'checkbox'">
                        <div style="width:100%;height:65px;padding-top:18px;">
                          <input
                            [readonly]="!data.editable"
                            type="checkbox"
                            name="{{ column.key }}"
                            [(ngModel)]="data[column.key]"
                            (change)="($event.target.checked ? 1 : 0)"
                          />
                        </div>
                      </div>

                      <div *ngSwitchCase="'select'">
                        <select-ds
                          [editable]="!data.editable"
                          [label]="false"
                          [model]="data"
                          [listsources]="formdata.listresources"
                          [config]="column"
                        ></select-ds>
                        <input
                          parsley
                          [(validation)]="column.validation"
                          type="input"
                          style="display:none;"
                          [(ngModel)]="data[column.key]"
                        />
                      </div>

                      <div *ngSwitchCase="'date'">
                        <date-picker
                          [model]="data"
                          [config]="column"
                          [label]="false"
                          [editable]="!data.editable"
                          [addmode]="addmode"
                        ></date-picker>
                      </div>

                      <div *ngSwitchDefault><input type="hidden" /></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class EditableTableComponent implements OnInit, OnChanges {
  @Input() formdata: any;
  @Input() listresources: any;
  currentdata = [];
  alldata = [];
  currentSort = null;
  sortOrder = "ASC";

  scratchDisk: any = null;

  @Input() editable = false;
  @Input() addmode = false;
  @Input() model: any = null;
  @Input() config: any = null;
  @Input() updatewall: any;
  @Input() linkfield: any;
  mode = "PENDING";
  emptyLink = {
    agentid: "",
    calendarlinkid: guid.create(""),
    calendarlink: "",
    calendarlinkname: "",
    state: "NEW",
  };

  getData() {
    const ret = [];

    this.alldata.map((item: any) => {
      if (item.state !== "DELETE") {
        ret.push(item);
      }
    });

    return ret;
  }

  getCurrentDataSet(callback) {
    if (this.config.data !== null && this.config.data !== undefined) {
      let jdata =
        '{"' + this.config.keylink + '":"' + this.model[this.config.key] + '"}';

      let v1 = JSON.parse(jdata);
      DataAction.runData(
        v1,
        this.config.data.getResource.resourceType,
        this.config.data.getResource.id,
        () => {
          callback();
        }
      );
    }
    this.mode = "PENDING";
  }

  getCurrentDataSetCallBack(callback) {
    this.mode = "PENDING";
    if (this.config.data !== null && this.config.data !== undefined) {
      let jdata =
        '{"' + this.config.keylink + '":"' + this.model[this.config.key] + '"}';

      let v1 = JSON.parse(jdata);
      DataAction.runData(
        v1,
        this.config.data.getResource.resourceType,
        this.config.data.getResource.id,
        () => {
          callback();
        }
      );
    } else {
      callback();
    }
  }

  ngOnChanges(data) {
    if (data.model !== null && data.model !== undefined) {
      this.getCurrentDataSet(() => {});
    }
  }

  getColSize(col) {
    let cursize = 12;

    if (!this.editable) {
      cursize = 11;
    }

    let coloff: number = Math.floor(cursize / this.config.fields.length);
    if (col.larger === "true") {
      return "col-md-" + (coloff + 1);
    } else {
      return "col-md-" + coloff;
    }
  }

  getPrimaryKey(fld): any {
    return this.model[fld];
  }

  getPrimary(p): boolean {
    return p !== "true" || p === undefined;
  }

  getSortClass(column) {
    if (column.key === this.currentSort) {
      if (this.sortOrder === "ASC") {
        return "mdi mdi-chevron-down";
      } else {
        return "mdi mdi-chevron-up";
      }
    } else {
      return "mdi mdi-unfold-more";
    }
  }

  ngOnInit() {
    DataStore.emitter.subscribe(
      constants.DataActions.DATA_AVAILABLE,
      (data) => {
        if (this.config.datasource !== undefined) {
          if (data[0].dataid === this.config.datasource) {
            this.alldata = data[0].data;
          }
        }
      }
    );

    DataStore.emitter.subscribe(
      constants.DataActions.DATA_AVAILABLE,
      (data) => {
        if (this.config.data !== undefined && this.config.data !== null) {
          if (data[0].dataid === this.config.data.getResource.id) {
            this.alldata = data[0].data;
          }
        }
      }
    );
    if (this.updatewall !== undefined) {
      this.updatewall.updateWall.push(this.updatechanges.bind(this));
      this.updatewall.recycle.push(this.getCurrentDataSetCallBack.bind(this));
    }
  }

  setSort(key) {
    this.currentSort = key;
    if (this.currentSort === key) {
      if (this.sortOrder === "ASC") {
        this.sortOrder = "DSC";
      } else {
        this.sortOrder = "ASC";
      }
    } else {
      this.sortOrder = "ASC";
    }
    this.filterdata();
  }

  addNew() {
    let scratchData = {
      state: "ADD",
      editable: true,
    };

    this.config.fields.map((field: any) => {
      scratchData[field.key] = null;
    });

    scratchData[this.config.keylink] = this.model[this.config.key];

    this.alldata.push(scratchData);
    this.mode = "ADDING";
  }

  cancelItem(item) {
    if (item.state !== "ADD") {
      if (item.state !== "NEW") {
        item.state = undefined;
      }
      item.editable = false;
    } else {
      const elementPos = this.alldata
        .map(function (x) {
          return x;
        })
        .indexOf(item);
      this.alldata.splice(elementPos, 1);
    }

    this.mode = "PENDING";
  }

  getIdentity(fld: any): string {
    if (fld.identity !== null && fld.identity !== undefined) {
      return fld.identity;
    }
    return guid.create("identity");
  }

  saveItem(item, idx) {
    let valid = this.getFormRowID(idx);

    let demoform: any = $("#demo-form");
    if (demoform.length > 0) {
      let v = demoform["parsley"]();

      v.validate();

      if (v.isValid()) {
        if (item.state === "ADD") {
          item.state = "NEW";
        }

        if (item.state !== "NEW") {
          item.state = "EDIT";
        }
        item.editable = false;
        this.mode = "PENDING";
      }
    }
  }

  removeItem(item) {
    if (item.state !== "NEW") {
      item.state = "DELETE";
    } else {
      let elementPos = this.alldata
        .map(function (x) {
          return x;
        })
        .indexOf(item);
      this.alldata.splice(elementPos, 1);
    }
  }

  deleteItem(item) {
    swal
      .fire({
        title: "Confirm Delete",
        icon: "error",
        html:
          '<p>You are about to delete this item from the database. Type the word <span style="color:red">delete</span> below and click continue to confirm removal.</p><input id="input-field" class="form-control" style="background:#ffffff!important;color:red!important;"></p>',
        showCancelButton: true,
        confirmButtonColor: "#0171C5",
        confirmButtonText: "Continue",
        cancelButtonText: "Cancel",
      })
      .then(
        (result) => {
          if (result.value) {
            let val = $("#input-field").val();
            if (val === "delete") {
              this.removeItem(item);
            } else {
              alert("please try again you did not type delete.");
            }
          }
        },
        () => {}
      );
  }

  editItem(item) {
    this.scratchDisk = JSON.parse(JSON.stringify(item));
    this.mode = "EDITING";
    if (item.state !== "NEW") {
      item.state = "UPDATE";
    }
    item.editable = true;
  }

  getFormRowID(index) {
    return "editable-row-" + index.toString();
  }

  filterdata() {
    return this.alldata;
  }

  getcolstyle() {
    return {
      width: (90 / this.config.fields.length).toString() + "%",
    };
  }

  updatechanges(callback) {
    if (this.alldata.length > 0) {
      this.processUpdate(0, callback);
    } else {
      this.mode = "PENDING";

      callback();
    }
  }

  processUpdate(index, callback) {
    let type: string = this.alldata[index]["state"];

    if (type === "NEW")
      DataAction.runData(
        this.alldata[index],
        this.config.data.createResource.resourceType,
        this.config.data.createResource.id,
        () => {
          this.alldata[index]["state"] = "";
          if (index < this.alldata.length - 1) {
            this.processUpdate(index + 1, callback);
          } else {
            callback();
          }
        }
      );
    else if (type === "EDIT")
      DataAction.runData(
        this.alldata[index],
        this.config.data.updateResource.resourceType,
        this.config.data.updateResource.id,
        () => {
          this.alldata[index]["state"] = "";
          if (index < this.alldata.length - 1) {
            this.processUpdate(index + 1, callback);
          } else {
            callback();
          }
        }
      );
    else if (type === "ADD" || type === "UPDATE") {
      toastr.warning(
        "Please note you have pending changes in a table section that need to be committed or cancelled before you can save",
        "Warning",
        {
          timeOut: 5000,
          closeButton: true,
          newestOnTop: true,
          positionClass: "toast-bottom-right",
          showEasing: "swing",
          hideEasing: "linear",
          showMethod: "fadeIn",
          hideMethod: "fadeOut",
        }
      );
    } else if (type === "DELETE")
      DataAction.runData(
        this.alldata[index],
        this.config.data.deleteResource.resourceType,
        this.config.data.deleteResource.id,
        () => {
          this.alldata[index]["state"] = "";
          if (index < this.alldata.length - 1) {
            this.processUpdate(index + 1, callback);
          } else {
            callback();
          }
        }
      );
    else if (index < this.alldata.length - 1) {
      this.alldata[index]["state"] = "";
      this.processUpdate(index + 1, callback);
    } else {
      this.mode = "PENDING";
      this.getCurrentDataSet(callback);
    }
  }
}
