import { Grid } from "@syncfusion/ej2-ng-grids";
import {onShowDependentDetail} from "./onShowDependentDetail";
export const createQualifiedRetiredPersonApplicationDependentListGrid = function(
  dataSource: any
)  {
  const cols = [
    {
      field: "FirstName",
      headerText: "First Name"
    },
    {
      field: "LastName",
      headerText: "Last Name"
    },
    {
      field: "Gender",
      headerText: "Gender"
    },
    {
      field: "DateOfBirth",
      headerText: "Date Of Birth"
    },
    {
      field: "CellPhone",
      headerText: "Cell Phone"
    },
    {
      field: "HomePhone",
      headerText: "Home Phone"
    },
    {
      field: "EmailAddress",
      headerText: "Email Address"
    },
    {
      headerText: "",
      width: 120,
      isUnbound: false,
      commands: [
        {
          buttonOption: {
            content: "Details",
            cssClass: "e-flat",
            click: onShowDependentDetail.bind(this)
          }
        },

      ]
    }
  ];
  this.props.dependentGrid = new Grid({
    dataSource,
    width: "100%",
    allowTextWrap: true,
    textWrapSettings: {
      wrapMode: "Content"
    },
    columns: cols
  });
  this.props.dependentGrid.appendTo(`#${this.props.referenceid3}`);
};
