export default `
<div class="row">

    <div class="col-md-6">
    <div class="form-group">
    <label>Finance Email</label>
    <div class="input-group">
    <input
    readOnly="readOnly"
    class="form-control"
    [(ngModel)]="props.currentSelectedModification.OldFinanceEmailAddress"
    />
    </div>
    </div>

    </div>

    <div class="col-md-6">
    <div class="form-group">
    <label>Insurance Comp.</label>
    <div class="input-group">
    <input
    readOnly="readOnly"
    class="form-control"
    [(ngModel)]="props.currentSelectedModification.OldInsuranceCompany"
    />
    </div>
    </div>

    </div>




  </div>
`;
