export const TOURGUIDEMODIFCATION = {
  access: ['admin'],
  formtype: 'tourguidemodification',
  listresources: [
    {
      id: 'controlids',
      type: 'SQL'
    }
  ],
  caption: 'Tour Guide Modifications'
};
