import { Component, Input, AfterViewInit } from '@angular/core';
import guid from '../../../constants/guid';
import validate from '../../../constants/validate';

@Component({
  selector: 'form-body',
  template: `
        <div *ngIf="formdata.formtype !== 'gridView' && formdata.formtype !== 'clientAssignment'">
            <div class="row" *ngFor='let row of formdata.formDefinition.rows'>
                <div *ngFor='let column of row.columns; let df = index' class="{{getColSize(row)}}">
                    <div class='card'
                         *ngIf="column.hideOnAdd !== 'true' || (column.hideOnAdd === 'true' && addmode === false)">
                        <div class='card-title'><span [ngStyle]="{'color':'#'+colors[df]}">{{column.title}}</span></div>
                        <div class='card-body'>

                            <div *ngFor="let field of column.fields; let i = index">
                                <div [ngSwitch]="field.type" *ngIf="showOnlyAdd(field)">
                                    <div *ngSwitchCase="'auth'">
                                        <authentication-control [config]="column.fields[i]"></authentication-control>
                                    </div>

                                    <div *ngSwitchCase="'text'">
                                        <div style="width:100%" *ngIf="getPrimary(field.isPrimary)">
                                            <label style="width:100%">
                                                <span class="ThemeControlled">{{field.label}}</span>
                                                <input parsley [validation]="field.validation"
                                                       [readonly]="editable || field.readonly ? true : null"
                                                       class="form-control" name="{{field.key}}"
                                                       [(ngModel)]="model[field.key]"
                                                       placeholder="{{field.placeholder}}"/>
                                            </label>
                                        </div>
                                        <div style="width:100%" *ngIf="!getPrimary(field.isPrimary)">
                                            <label style="width:100%">
                                                <span class="ThemeControlled">{{field.label}}</span>


                                                <div class="input-group">

                                                    <input parsley [validation]="field.validation"
                                                           [readonly]="editable ? true : null" class="form-control"
                                                           name="{{field.key}}" [(ngModel)]="model[field.key]"
                                                           placeholder="{{field.placeholder}}"/>
                                                    <span class="input-group-addon"
                                                          style="color:#fff!important;background:#92C2E3!important;"
                                                          data-balloon-length="large"
                                                          data-balloon="Please note that this field is both required and cannot be duplicated. Please ensure that you only use the text in this field once per record or you will have issues saving."
                                                          data-balloon-pos="up" id="basic-addon1"><i
                                                            class="mdi mdi-help" style="font-size:14px!important;"></i></span>
                                                </div>


                                            </label>
                                        </div>
                                    </div>

                                    <div *ngSwitchCase="'usercontrol'">
                                    <user-controls [model]="model" [config]="field" [listresources]="formdata.listresources" [updatewall]="updatewall"
                                                 [editable]="editable || field.readonly ? true : null"
                                                 [addmode]="addmode" linkfield="UserId"></user-controls>
                                    </div>


                                    <div *ngSwitchCase="'number'">
                                        <div style="width:100%" *ngIf="getPrimary(field.isPrimary)">
                                            <label style="width:100%">
                                                <span class="ThemeControlled">{{field.label}}</span>
                                                <input type="number" parsley [validation]="field.validation"
                                                       [readonly]="editable ? true : null" class="form-control"
                                                       name="{{field.key}}" [(ngModel)]="model[field.key]"
                                                       placeholder="{{field.placeholder}}"/>
                                            </label>
                                        </div>
                                        <div style="width:100%" *ngIf="!getPrimary(field.isPrimary)">
                                            <label style="width:100%">
                                                <span class="ThemeControlled">{{field.label}}</span>


                                                <div class="input-group">

                                                    <input type="number" parsley [validation]="field.validation"
                                                           [readonly]="editable ? true : null" class="form-control"
                                                           name="{{field.key}}" [(ngModel)]="model[field.key]"
                                                           placeholder="{{field.placeholder}}"/>
                                                    <span class="input-group-addon"
                                                          style="color:#fff!important;background:#92C2E3!important;"
                                                          data-balloon-length="large"
                                                          data-balloon="Please note that this field is both required and cannot be duplicated. Please ensure that you only use the text in this field once per record or you will have issues saving."
                                                          data-balloon-pos="up" id="basic-addon1"><i
                                                            class="mdi mdi-help" style="font-size:14px!important;"></i></span>
                                                </div>


                                            </label>
                                        </div>
                                    </div>

                                    <div *ngSwitchCase="'password'">
                                        <div style="width:100%">
                                            <label style="width:100%">
                                                <span class="ThemeControlled">{{field.label}}</span>
                                                <input parsley [validation]="field.validation"
                                                       [readonly]="editable ? true : null" type="password"
                                                       class="form-control" name="{{field.key}}"
                                                       id="{{getIdentity(field)}}" [(ngModel)]="model[field.key]"
                                                       placeholder="{{field.placeholder}}"/>
                                            </label>
                                        </div>
                                    </div>

                                    <div *ngSwitchCase="'spacer'">
                                        <div style="height:67px;"></div>
                                    </div>

                                    <div *ngSwitchCase="'checkbox'" class="checkbox1">

                                    <label style="width:100%">
                                    <span class="ThemeControlled">{{field.label}}</span>


                                                <input  style="margin-left:20px;padding:5px;"  type="checkbox" id="{{field.key}}" name="{{field.key}}"
                                                       [(ngModel)]="model[field.key]"
                                                       (change)="$event.target.checked ? 1 : 0"/>




                                                       </label>




                                    </div>

                                    <div *ngSwitchCase="'select'">
                                        <select-ds [editable]="editable" [model]="model"
                                                   [listsources]="formdata.listresources" [config]="field"></select-ds>
                                        <input parsley [validation]="field.validation" type="input"
                                               style="display:none;" [(ngModel)]="model[field.key]"/>


                                    </div>

                                    <div *ngSwitchCase="'date'">
                                        <date-picker [model]="model" [config]="field"
                                                     [editable]="editable || field.readonly ? true : null"
                                                     [addmode]="addmode"></date-picker>

                                    </div>

                                    <div *ngSwitchCase="'table'">
                                        <div style="width:100%;position:relative;">
                                            <simple-table-view [formdata]="field"></simple-table-view>
                                        </div>
                                    </div>


                                    <div *ngSwitchCase="'editabletable'">
                                        <div style="width:100%;position:relative;">
                                            <editable-table-view [editable]="editable" [addmode]="addmode"
                                                                 [config]="field" [updatewall]="updatewall"
                                                                 [model]="model"
                                                                 [formdata]="formdata"></editable-table-view>
                                        </div>
                                    </div>




                                  


                                    <div *ngSwitchDefault><input type="hidden"/></div>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>


    `
})
export class FormBodyComponent implements AfterViewInit {
  @Input()
  formdata: any;
  @Input()
  addmode = false;
  @Input()
  editable: boolean;
  @Input()
  refresh: Function;
  @Input()
  updatewall: any;
  colors = [
    '1D8CF7',
    '1EB2A4',
    '6C3AAF',
    '86582E',
    'E00015',
    '4ACB62',
    'FF753A',
    '1D8CF7',
    '4ACB62'
  ];
  @Input()
  model: any = {};
  private readOnly = true;

  showOnlyAdd(item: any) {
    return item.hideOnEdit === 'true' ? this.addmode : true;
  }

  getColSize(row: any) {
    return (
      'cardholder col-md-' + Math.floor(12 / row.columns.length).toString()
    );
  }

  getIdentity(fld: any): string {
    /*if (fld.key !== null && fld.identity !== undefined) {
      return fld.identity;
    }
    return guid.create('identity');*/
    return fld.key + '-id-field-1';
  }

  getPrimaryKey(fld): any {
    return this.model[fld];
  }

  getPrimary(p): boolean {
    return p !== 'true' || p === undefined;
  }

  ngAfterViewInit() {
    const demoform: any = $('#demo-form');
    if (demoform.length > 0) {
      validate.validator = demoform['parsley']();
      if (this.refresh !== null && this.refresh !== undefined) {
        this.refresh();
      }
    }
  }
}
