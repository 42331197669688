export default `
<div class="row">

<div class="col-md-10">
  <div class="form-group">
    <label>Physical Address</label>
    <div class="input-group">
      <input
      [style.background-color]="compare(props.currentSelectedModification.NewAddress,props.currentSelectedModification.OldAddress) ? '#AEF0B7' : '#fff'"
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedModification.NewAddress"
      />
    </div>
  </div>
</div>

<div class="col-md-2">
  <div class="form-group">
    <label>City Name</label>
    <div class="input-group">
      <input
      [style.background-color]="compare(props.currentSelectedModification.NewCityName,props.currentSelectedModification.OldCityName) ? '#AEF0B7' : '#fff'"
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedModification.NewCityName"
      />
    </div>
  </div>
</div>


<div class="col-md-10">
  <div class="form-group">
    <label>Mailing Address</label>
    <div class="input-group">
      <input
      [style.background-color]="compare(props.currentSelectedModification.NewMailingAddress,props.currentSelectedModification.OldMailingAddress) ? '#AEF0B7' : '#fff'"
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedModification.NewMailingAddress"
      />
    </div>
  </div>
</div>

<div class="col-md-2">
  <div class="form-group">
    <label>City Name</label>
    <div class="input-group">
      <input
      [style.background-color]="compare(props.currentSelectedModification.NewMailingCityName,props.currentSelectedModification.OldMailingCityName) ? '#AEF0B7' : '#fff'"
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedModification.NewMailingCityName"
      />
    </div>
  </div>
</div>



<div class="col-md-6">
  <div class="form-group">
    <label>Cell Phone</label>
    <div class="input-group">
      <input
      [style.background-color]="compare(props.currentSelectedModification.NewCellPhone,props.currentSelectedModification.OldCellPhone) ? '#AEF0B7' : '#fff'"
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedModification.NewCellPhone"
      />
    </div>
  </div>
</div>


<div class="col-md-6">
  <div class="form-group">
    <label>Home Phone</label>
    <div class="input-group">
      <input
      [style.background-color]="compare(props.currentSelectedModification.NewHomePhone,props.currentSelectedModification.OldHomePhone) ? '#AEF0B7' : '#fff'"
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedModification.NewHomePhone"
      />
    </div>
  </div>
</div>




<div class="col-md-12">
<div class="form-group">
<label>Email Address</label>
<div class="input-group">
<input
[style.background-color]="compare(props.currentSelectedModification.NewEmailAddress,props.currentSelectedModification.OldEmailAddress) ? '#AEF0B7' : '#fff'"
readOnly="readOnly"
class="form-control"
[(ngModel)]="props.currentSelectedModification.NewEmailAddress"
/>
</div>
</div>

</div>







</div>
`;
