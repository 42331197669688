import {Component, Input} from '@angular/core';


@Component({
    selector: 'theme-render',


    template: `
                       <div [ngSwitch]="checktemplate()">
                               
                                  <div *ngSwitchCase ="'fullpage'"></div>
                                   <div *ngSwitchCase ="'centeredintropage'"><centered-into-page [formdata]="flowShow[inx].Data"></centered-into-page></div>
                                  <div *ngSwitchCase ="'sidebar'"><side-bar-template></side-bar-template></div>                                  
                                    <div *ngSwitchDefault>none</div>
                        
                    </div>
                `
})


export class TemplateRenderComponent {

    @Input() inx:number;
    @Input() flowShow:any;

    checktemplate() {
        return this.flowShow[this.inx] !== null && this.flowShow[this.inx] !== undefined ? this.flowShow[this.inx].template : "none";
    }

}

