import guid from "../../../../../..//app/constants/guid";
import { UserStore } from "../../../../../../app/stores/store.user";

export default {
  data: [],
  postdata: {
    BeginDate: "",
    EndDate: ""
  },
  postDataComplete: {
    TourOperatorApplicationRecordNumber: "",
    CheckpointName: "",
    TourOperatorApplicationCheckpointRecordNumber: "",
    CheckpointDate: "",
    CompletedBy: UserStore.getUserName(),
    CheckpointComments: ""
  },
  postDataProcess: {
    TourOperatorApplicationRecordNumber: "",
    ExpirationDate: "",
    LicenseDate: "",
    LicenseNumber: "",
    UserName: UserStore.getUserName(),
    UserToken: UserStore.getUserDetails().token
  },
  selectedTourOperator: "",
  currentSelectedApplication: {},
  grid: null,
  _onChange: null,
  referenceid1: guid.create("syncfusion1"),
  referenceid8: guid.create("syncfusion8"),
  referenceid: guid.create("syncfusion"),
  referenceid2: guid.create("syncfusion2"),
  referenceid3: guid.create("syncfusion3"),
  referenceid4: guid.create("syncfusion4"),
  referenceid5: guid.create("syncfusion5"),
  referenceid6: guid.create("syncfusion6"),
  referenceid7: guid.create("syncfusion7"),
  completed: [],
  currentId: "",
  completeGridTrack: null,
  completeGrid:null
};
