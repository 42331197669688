import viewModifications from "./viewModification";
import completeSection from "./complete";
import processSection from "./process";
import approvalSection from "./approval";
import documentSection from "./documents";


export default `
${viewModifications}
${completeSection}
${processSection}
${approvalSection}
${documentSection}

<div class="editorActions" style="right:10px;"></div>
<div
  style="position:absolute;top: 10px;left: 20px;right: 20px;bottom:10px; overflow:auto;"
  class="clientassign"
>
<div class="row" style="margin:50px 0px 0px 25px">
<div class="form-group" style="width:300px;margin:20px;">
  <label for="sel1">Control Id:</label>

  <searchable
    [placeholder]="'Tour Operator'"
    [editable]="true"
    [onChange]="_onChange"
    [model]="props.postdata"
    name="IdNumber"
    [(data)]="formdata.listresources[0]['controlids']"
    value="IdNumber"
    text="ControlIdName"
    [displays]="['ControlId']"
    theme="formSearch"
  ></searchable>
</div>

<div class="col-md-3" style="margin-top:15px;">
  <button (click)="showModificationsList()" class="btn btn-default">
    Show Modifications
  </button>
</div>
</div>

  <div style="margin:40px;"><div id="{{ props.referenceid }}"></div></div>
</div>

`
