import {Directive, ElementRef, Inject, Output, EventEmitter} from '@angular/core';

@Directive({
    selector: "[sortable]"
})
export class sortable {

    @Output() stopSort:any = new EventEmitter();
    @Output() connectWith: string = "";
    @Output() handle: string = "";

    private el: HTMLElement = null;
    private index:number = 0;
    private start_pos:number = 0;

    constructor(@Inject(ElementRef) elementRef: ElementRef) {
        this.el = elementRef.nativeElement;
    }

    ngAfterViewInit() {

        $(this.el)["sortable"]({
            connectWith: this.connectWith,
            start: (event, ui) => {
                this.start_pos = ui.item.index();
                ui.item.data('start_pos', this.start_pos);
            },

            stop: (event, ui) => {
                this.index = ui.item.index();
                this.start_pos = ui.item.data('start_pos');
                if (this.start_pos < this.index) {

                    this.stopSort.emit({start: this.start_pos, newpos: this.index});
                } else {

                    this.stopSort.emit({start: this.start_pos, newpos: this.index});
                }

            },
            placeholder: "ui-state-highlight",
            handle: this.handle
        });

        $(this.el)["disableSelection"]();
    }


}