import { FormStore } from "./store.form";
import swal from "sweetalert2";
import * as toastr from "toastr";
import lockr from "lockr";
class TrackingStoreClass {
  private _currentlyTracking: any = null;
  private _recordEditMessage = "";

  private _isEditmode = false;

  constructor() {}

  public setWhereIAm(data: any, locationtype: string, formid: string) {
    lockr.set("trackingdata", {
      data: data,
      locationtype: locationtype,
      formid: formid,
    });
  }

  public getWhereIWas() {
    return lockr.get("trackingdata");
  }

  public confirmDelete(callback, data) {
    this._isEditmode = false;
    let frm: any = FormStore.getCurrentForm();
    swal
      .fire({
        title: "Confirm Delete",
        icon: "error",
        html:
          '<p>You are about to delete this item from the database. Type the word <span style="color:red">delete</span> below and click continue to confirm removal.</p><input id="input-field" class="form-control" style="background:#ffffff!important;color:red!important;"></p>',
        showCancelButton: true,
        confirmButtonColor: "#FCB022",
        confirmButtonText: "Continue",
        cancelButtonText: "Cancel",
      })
      .then(
        (result) => {
          if (result.value) {
            const val = $("#input-field").val();
            if (val === "delete") {
              toastr.success(
                "The Item has been moved to the recycling bin and will no longer be visible by users to modify. This Can Be restored by admin at a later time. ",
                "Item Removed",
                {
                  timeOut: 5000,
                  closeButton: true,
                  newestOnTop: true,
                  positionClass: "toast-bottom-right",
                  showEasing: "swing",
                  hideEasing: "linear",
                  showMethod: "fadeIn",
                  hideMethod: "fadeOut",
                }
              );
              callback(true);
            } else {
              alert("please try again you did not type delete.");
            }
          }
        },
        () => {
          callback(false);
        }
      );
  }

  public endEdit() {
    this._isEditmode = false;
  }

  public closeRecord(callback, isClosing) {

    if (this._isEditmode === true) {
      let frm: any = FormStore.getCurrentForm();

      if (frm.concurencyTrackingEnabled === true) {
        let message = frm.concurencyMessage.message1;

        if (isClosing) {
          message = frm.concurencyMessage.message2;
        }

        swal
          .fire({
            title: frm.concurencyMessage.title,
            text: message,
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#FCB022",
            confirmButtonText: "Continue",
            cancelButtonText: "Cancel",
          })
          .then(
            (result) => {
              if (result.value) {
                this._isEditmode = false;
                callback(true);
              }
            },
            () => {
              callback(false);
            }
          );
      } else {
        this._isEditmode = false;
        callback(true);
      }
    } else {
      this._isEditmode = false;
      callback(true);
    }
  }

  public setOpen() {
    this._isEditmode = true;
  }

  public setItemTracmed(item: any) {
    this._currentlyTracking = item;
  }

  public updateHistory() {}
}

export var TrackingStore = new TrackingStoreClass();
