import { closest } from "@syncfusion/ej2-base";
import { fit_modal_body } from "./fitmodalbody";
import { showDocuments } from "./showDocuments";
import { createDocumentGrid } from "./createDocumentGrid";

export const onDocument = function(args) {
  const rowObj = this.props.grid.getRowObjectFromUID(
    closest(args.target, ".e-row").getAttribute("data-uid")
  );
  const data: any = rowObj.data;
    const _showDocuments  = showDocuments.bind(this);
    const _createDocumentGrid = createDocumentGrid.bind(this);
  this.props.selectedTourOperator = data.OldTourOperatorName;
  _showDocuments(
    data.TourOperatorModificationRequestNumber,
    (response: any) => {
      this.props.currentSelectedModification = response;
      this.modalService
        .open(this.document, { centered: true, size: "lg" })
        .result.then(
          result => {
            // this.closeResult = `Closed with: ${result}`;
          },
          reason => {
            // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
      setTimeout(() => {
        _createDocumentGrid(response);
      }, 100);
      setTimeout(() => {
        fit_modal_body($(".modal"));
      }, 100);
    }
  );

};
