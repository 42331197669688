export const SETSTATUS = {
  access: ['admin'],
  formtype: 'multiaction',
  listresources: [{ type: 'SQL', id: 'ViewUserListing' }],
  caption: 'Set Status',
  datasource: 'ViewUserListing',
  actionLabel: 'Change Selected',
  action: { type: 'SQL', id: 'ToggleStatus' },
  fields: [
    {
      key: 'FirstName',
      type: 'text',
      label: 'First Name',
      width: '20%'
    },
    {
      key: 'LastName',
      type: 'text',
      label: 'Last Name',
      width: '20%'
    },
    {
      key: 'EmailAddress',
      type: 'text',
      label: 'Email Address',
      width: '40%'
    },
    {
      key: 'ControlId',
      type: 'text',
      label: 'Control Id',
      width: '10%'
    },
    {
      key: 'UserActive',
      type: 'text',
      label: 'Active',
      width: '10%'
    }
  ]
};
