import dispatcher from "../dispatcher/dispatcher.app";
import constants from "../constants/constants";
import formdefs from "../constants/formdefs";
import { RESTLoaderAuthOnly } from "../dispatcher/dispatcher.rest.noerror";

export default class FlowControl {
  static startFlowControl() {    
    setTimeout(() => {
      dispatcher.dispatch({
        action: constants.FlowControl.FLOW_STATE_INITIATED,
        data: {
          routeDefinitions: [
            {
              name: "login",
              definition: {
                template: "centeredintropage",
                requiresAuthenticated: "NO",
                form: "authentication/login",
                formtype: "form",
                SSOBypass: "YES"
              }
            },
            {
              name: "admindashboard",
              definition: {
                template: "sidebar",
                requiresAuthenticated: "YES",
                authenticationRoles: ["ADMIN"],
                form: "startup/blank",
                formtype: "form"
              }
            }
          ],
          routeDetails: [
            {
              kind: "point",
              route: "login"
            },
            {
              kind: "choice",
              chooseBy: "usertype",
              routes: [
                {
                  value: "ADMIN",
                  route: "admindashboard"
                }
              ]
            }
          ]
        }
      });
    }, 10);

    // });
  }

  static loadFlowResource(formid: string, type: string) {
    setTimeout(() => {
      if (formid === "authentication/login") {
        setTimeout(() => {
          dispatcher.dispatch({
            action: constants.FlowControl.FLOW_STATE_CHANGED,
            data: formdefs.FORMDEFS.AUTHENTICATION
          });
        });
      } else if (formid === "startup/blank") {
        const dat = JSON.parse(JSON.stringify(formdefs.FORMDEFS.BLANK));

        setTimeout(() => {
          dispatcher.dispatch({
            action: constants.FlowControl.FLOW_STATE_CHANGED,
            data: dat
          });
        });
      }
    }, 30);

    /* if(type==="form") {
            RESTLoader.processRequest(true, 'forms/' + formid, null, 'GET', function (response) {
                dispatcher.dispatch({
                        action: constants.FlowControl.FLOW_STATE_CHANGED,
                        data: response
                    }
                );
            });
        }
        else
        {
            RESTLoader.processRequest(true, 'dashboard/' + formid, null, 'GET', function (response) {



                dispatcher.dispatch({
                        action: constants.DashboardActions.LAUNCH_DASHBOARD,
                        data: response
                    }
                );

                dispatcher.dispatch({
                        action: constants.FlowControl.FLOW_STATE_CHANGED,
                        data: response
                    }
                );
            });
        }*/
  }
}
