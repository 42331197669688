import { AfterViewInit, Component, ViewChild, OnInit, Input, OnChanges } from '@angular/core';
import DataAction from '../../../actions/action.data';
import { DetailRowService, GridModel, FilterSettingsModel, RowSelectEventArgs, GridComponent } from '@syncfusion/ej2-ng-grids';
import { PageSettingsModel } from '@syncfusion/ej2-ng-grids';
import { ToolbarItems } from '@syncfusion/ej2-ng-grids';
import axios from "axios";
import { UserStore } from "src/app/stores/store.user";
import { closest } from "@syncfusion/ej2-base";

import guid from '../../../constants/guid';
import {
  Grid,
  Group,
  Filter,
  Sort,
  ExcelExport,
  Page,
  Toolbar,
  PdfExport,
  Edit
} from '@syncfusion/ej2-grids';

import * as moment from 'moment';
Grid.Inject(Filter, Group, Sort, Page, ExcelExport, Toolbar, PdfExport);
@Component({
  selector: "hotel-accommodation-tax-return",
  template: `
  <div class="editorActions" style="right:10px;">          
  </div>
  <div style="position:absolute;top: 10px;left: 20px;right: 20px;bottom:10px; overflow:auto;" class="clientassign">
  <table style = "width: 100%">
    <tr>
      <td>
        <div class="form-group" style="width:100%;padding:20px;">
          <date-picker
            [model]="postdata"
            [config]="{ key: 'BeginDate', label: 'Begin Date' }"
            [editable]="false"
            [addmode]="false">
          </date-picker>
        </div>
      </td>
      <td>
        <div class="form-group" style="width:100%;padding:20px;">
          <date-picker
            [model]="postdata"
            [config]="{ key: 'EndDate', label: 'End Date' }"
            [editable]="false"
            [addmode]="false">
          </date-picker>
        </div>
      </td>
      <td>
        <div class="form-group" style="width:100%;padding:20px;padding-top: 10px;">
            <label for="sel1" style="font-weight:300">Control Id:</label> 
            <searchable 
              [placeholder]="'Control Id'" 
              [editable]="true" 
              [onChange]="_onChange"
              [model]="postdata" 
              name="ControlId" 
              [(data)]="formdata.listresources[0]['controlids']" 
              value="ControlId" 
              text="Name" 
              [displays]="['ControlIdName']" 
              theme="formSearch">
            </searchable>            
        </div>   
      </td>
      <td>
        <button 
          (click)="showHotelAccommodationTaxReturns()" 
          class="btn btn-default"          
          style = "margin-top: 6px;">
          Show MATR's
        </button>
      </td>
    </tr>
  </table>      
      <div style="margin:40px;">
     
      <div id='{{referenceid}}'>
      </div>
	  
	  </div> 
	  
	  <div style="margin:40px;">
    <div class="col-md-12">  
    <div *ngIf="selectedrecords !== undefined && selectedrecords !== null" class="col-md-12">
    <h3>Hotel Accommodation Tax Return Details</h3>
     <div class="col-md-4">
      <table class="table">
        <tbody>
          <tr>
              <th style="font-weight:bolder">Reporting Year</th>
              <td>{{selectedrecords.ReportingYear}}</td>
          </tr>
          <tr>
              <th style="font-weight:bolder">Reporting Month</th>
              <td>{{selectedrecords.ReportingMonth}}</td>
          </tr>
          <tr>
              <th style="font-weight:bolder">Revenue</th>
              <td>{{selectedrecords.Revenue}}</td>
          </tr>
          <tr>
              <th style="font-weight:bolder">Tax Exempted Revenue</th>
              <td>{{selectedrecords.TaxExemptedRevenue}}</td>
          </tr>
          <tr>
              <th style="font-weight:bolder">Tax Rate</th>
              <td>{{selectedrecords.AccommodationTaxRate}}</td>
          </tr>
          <tr>
              <th style="font-weight:bolder">Tax Amount</th>
              <td>{{selectedrecords.AccommodationTaxAmount}}</td>
          </tr>
          <tr>
              <th style="font-weight:bolder">Due Date</th>
              <td>{{formatDate(selectedrecords.TaxDueDate)}}</td>
          </tr>
          <tr>
              <th style="font-weight:bolder">Submission Date</th>
              <td>{{formatDate(selectedrecords.SubmissionDate)}}</td>
          </tr>
         </tbody>
       </table>
     </div>
     <div class="col-md-4">
         <table class="table">
         <tbody> 
          <tr>
              <th style="font-weight:bolder">Submission Source</th>
              <td>{{selectedrecords.SubmissionSource}}</td>
          </tr>
          <tr>
              <th style="font-weight:bolder">Beginning GRF No.</th>
              <td>{{selectedrecords.BeginningGRFSerialNumber}}</td>
          </tr>
          <tr>
              <th style="font-weight:bolder">Ending GRF No.</th>
              <td>{{selectedrecords.EndingGRFSerialNumber}}</td>
          </tr>
          <tr>
              <th style="font-weight:bolder">Number of Rooms</th>
              <td>{{selectedrecords.NumberOfRooms}}</td>
          </tr>
          <tr>
              <th style="font-weight:bolder">Number of Units</th>
              <td>{{selectedrecords.NumberOfUnits}}</td>
          </tr>
          <tr>
            <th style="font-weight:bolder">Number of Beds</th>
            <td>{{selectedrecords.NumberOfBeds}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-md-4">
      <table class="table">
        <tbody> 
          <tr>
            <th style="font-weight:bolder">Sold Rooms (Local)</th>
            <td>{{selectedrecords.RoomsSoldBelizeans}}</td>
          </tr>
          <tr>
            <th style="font-weight:bolder">Sold Rooms (Foreign)</th>
            <td>{{selectedrecords.RoomsSoldNonBelizeans}}</td>
          </tr>
          <tr>
            <th style="font-weight:bolder">Comp. Rooms (Local)</th>
            <td>{{selectedrecords.ComplimentaryRoomsBelizeans}}</td>
          </tr>
          <tr>
            <th style="font-weight:bolder">Comp Rooms (Foreign)</th>
            <td>{{selectedrecords.ComplimentaryRoomsNonBelizeans}}</td>
          </tr>
          <tr>
            <th style="font-weight:bolder">Sold Units (Local)</th>
            <td>{{selectedrecords.UnitsSoldBelizeans}}</td>
          </tr>
          <tr>
            <th style="font-weight:bolder">Sold Units (Foreign)</th>
            <td>{{selectedrecords.UnitsSoldNonBelizeans}}</td>
          </tr>
          <tr>
            <th style="font-weight:bolder">Comp. Units (Local)</th>
            <td>{{selectedrecords.ComplimentaryUnitsBelizeans}}</td>
          </tr>
          <tr>
            <th style="font-weight:bolder">Comp Units (Foreign)</th>
            <td>{{selectedrecords.ComplimentaryUnitsNonBelizeans}}</td>
          </tr>
          <tr>
            <th style="font-weight:bolder">Sold Beds (Local)</th>
            <td>{{selectedrecords.BedsSoldBelizeans}}</td>
          </tr>
          <tr>
            <th style="font-weight:bolder">Sold Beds (Foreign)</th>
            <td>{{selectedrecords.BedsSoldNonBelizeans}}</td>
          </tr>
          <tr>
            <th style="font-weight:bolder">Comp. Beds (Local)</th>
            <td>{{selectedrecords.ComplimentaryBedsBelizeans}}</td>
          </tr>
          <tr>
            <th style="font-weight:bolder">Comp Beds (Foreign)</th>
            <td>{{selectedrecords.ComplimentaryBedsNonBelizeans}}</td>
          </tr>
        </tbody>
      </table>
    </div>
   
   </div>
   
   </div>
     
        
   
  </div>`,
  providers: [DetailRowService]
})

export class HotelAccommodationTaxReturn implements AfterViewInit {
  public data: Object[];
  public toolbarOptions: ToolbarItems[];
  public pageSettings: PageSettingsModel;
  public postdata = {
    BeginDate: "",
    EndDate: "",
    ControlId: ""
  };
  public filterOptions: FilterSettingsModel;
  public selectedrecords: any;
  public completeGrid: Grid = null;
  public referenceid = guid.create('syncfusion');
  public grid: Grid = null;

  _onChange = null;


  constructor() {
    this.filterOptions = {
      type: 'Menu'
    };

    this._onChange = changed => {
      this.postdata.ControlId = changed.ControlId;
    };
  }

  formatDate(value: any) {
    return moment(value).format('LL');
  }

  rowSelected(args: RowSelectEventArgs) {

    this.selectedrecords = this.grid.getSelectedRecords()[0];  // Get the selected records.

  }

  @Input() formdata: any;
  ngOnInit(): void {
    this.pageSettings = { pageSize: 6 };
    this.toolbarOptions = ['Search'];
  }
  controlids = [];


  ngAfterViewInit() {

    const cols = [
      {
        field: 'ReportingYear',
        headerText: 'Reporting Year'
      },
      {
        field: 'ReportingMonth',
        headerText: 'Reporting Month'
      },
      {
        field: 'Revenue',
        headerText: 'Revenue'
      },
      {
        field: 'TaxExemptedRevenue',
        headerText: 'Exempted Revenue'
      },
      {
        field: 'AccommodationTaxAmount',
        headerText: 'Accommodation Tax'
      },
      {
        field: 'SubmissionSource',
        headerText: 'Submission Source'
      },
      {
        field: 'SubmissionDate',
        headerText: 'Submission Date',
        type: 'date',
        format: 'yMd',
        filter: { type: 'Menu' }
      }
    ];

    this.grid = new Grid({
      dataSource: this.data,
      allowPaging: true,
      allowGrouping: true,
      allowFiltering: true,
      allowSorting: true,
      filterSettings: { type: 'Excel' },
      allowPdfExport: true,
      allowExcelExport: true,
      toolbar: [
        'ExcelExport',
        'PdfExport'
      ],
      columns: cols,
      rowSelected: this.rowSelected.bind(this)
    });
    this.grid.appendTo(`#${this.referenceid}`);
    this.grid.toolbarClick = (args: Object) => {
      if (args['item'].id === `${this.referenceid}_excelexport`) {
        this.grid.excelExport();
      }
      if (args['item'].id === `${this.referenceid}_pdfexport`) {
        this.grid.pdfExport();
      }
    };
  }


  showHotelAccommodationTaxReturns() {
    axios
      .post("/api/GetAccommodationTaxReturnList", this.postdata, {
        headers: {
          "user-token": UserStore.getUserDetails().token,
          "site-token": "7pdvAXwKchuAQGw7QBLnSHS"
        }
      })
      .then(response => {
        this.selectedrecords = null;
        this.grid.dataSource = response.data.TaxReturns;
        this.grid.refresh();
      });
  }
}
