import axios from "axios";
import { UserStore } from "src/app/stores/store.user";
export const  showDependentDetail = (applicationId: number,QualifiedRetiredPersonModificationRequestDependentRecordNumber:number, callback) => {
  const showPostData = {
    QualifiedRetiredPersonModificationRequestNumber: applicationId,
    QualifiedRetiredPersonModificationRequestDependentRecordNumber:QualifiedRetiredPersonModificationRequestDependentRecordNumber
  };
  axios
    .post("/GetQualifiedRetiredPersonModificationRequestDependent", showPostData, {
      headers: {
        "user-token": UserStore.getUserDetails().token,
        "site-token": "7pdvAXwKchuAQGw7QBLnSHS",
        "user-id": 1,
        "control-id": 1
      }
    })
    .then((response: any) => {
      callback(response.data.QualifiedRetiredPersonModificationRequestDependent);
    });
}
