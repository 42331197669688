import { UserStore } from "src/app/stores/store.user";
import { closest } from "@syncfusion/ej2-base";
import { fit_modal_body } from "./fitmodalbody";
import { showDependentDetail } from "./showDependentDetail";

export const onShowDependentDetail = function(typeId,data) {

  this.props.selectedDependent = typeId==="old"?`${data.OldFullName}`:`${data.NewFullName}`;



  showDependentDetail(
    data.QualifiedRetiredPersonModificationRequestNumber,
    data.QualifiedRetiredPersonModificationRequestDependentRecordNumber,
    (response: any) => {
     this.props.currentSelectedDependent = response;
      this.completed = response;
      this.modalService
        .open(typeId==="old"?this.olddependent:this.moddependent, { centered: true, size: "lg" })
        .result.then(
          result => {

          },
          reason => {
            // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
      setTimeout(() => {
        fit_modal_body($(".modal"));
      }, 100);
    }
  );

};
