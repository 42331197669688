import * as React from 'react';
import * as _ from 'underscore';


export interface Props { fulldata: any,
    onClose: any
}

export default class advanceSearchReact extends React.Component<Props, {}> {


    config: any;
    isMulti = false;
    data: any = [];
    model: any = "";
    theme = "";
    name: string = null;
    value = "";
    text = "";
    onChange: any;
    displays: any;
    editable = true;
    listener2: any = null;
    listener1: any = null;
    lastlen = -1;
    currentIndex = 0;
    currentSearch: any = "";
    currentData: any = [];
    ontag = null;

  
    componentWillMount() {

        const data = this.props.fulldata;
        this.data = [];
        this.config = data.config;
        this.model = data.model;
        this.theme = data.theme;
        this.name = data.name;
        this.value = data.value;
        this.text = data.text;
        this.onChange = data.onChange;
        this.displays = data.displays;
        this.editable = data.editable;
        this.data = data.data;
        this.ontag = data.tagger;

        if (data.isMulti !== undefined) {
            this.isMulti = data.isMulti;
        }

        let k = 0;


        if (this.model !== undefined) {
            if (this.config !== null && this.config !== undefined) {
                if (this.model[this.config.key] !== undefined && this.model[this.config.key] !== null && this.model[this.config.key] !== "") {
                    k = this.data.map((x) => {
                        return x[this.value];
                    }).indexOf(this.model[this.config.key]);
                }
            } else if (this.model[this.value] !== undefined && this.model[this.value] !== null && this.model[this.value] !== "") {
                k = this.data.map((x) => {
                    return x[this.value];
                }).indexOf(this.model[this.value]);
            }
        }

        this.currentIndex = k;


        const me = this;

        if (this.theme !== "formSearch") {
            this.listener1 = (event) => {

                if (this.theme !== "formSearch") {

                    if (($(event.target).closest("#" + this.name).length === 0)) {
                        if (($(event.target).closest(".dropdown-list").length === 0)) {
                            this.props.onClose();

                        }
                    }
                }
            };
        }


        this.listener2 = (e) => {
            if (e.which === 13) {
                this.autoPick();
            }
            if (e.which === 27) {
                me.props.onClose();
            } else if (e.which === 38) {
                if (this.currentIndex > 0) {
                    this.currentIndex--;
                    this.forceUpdate();
                }
            } else if (e.which === 40) {
                if (this.currentIndex <= this.filterdatanochange().length - 1) {
                    this.currentIndex++;
                    this.forceUpdate();
                }
            }
        }

    }


    getActiveState(i) {
        if (i === this.currentIndex) {
            return "resultlistitem resultlistitemactive";
        } else {
            return "resultlistitem";
        }
    }

    changetext() {
        this.currentSearch = $(this.refs["currentSearch"]).val();
        this.forceUpdate();
    }

    autoPick() {
        const sel = this.filterdatanochange();
        if (sel.length === 0) {
            return;
        }
        if (sel.length > 0) {
            if (this.onChange !== null && this.onChange !== undefined) {
                this.onChange(sel[this.currentIndex]);
            }
            this.props.onClose();
        }

    }

    itemSelected(item: any) {


        if (this.onChange !== null && this.onChange !== undefined) {
            this.onChange(item);
        }

        if (this.ontag !== null && this.ontag !== undefined) {
            this.ontag(item);
        }

        this.props.onClose();
    }

    boldpartial(text: string) {
        if (this.currentSearch.trim().length === 0) {
            return text;
        } else {
            const regex = new RegExp(this.currentSearch, "gi");
            return text.replace(regex, function (matched) {
                return '<span class="activehigh">' + matched + '</span>';
            });
        }
    }

    filterdata() {

        if (this.currentSearch.trim().length > 0) {
            const results = _.filter(JSON.parse(JSON.stringify(this.data)), (ctx) => {
                return ctx[this.text].toLowerCase().indexOf(this.currentSearch.toLocaleLowerCase()) >= 0;
            });
            if (this.lastlen !== results.length) {
                this.lastlen = results.length;
                // this.currentIndex = 0;
            }

            return results;
        } else {
            if (this.lastlen !== this.data.length) {
                this.lastlen = this.data.length;
                //  this.currentIndex = 0;
            }
            return JSON.parse(JSON.stringify(this.data));
        }
    }

    filterdatanochange() {

        if (this.currentSearch.trim().length > 0) {
            return _.filter(JSON.parse(JSON.stringify(this.data)), (ctx) => {
                return ctx[this.text].toLowerCase().indexOf(this.currentSearch.toLocaleLowerCase()) >= 0;
            });
        } else {
            return JSON.parse(JSON.stringify(this.data));
        }
    }

    componentWillUnmount() {
        $(document).off("keyup", this.listener2);
        if (this.theme !== "formSearch") {
            $(window).off("click", this.listener1);
            $(window).off("touchend", this.listener1);
        }
    }

    componentDidMount() {

        const dl = $(".dropdown-list");

        dl.css("display", "block").parent().css("display", "block");


        if (this.theme !== "formSearch") {
            dl.css("opacity", "1").css("bottom", "40px");

            dl.find(".form-control").focus();


        } else {
            dl.css("opacity", "1");
            dl.find(".form-control").focus();
        }


        if (this.theme !== "formSearch") {

            $(window).on("click", this.listener1);
            $(window).on("touchend", this.listener1);
        }


        $(document).on("keyup", this.listener2);


    }

    closesearch() {
        this.props.onClose();
    }


    getTheme() {
        if (!this.editable) {
            if (this.theme === "formSearch") {
                return this.theme + " readonly";
            } else {
                return this.theme + " readonly1";
            }

        } else {
            return this.theme;
        }
    }


    render() {


        let i = -1;
        this.currentData = this.filterdata();

        let alllist = this.currentData;

        let morethan: any = {"display": "none"};

        if (this.currentData.length > 15) {
            alllist = this.currentData.splice(0, 15);
            morethan = {
                "display": "inherit",
                "padding": "5px 8px",
                "color": "#0171C5",
                fontWeight: "700"
            }
        }

        let dispsearch = {"display": "table"};
        let topsearch = {"top": "34px"};
        let filterdata = (
            <li className="nodatacheck"><span>No Results Found</span></li>
        );


        if (alllist.length > 0) {
            filterdata = alllist.map((lst) => {
                i++;
                let l = 0;

                let deps1 = (<span/>);


                if (this.displays !== null && this.displays !== undefined) {
                    const deps = this.displays.map((dsp) => {
                        l++;
                        return (
                            <li key={i.toString() + l.toString()} className="resultdispitem">
                                {lst[dsp.toString()]}
                            </li>
                        )
                    });


                    deps1 = (<ul className="resultdisp">
                        {deps}
                    </ul>)
                }


                return (
                    <li key={i.toString()} onClick={this.itemSelected.bind(this, lst)}
                        className={this.getActiveState(i)}>

                <span dangerouslySetInnerHTML={{__html: this.boldpartial(lst[this.text])}}>
 </span>
                        <span>

     {deps1}

 </span>


                    </li>

                )
            });
        } else {
            if (this.data.length === 0) {
                dispsearch = {"display": "none"};
                topsearch = {"top": "0px"};
            }
        }


        let adj = {};




        return (

            <div style={{'width': '100%'}} className={this.getTheme()}>

                <div className="dropdown-list" style={adj}>
                    <div onClick={this.closesearch.bind(this)} className="closebutton">

                        <table cellPadding="0" cellSpacing="0">
                            <tbody>
                            <tr>
                                <td style={{"textAlign": "center"}}><i className="mdi mdi-close-circle-outline"/></td>
                            </tr>
                            <tr>
                                <td style={{"textAlign": "center"}}><span
                                    style={{"fontSize": "10px", "textAlign": "center", "color": "#fff"}}>close</span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="dropdown-list-inner">
                        <div>


                            <div className="input-group" style={dispsearch}>
                                <span className="input-group-addon"><i className="mdi mdi-magnify"/></span>
                                <input type="text" className="form-control" onChange={this.changetext.bind(this)}
                                       placeholder="Search" ref="currentSearch"
                                />

                            </div>


                            <div className="results" style={topsearch}>

                                <ul className="resultlist">
                                    {filterdata}
                                    <li style={morethan}>
                                        More Than 15 Items To Display Please Filter List
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </div>



        )
    }
}
