import { Component, AfterViewInit, ViewChild } from "@angular/core";
import * as _ from "underscore";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import ui from './tourGuideApplicationsections';
import constants from './tourGuideApplicationsections/constants';
import {init} from './tourGuideApplicationsections/actions/setup';
import guid from "../../../../app/constants/guid";
import {showApplications}  from "./tourGuideApplicationsections/actions/showApplications";
import { processDetails } from "./tourGuideApplicationsections/actions/processdetails";
@Component({
  selector: "view-tourguide-application-request",
  template: ui
})
export class ViewTourGuideApplicationRequest implements AfterViewInit {
  props:any = null;
  guid:Function=null;
  showApplications:Function=null;
  processDetails:Function=null;
  @ViewChild("content", {static: false})
  content;
  @ViewChild("process", {static: false})
  process;
  @ViewChild("complete", {static: false})
  complete;
  @ViewChild("image", {static: false})
  image;
  @ViewChild("document", {static: false})
  document;
  @ViewChild("approvecomplete", {static: false})
  approvecomplete;
  ngAfterViewInit() {
    const _init = init.bind(this);
    _init();
  }

  constructor(private modalService: NgbModal) {
    this.props = _.clone(constants);
    this.guid=guid.create;
    this.showApplications=showApplications.bind(this);
    this.processDetails=processDetails.bind(this);
  }
}
