import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import * as _ from 'underscore';
import DataAction from '../../../actions/action.data';
import * as moment from 'moment';
import * as numeral from 'numeral';
import { isNull } from 'util';

import {
  DetailRowService,
  GridModel,
  FilterSettingsModel,
  RowSelectEventArgs,
  GridComponent,
  IRow,
  Column
} from '@syncfusion/ej2-ng-grids';
import { closest } from '@syncfusion/ej2-base';
import { PageSettingsModel } from '@syncfusion/ej2-ng-grids';
import { ToolbarItems } from '@syncfusion/ej2-ng-grids';
import guid from '../../../constants/guid';
import {
  Grid,
  Group,
  Filter,
  Sort,
  ExcelExport,
  CommandColumn,
  Page,
  Toolbar,
  PdfExport,
  Edit
} from '@syncfusion/ej2-grids';

Grid.Inject(
  Filter,
  Group,
  Sort,
  Page,
  ExcelExport,
  Toolbar,
  PdfExport,
  CommandColumn
);
@Component({
  selector: 'table-view',
  template: `
        <div class="cardfull">
            <div class="card-title">
                <span [ngStyle]="{'color':'#'+colors[0]}">{{formdata.formDefinition.rows[0].columns[0].title}}</span>
            </div>
            <div class="card-body">

                  <div id='{{referenceid}}'>
                  </div>




            </div>



        </div>
    `
})
export class TableViewComponent implements OnInit, AfterViewInit {
  @Input()
  formdata: any;

  public referenceid = guid.create('syncfusion');
  public grid: Grid = null;


  ngOnInit() {
    if (!isNull(this.formdata.listresources)) {
      this.formdata.listresources.map(res => {
        if (res.id === this.formdata.startResourceId) {
         this.grid.dataSource = res[res.id];
         this.grid.refresh();
        }
      });
    }
  }

  ngAfterViewInit() {
    const cols = [];
    this.formdata.fields.map(column => {
      if (column.type !== 'action') {
        cols.push({
          field: column.key,
          headerText: column.label,
          type: column.type === 'test' ? 'string' : column.type
        });
      }
    });

    let actionCol = {
        headerText: 'Actions',
        width: 200,
        isUnbound: false,
        commands: []
      };


    this.formdata.fields.map(column => {
      if (column.type === 'action') {
        actionCol.commands.push(


              {
                buttonOption: {
                  content: 'Edit',
                  cssClass: 'e-flat',
                  click: (args) => {

                    const rowObj = this.grid.getRowObjectFromUID(
                      closest(args.target, '.e-row').getAttribute('data-uid')
                    );
                    const data: any = rowObj.data;

                    switch (column.action.actiontype) {
                      case 'execute':
                        {
                          DataAction.runData(data, column.action.typeid, column.action.model, () => {});
                        }
                        break;
                    }
                  }
                }
              }



        );
      }
    });

    if(actionCol.commands.length>0)
    {
      cols.push(actionCol);
    }

    /*const cols = [
      {
        field: 'MessageTitle',
        headerText: 'Title'
      },
      {
        field: 'EffectiveDate',
        headerText: 'Effective Date',
        type: 'date',
        format: 'yMd',
        filter: { type: 'Menu' }
      },
      {
        field: 'ExpirationDate',
        headerText: 'Expiration Date',
        type: 'date',
        format: 'yMd',
        filter: { type: 'Menu' }
      },
      {
        headerText: 'Commands',
        width: 200,
        isUnbound: false,
        commands: [
          {
            buttonOption: {
              content: 'Edit',
              cssClass: 'e-flat',
              click: onClick
            }
          },
          {
            buttonOption: {
              content: 'Send',
              cssClass: 'e-flat',
              click: onSend
            }
          }
        ]
      }
    ];*/

    this.grid = new Grid({
      dataSource: [],
      allowPaging: true,
      allowGrouping: true,
      allowFiltering: true,
      allowSorting: true,
      filterSettings: { type: 'Excel' },
      allowPdfExport: true,
      allowExcelExport: true,
      toolbar: ['ExcelExport', 'PdfExport'],
      columns: cols
    });
    this.grid.appendTo(`#${this.referenceid}`);
    this.grid.toolbarClick = (args: Object) => {
      if (args['item'].id === `${this.referenceid}_excelexport`) {
        this.grid.excelExport();
      }
      if (args['item'].id === `${this.referenceid}_pdfexport`) {
        this.grid.pdfExport();
      }
    };
  }

  onProcessAction(action, data) {

  }
}
