import { Grid } from "@syncfusion/ej2-ng-grids";

export const createTourOperatorApplicationSafetyEquipmentListGrid = function(dataSource){
  const cols = [
    {
      field: "SafetyEquipmentType",
      headerText: "Safety Equipment Type"
    },
    {
      field: "SafetyEquipmentExists",
      headerText: "Safety Equipment Exists"
    },
    {
      field: "SafetyEquipmentComments",
      headerText: "Safety Equipment Commentsh"
    }
  ];
  const ownerGrid = new Grid({
    dataSource,
    width: "100%",
    allowTextWrap: true,
    textWrapSettings: {
      wrapMode: "Content"
    },
    columns: cols
  });
  ownerGrid.appendTo(`#${this.props.referenceid2}`);
}
