export default `
<div class="row">
<div class="col-md-6">
    <div class="form-group">
      <label>Tour Guide Association</label>
      <div class="input-group">
        <input
        [style.background-color]="compare(props.currentSelectedModification.NewTourGuideAssociationName,props.currentSelectedModification.OldTourGuideAssociationName) ? '#AEF0B7' : '#fff'"
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedModification.NewTourGuideAssociationName"
          class="form-control"
        />
      </div>
    </div>
  </div>

  <div class="col-md-6">
    <div class="form-group">
      <label>Tour Guide Association District</label>
      <div class="input-group">
        <input
        [style.background-color]="compare(props.currentSelectedModification.NewTourGuideAssociationDistrictName,props.currentSelectedModification.OldTourGuideAssociationDistrictName) ? '#AEF0B7' : '#fff'"
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedModification.NewTourGuideAssociationDistrictName"
          class="form-control"
        />
      </div>
    </div>
  </div>

  <div class="col-md-12">
    <div class="form-group">
      <label>Education Level</label>
      <div class="input-group">
        <input
        [style.background-color]="compare(props.currentSelectedModification.NewEducationLevel,props.currentSelectedModification.OldEducationLevel) ? '#AEF0B7' : '#fff'"
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedModification.NewEducationLevel"
          class="form-control"
        />
      </div>
    </div>
  </div>



























  </div>
`;
