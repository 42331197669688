export default `
<section *ngIf="(props.currentSelectedModification.newDependents !==undefined && props.currentSelectedModification.newDependents.length>0)">

<h3> New Dependent(s)</h3>

      <div class="form-group row">
        <div class="col-sm-12">
          <table class="data-table">
            <thead>
              <th>Name</th>
              <th>Relationship</th>
              <th>Gender</th>
              <th>Date Of Birth</th>
              <th>Cell Phone</th>
              <th>Home Phone</th>
              <th>Email Address</th>
              <th></th>
            </thead>
            <tbody>
              <tr *ngFor="let owner of props.currentSelectedModification.newDependents">
                <td>{{ owner.FirstName }} {{ owner.MiddleName }} {{ owner.LastName }}</td>
                <td>{{ owner.Relationship }}</td>
                <td>{{ owner.Gender }}</td>
                <td>{{ owner.DateOfBirth }}</td>
                <td>{{ owner.CellPhone }}</td>
                <td>{{ owner.HomePhone }}</td>
                <td>{{ owner.EmailAddress }}</td>
                <td><button type="button" title="Details" (click)="showNewDependentDetails(owner)" class="e-lib e-btn e-control e-flat">Details</button></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

</section>
`;
