import { AfterViewInit, Component,ViewChild, OnInit, Input, OnChanges } from '@angular/core';
import DataAction from '../../../actions/action.data';
import { DetailRowService, GridModel, FilterSettingsModel, RowSelectEventArgs, GridComponent } from '@syncfusion/ej2-ng-grids';
import {PageSettingsModel } from '@syncfusion/ej2-ng-grids';
import { ToolbarItems } from '@syncfusion/ej2-ng-grids';
import guid from '../../../constants/guid';
import {
  Grid,
  Group,
  Filter,
  Sort,
  ExcelExport,
  Page,
  Toolbar,
  PdfExport,
  Edit
} from '@syncfusion/ej2-grids';
import * as moment from 'moment';
Grid.Inject(Filter, Group, Sort, Page, ExcelExport, Toolbar, PdfExport);
@Component({
  selector: "btb-get-user-activity-records",
  template: `
  <div class="editorActions" style="right:10px;">          
  </div>
  <div style="position:absolute;top: 10px;left: 20px;right: 20px;bottom:10px; overflow:auto;" class="clientassign">
      <div class="form-group" style="width:300px;margin:20px;">
          <label for="sel1">User Name:</label>          
          <searchable [placeholder]="'User'" [editable]="true"  [onChange]="_onChange" [model]="postdata" name="controlidcol" [(data)]="formdata.listresources[0]['users']" value="UserName" text="FullName" [displays]="[]" theme="formSearch"></searchable>
      </div>
      <div style="margin:40px;">     
        <div id='{{referenceid}}'>
        </div>	  
	    </div>   
  </div>`,
  providers: [DetailRowService]
})
export class BTBGetUserActivityRecords implements AfterViewInit {
  public data: Object[];
  public toolbarOptions: ToolbarItems[];
  public pageSettings: PageSettingsModel;
  public postdata = { UserName : ""};
  public filterOptions: FilterSettingsModel;
  public selectedrecords:any;

  public referenceid = guid.create('syncfusion');
  public grid: Grid = null;
  _onChange = null; 
  
  constructor() {
    this.filterOptions = {
      type: 'Menu'
   };
    
   this._onChange = changed => {
      this.postdata.UserName = changed.UserName;
      this.submitdata(changed.UserName)
    };
  }

  formatDate(value: any){
      return moment(value).format('LL');
  }

  
  
  @Input() formdata: any;
  ngOnInit(): void {
    this.pageSettings = { pageSize: 6 };
    this.toolbarOptions = ['Search'];
  }
  controlids = [];



  ngAfterViewInit() {

    const cols = [
      {
        field: 'EntityType',
        headerText: 'Entity Type'
      },
      {
        field: 'EntityRecordNumber',
        headerText: 'Entity Record Number'
      },
      {
        field: 'LogDate',
        headerText: 'Log Date',
        type: 'date',
        format: 'yMd',
        filter: {type: 'Menu'}
      },
      {
        field: 'LogUser',
        headerText: 'User'
      },
      {
        field: 'LogDescription',
        headerText: 'Description'
      },
      {
        field: 'ModifiedField',
        headerText: 'Modified'
      },
      {
        field: 'OldValue',
        headerText: 'Old Value'
      },
      {
        field: 'NewValue',
        headerText: 'New Value'
      }
    ];

    this.grid = new Grid({
      dataSource: this.data,
      allowPaging: true,
      allowGrouping: true,
      allowFiltering: true,
      allowSorting: true,
      filterSettings: { type: 'Excel' },
      allowPdfExport: true,
      allowExcelExport: true,
      toolbar: [
        'ExcelExport',
        'PdfExport'
      ],
      columns: cols
     
    });
    this.grid.appendTo(`#${this.referenceid}`);
    this.grid.toolbarClick = (args: Object) => {
      if (args['item'].id === `${this.referenceid}_excelexport`) {
        this.grid.excelExport();
      }
      if (args['item'].id === `${this.referenceid}_pdfexport`) {
        this.grid.pdfExport();
      }
    };
  }


private dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/;

reviver(key, value) {
      if (key === 'CheckInDate' || key === 'CheckOutDate' ) {
          return new Date(value);
      }
      
      return value;
  }
   

submitdata(UserName) {
  DataAction.runData(
    {UserName}, "SQL", "GetUserActivityRecords", data => {
        this.selectedrecords = null;
        this.grid.dataSource = JSON.parse(JSON.stringify(data), this.reviver);
        this.grid.refresh();
  });
}
}
