import { Component, Input, AfterViewInit, ViewChild } from "@angular/core";
import axios from "axios";
import { closest } from "@syncfusion/ej2-base";
import * as _ from "underscore";
import swal from "sweetalert2";
import { UserStore } from "../../../stores/store.user";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import {
  DetailRowService,
  GridModel,
  FilterSettingsModel,
  RowSelectEventArgs,
  GridComponent
} from "@syncfusion/ej2-ng-grids";
import { PageSettingsModel } from "@syncfusion/ej2-ng-grids";
import { ToolbarItems } from "@syncfusion/ej2-ng-grids";
import guid from "../../../constants/guid";
import {
  Grid,
  Group,
  CommandColumn,
  Filter,
  Sort,
  ExcelExport,
  Page,
  Toolbar,
  PdfExport,
  Edit,
  Resize
} from "@syncfusion/ej2-grids";
import * as moment from "moment";
Grid.Inject(
  Filter,
  Group,
  Sort,
  Page,
  ExcelExport,
  Toolbar,
  PdfExport,
  CommandColumn,
  Resize
);

@Component({
  selector: "view-application-request",
  template: `
    <ng-template #content let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
          Application Form - {{ selectedhotel }}
        </h4>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h3>General Information</h3>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>Name of Hotel or Tourist Accomodation</label>
              <div class="input-group">
                <input
                  readOnly="readOnly"
                  [(ngModel)]="currentSelectedApplication.HotelName"
                  class="form-control"
                />
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group">
              <label>Previous Name (if any)</label>
              <div class="input-group">
                <input
                  readOnly="readOnly"
                  [(ngModel)]="currentSelectedApplication.PreviousHotelName"
                  class="form-control"
                />
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group">
              <label>Type or Category of Accomodation</label>
              <div class="input-group">
                <input
                  readOnly="readOnly"
                  [(ngModel)]="currentSelectedApplication.HotelCategory"
                  class="form-control"
                />
              </div>
            </div>
          </div>
        </div>

        <h3>Contact Information</h3>
        <div class="row">
          <div class="col-md-10">
            <div class="form-group">
              <label>Physical Address</label>
              <div class="input-group">
                <input
                  readOnly="readOnly"
                  class="form-control"
                  [(ngModel)]="currentSelectedApplication.Address"
                />
              </div>
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-group">
              <label>District Area</label>
              <div class="input-group">
                <input
                  readOnly="readOnly"
                  class="form-control"
                  [(ngModel)]="currentSelectedApplication.DistrictAreaName"
                />
              </div>
            </div>
          </div>

          <div class="col-md-8">
            <div class="form-group">
              <label>Mailing Address</label>
              <div class="input-group">
                <input
                  readOnly="readOnly"
                  class="form-control"
                  [(ngModel)]="currentSelectedApplication.Address"
                />
              </div>
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-group">
              <label>P.O. Box</label>
              <div class="input-group">
                <input readOnly="readOnly" 
                class="form-control"                 
                [(ngModel)]="currentSelectedApplication.PostOfficeBox"/>
              </div>
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-group">
              <label>District Area</label>
              <div class="input-group">
                <input
                  readOnly="readOnly"
                  class="form-control"
                  [(ngModel)]="currentSelectedApplication.DistrictAreaName"
                />
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label>Telephone 1</label>
              <div class="input-group">
                <input
                  readOnly="readOnly"
                  class="form-control"
                  [(ngModel)]="currentSelectedApplication.Telephone1"
                />
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label>Telephone 2</label>
              <div class="input-group">
                <input
                  readOnly="readOnly"
                  class="form-control"
                  [(ngModel)]="currentSelectedApplication.Telephone2"
                />
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label>Fax</label>
              <div class="input-group">
                <input
                  readOnly="readOnly"
                  class="form-control"
                  [(ngModel)]="currentSelectedApplication.FaxNumber"
                />
              </div>
            </div>
          </div>
          

          <div class="col-md-4">
            <div class="form-group">
              <label>Email Address</label>
              <div class="input-group">
                <input
                  readOnly="readOnly"
                  class="form-control"
                  [(ngModel)]="currentSelectedApplication.EmailAddress"
                />
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label>Finance Email</label>
              <div class="input-group">
                <input
                  readOnly="readOnly"
                  class="form-control"
                  [(ngModel)]="currentSelectedApplication.FinanceEmailAddress"
                />
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label>Web Site</label>
              <div class="input-group">
                <input
                  readOnly="readOnly"
                  class="form-control"
                  [(ngModel)]="currentSelectedApplication.WebSite"
                />
              </div>
            </div>
          </div>



        </div>

        <h3>Owner(s)</h3>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <div><div id="{{ referenceid4 }}"></div></div>
            </div>
          </div>
        </div>

        <h3>Manager(s)</h3>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <div><div id="{{ referenceid3 }}"></div></div>
            </div>
          </div>
        </div>

        <h3>Employment Information</h3>

        <div class="col-md-4">
          <div class="form-group">
            <label>&nbsp;</label>
            <div class="input-group">
              <label>i) Number of Local Employees</label>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label>Male</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="
                  currentSelectedApplication.NumberOfLocalMaleEmployees
                "
                class="form-control"
              />
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label>Female</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="
                  currentSelectedApplication.NumberOfLocalFemaleEmployees
                "
                class="form-control"
              />
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label>&nbsp;</label>
            <div class="input-group">
              <label>ii) Number of Foreign Employees</label>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label>Male</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="
                  currentSelectedApplication.NumberOfForeignMaleEmployees
                "
                class="form-control"
              />
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label>Female</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="
                  currentSelectedApplication.NumberOfForeignFemaleEmployees
                "
                class="form-control"
              />
            </div>
          </div>
        </div>

        <h3>Room Information</h3>

        <div class="col-md-6">
          <div class="form-group">
            <label>i) Total Rooms at Property</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="currentSelectedApplication.NumberOfRooms"
                class="form-control"
              />
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label>Available Rooms for Guest Use</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="currentSelectedApplication.NumberOfAvailableRooms"
                class="form-control"
              />
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label>i) Available Units</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="currentSelectedApplication.NumberOfAvailableUnits"
                class="form-control"
              />
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label>Available Beds</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="currentSelectedApplication.NumberOfAvailableBeds"
                class="form-control"
              />
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label>&nbsp;</label>
            <div class="input-group"><label>ii) Number of Beds</label></div>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group">
            <label>Single</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="currentSelectedApplication.NumberOfSingleBeds"
                class="form-control"
              />
            </div>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group">
            <label>Double</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="currentSelectedApplication.NumberOfDoubleBeds"
                class="form-control"
              />
            </div>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group">
            <label>Queen</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="currentSelectedApplication.NumberOfQueenBeds"
                class="form-control"
              />
            </div>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group">
            <label>King</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="currentSelectedApplication.NumberOfKingBeds"
                class="form-control"
              />
            </div>
          </div>
        </div>

        <h3>Room Rates (IN BZD $)</h3>

        <div class="col-md-6">
          <div class="form-group">
            <label>Low Season Months</label>
            <div class="input-group">
              <div class="col-md-5">
                <input
                  readOnly="readOnly"
                  [(ngModel)]="
                    currentSelectedApplication.LowSeasonBeginningMonth
                  "
                  class="form-control"
                />
              </div>
              <div class="col-md-2">-</div>
              <div class="col-md-5">
                <input
                  readOnly="readOnly"
                  [(ngModel)]="currentSelectedApplication.LowSeasonEndingMonth"
                  class="form-control"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label>High Season Months</label>
            <div class="input-group">
              <div class="col-md-5">
                <input
                  readOnly="readOnly"
                  [(ngModel)]="
                    currentSelectedApplication.HighSeasonBeginningMonth
                  "
                  class="form-control"
                />
              </div>
              <div class="col-md-2">-</div>
              <div class="col-md-5">
                <input
                  readOnly="readOnly"
                  [(ngModel)]="currentSelectedApplication.HighSeasonEndingMonth"
                  class="form-control"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label>Single Occupancy</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="
                  currentSelectedApplication.SingleOccupancyLowSeasonRate
                "
                class="form-control"
              />
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label>&nbsp;</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="
                  currentSelectedApplication.SingleOccupancyHighSeasonRate
                "
                class="form-control"
              />
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label>Double Occupancy</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="
                  currentSelectedApplication.DoubleOccupancyLowSeasonRate
                "
                class="form-control"
              />
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label>&nbsp;</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="
                  currentSelectedApplication.DoubleOccupancyHighSeasonRate
                "
                class="form-control"
              />
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label>Tripple Occupancy</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="
                  currentSelectedApplication.TripleOccupancyLowSeasonRate
                "
                class="form-control"
              />
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label>&nbsp;</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="
                  currentSelectedApplication.TripleOccupancyHighSeasonRate
                "
                class="form-control"
              />
            </div>
          </div>
        </div>

        <h3>Facilities</h3>
        <div class="row">
          <div class="col-md-12">
            <ul>
              <li *ngFor="let facility of facilities">
                <i class="mdi mdi-check"></i>
                <span>{{ facility.FacilityTypeDescription }}</span>
              </li>
            </ul>
          </div>
        </div>

        <h3>Initial Investment</h3>

        <div class="col-md-6">
          <div class="form-group">
            <label>Proposed Opening</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="currentSelectedApplication.ProposedOpeningDate"
                class="form-control"
              />
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label>Total Initial Investment</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="currentSelectedApplication.InitialInvestmentAmount"
                class="form-control"
              />
            </div>
          </div>
        </div>

        <h3>Future Investment</h3>

        <div class="col-md-12">
          <div class="form-group">
            <label>Future Investment Plans</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="currentSelectedApplication.FutureInvestmentPlans"
                class="form-control"
              />
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label>Future Investment Amount</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="currentSelectedApplication.FutureInvestmentAmount"
                class="form-control"
              />
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label>Future Investment Date</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="currentSelectedApplication.FutureInvestmentDate"
                class="form-control"
              />
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label>Future Additional Rooms</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="currentSelectedApplication.FutureAdditionalRooms"
                class="form-control"
              />
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label>Future Additional Units</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="currentSelectedApplication.FutureAdditionalUnits"
                class="form-control"
              />
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label>Future Additional Beds</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="currentSelectedApplication.FutureAdditionalBeds"
                class="form-control"
              />
            </div>
          </div>
        </div>

        <h3>Other Details</h3>

        <div class="col-md-4">
          <div class="form-group">
            <label>Application Fee Paid</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="currentSelectedApplication.ApplicationFeeAmount"
                class="form-control"
              />
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label>Date Paid</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="
                  currentSelectedApplication.ApplicationFeePaymentDate
                "
                class="form-control"
              />
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label>Receipt No.</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="
                  currentSelectedApplication.ApplicationFeeReceiptNumber
                "
                class="form-control"
              />
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label>License Fee Paid</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="currentSelectedApplication.LicenseFeeAmount"
                class="form-control"
              />
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label>Date Paid</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="currentSelectedApplication.LicenseFeePaymentDate"
                class="form-control"
              />
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label>Receipt No.</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="currentSelectedApplication.LicenseFeeReceiptNumber"
                class="form-control"
              />
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label>License Date</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="currentSelectedApplication.LicenseDate"
                class="form-control"
              />
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label>License #</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="currentSelectedApplication.LicenseNumber"
                class="form-control"
              />
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label>License Year</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="currentSelectedApplication.LicenseYear"
                class="form-control"
              />
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label>Hotel Id Number</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="currentSelectedApplication.HotelIdNumber"
                class="form-control"
              />
            </div>
          </div>
        </div>


      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-outline-dark"
          (click)="modal.close('Save click')"
        >
          Close
        </button>
      </div>
    </ng-template>

    <ng-template #complete let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
          Checkpoints
        </h4>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div><div id="{{ referenceid2 }}"></div></div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-outline-dark"
          (click)="modal.close('Process click')"
        >
          Process
        </button>

        <button
          type="button"
          class="btn btn-outline-dark"
          (click)="modal.close('Save click')"
        >
          Close
        </button>
      </div>
    </ng-template>

    <ng-template #process let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
          Process Application - {{ selectedhotel }}
        </h4>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-md-12">
          <date-picker
            [model]="postDataProcess"
            [config]="{ key: 'ExpirationDate', label: 'Expiration Date' }"
            [addmode]="false"
          ></date-picker>
        </div>
        <div class="col-md-12">
          <date-picker
            [model]="postDataProcess"
            [config]="{ key: 'LicenseDate', label: 'License Date' }"
            [editable]="false"
            [addmode]="false"
          ></date-picker>
        </div>
        <div class="col-md-12">
          <label>License Year</label>
          <input
            class="form-control"
            type="number"
            [(ngModel)]="postDataProcess.LicenseYear"
          />
          
        </div>
        <div class="col-md-12">
          <date-picker
            [model]="postDataProcess"
            [config]="{ key: 'OpeningDate', label: 'Opening Date' }"
            [editable]="false"
            [addmode]="false"
          ></date-picker>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-outline-dark"
          (click)="processDetails(modal)"
        >
          Complete Processing
        </button>

        <button
          type="button"
          class="btn btn-outline-dark"
          (click)="modal.close('Save click')"
        >
          Close
        </button>
      </div>
    </ng-template>

    <ng-template #approvecomplete let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
          Checkpoints
        </h4>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-md-12">
          <date-picker
            [model]="postDataComplete"
            [config]="{ key: 'CheckpointDate', label: 'Checkpoint Date' }"
            [editable]="false"
            [addmode]="false"
          ></date-picker>
        </div>

        <div class="col-md-12">
          <div class="form-group">
            <label>Comments</label>
            <div class="input-group">
              <input
                class="form-control"
                [(ngModel)]="postDataComplete.CheckpointComments"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-outline-dark"
          (click)="modal.close('Save click')"
        >
          Save
        </button>
        <button
          type="button"
          class="btn btn-outline-dark"
          (click)="modal.close('Close click')"
        >
          Close
        </button>
      </div>
    </ng-template>

    <ng-template #document let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
          Documents Attached
        </h4>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div><div id="{{ referenceid5 }}"></div></div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-outline-dark"
          (click)="modal.close('Save click')"
        >
          Close
        </button>
      </div>
    </ng-template>

    <div class="editorActions" style="right:10px;"></div>
    <div
      style="position:absolute;top: 10px;left: 20px;right: 20px;bottom:10px; overflow:auto;"
      class="clientassign"
    >
      <div class="row" style="margin:50px 0px 0px 25px">
        <div class="col-md-3">
          <date-picker
            [model]="postdata"
            [config]="{ key: 'BeginDate', label: 'Begin Date' }"
            [editable]="false"
            [addmode]="false"
          ></date-picker>
        </div>
        <div class="col-md-3">
          <date-picker
            [model]="postdata"
            [config]="{ key: 'EndDate', label: 'End Date' }"
            [editable]="false"
            [addmode]="false"
          ></date-picker>
        </div>

        <div class="col-md-3" style="margin-top:15px;">
          <button (click)="showApplications()" class="btn btn-default">
            Show Applications
          </button>
        </div>
      </div>

      <div style="margin:40px;"><div id="{{ referenceid }}"></div></div>
    </div>
  `
})
export class ViewHotelApplicationRequest implements AfterViewInit {
  @ViewChild("content", {static: false})
  content;
  @ViewChild("process", {static: false})
  process;
  @ViewChild("complete", {static: false})
  complete;
  @ViewChild("image", {static: false})
  image;
  @ViewChild("document", {static: false})
  document;
  @ViewChild("approvecomplete", {static: false})
  approvecomplete;

  @Input() test: any;
  public data = [];
  public postdata = {
    BeginDate: "",
    EndDate: ""
  };

  public postDataComplete = {
    HotelApplicationRecordNumber: "",
    CheckpointName: "",
    HotelApplicationCheckpointRecordNumber: "",
    CheckpointDate: "",
    CompletedBy: UserStore.getUserName(),
    CheckpointComments: ""
  };
  public postDataProcess = {
    HotelApplicationRecordNumber: "",
    ExpirationDate: "",
    LicenseDate: "",
    LicenseYear: "",
    OpeningDate: "",
    UserName: UserStore.getUserName(),
    UserToken: UserStore.getUserDetails().token
  };
  public selectedhotel = "";
  public currentSelectedApplication = {};
  public grid: Grid = null;
  public _onChange = null;
  public referenceid = guid.create("syncfusion");
  public referenceid2 = guid.create("syncfusion2");
  public referenceid3 = guid.create("syncfusion3");
  public referenceid4 = guid.create("syncfusion4");
  public referenceid5 = guid.create("syncfusion5");
  public facilities = [];
  public managers = {};
  public owners = {};
  public completed = [];
  public currentId = "";
  public completeGridTrack = null;

  processDetails(modal) {
    var allowComplete = true;
    if (this.completed !== undefined) {
      this.completed.forEach(appcomplete => {
        if (
          appcomplete.CompletedBy === null ||
          appcomplete.CompletedBy === undefined ||
          appcomplete.CompletedBy === ""
        ) {
          allowComplete = false;
        }
      });
    }

    if (!allowComplete) {
      if (confirm("Not all checkpoints were completed. Would you like to process anyway?")) {
        axios
        .post("/api/processhotelapplicationrequest", 
          this.postDataProcess
        , {
          headers: {
            "user-token": UserStore.getUserDetails().token,
            "site-token": "7pdvAXwKchuAQGw7QBLnSHS",
            "user-id": 1,
            "control-id": 1
          }
        })
        .then((response: any) => {
          
          modal.close('Process click')
          if(response.data.Error)
          {
            swal.fire({
              title: "Process Error",
              text: `${response.data.Message}. ${response.data.Error}`,
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: "#DD6B55",
              confirmButtonText: "Close"
            });
          }
          else
          {
            swal.fire({
              title: "Process Complete",
              text: `${response.data.Message}. Control Id ${response.data.HotelApplicationProcess.ControlId} has been assigned`,
              icon: 'info',
              showCancelButton: false,
              confirmButtonColor: "#DD6B55",
              confirmButtonText: "Close"
            });
          }
         
    
    
    
        });
    
      } else {
        return;
      }
    }
    else
    {
      axios
    .post("/api/processhotelapplicationrequest", 
      this.postDataProcess
    , {
      headers: {
        "user-token": UserStore.getUserDetails().token,
        "site-token": "7pdvAXwKchuAQGw7QBLnSHS",
        "user-id": 1,
        "control-id": 1
      }
    })
    .then((response: any) => {
      
      modal.close('Process click')
      swal.fire({
        title: "Process Complete",
        text: "Process completed successfully",
        icon: 'info',
        showCancelButton: false,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Close"
      });



    });

    }
  }

  createCompleteGrid(dataSource: any) {
    const onComplete = args => {
      const rowObj = this.completeGridTrack.getRowObjectFromUID(
        closest(args.target, ".e-row").getAttribute("data-uid")
      );
      const data: any = rowObj.data;

      this.postDataComplete = {
        HotelApplicationRecordNumber: data.HotelApplicationRecordNumber,
        HotelApplicationCheckpointRecordNumber:
          data.HotelApplicationCheckpointRecordNumber,
        CheckpointDate: "",
        CheckpointName: data.CheckpointName,
        CompletedBy: UserStore.getUserName(),
        CheckpointComments: ""
      };

      this.modalService
        .open(this.approvecomplete, { centered: true, size: "lg" })
        .result.then(
          result => {
            // this.closeResult = `Closed with: ${result}`;
            if (result === "Save click") {
              axios
                .post(
                  "/api/UpdateHotelApplicationCheckpoint",
                  this.postDataComplete,
                  {
                    headers: {
                      "user-token": UserStore.getUserDetails().token,
                      "site-token": "7pdvAXwKchuAQGw7QBLnSHS",
                      "user-id": 1,
                      "control-id": 1
                    }
                  }
                )
                .then((response: any) => {
                  this.showComplete(
                    parseInt(data.HotelApplicationRecordNumber.toString()),
                    response => {
                      this.completeGridTrack.dataSource = response;
                    }
                  );
                });
            }
          },
          reason => {
            // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
      setTimeout(() => {
        this.fit_modal_body($(".modal"));
      }, 100);
    };

    const cols = [
      {
        field: "HotelApplicationCheckpointRecordNumber",
        headerText: "HotelApplicationCheckpointRecordNumber",
        visible: false
      },
      {
        field: "HotelApplicationRecordNumber",
        headerText: "HotelApplicationRecordNumber",
        visible: false
      },
      {
        field: "CheckpointName",
        headerText: "Checkpoint Name"
      },
      {
        field: "CheckpointDate",
        headerText: "Checkpoint Date"
      },
      {
        field: "CompletedBy",
        headerText: "Completed By"
      },
      {
        field: "CheckpointComments",
        headerText: "Checkpoint Comments"
      },
      {
        headerText: "",
        isUnbound: false,
        commands: [
          {
            buttonOption: {
              content: "Modify",
              cssClass: "e-flat",
              click: onComplete
            }
          }
        ]
      }
    ];

    this.completeGridTrack = new Grid({
      dataSource,
      allowTextWrap: true,
      textWrapSettings: {
        wrapMode: "Content"
      },
      columns: cols
    });
    this.completeGridTrack.appendTo(`#${this.referenceid2}`);
  }

  createDocumentGrid(dataSource: any) {
    let completeGrid = null;
    const onViewDocument = args => {
      const rowObj = completeGrid.getRowObjectFromUID(
        closest(args.target, ".e-row").getAttribute("data-uid")
      );
      const data: any = rowObj.data;

      this.createImage(
        "/api/gethotelapplicationdocument",
        data.HotelApplicationRecordNumber,
        data.HotelApplicationDocumentRecordNumber,
        data.DocumentName
      );

    };

    const cols = [
      {
        field: "HotelModificationRequestNumber",
        headerText: "Checkpoint Comments",
        visible: false
      },

      {
        field: "HotelModificationRequestDocumentRecordNumber",
        headerText: "Checkpoint Comments",
        visible: false
      },
      {
        field: "DocumentName",
        headerText: "Name"
      },
      {
        field: "DocumentTypeDescription",
        headerText: "Document Type"
      },
      {
        field: "IssueDate",
        headerText: "Issue Date"
      },
      {
        field: "ExpirationDate",
        headerText: "Expiration Date"
      },

      {
        headerText: "",
        isUnbound: false,
        commands: [
          {
            buttonOption: {
              content: "View",
              cssClass: "e-flat",
              click: onViewDocument
            }
          }
        ]
      }
    ];

    completeGrid = new Grid({
      dataSource,
      allowTextWrap: true,
      textWrapSettings: {
        wrapMode: "Content"
      },
      columns: cols
    });
    completeGrid.appendTo(`#${this.referenceid5}`);
  }

  createImage(url, hotelId, documentId, caption) {
    var xhr = new XMLHttpRequest();
    xhr.responseType = "blob"; //so you can access the response like a normal URL
    xhr.onreadystatechange = function() {
      if (xhr.readyState == XMLHttpRequest.DONE && xhr.status == 200) {
        $["fancybox"].open({
          padding: 0,
          src: URL.createObjectURL(xhr.response),
          type: "iframe",
          caption
        });
      }
    };
    xhr.open("GET", url, true);
    xhr.setRequestHeader("user-token", UserStore.getUserDetails().token);
    xhr.setRequestHeader("site-token", "7pdvAXwKchuAQGw7QBLnSHS");
    xhr.setRequestHeader("user-id", "1");
    xhr.setRequestHeader("control-id", "1");
    xhr.setRequestHeader("hotel-application-id", hotelId.toString());
    xhr.setRequestHeader("document-id", documentId.toString());
    xhr.send();
  }

  showDocuments(applicationId: number, callback) {
    const showPostData = {
      HotelApplicationRecordNumber: applicationId
    };
    axios
      .post("/api/gethotelapplicationdocumentlist", showPostData, {
        headers: {
          "user-token": UserStore.getUserDetails().token,
          "site-token": "7pdvAXwKchuAQGw7QBLnSHS",
          "user-id": 1,
          "control-id": 1
        }
      })
      .then((response: any) => {
        callback(response.data.HotelApplicationDocuments);
      });
  }

  showComplete(applicationId: number, callback) {
    const showPostData = {
      HotelApplicationRecordNumber: applicationId
    };
    axios
      .post("/api/GetHotelApplicationCheckpointList", showPostData, {
        headers: {
          "user-token": UserStore.getUserDetails().token,
          "site-token": "7pdvAXwKchuAQGw7QBLnSHS",
          "user-id": 1,
          "control-id": 1
        }
      })
      .then((response: any) => {
        callback(response.data.HotelApplicationCheckpoints);
      });
  }

  showFacilityList(applicationId: number, callback) {
    const showPostData = {
      HotelApplicationRecordNumber: applicationId
    };
    axios
      .post("/api/GetHotelApplicationFacilityList", showPostData, {
        headers: {
          "user-token": UserStore.getUserDetails().token,
          "site-token": "7pdvAXwKchuAQGw7QBLnSHS",
          "user-id": 1,
          "control-id": 1
        }
      })
      .then((response: any) => {
        callback(response.data.HotelApplicationFacilitys);
      });
  }

  createHotelApplicationManagerListGrid(dataSource: any) {
    const cols = [
      {
        field: "FirstName",
        headerText: "First Name"
      },
      {
        field: "MiddleName",
        headerText: "Middle Name"
      },
      {
        field: "LastName",
        headerText: "Last Name"
      },
      {
        field: "CountryName",
        headerText: "Nationality"
      },
      {
        field: "EmailAddress",
        headerText: "Email Address"
      },
      {
        field: "TelephoneNumber",
        headerText: "Telephone Number"
      },
      {
        field: "BusinessEntityType",
        headerText: "Entity Type"
      }
    ];
    const managerGrid = new Grid({
      dataSource,
      width: "100%",
      allowTextWrap: true,
      textWrapSettings: {
        wrapMode: "Content"
      },
      columns: cols
    });
    managerGrid.appendTo(`#${this.referenceid3}`);
  }

  showManagerList(applicationId: number, callback) {
    const showPostData = {
      HotelApplicationRecordNumber: applicationId
    };
    axios
      .post("/api/GetHotelApplicationManagerList", showPostData, {
        headers: {
          "user-token": UserStore.getUserDetails().token,
          "site-token": "7pdvAXwKchuAQGw7QBLnSHS",
          "user-id": 1,
          "control-id": 1
        }
      })
      .then((response: any) => {
        callback(response.data.HotelApplicationManagers);
      });
  }

  createHotelApplicationOwnerListGrid(dataSource: any) {
    const cols = [
      {
        field: "FirstName",
        headerText: "First Name"
      },
      {
        field: "MiddleName",
        headerText: "Middle Name"
      },
      {
        field: "LastName",
        headerText: "Last Name"
      },
      {
        field: "CountryName",
        headerText: "Nationality"
      },
      {
        field: "EmailAddress",
        headerText: "Email Address"
      },
      {
        field: "TelephoneNumber",
        headerText: "Telephone Number"
      },
      {
        field: "BusinessEntityType",
        headerText: "Entity Type"
      },
      {
        field: "SharePercentage",
        headerText: "Share Percent",
        format: "#,###0.00'%'"
      }
    ];
    const ownerGrid = new Grid({
      dataSource,
      width: "100%",
      allowTextWrap: true,
      textWrapSettings: {
        wrapMode: "Content"
      },
      columns: cols
    });
    ownerGrid.appendTo(`#${this.referenceid4}`);
  }

  showOwnerList(applicationId: number, callback) {
    const showPostData = {
      HotelApplicationRecordNumber: applicationId
    };
    axios
      .post("/api/GetHotelApplicationOwnerList", showPostData, {
        headers: {
          "user-token": UserStore.getUserDetails().token,
          "site-token": "7pdvAXwKchuAQGw7QBLnSHS",
          "user-id": 1,
          "control-id": 1
        }
      })
      .then((response: any) => {
        callback(response.data.HotelApplicationOwners);
      });
  }

  showApplications() {
    axios
      .post("/api/gethotelapplicationlist", this.postdata, {
        headers: {
          "user-token": UserStore.getUserDetails().token,
          "site-token": "7pdvAXwKchuAQGw7QBLnSHS"
        }
      })
      .then(response => {
        this.grid.dataSource = response.data.HotelApplications;
      });
  }

  showSelectedApplication(applicationId: number, callback) {
    const showPostData = {
      HotelApplicationRecordNumber: applicationId
    };
    axios
      .post("/api/GetHotelApplication", showPostData, {
        headers: {
          "user-token": UserStore.getUserDetails().token,
          "site-token": "7pdvAXwKchuAQGw7QBLnSHS",
          "user-id": 1,
          "control-id": 1
        }
      })
      .then((response: any) => {
        callback(response.data.HotelApplication);
      });
  }

  fit_modal_body(modal: any) {
    const header = $(".modal-header", modal);
    const footer = $(".modal-footer", modal);
    const body = $(".modal-body", modal);

    const modalheight = parseInt(modal.css("height"));
    const headerheight =
      parseInt(header.css("height")) +
      parseInt(header.css("padding-top")) +
      parseInt(header.css("padding-bottom"));
    const bodypaddings =
      parseInt(body.css("padding-top")) + parseInt(body.css("padding-bottom"));
    const footerheight =
      parseInt(footer.css("height")) +
      parseInt(footer.css("padding-top")) +
      parseInt(footer.css("padding-bottom"));
    const height = modalheight - headerheight - footerheight - bodypaddings - 5; // fudge factor

    return body.css("max-height", `${height}px`);
  }

  ngAfterViewInit() {
    // Here you need to bind your event with the appropriate modal, as an example:
    $(window).resize(() => this.fit_modal_body($(".modal")));

    const onClick = args => {
      const rowObj = this.grid.getRowObjectFromUID(
        closest(args.target, ".e-row").getAttribute("data-uid")
      );
      const data: any = rowObj.data;

      this.selectedhotel = data.HotelName;
      this.showSelectedApplication(
        data.HotelApplicationRecordNumber,
        (response: any) => {
          this.currentSelectedApplication = response;

          this.showManagerList(
            data.HotelApplicationRecordNumber,
            (managerList: any) => {
              this.managers = managerList;
              setTimeout(() => {
                this.createHotelApplicationManagerListGrid(managerList);
              }, 1000);
            }
          );
          this.showOwnerList(
            data.HotelApplicationRecordNumber,
            (ownerList: any) => {
              this.owners = ownerList;
              setTimeout(() => {
                this.createHotelApplicationOwnerListGrid(ownerList);
              }, 1000);
            }
          );
          this.showFacilityList(
            data.HotelApplicationRecordNumber,
            (facilityList: any) => {
              this.facilities = facilityList;
              this.modalService
                .open(this.content, { centered: true, size: "lg" })
                .result.then(
                  result => {
                    // this.closeResult = `Closed with: ${result}`;
                  },
                  reason => {
                    // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                  }
                );
              setTimeout(() => {
                this.fit_modal_body($(".modal"));
              }, 100);

              //grid Initiation Here
            }
          );
        }
      );
    };

    const onComplete = args => {
      const rowObj = this.grid.getRowObjectFromUID(
        closest(args.target, ".e-row").getAttribute("data-uid")
      );
      const data: any = rowObj.data;

      this.selectedhotel = data.HotelName;

      this.postDataProcess = {
        HotelApplicationRecordNumber: data.HotelApplicationRecordNumber,
        ExpirationDate: "",
        LicenseDate: "",
        LicenseYear: "",
        OpeningDate: "",
        UserName: UserStore.getUserName(),
        UserToken: UserStore.getUserDetails().token
      };


      this.showComplete(data.HotelApplicationRecordNumber, (response: any) => {
        // this.currentSelectedApplication = response;
        this.completed = response;
        this.modalService
          .open(this.complete, { centered: true, size: "lg" })
          .result.then(
            result => {
              // this.closeResult = `Closed with: ${result}`;
              if (result === "Process click") {
                this.modalService
                  .open(this.process, { centered: true, size: "lg" })
                  .result.then(
                    result => {
                      // this.closeResult = `Closed with: ${result}`;
                      if (result === "Process click") {
                      }
                    },
                    reason => {
                      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                    }
                  );
                setTimeout(() => {
                  this.fit_modal_body($(".modal"));
                }, 100);
              }
            },
            reason => {
              // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
          );
        setTimeout(() => {
          this.createCompleteGrid(response);
        }, 1000);
        setTimeout(() => {
          this.fit_modal_body($(".modal"));
        }, 100);
      });

    };

    const onDocument = args => {
      const rowObj = this.grid.getRowObjectFromUID(
        closest(args.target, ".e-row").getAttribute("data-uid")
      );
      const data: any = rowObj.data;

      this.selectedhotel = data.HotelName;
      this.showDocuments(data.HotelApplicationRecordNumber, (response: any) => {
        this.currentSelectedApplication = response;
        this.modalService
          .open(this.document, { centered: true, size: "lg" })
          .result.then(
            result => {
              // this.closeResult = `Closed with: ${result}`;
            },
            reason => {
              // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
          );
        setTimeout(() => {
          this.createDocumentGrid(response);
        }, 1000);
        setTimeout(() => {
          this.fit_modal_body($(".modal"));
        }, 100);
      });

    };

    const cols = [
      {
        field: "HotelName",
        headerText: "Hotel Name"
      },
      {
        field: "Address",
        headerText: "Address"
      },
      {
        field: "DistrictAreaName",
        headerText: "District Area Name"
      },
      {
        field: "Telephone1",
        headerText: "Telephone"
      },

      {
        field: "EmailAddress",
        headerText: "Email Address"
      },
      {
        field: "ApplicationDate",
        headerText: "Application Date",
        type: "date",
        format: "yMd",
        filter: { type: "Menu" }
      },
      {
        field: "ApplicationStatus",
        headerText: "Application Status"
      },
      {
        headerText: "",
        width: 350,
        isUnbound: false,
        commands: [
          {
            buttonOption: {
              content: "View",
              cssClass: "e-flat",
              click: onClick
            }
          },
          {
            buttonOption: {
              content: "Attachments",
              cssClass: "e-flat",
              click: onDocument
            }
          },
          {
            buttonOption: {
              content: "Checkpoints",
              cssClass: "e-flat",
              click: onComplete
            }
          }
        ]
      }
    ];

    this.grid = new Grid({
      dataSource: [],
      allowPaging: true,
      allowGrouping: true,
      allowFiltering: true,
      allowSorting: true,
      width: "100%",
      allowTextWrap: true,
      textWrapSettings: {
        wrapMode: "Content"
      },
      filterSettings: { type: "Excel" },
      allowPdfExport: true,
      allowExcelExport: true,
      toolbar: ["ExcelExport", "PdfExport"],
      columns: cols
    });
    this.grid.appendTo(`#${this.referenceid}`);
    this.grid.toolbarClick = (args: Object) => {
      if (args["item"].id === `${this.referenceid}_excelexport`) {
        this.grid.excelExport();
      }
      if (args["item"].id === `${this.referenceid}_pdfexport`) {
        this.grid.pdfExport();
      }
    };
  }

  constructor(private modalService: NgbModal) {
    this._onChange = changed => {
      //this.postdata.ControlId = changed.ControlId;
      //this.submitdata(changed.ControlId);
    };
  }
}
