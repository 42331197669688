import general from "./general";
import contactinformation from "./contactinformation";
import otherinformation from "./otherinformation";
import education from "./education";
import language from "./language";
import dependents from "./dependents";

export default `
<ng-template #content let-modal>
<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    Application Form - {{ props.selectedQualifiedRetiredPerson }}
  </h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <h3>General Information</h3>
  ${general}
  <h3>Contact Information</h3>
  ${contactinformation}
  <h3>Education</h3>
  ${education}
  <h3>Language(s)</h3>
  ${language}
  <h3>Dependent(s)</h3>
  ${dependents}
  <h3>Other Details</h3>
  ${otherinformation}
</div>

<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="modal.close('Save click')"
  >
    Close
  </button>
</div>
</ng-template>

`
