import generalold from "./general.old";
import generalnew from "./general.new";
import contactinformationold from "./contactinformation.old";
import contactinformationnew from "./contactinformation.new";
import otherold from "./otherinformation.old";
import othernew from "./otherinformation.new";
import languageold from "./language.old";
import languagenew from "./language.new";
import dependents from "./dependents";
import newdependents from "./newdependents";
import educationold from "./education.old";
import educationnew from "./education.new";

export default `
<ng-template #content let-modal>
<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    Modification Form - {{ props.currentSelectedModification.OldFirstName }} {{ props.currentSelectedModification.OldLastName }}
  </h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <h3>General Information</h3>

  <ngb-tabset>
  <ngb-tab title="After">
    <ng-template ngbTabContent>
    ${generalnew}
    </ng-template>
  </ngb-tab>
  <ngb-tab title="Before">
    <ng-template ngbTabContent>
    ${generalold}
    </ng-template>
  </ngb-tab>
</ngb-tabset>

  <h3>Contact Information</h3>

  <ngb-tabset>
  <ngb-tab title="After">
    <ng-template ngbTabContent>
    ${contactinformationnew}
    </ng-template>
  </ngb-tab>
  <ngb-tab title="Before">
    <ng-template ngbTabContent>
    ${contactinformationold}
    </ng-template>
  </ngb-tab>
</ngb-tabset>


<h3>Language(s)</h3>


<ngb-tabset>
<ngb-tab title="After">
  <ng-template ngbTabContent>
  ${languagenew}
  </ng-template>
</ngb-tab>
<ngb-tab title="Before">
  <ng-template ngbTabContent>
  ${languageold}
  </ng-template>
</ngb-tab>
</ngb-tabset>

${newdependents}

${dependents}

<h3>Other Information</h3>

<ngb-tabset>
<ngb-tab title="After">
  <ng-template ngbTabContent>
  ${othernew}
  </ng-template>
</ngb-tab>
<ngb-tab title="Before">
  <ng-template ngbTabContent>
  ${otherold}
  </ng-template>
</ngb-tab>
</ngb-tabset>




</div>

<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="modal.close('Save click')"
  >
    Close
  </button>
</div>
</ng-template>

`
