import { UserStore } from "src/app/stores/store.user";
import { closest } from "@syncfusion/ej2-base";
import { fit_modal_body } from "./fitmodalbody";
import { showNewDependentDetail } from "./showNewDependentDetail";

export const onShowNewDependentDetail = function(data) {

  this.props.selectedDependent = `${data.FirstName} ${data.LastName}`;



  showNewDependentDetail(
    data.QualifiedRetiredPersonModificationRequestNumber,
    data.QualifiedRetiredPersonModificationRequestNewDependentRecordNumber,
    (response: any) => {
     this.props.currentSelectedDependent = response;
      this.completed = response;
      this.modalService
        .open(this.newdependent, { centered: true, size: "lg" })
        .result.then(
          result => {

          },
          reason => {
            // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
      setTimeout(() => {
        fit_modal_body($(".modal"));
      }, 100);
    }
  );

};
