export default `
<div class="row">
<div class="col-md-6">
    <div class="form-group">
      <label>Foreign Source Of Funds</label>
      <div class="input-group">
        <input
        [style.background-color]="compare(props.currentSelectedModification.NewForeignSourceOfFunds,props.currentSelectedModification.OldForeignSourceOfFunds) ? '#AEF0B7' : '#fff'"
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedModification.NewForeignSourceOfFunds"
          class="form-control"
        />
      </div>
    </div>
  </div>

  <div class="col-md-6">
    <div class="form-group">
      <label>Local Financial Institution</label>
      <div class="input-group">
        <input
        [style.background-color]="compare(props.currentSelectedModification.NewLocalfinancialInstitution,props.currentSelectedModification.OldLocalfinancialInstitution) ? '#AEF0B7' : '#fff'"
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedModification.NewLocalfinancialInstitution"
          class="form-control"
        />
      </div>
    </div>
  </div>







  </div>
`;
