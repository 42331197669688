import { AfterViewInit, Component,ViewChild, OnInit, Input, OnChanges } from '@angular/core';
import DataAction from '../../../actions/action.data';
import { DetailRowService, GridModel, FilterSettingsModel, RowSelectEventArgs, GridComponent } from '@syncfusion/ej2-ng-grids';
import {PageSettingsModel } from '@syncfusion/ej2-ng-grids';
import { ToolbarItems } from '@syncfusion/ej2-ng-grids';
import axios from "axios";
import { UserStore } from "src/app/stores/store.user";
import { closest } from "@syncfusion/ej2-base";
import { fit_modal_body } from "./TourOperatorModificationSections/actions/fitmodalbody";
//import { showDocuments } from "./showDocuments";
//import { createDocumentGrid } from "./createDocumentGrid";

import guid from '../../../constants/guid';
import {
  Grid,
  Group,
  Filter,
  Sort,
  ExcelExport,
  Page,
  Toolbar,
  PdfExport,
  Edit
} from '@syncfusion/ej2-grids';
import * as moment from 'moment';
Grid.Inject(Filter, Group, Sort, Page, ExcelExport, Toolbar, PdfExport);
@Component({
  selector: "btb-guest-registration",
  template: `
  <div class="editorActions" style="right:10px;">

          
  </div>
  <div style="position:absolute;top: 10px;left: 20px;right: 20px;bottom:10px; overflow:auto;" class="clientassign">
    <table style = "width: 100%">
      <tr>
        <td>
          <div class="form-group" style="width:100%;padding:20px;">
            <date-picker
              [model]="postdata"
              [config]="{ key: 'BeginDate', label: 'Begin Date' }"
              [editable]="false"
              [addmode]="false">
            </date-picker>
          </div>
        </td>
        <td>
          <div class="form-group" style="width:100%;padding:20px;">
            <date-picker
              [model]="postdata"
              [config]="{ key: 'EndDate', label: 'End Date' }"
              [editable]="false"
              [addmode]="false">
            </date-picker>
          </div>
        </td>
        <td>
          <div class="form-group" style="width:100%;padding:20px;padding-top: 10px;">
              <label for="sel1" style="font-weight:300">Control Id:</label> 
              <searchable 
                [placeholder]="'Control Id'" 
                [editable]="true" 
                [onChange]="_onChange"
                [model]="postdata" 
                name="ControlId" 
                [(data)]="formdata.listresources[0]['controlids']" 
                value="ControlId" 
                text="Name" 
                [displays]="['ControlIdName']" 
                theme="formSearch">
              </searchable>     
          </div>   
        </td>
        <td>
          <button 
            (click)="showGuestRegistrationForms()" 
            class="btn btn-default"          
            style = "margin-top: 6px;">
            Show GRF's
          </button>
        </td>
      </tr>
    </table> 
      
    <div style="margin:40px;">
    
    <div id='{{referenceid}}'>
    </div>
	  
	  </div> 
	  
	  <div style="margin:40px;">
    <div class="col-md-12">  
    <div *ngIf="selectedrecords !== undefined && selectedrecords !== null" class="col-md-6">
    <h3>Guest Registration Details</h3>
     <div class="col-md-6">
      <table class="table">
        <tbody>
         <tr>
            <th style="font-weight:bolder">GRF Serial Number</th>
            <td>{{selectedrecords.GuestRegistrationFormSerialNumber}}</td>
         </tr>
         <tr>
             <th style="font-weight:bolder">Rental Space Type</th>
             <td>{{selectedrecords.RentalSpaceType}}</td>
         </tr>
         <tr>
            <th style="font-weight:bolder">Date Entered</th>
            <td>{{formatDate(selectedrecords.DateEntered)}}</td>
         </tr>
         <tr>
            <th style="font-weight:bolder">First Name</th>
            <td>{{selectedrecords.FirstName}}</td>
         </tr>
         <tr>
            <th style="font-weight:bolder">Last Name</th>
            <td>{{selectedrecords.LastName}}</td>
         </tr>
         <tr>
            <th style="font-weight:bolder">Middle Name</th>
            <td>{{selectedrecords.MiddleName}}</td>
         </tr>
         <tr>
            <th style="font-weight:bolder">Address</th>
            <td>{{selectedrecords.Address}}</td>
         </tr>
         <tr>
            <th style="font-weight:bolder">City</th>
            <td>{{selectedrecords.City}}</td>
         </tr>
         <tr>
            <th style="font-weight:bolder">State </th>
            <td>{{selectedrecords.State}}</td>
         </tr>
         <tr>
            <th style="font-weight:bolder">Zip Code </th>
            <td>{{selectedrecords.ZipCode}}</td>
         </tr>
         <tr>
            <th style="font-weight:bolder">Country Id</th>
            <td>{{selectedrecords.GuestCountryId}}</td>
         </tr>
         <tr>
            <th style="font-weight:bolder">Telephone Number</th>
            <td>{{selectedrecords.TelephoneNumber}}</td>
         </tr>
         <tr>
            <th style="font-weight:bolder">Email Address</th>
            <td>{{selectedrecords.EmailAddress}}</td>
         </tr>
         <tr>
           <th style="font-weight:bolder">Document Type</th>
            <td>{{selectedrecords.DocumentType}}</td>
        </tr>
        <tr>
            <th style="font-weight:bolder">Document Number</th>
           <td>{{selectedrecords.DocumentNumber}}</td>
        </tr>
        <tr>
            <th style="font-weight:bolder">Document Country Id</th>
           <td>{{selectedrecords.DocumentCountryIdOfIssue}}</td>
        </tr>
        <tr>
             <th style="font-weight:bolder">Document Expiration Date </th>
           <td>{{formatDate(selectedrecords.DocumentExpirationDate)}}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="col-md-6">
        <table class="table">
        <tbody> 
        <tr>
            <th style="font-weight:bolder">Number Of Adults</th>
            <td>{{selectedrecords.NumberOfAdults}}</td>
        </tr>
        <tr>
            <th style="font-weight:bolder">Number Of Children</th>
            <td>{{selectedrecords.NumberOfChildren}}</td>
        </tr>
        <tr>
            <th style="font-weight:bolder">Room Number</th>
            <td>{{selectedrecords.RoomNumber}}</td>
        </tr>
        <tr>
            <th style="font-weight:bolder">Number Of Nights</th>
            <td>{{selectedrecords.NumberOfNights}}</td>
        </tr>
        <tr>
            <th style="font-weight:bolder">Check In Date</th>
            <td>{{formatDate(selectedrecords.CheckInDate)}}</td>
        </tr>
        <tr>
            <th style="font-weight:bolder">Check Out Date</th>
            <td>{{formatDate(selectedrecords.CheckOutDate)}}</td>
        </tr>
        <tr>
            <th style="font-weight:bolder">Hotel Invoice Number</th>
            <td>{{selectedrecords.HotelInvoiceNumber}}</td>
        </tr>
        <tr>
            <th style="font-weight:bolder">Reference Number</th>
            <td>{{selectedrecords.ReferenceNumber}}</td>
        </tr>
        <tr>
          <th style="font-weight:bolder">Tax Exempt</th>
            <td>{{selectedrecords.TaxExempt}}</td>
        </tr>
        <tr>
            <th style="font-weight:bolder">Complimentary Stay</th>
          <td>{{selectedrecords.ComplimentaryStay}}</td>
        </tr>
        <tr>
            <th style="font-weight:bolder">No Show</th>
          <td>{{selectedrecords.NoShow}}</td>
        </tr>
        <tr>
            <th style="font-weight:bolder">Nightly Room Rate</th>
          <td>{{selectedrecords.NightlyRoomRate}}</td>
        </tr>
        <tr>
            <th style="font-weight:bolder">Room Charges</th>
          <td>{{selectedrecords.RoomCharges}}</td>
        </tr>
        <tr>
            <th style="font-weight:bolder">Accommodation Tax Rate</th>
          <td>{{selectedrecords.AccommodationTaxRate}}</td>
        </tr>
        <tr>
            <th style="font-weight:bolder">Accommodation Tax Amount</th>
          <td>{{selectedrecords.AccommodationTaxAmount}}</td>
        </tr>
        <tr>
            <th style="font-weight:bolder">Notes</th>
          <td>{{selectedrecords.GRFNotes}}</td>
        </tr>
        <tr>
            <th style="font-weight:bolder">Discount Notes</th>
          <td>{{selectedrecords.DiscountNotes}}</td>
        </tr>
        <tr>
            <th style="font-weight:bolder">Void Date</th>
          <td>{{selectedrecords.VoidDate}}</td>
        </tr>
        <tr>
            <th style="font-weight:bolder">Status</th>
          <td>{{selectedrecords.GRFStatus}}</td>
        </tr>
        </tbody>
      </table>
    </div>
   
   </div>
   <div *ngIf="selectedrecords !== undefined && selectedrecords !== null" class="col-md-6">
   <h3>Tax Exempt Letters</h3>
   <div id="{{ referenceid8 }}"></div>
   </div>
   </div>
	 </div>
     
        
   
  </div>`,
  providers: [DetailRowService]
})
export class BTBGuestRegistration implements AfterViewInit {
  public data: Object[];
  public toolbarOptions: ToolbarItems[];
  public pageSettings: PageSettingsModel;
  public postdata = { 
    BeginDate: "",
    EndDate: "",
    ControlId : ""
  };
  public filterOptions: FilterSettingsModel;
  public selectedrecords:any;
  public completeGrid:Grid = null;
  public referenceid = guid.create('syncfusion');
  public grid: Grid = null;
  public referenceid8 = guid.create('syncfusion');
  
  _onChange = null;
  
  
  constructor() {
    this.filterOptions = {
      type: 'Menu'
   };
    
   this._onChange = changed => {
      this.postdata.ControlId = changed.ControlId;
    };
  }

  formatDate(value: any){
      return moment(value).format('LL');
  }



  onDocument(id) {
    
    
    this.getDocuments(
      {GuestRegistrationFormSerialNumber:id},
      (response: any) => {        
        setTimeout(() => {
          this.createDocumentGrid(response);
        }, 100);       
      }
    );
  
  };

  getDocuments(showPostData,callback){
    axios
    .post("/getguestregistrationformexemptionletterlist", showPostData, {
      headers: {
        "user-token": UserStore.getUserDetails().token,
        "site-token": "7pdvAXwKchuAQGw7QBLnSHS",
        "user-id": 1,
        "control-id": 1
      }
    })
    .then((response: any) => {
      callback(response.data.GuestRegistrationFormExemptionLetters);
    });
  }

 


  createImage(url, TourOperatorId, documentId, caption) {
    var xhr = new XMLHttpRequest();
    xhr.responseType = "blob"; //so you can access the response like a normal URL
    xhr.onreadystatechange = function() {
      if (xhr.readyState == XMLHttpRequest.DONE && xhr.status == 200) {
        $["fancybox"].open({
          padding: 0,
          src: URL.createObjectURL(xhr.response),
          type: "iframe",
          caption
        });
      }
    };
    xhr.open("GET", url, true);
    xhr.setRequestHeader("user-token", UserStore.getUserDetails().token);
    xhr.setRequestHeader("site-token", "7pdvAXwKchuAQGw7QBLnSHS");
    xhr.setRequestHeader("user-id", "1");
    xhr.setRequestHeader("control-id", "1");
    xhr.setRequestHeader(
      "GuestRegistrationFormSerialNumber",
      TourOperatorId.toString()
    );
    xhr.setRequestHeader("document-id", documentId.toString());
    xhr.send();
  };

  createDocumentGrid(dataSource: any) {

    const onViewDocument = args => {
      const rowObj = this.completeGrid.getRowObjectFromUID(
        closest(args.target, ".e-row").getAttribute("data-uid")
      );
      const data: any = rowObj.data;
  
      this.createImage(
        "/getguestregistrationformexemptionletter",        
        data.GuestRegistrationFormSerialNumber,
        data.GRFExemptionLetterRecordNumber,
        data.ExemptionLetterName
      );
  
    };
  
    const cols = [
      {
        field: "GRFExemptionLetterRecordNumber",
        headerText: "Checkpoint Comments",
        visible: false
      },
  
     
      {
        field: "ExemptionLetterName",
        headerText: "Name"
      },
      
  
      {
        headerText: "",
        isUnbound: false,
        commands: [
          {
            buttonOption: {
              content: "View",
              cssClass: "e-flat",
              click: onViewDocument
            }
          }
        ]
      }
    ];
  
    this.completeGrid = new Grid({
      dataSource,
      allowTextWrap: true,
      textWrapSettings: {
        wrapMode: "Content"
      },
      columns: cols
    });
    $(`#${this.referenceid8}`).html("");
    this.completeGrid.appendTo(`#${this.referenceid8}`);
  };



  rowSelected(args: RowSelectEventArgs) {
   
    this.selectedrecords = this.grid.getSelectedRecords()[0];  // Get the selected records.
    this.onDocument(this.selectedrecords.GuestRegistrationFormSerialNumber);
    
}
  
  @Input() formdata: any;
  ngOnInit(): void {
    this.pageSettings = { pageSize: 6 };
    this.toolbarOptions = ['Search'];
  }
  controlids = [];



  ngAfterViewInit() {

    const cols = [
      {
        field: 'GuestRegistrationFormSerialNumber',
        headerText: 'Serial Number'
      },
      {
        field: 'HotelInvoiceNumber',
        headerText: 'Invoice Number'
      },
      {
        field: 'ReferenceNumber',
        headerText: 'Reference Number'
      },
      {
        field: 'FirstName',
        headerText: 'First Name'
      },
      {
        field: 'LastName',
        headerText: 'Last Name'
      },
      {
        field: 'CheckInDate',
        headerText: 'Check In',
        type: 'date',
        format: 'yMd',
        filter: {type: 'Menu'}
      },
      {
        field: 'CheckOutDate',
        headerText: 'Check Out',
        type: 'date',
        format: 'yMd',
        filter: {type: 'Menu'}
      }
    ];

    this.grid = new Grid({
      dataSource: this.data,
      allowPaging: true,
      allowGrouping: true,
      allowFiltering: true,
      allowSorting: true,
      filterSettings: { type: 'Excel' },
      allowPdfExport: true,
      allowExcelExport: true,
      toolbar: [
        'ExcelExport',
        'PdfExport'
      ],
      columns: cols,
      rowSelected: this.rowSelected.bind(this)
    });
    this.grid.appendTo(`#${this.referenceid}`);
    this.grid.toolbarClick = (args: Object) => {
      if (args['item'].id === `${this.referenceid}_excelexport`) {
        this.grid.excelExport();
      }
      if (args['item'].id === `${this.referenceid}_pdfexport`) {
        this.grid.pdfExport();
      }
    };
  }


private dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/;

reviver(key, value) {
      if (key === 'CheckInDate' || key === 'CheckOutDate' ) {
          return new Date(value);
      }
      
      return value;
  }
   

showGuestRegistrationForms() {
  axios
    .post("/api/GetGuestRegistrationFormList", this.postdata, {
      headers: {
        "user-token": UserStore.getUserDetails().token,
        "site-token": "7pdvAXwKchuAQGw7QBLnSHS"
      }
    })
    .then(response => {
      this.selectedrecords = null;
      this.grid.dataSource = response.data.GuestRegistrationFormList;
      this.grid.refresh();
    });
}
}
