import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { ThemeManagementComponent } from './components/angular/themes/component.theme.thememanagement';
import { SearchableComponentModal } from './components/angular/widgets/component.widget.avancesearchmodal';
import { BTBFileUploader } from './components/angular/widgets/component.widget.btbfileuploader';
import { TemplateRenderComponent } from './components/angular/themes/component.theme.templaterender';
import { SideBarTemplateComponent } from './components/angular/themes/component.theme.sidebar.template';
import { CenteredIntroPageComponent } from './components/angular/themes/component.theme.centeredintro.page';
import { SideBarComponent } from './components/angular/themes/component.theme.sidebar';
import { DashboardAdminComponent } from './components/angular/themes/component.theme.sidebar.dashboard';
import { ActionAssignmentComponent } from './components/angular/widgets/component.widget.actionassignment';
import { FormRenderComponent } from './components/angular/form/component.formrender';
import { LogoComponent } from './components/angular/themes/component.theme.logo';
import { SearchableComponent } from './components/angular/widgets/component.widget.advancesearch';
import { AuthenticationComponent } from './components/angular/widgets/component.widget.authentication';
import { SelectDSComponent } from './components/angular/widgets/component.widget.select';
import { CodeComponent } from './components/angular/widgets/component.widget.codemirror';
import { CalLinksComponent } from './components/angular/widgets/component.widget.callinks';
import { ParsleyDirective } from './directives/directive.parsely';
import { sortable } from './directives/directive.sortable';
import { SimpleTableComponent } from './components/angular/widgets/component.widget.simpletable';
import { FormBodyComponent } from './components/angular/form/component.formbodyrender';
import { TableViewComponent } from './components/angular/widgets/component.widget.tableview';
import { ClientAssignmentComponent } from './components/angular/widgets/component.widget.clientassignment';
import { TagSearchComponent } from './components/angular/widgets/component.widget.tagsearch';
import { DatePicker } from './components/angular/widgets/component.widget.datepicker';
import { EditableTableComponent } from './components/angular/widgets/component.widget.editabletable';
import { HeaderComponent } from './components/angular/themes/component.theme.sidebar.header';
import { BTBInterestCalc } from './components/angular/widgets/component.widget.interestcalc';
import { BTBSendStatement } from './components/angular/widgets/component.widget.sendstatement';
import { SystemSettings } from './components/angular/widgets/component.widget.settings';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { BTBGuestRegistration } from './components/angular/widgets/component.widget.guestregistration';
import { BTBOnlinePortalMessages } from './components/angular/widgets/component.widget.onlineportalmessages';
import { EditorModule } from '@tinymce/tinymce-angular';
import { BTBGetUserActivityRecords } from './components/angular/widgets/component.widget.getuseractivityrecords';
import { ViewHotelApplicationRequest } from './components/angular/widgets/component.widget.viewhotelapplicationrequest';
import { ViewHotelModificationRequest } from './components/angular/widgets/component.widget.viewhotelmodificationrequest';
import { ViewTourOperatorApplicationRequest } from './components/angular/widgets/component.widget.viewtouroperatorapplicationrequest';
import { ViewTourOperatorModificationRequest } from './components/angular/widgets/component.widget.viewtouroperatormodificationrequest';
import { ViewTourGuideApplicationRequest } from './components/angular/widgets/component.widget.viewtourguideapplicationrequest';
import { ViewTourGuideModificationRequest } from './components/angular/widgets/component.widget.viewtourguidemodificationrequest';
import { UserControlComponent } from './components/angular/widgets/component.widget.usercontrols';

import { ViewQualifiedRetiredPersonApplicationRequest } from './components/angular/widgets/component.widget.viewqualifiedretiredpersonapplicationrequest';
import { ViewQualifiedRetiredPersonModificationRequest } from './components/angular/widgets/component.widget.viewqualifiedretiredpersonmodificationrequest';
import { HotelAccommodationTaxReturn} from './components/angular/widgets/component.widget.hotelaccommodationtaxreturn';




@NgModule({
  imports: [BrowserModule, FormsModule, NgbModule, EditorModule],
  declarations: [  
    AppComponent,
    ThemeManagementComponent,
    BTBGuestRegistration,
    SearchableComponentModal,
    BTBOnlinePortalMessages,
    BTBFileUploader,
    TemplateRenderComponent,
    SideBarTemplateComponent,
    CenteredIntroPageComponent,
    HeaderComponent,
    SideBarComponent,
    DashboardAdminComponent,
    ActionAssignmentComponent,
    FormRenderComponent,
    LogoComponent,
    SearchableComponent,
    AuthenticationComponent,
    SelectDSComponent,
    CodeComponent,
    BTBInterestCalc,
    CalLinksComponent,
    ViewHotelApplicationRequest,
    ViewHotelModificationRequest,
    ViewTourOperatorApplicationRequest,
    ViewTourOperatorModificationRequest,
    ViewTourGuideApplicationRequest,
    ViewTourGuideModificationRequest,
    ParsleyDirective,
    sortable,
    SimpleTableComponent,
    FormBodyComponent,
    TableViewComponent,
    ClientAssignmentComponent,
    TagSearchComponent,
    DatePicker,
    EditableTableComponent,
    BTBSendStatement,
    SystemSettings,
    BTBGetUserActivityRecords,
    ViewQualifiedRetiredPersonApplicationRequest,
    ViewQualifiedRetiredPersonModificationRequest,
    UserControlComponent,
    HotelAccommodationTaxReturn
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
