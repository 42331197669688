import {Component, Input} from "@angular/core";
@Component({

    selector: "dashboard-admin",
    template: `<div  style="position: relative;height: 100%;width:100%;">
<form-render [formdata]="formdata"></form-render>
</div>`
})
export class DashboardAdminComponent {
    @Input() formdata: any;
}

