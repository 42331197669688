export default `
<div class="row">
<div class="col-md-6">
    <div class="form-group">
      <label>Tour Guide Association</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedApplication.TourGuideAssociationName"
          class="form-control"
        />
      </div>
    </div>
  </div>

  <div class="col-md-6">
    <div class="form-group">
      <label>Tour Guide Association District</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedApplication.TourGuideAssociationDistrictName"
          class="form-control"
        />
      </div>
    </div>
  </div>

  <div class="col-md-4">
    <div class="form-group">
      <label>Education Level</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedApplication.EducationLevel"
          class="form-control"
        />
      </div>
    </div>
  </div>

    <div class="col-md-4">
      <div class="form-group">
        <label>Application Fee Paid</label>
        <div class="input-group">
          <input
            readOnly="readOnly"
            [(ngModel)]="props.currentSelectedApplication.ApplicationFeeAmount"
            class="form-control"
          />
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label>Date Paid</label>
        <div class="input-group">
          <input
            readOnly="readOnly"
            [(ngModel)]="
              props.currentSelectedApplication.ApplicationFeePaymentDate
            "
            class="form-control"
          />
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label>Receipt No.</label>
        <div class="input-group">
          <input
            readOnly="readOnly"
            [(ngModel)]="
              props.currentSelectedApplication.ApplicationFeeReceiptNumber
            "
            class="form-control"
          />
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label>License Fee Paid</label>
        <div class="input-group">
          <input
            readOnly="readOnly"
            [(ngModel)]="props.currentSelectedApplication.LicenseFeeAmount"
            class="form-control"
          />
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label>Date Paid</label>
        <div class="input-group">
          <input
            readOnly="readOnly"
            [(ngModel)]="props.currentSelectedApplication.LicenseFeePaymentDate"
            class="form-control"
          />
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label>Receipt No.</label>
        <div class="input-group">
          <input
            readOnly="readOnly"
            [(ngModel)]="props.currentSelectedApplication.LicenseFeeReceiptNumber"
            class="form-control"
          />
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label>License Date</label>
        <div class="input-group">
          <input
            readOnly="readOnly"
            [(ngModel)]="props.currentSelectedApplication.LicenseDate"
            class="form-control"
          />
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label>License #</label>
        <div class="input-group">
          <input
            readOnly="readOnly"
            [(ngModel)]="props.currentSelectedApplication.LicenseNumber"
            class="form-control"
          />
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label>License Year</label>
        <div class="input-group">
          <input
            readOnly="readOnly"
            [(ngModel)]="props.currentSelectedApplication.LicenseYear"
            class="form-control"
          />
        </div>
      </div>
    </div>





    <div class="col-md-4">
    <div class="form-group">
    <label>Tour Guide Id Number</label>
    <div class="input-group">
    <input
    readOnly="readOnly"
    class="form-control"
    [(ngModel)]="props.currentSelectedApplication.TourGuideIdNumber"
    />
    </div>
    </div>
    </div>



    <div class="col-md-4">
    <div class="form-group">
    <label>User Id</label>
    <div class="input-group">
    <input
    readOnly="readOnly"
    class="form-control"
    [(ngModel)]="props.currentSelectedApplication.UserId"
    />
    </div>
    </div>
    </div>






  </div>
`;
