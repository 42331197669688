import dispatcher from '../dispatcher/dispatcher.app';
import constants from '../constants/constants';
import EventEmitter from 'eventemitter-rx';

class DataStoreClass {


    public emitter:EventEmitter = new EventEmitter();
    
    private currentModel = {};
    private nextAction = null;   

    constructor() {
        dispatcher.register((payload) => {
            this.__invokeOnDispatch(payload);
        });
    }   
    
    public updateState(state) {
        if (state === false) {
            this.emitter.next(constants.DataActions.READONLY_STATE, null);
        } else {
            this.emitter.next(constants.DataActions.EDITABLE_STATE, null);
        }
    }

    public emitBlankState(id) {
        let payload = {
            'dataid': id, 'data': []
        };
        this.emitter.next(constants.DataActions.DATA_AVAILABLE, [payload]);
    }

    public setCurrentModel(model) {
        this.currentModel = model;
    }

    public obtainCurrentModel() {
        this.emitter.next(constants.DataActions.OBTAIN_CURRENT, null);
    }

    public nextObatinAction(callback) {
        this.nextAction = callback;

    }

    public runNextObtainAction() {
        this.nextAction(this.currentModel);
    }

    public triggerNoData(dataid)
    {
        this.emitter.next(constants.DataActions.DATA_AVAILABLE, [{
            'dataid': dataid,
            'data': []
        }]);
    }

    private __invokeOnDispatch(payload:any) {

        switch (payload.action) {
            case constants.DataActions.DATA_AVAILABLE:
            {
                this.emitter.next(constants.DataActions.DATA_AVAILABLE, [payload.data]);
            }
                break;
            case constants.DataActions.RANGE_CHANGED:
            {
                this.emitter.next(constants.DataActions.RANGE_CHANGED, null);
            }
                break;

        }
    }

   

}

export var DataStore = new DataStoreClass();