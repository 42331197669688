export default `
<div class="row">



<div class="col-md-6">
    <div class="form-group">
      <label>First Name</label>
      <div class="input-group">
        <input
        [style.background-color]="compare(props.currentSelectedModification.NewFirstName,props.currentSelectedModification.OldFirstName) ? '#AEF0B7' : '#fff'"
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedModification.NewFirstName"
          class="form-control"
        />
      </div>
    </div>
  </div>

  <div class="col-md-6">
    <div class="form-group">
      <label>Last Name</label>
      <div class="input-group">
        <input
        [style.background-color]="compare(props.currentSelectedModification.NewLastName,props.currentSelectedModification.OldLastName) ? '#AEF0B7' : '#fff'"
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedModification.NewLastName"
          class="form-control"
        />
      </div>
    </div>
  </div>

  <div class="col-md-4">
    <div class="form-group">
      <label>Date of Birth</label>
      <div class="input-group">
        <input
        [style.background-color]="compare(props.currentSelectedModification.NewDateOfBirth,props.currentSelectedModification.OldDateOfBirth) ? '#AEF0B7' : '#fff'"
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedModification.NewDateOfBirth"
          class="form-control"
        />
      </div>
    </div>
  </div>

  <div class="col-md-4">
    <div class="form-group">
      <label>Gender</label>
      <div class="input-group">
        <input
        [style.background-color]="compare(props.currentSelectedModification.NewGender,props.currentSelectedModification.OldGender) ? '#AEF0B7' : '#fff'"
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedModification.NewGender"
          class="form-control"
        />
      </div>
    </div>
  </div>

  <div class="col-md-4">
  <div class="form-group">
    <label>Social Security Number</label>
    <div class="input-group">
      <input
      [style.background-color]="compare(props.currentSelectedModification.NewSocialSecurityNumber,props.currentSelectedModification.OldSocialSecurityNumber) ? '#AEF0B7' : '#fff'"
        readOnly="readOnly"
        [(ngModel)]="props.currentSelectedModification.NewSocialSecurityNumber"
        class="form-control"
      />
    </div>
  </div>
  </div>



  <div class="col-md-4">
  <div class="form-group">
    <label>Tour Guide Category</label>
    <div class="input-group">
      <input
      [style.background-color]="compare(props.currentSelectedModification.NewTourGuideCategory,props.currentSelectedModification.OldTourGuideCategory) ? '#AEF0B7' : '#fff'"
        readOnly="readOnly"
        [(ngModel)]="props.currentSelectedModification.NewTourGuideCategory"
        class="form-control"
      />
    </div>
  </div>
</div>





  <div class="col-md-4">
  <div class="form-group">
    <label>District Area Of Operation</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        [style.background-color]="compare(props.currentSelectedModification.NewDistrictAreaOfOperationName,props.currentSelectedModification.OldDistrictAreaOfOperationName) ? '#AEF0B7' : '#fff'"
        [(ngModel)]="props.currentSelectedModification.NewDistrictAreaOfOperationName"
        class="form-control"
      />
    </div>
  </div>
  </div>

  <div class="col-md-4">
  <div class="form-group">
    <label>Training Program Completion Date</label>
    <div class="input-group">
      <input
      [style.background-color]="compare(props.currentSelectedModification.NewDistrictAreaOfOperationName,props.currentSelectedModification.OldDistrictAreaOfOperationName) ? '#AEF0B7' : '#fff'"
        readOnly="readOnly"
        [(ngModel)]="props.currentSelectedModification.NewTrainingProgramCompletionDate"
        class="form-control"
      />
    </div>
  </div>
  </div>

  <div class="col-md-6">
  <div class="form-group">
    <label>Tour Guide Type</label>
    <div class="input-group">
      <input
      [style.background-color]="compare(props.currentSelectedModification.NewTourGuideType,props.currentSelectedModification.OldTourGuideType) ? '#AEF0B7' : '#fff'"
        readOnly="readOnly"
        [(ngModel)]="props.currentSelectedModification.NewTourGuideType"
        class="form-control"
      />
    </div>
  </div>
</div>

<div class="col-md-6">
  <div class="form-group">
    <label>Tour Guide Site</label>
    <div class="input-group">
      <input
      [style.background-color]="compare(props.currentSelectedModification.NewTourGuideSite,props.currentSelectedModification.OldTourGuideSite) ? '#AEF0B7' : '#fff'"
        readOnly="readOnly"
        [(ngModel)]="props.currentSelectedModification.NewTourGuideSite"
        class="form-control"
      />
    </div>
  </div>
</div>







</div>



`;
