import { Component, Input, AfterViewInit, ViewChild } from "@angular/core";
import axios from "axios";
import { closest } from "@syncfusion/ej2-base";
import * as _ from "underscore";
import { UserStore } from "../../../stores/store.user";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import swal from "sweetalert2";
import {
  DetailRowService,
  GridModel,
  FilterSettingsModel,
  RowSelectEventArgs,
  GridComponent
} from "@syncfusion/ej2-ng-grids";
import { PageSettingsModel } from "@syncfusion/ej2-ng-grids";
import { ToolbarItems } from "@syncfusion/ej2-ng-grids";
import guid from "../../../constants/guid";
import { fit_modal_body } from "./fitmodalbody";
import {
  Grid,
  Group,
  CommandColumn,
  Filter,
  Sort,
  ExcelExport,
  Page,
  Toolbar,
  PdfExport,
  Edit
} from "@syncfusion/ej2-grids";

Grid.Inject(
  Filter,
  Group,
  Sort,
  Page,
  ExcelExport,
  Toolbar,
  PdfExport,
  CommandColumn
);

import generalinformation from "./hotelmodificationSections/generalinformation";
import degregistration from "./hotelmodificationSections/degregistration";
import contactinformation from "./hotelmodificationSections/contactinformation";
import modifymanager from "./hotelmodificationSections/modifymanager";
import modifyowner from "./hotelmodificationSections/modifyowner";
import newmanager from "./hotelmodificationSections/newmanager";
import newowner from "./hotelmodificationSections/newowner";
import facility from "./hotelmodificationSections/facility";
import tempclosure from "./hotelmodificationSections/tempclosure";
@Component({
  selector: "view-modification-request",
  template: `
    <section id="general">
      <ng-template #content let-modal>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Hotel Modification</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          ${generalinformation} ${contactinformation} ${newowner} ${modifyowner}
          ${newmanager} ${modifymanager} ${facility} ${tempclosure} ${degregistration}
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-dark"
            (click)="modal.close('Save click')"
          >
            Close
          </button>
        </div>
      </ng-template>

      <ng-template #document let-modal>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Documents Attached</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div><div id="{{ referenceid5 }}"></div></div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-dark"
            (click)="modal.close('Save click')"
          >
            Close
          </button>
        </div>
      </ng-template>

      <ng-template #complete let-modal>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">
           Checkpoints
          </h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div><div id="{{ referenceid22 }}"></div></div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-dark"
            (click)="processDetails(modal)"
          >
            Process
          </button>

          <button
            type="button"
            class="btn btn-outline-dark"
            (click)="modal.close('Save click')"
          >
            Close
          </button>
        </div>
      </ng-template>

      <div class="editorActions" style="right:10px;"></div>
      <div
        style="position:absolute;top: 10px;left: 20px;right: 20px;bottom:10px; overflow:auto;"
        class="clientassign"
      >
        <div class="row" style="margin:50px 0px 0px 25px">
          <div class="form-group" style="width:300px;margin:20px;">
            <label for="sel1">Control Id:</label>

            <searchable
              [placeholder]="'Hotel'"
              [editable]="true"
              [onChange]="_onChange"
              [model]="postdata"
              name="IdNumber"
              [(data)]="formdata.listresources[0]['controlids']"
              value="IdNumber"
              text="Name"
              [displays]="['ControlId']"
              theme="formSearch"
            ></searchable>
          </div>

          <div class="col-md-3" style="margin-top:15px;">
            <button (click)="showModificationsList()" class="btn btn-default">
              Show Modifications
            </button>
          </div>
        </div>

        <div style="margin:40px;"><div id="{{ referenceid }}"></div></div>
      </div>
    </section>

    <ng-template #approvecomplete let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Complete</h4>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-md-12">
          <date-picker
            [model]="postDataComplete"
            [config]="{ key: 'CheckpointDate', label: 'Checkpoint Date' }"
            [editable]="false"
            [addmode]="false"
          ></date-picker>
        </div>

        <div class="col-md-12">
          <div class="form-group">
            <label>Comments</label>
            <div class="input-group">
              <input
                class="form-control"
                [(ngModel)]="postDataComplete.CheckpointComments"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-outline-dark"
          (click)="modal.close('Save click')"
        >
          Save
        </button>
        <button
          type="button"
          class="btn btn-outline-dark"
          (click)="modal.close('Close click')"
        >
          Close
        </button>
      </div>
    </ng-template>
  `
})
export class ViewHotelModificationRequest implements AfterViewInit {
  

  public headerText: Object = [
    { id: "after", text: "After", content: "#content" },
    { id: "before", text: "Before", content: "#content" }
  ];
  @ViewChild("content", {static: false})
  content;
  @ViewChild("complete", {static: false})
  complete;
  @Input() test: any;

  @ViewChild("image", {static: false})
  image;
  @ViewChild("document", {static: false})
  document;
  @ViewChild("approvecomplete", {static: false})
  approvecomplete;

  public postDataComplete = {
    HotelModificationRequestNumber: "",
    CheckpointName: "",
    HotelModificationRequestCheckpointRecordNumber: "",
    CheckpointDate: "",
    CompletedBy: UserStore.getUserName(),
    CheckpointComments: ""
  };

  public data = [];

  @Input() formdata: any;

  public postdata = { IdNumber: "" };
  public currentSelectedModification = {};
  public grid: Grid = null;
  public _onChange = null;
  public referenceid = guid.create("syncfusion");
  public referenceid2 = guid.create("syncfusion2");
  public referenceid3 = guid.create("syncfusion3");
  public referenceid22 = guid.create("referenceid22");
  public referenceid5 = guid.create("referenceid5");
  public referenceid14 = guid.create("referenceid14");
  public completed = [];
  public newOwners = false;
  public newManagers = false;
  public facilities = false;
  public requestType = [];

  public managers = {};
  public owners = [];
  public sections = [];
  public temporaryClosure = {};
  public deregistration = {};
  public completeGridTrack = null;
  public completeId = "";

  processDetails(modal) {
    var allowComplete = true;
    if (this.completed !== undefined) {
      this.completed.forEach(appcomplete => {
        if (
          appcomplete.CompletedBy === null ||
          appcomplete.CompletedBy === undefined ||
          appcomplete.CompletedBy === ""
        ) {
          allowComplete = false;
        }
      });
    }

    if (!allowComplete) {
      if (
        confirm(
          "Not all checkpoints were completed. Would you like to process anyway?"
        )
      ) {
        axios
          .post(
            "/api/processhotelmodificationrequest",
            {
              HotelModificationRequestNumber: this.completeId
            },
            {
              headers: {
                "user-token": UserStore.getUserDetails().token,
                "site-token": "7pdvAXwKchuAQGw7QBLnSHS",
                "user-id": 1,
                "control-id": 1
              }
            }
          )
          .then((response: any) => {
            modal.close();
            if (response.data.Error) {
              swal.fire({
                title: "Process Error",
                text: `${response.data.Message}. ${response.data.Error}`,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Close"
              });
            } else {
              swal.fire({
                title: "Process Complete",
                text: `${response.data.Message}.`,
                icon: 'info',
                showCancelButton: false,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Close"
              });
            }
          });
      } else {
        return;
      }
    } else {
      axios
        .post(
          "/api/processhotelmodificationrequest",
          {
            HotelModificationRequestNumber: this.completeId
          },
          {
            headers: {
              "user-token": UserStore.getUserDetails().token,
              "site-token": "7pdvAXwKchuAQGw7QBLnSHS",
              "user-id": 1,
              "control-id": 1
            }
          }
        )
        .then((response: any) => {
          modal.close();
          swal.fire({
            title: "Process Complete",
            text: "Process completed successfully",
            icon: 'info',
            showCancelButton: false,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Close"
          });
        });
    }
  }

  createHotelModificationOwnerListGrid(dataSource: any) {}
  compare(val1, val2) {
    if (val1 !== undefined && val2 !== undefined) {
      return (
        val1
          .toString()
          .trim()
          .toLowerCase() !==
        val2
          .toString()
          .trim()
          .toLowerCase()
      );
    } else {
      return val1 !== val2;
    }
  }
  createHotelNewOwnerListGrid(dataSource: any) {
    const cols = [
      {
        field: "FirstName",
        headerText: "First Name"
      },
      {
        field: "MiddleName",
        headerText: "Middle Name"
      },
      {
        field: "LastName",
        headerText: "Last Name"
      },
      {
        field: "CountryName",
        headerText: "Nationality"
      },
      {
        field: "EmailAddress",
        headerText: "Email Address"
      },
      {
        field: "TelephoneNumber",
        headerText: "Telephone Number"
      },
      {
        field: "BusinessEntityType",
        headerText: "Entity Type"
      },
      {
        field: "SharePercentage",
        headerText: "Share Percentage",
        format: "#,###0.00'%'"
      }
    ];
    const ownerGrid = new Grid({
      dataSource,
      width: "100%",
      allowTextWrap: true,
      textWrapSettings: {
        wrapMode: "Content"
      },
      columns: cols
    });
    this.newOwners = dataSource !== undefined && dataSource.length > 0;
    if (this.newOwners) {
      setTimeout(() => {
        ownerGrid.appendTo(`#${this.referenceid2}`);
      }, 500);
    }
  }

  createHotelNewManagerListGrid(dataSource: any) {
    const cols = [
      {
        field: "FirstName",
        headerText: "First Name"
      },
      {
        field: "MiddleName",
        headerText: "Middle Name"
      },
      {
        field: "LastName",
        headerText: "Last Name"
      },
      {
        field: "CountryName",
        headerText: "Nationality"
      },
      {
        field: "TelephoneNumber",
        headerText: "Telephone Number"
      },
      {
        field: "EmailAddress",
        headerText: "Email Address"
      },
      {
        field: "BusinessEntityType",
        headerText: "Entity Type"
      }
    ];
    const managerGrid = new Grid({
      dataSource,
      width: "100%",
      allowTextWrap: true,
      textWrapSettings: {
        wrapMode: "Content"
      },
      columns: cols
    });
    this.newManagers = dataSource !== undefined && dataSource.length > 0;
    if (this.newManagers) {
      setTimeout(() => {
        managerGrid.appendTo(`#${this.referenceid3}`);
      }, 500);
    }
  }

  
  createHotelFacilityListGrid(dataSource: any) {

    const queryCell = (args)=>{
      if(args.data.Status === "New")
      {
        $(args.cell).css("background","#AEF0B7");
      }
      else if(args.data.Status === "Removed")
      {
        $(args.cell).css("background","#F0B1AE");
      }
      else
      {
  
      }
    }

    const cols = [
      {
        field: "FacilityTypeDescription",
        headerText: "Facility"
      },
      {
        field: "Status",
        headerText: "Status"
      }
    ];

    const facilityGrid = new Grid({
      dataSource,
      width: "100%",
      allowTextWrap: true,
      textWrapSettings: {
        wrapMode: "Content"
      },
      queryCellInfo:queryCell.bind(this),
      columns: cols
    });
    
    this.facilities = dataSource !== undefined && dataSource.length > 0;
    if (this.facilities) {
      setTimeout(() => {
        facilityGrid.appendTo(`#${this.referenceid14}`);
      }, 500);
    }
  }

  createDocumentGrid(dataSource: any) {
    let completeGrid = null;
    const onViewDocument = args => {
      const rowObj = completeGrid.getRowObjectFromUID(
        closest(args.target, ".e-row").getAttribute("data-uid")
      );
      const data: any = rowObj.data;

      this.createImage(
        "/api/GetHotelModificationRequestDocument",
        data.HotelModificationRequestNumber,
        data.HotelModificationRequestDocumentRecordNumber,
        data.DocumentName
      );

    };

    const cols = [
      {
        field: "HotelModificationRequestNumber",
        headerText: "Checkpoint Comments",
        visible: false
      },

      {
        field: "HotelModificationRequestDocumentRecordNumber",
        headerText: "Checkpoint Comments",
        visible: false
      },
      {
        field: "DocumentName",
        headerText: "Name"
      },
      {
        field: "DocumentTypeDescription",
        headerText: "Document Type"
      },
      {
        field: "IssueDate",
        headerText: "Issue Date"
      },
      {
        field: "ExpirationDate",
        headerText: "Expiration Date"
      },

      {
        headerText: "",
        isUnbound: false,
        commands: [
          {
            buttonOption: {
              content: "View",
              cssClass: "e-flat",
              click: onViewDocument
            }
          }
        ]
      }
    ];

    completeGrid = new Grid({
      dataSource,
      allowTextWrap: true,
      textWrapSettings: {
        wrapMode: "Content"
      },
      columns: cols
    });
    completeGrid.appendTo(`#${this.referenceid5}`);
  }

  createImage(url, hotelId, documentId, caption) {
    var xhr = new XMLHttpRequest();
    xhr.responseType = "blob"; //so you can access the response like a normal URL
    xhr.onreadystatechange = function() {
      if (xhr.readyState == XMLHttpRequest.DONE && xhr.status == 200) {       

        $["fancybox"].open({
          padding: 0,
          src: URL.createObjectURL(xhr.response),
          type: "iframe",
          caption
        });
      }
    };
    xhr.open("GET", url, true);
    xhr.setRequestHeader("user-token", UserStore.getUserDetails().token);
    xhr.setRequestHeader("site-token", "7pdvAXwKchuAQGw7QBLnSHS");
    xhr.setRequestHeader("user-id", "1");
    xhr.setRequestHeader("control-id", "1");
    xhr.setRequestHeader("hotel-modification-request-id", hotelId.toString());
    xhr.setRequestHeader("document-id", documentId.toString());
    xhr.send();
  }

  showDocuments(applicationId: number, callback) {
    const showPostData = {
      HotelModificationRequestNumber: applicationId
    };
    axios
      .post("/api/GetHotelModificationRequestDocumentList", showPostData, {
        headers: {
          "user-token": UserStore.getUserDetails().token,
          "site-token": "7pdvAXwKchuAQGw7QBLnSHS",
          "user-id": 1,
          "control-id": 1
        }
      })
      .then((response: any) => {
        callback(response.data.HotelModificationRequestDocuments);
      });
  }

  creatCheckListGrid(dataSource: any) {
    const onComplete = args => {
      const rowObj = this.completeGridTrack.getRowObjectFromUID(
        closest(args.target, ".e-row").getAttribute("data-uid")
      );
      const data: any = rowObj.data;

      this.postDataComplete = {
        HotelModificationRequestNumber: data.HotelModificationRequestNumber,
        HotelModificationRequestCheckpointRecordNumber:
          data.HotelModificationRequestCheckpointRecordNumber,
        CheckpointDate: "",
        CheckpointName: data.CheckpointName,
        CompletedBy: UserStore.getUserName(),
        CheckpointComments: ""
      };

      this.modalService
        .open(this.approvecomplete, { centered: true, size: "lg" })
        .result.then(
          result => {
            // this.closeResult = `Closed with: ${result}`;
            if (result === "Save click") {
              axios
                .post(
                  "/api/UpdateHotelModificationRequestCheckpoint",
                  this.postDataComplete,
                  {
                    headers: {
                      "user-token": UserStore.getUserDetails().token,
                      "site-token": "7pdvAXwKchuAQGw7QBLnSHS",
                      "user-id": 1,
                      "control-id": 1
                    }
                  }
                )
                .then((response: any) => {
                  this.showChecklist(
                    parseInt(data.HotelModificationRequestNumber.toString()),
                    response => {
                      this.completeGridTrack.dataSource =
                        response.HotelModificationRequestCheckpoints;
                    }
                  );
                });
            }
          },
          reason => {
            // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );

    };

    const cols = [
      {
        field: "CheckpointName",
        headerText: "Checkpoint"
      },
      {
        field: "CheckpointDate",
        headerText: "Date"
      },
      {
        field: "CompletedBy",
        headerText: "Completed By"
      },
      {
        field: "CheckpointComments",
        headerText: "Comments"
      },
      {
        headerText: "",
        isUnbound: false,
        commands: [
          {
            buttonOption: {
              content: "Complete",
              cssClass: "e-flat",
              click: onComplete
            }
          }
        ]
      }
    ];
    this.completeGridTrack = new Grid({
      dataSource,
      width: "100%",
      allowTextWrap: true,
      textWrapSettings: {
        wrapMode: "Content"
      },
      columns: cols
    });

    this.completeGridTrack.appendTo(`#${this.referenceid22}`);
  }

  showOwnersModifiedList(requestNumber: number, callback) {
    const showPostData = {
      HotelModificationRequestNumber: requestNumber
    };
    axios
      .post("/api/GetHotelModificationRequestOwnerList", showPostData, {
        headers: {
          "user-token": UserStore.getUserDetails().token,
          "site-token": "7pdvAXwKchuAQGw7QBLnSHS",
          "user-id": 1,
          "control-id": 1
        }
      })
      .then((response: any) => {
        callback(response.data);
      });
  }

  showOwnersNewList(requestNumber: number, callback) {
    const showPostData = {
      HotelModificationRequestNumber: requestNumber
    };
    axios
      .post("/api/GetHotelModificationRequestNewOwnerList", showPostData, {
        headers: {
          "user-token": UserStore.getUserDetails().token,
          "site-token": "7pdvAXwKchuAQGw7QBLnSHS",
          "user-id": 1,
          "control-id": 1
        }
      })
      .then((response: any) => {
        callback(response.data);
      });
  }

  showTemporaryClosure(requestNumber: number, callback) {
    const showPostData = {
      HotelModificationRequestNumber: requestNumber
    };
    axios
      .post("/api/GetHotelModificationRequestTemporaryClosure", showPostData, {
        headers: {
          "user-token": UserStore.getUserDetails().token,
          "site-token": "7pdvAXwKchuAQGw7QBLnSHS",
          "user-id": 1,
          "control-id": 1
        }
      })
      .then((response: any) => {
        callback(response.data);
      });
  }

  showDeregistration(requestNumber: number, callback) {
    const showPostData = {
      HotelModificationRequestNumber: requestNumber
    };
    axios
      .post("/api/GetHotelModificationRequestDeregistration", showPostData, {
        headers: {
          "user-token": UserStore.getUserDetails().token,
          "site-token": "7pdvAXwKchuAQGw7QBLnSHS",
          "user-id": 1,
          "control-id": 1
        }
      })
      .then((response: any) => {
        callback(response.data);
      });
  }

  sectionVisible(sectionName) {
    return this.sections.indexOf(sectionName) > -1;
  }

  showManagerNewList(requestNumber: number, callback) {
    const showPostData = {
      HotelModificationRequestNumber: requestNumber
    };
    axios
      .post("/api/GetHotelModificationRequestNewManagerList", showPostData, {
        headers: {
          "user-token": UserStore.getUserDetails().token,
          "site-token": "7pdvAXwKchuAQGw7QBLnSHS",
          "user-id": 1,
          "control-id": 1
        }
      })
      .then((response: any) => {
        callback(response.data);
      });
  }  

  showFacilityList(requestNumber: number, callback) {
    const showPostData = {
      HotelModificationRequestNumber: requestNumber
    };
    axios
      .post("/api/GetHotelModificationRequestFacilityAndNewFacilityList", showPostData, {
        headers: {
          "user-token": UserStore.getUserDetails().token,
          "site-token": "7pdvAXwKchuAQGw7QBLnSHS",
          "user-id": 1,
          "control-id": 1
        }
      })
      .then((response: any) => {
        callback(response.data);
      });
  }
  

  showChecklist(requestNumber: number, callback) {
    const showPostData = {
      HotelModificationRequestNumber: requestNumber
    };
    axios
      .post("/api/GetHotelModificationRequestCheckpointList", showPostData, {
        headers: {
          "user-token": UserStore.getUserDetails().token,
          "site-token": "7pdvAXwKchuAQGw7QBLnSHS",
          "user-id": 1,
          "control-id": 1
        }
      })
      .then((response: any) => {
        callback(response.data);
      });
  }

  showManagerModifiedList(requestNumber: number, callback) {
    const showPostData = {
      HotelModificationRequestNumber: requestNumber
    };
    axios
      .post("/api/GetHotelModificationRequestManagerList", showPostData, {
        headers: {
          "user-token": UserStore.getUserDetails().token,
          "site-token": "7pdvAXwKchuAQGw7QBLnSHS",
          "user-id": 1,
          "control-id": 1
        }
      })
      .then((response: any) => {
        callback(response.data);
      });
  }

  getHotelModificationRequestRequestTypeList(requestNumber: number) {
    const showPostData = {
      HotelModificationRequestNumber: requestNumber
    };
    axios
      .post("/api/gethotelmodificationrequestrequesttypelist", this.postdata, {
        headers: {
          "user-token": UserStore.getUserDetails().token,
          "site-token": "7pdvAXwKchuAQGw7QBLnSHS",
          "control-id": 1,
          "user-id": 1
        },
        data: {
          ...showPostData
        }
      })
      .then(response => {
        this.requestType = response.data.HotelModificationRequestRequestTypes;
      });
  }

  showModificationsList() {
    axios
      .post(
        "/api/GetHotelModificationRequestList",
        { HotelIdNumber: this.postdata.IdNumber },
        {
          headers: {
            "user-token": UserStore.getUserDetails().token,
            "site-token": "7pdvAXwKchuAQGw7QBLnSHS",
            "control-id": 1,
            "user-id": 1
          }
        }
      )
      .then(response => {
        this.grid.dataSource = response.data.HotelModificationRequests;
      });
  }

  showModificationDetail(requestNumber: number) {
    const showPostData = {
      HotelModificationRequestNumber: requestNumber
    };
    axios
      .post("/api/GetHotelModificationRequestDetail", this.postdata, {
        headers: {
          "user-token": UserStore.getUserDetails().token,
          "site-token": "7pdvAXwKchuAQGw7QBLnSHS",
          "control-id": 1,
          "user-id": 1
        },
        data: {
          ...showPostData
        }
      })
      .then(response => {
        this.currentSelectedModification =
          response.data.HotelModificationRequestDetail;
      });
  }

  ngAfterViewInit() {
    $(window).resize(() => fit_modal_body($(".modal")));
    const onClick = args => {
      const rowObj = this.grid.getRowObjectFromUID(
        closest(args.target, ".e-row").getAttribute("data-uid")
      );
      const data: any = rowObj.data;

      this.showOwnersNewList(
        data.HotelModificationRequestNumber,
        (ownerList: any) => {
          setTimeout(() => {
            this.createHotelNewOwnerListGrid(
              ownerList.HotelModificationRequestNewOwners
            );
          }, 500);
        }
      );
      this.showManagerNewList(
        data.HotelModificationRequestNumber,
        (managerList: any) => {
          setTimeout(() => {
            this.createHotelNewManagerListGrid(
              managerList.HotelModificationRequestNewManagers
            );
          }, 500);
        }
      );
      
      this.showFacilityList(
        data.HotelModificationRequestNumber,
        (facilityList: any) => {
          setTimeout(() => {
            this.createHotelFacilityListGrid(
              facilityList.HotelModificationRequestFacilities
            );
          }, 500);
        }
      );
    

      this.showDeregistration(
        data.HotelModificationRequestNumber,
        (dereg: any) => {
          //    setTimeout(() => {
          //    this.createHotelModificationOwnerListGrid(ownerList);
          this.deregistration = dereg.HotelModificationRequestDeregistration;
          //   }, 500);
        }
      );
      this.showTemporaryClosure(
        data.HotelModificationRequestNumber,
        (temporaryClosure: any) => {
          //    setTimeout(() => {
          //    this.createHotelModificationOwnerListGrid(ownerList);
          this.temporaryClosure =
            temporaryClosure.HotelModificationRequestTemporaryClosure;
          //   }, 500);
        }
      );

      this.showOwnersModifiedList(
        data.HotelModificationRequestNumber,
        (ownerList: any) => {
          //    setTimeout(() => {
          //    this.createHotelModificationOwnerListGrid(ownerList);
          this.owners = ownerList.HotelModificationRequestOwners;
          //   }, 500);
        }
      );
      this.showManagerModifiedList(
        data.HotelModificationRequestNumber,
        (managerList: any) => {
          this.managers = managerList.HotelModificationRequestManagers;
        }
      );

      this.getHotelModificationRequestRequestTypeList(
        data.HotelModificationRequestNumber
      );
      this.showModificationDetail(data.HotelModificationRequestNumber);
      this.modalService
        .open(this.content, { centered: true, size: "lg" })
        .result.then(
          result => {
            // this.closeResult = `Closed with: ${result}`;
          },
          reason => {
            // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
        setTimeout(() => {
          fit_modal_body($(".modal"));
        }, 100);
      //grid Initiation Here
    };

    const onDocument = args => {
      const rowObj = this.grid.getRowObjectFromUID(
        closest(args.target, ".e-row").getAttribute("data-uid")
      );
      const data: any = rowObj.data;

      //this.selectedhotel = data.HotelName;
      this.showDocuments(
        data.HotelModificationRequestNumber,
        (response: any) => {
          //   this.currentSelectedApplication = response;
          this.modalService
            .open(this.document, { centered: true, size: "lg" })
            .result.then(
              result => {
                // this.closeResult = `Closed with: ${result}`;
              },
              reason => {
                // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
              }
            );
          setTimeout(() => {
            this.createDocumentGrid(response);
          }, 1000);
        }
      );

    };

    const onComplete = args => {
      const rowObj = this.grid.getRowObjectFromUID(
        closest(args.target, ".e-row").getAttribute("data-uid")
      );
      const data: any = rowObj.data;
      this.completeId = data.HotelModificationRequestNumber;
      this.modalService
        .open(this.complete, { centered: true, size: "lg" })
        .result.then(
          result => {
            // this.closeResult = `Closed with: ${result}`;
            if (result === "Process click") {
            }
          },
          reason => {
            // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );

      this.showChecklist(
        data.HotelModificationRequestNumber,
        (ownerList: any) => {
          this.completed = ownerList.HotelModificationRequestCheckpoints;

          setTimeout(() => {
            this.creatCheckListGrid(
              ownerList.HotelModificationRequestCheckpoints
            );
          }, 500);
        }
      );
    };

    const cols = [
      {
        field: "HotelIdNumber",
        headerText: "Hotel Id Number",
        visible: false
      },
      {
        field: "RequestDate",
        headerText: "Request Date",
        type: "date",
        format: "yMd",
        filter: { type: "Menu" }
      },
      {
        field: "RequestNotes",
        headerText: "Request Notes"
      },
      {
        field: "RequestStatus",
        headerText: "Request Status"
      },
      {
        field: "ApprovalExecutedBy",
        headerText: "Approval Executed By"
      },
      {
        field: "ApprovalExecutionDate",
        headerText: "Approval Execution Date",
        type: "date",
        format: "yMd",
        filter: { type: "Menu" }
      },
      {
        field: "HotelModificationRequestNumber",
        headerText: "Hotel Modification Request Number",
        visible: false
      },
      {
        headerText: "",
        isUnbound: false,
        width: 350,
        commands: [
          {
            buttonOption: {
              content: "View",
              cssClass: "e-flat",
              click: onClick
            }
          },
          {
            buttonOption: {
              content: "Attachments",
              cssClass: "e-flat",
              click: onDocument
            }
          },
          {
            buttonOption: {
              content: "Checkpoints",
              cssClass: "e-flat",
              click: onComplete
            }
          }
        ]
      }
    ];

    this.grid = new Grid({
      dataSource: [],
      allowPaging: true,
      width: "100%",
      allowTextWrap: true,
      textWrapSettings: {
        wrapMode: "Content"
      },
      allowGrouping: true,
      allowFiltering: true,
      allowSorting: true,
      filterSettings: { type: "Excel" },
      allowPdfExport: true,
      allowExcelExport: true,
      toolbar: ["ExcelExport", "PdfExport"],
      columns: cols
    });
    this.grid.appendTo(`#${this.referenceid}`);
    this.grid.toolbarClick = (args: Object) => {
      if (args["item"].id === `${this.referenceid}_excelexport`) {
        this.grid.excelExport();
      }
      if (args["item"].id === `${this.referenceid}_pdfexport`) {
        this.grid.pdfExport();
      }
    };
  }

  isEmpty(map) {
    for (var key in map) {
      if (map.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  constructor(private modalService: NgbModal) {
    this._onChange = changed => {
      this.postdata.IdNumber = changed.IdNumber;
      //this.submitdata(changed.ControlId);
    };
  }
}
