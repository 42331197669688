import { closest } from "@syncfusion/ej2-ng-grids/node_modules/@syncfusion/ej2-base";
import { showSelectedModification } from "./showSelectedModification";
import { fit_modal_body } from "./fitmodalbody";
import { getQualifiedRetiredPersonModificationDependentList } from "./getQualifiedRetiredPersonModificationDependentList";
import { getQualifiedRetiredPersonModificationNewDependentList } from "./getQualifiedRetiredPersonModificationNewDependentList";


import * as _ from 'underscore';


export const onViewTour = function(args) {
  const rowObj = this.props.grid.getRowObjectFromUID(
    closest(args.target, ".e-row").getAttribute("data-uid")
  );
  const _showSelectedModification = showSelectedModification.bind(this);

  const data: any = rowObj.data;
  this.props.selectedQualifiedRetiredPerson = data.OldFirstName + ' ' + data.OldLastName;










  _showSelectedModification(
    data.QualifiedRetiredPersonModificationRequestNumber,
    (response: any) => {
      getQualifiedRetiredPersonModificationNewDependentList(data.QualifiedRetiredPersonModificationRequestNumber,(newDependents)=>{


          getQualifiedRetiredPersonModificationDependentList(
            data.QualifiedRetiredPersonModificationRequestNumber,
            servicesList => {
      this.props.currentSelectedModification = response;
      this.props.currentSelectedModification.newDependents = newDependents;




        this.props.currentSelectedModification.dependents = servicesList;




        this.modalService
          .open(this.content, { centered: true, size: "lg" })
          .result.then(
            result => {
              // this.closeResult = `Closed with: ${result}`;
            },
            reason => {
              // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
          );
        setTimeout(() => {
          fit_modal_body($(".modal"));

        }, 100);
      });

    });
    }
  );
};
