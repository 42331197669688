export default `
<div class="row">



<div class="col-md-6">
    <div class="form-group">
      <label>First Name</label>
      <div class="input-group">
        <input
        [style.background-color]="compare(props.currentSelectedModification.NewFirstName,props.currentSelectedModification.OldFirstName) ? '#AEF0B7' : '#fff'"
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedModification.NewFirstName"
          class="form-control"
        />
      </div>
    </div>
  </div>

  <div class="col-md-6">
    <div class="form-group">
      <label>Last Name</label>
      <div class="input-group">
        <input
        [style.background-color]="compare(props.currentSelectedModification.NewLastName,props.currentSelectedModification.OldLastName) ? '#AEF0B7' : '#fff'"
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedModification.NewLastName"
          class="form-control"
        />
      </div>
    </div>
  </div>

  <div class="col-md-6">
  <div class="form-group">
    <label>Country Of Birth</label>
    <div class="input-group">
      <input
      [style.background-color]="compare(props.currentSelectedModification.NewCountryOfBirth,props.currentSelectedModification.OldCountryOfBirth) ? '#AEF0B7' : '#fff'"
        readOnly="readOnly"
        [(ngModel)]="props.currentSelectedModification.NewCountryOfBirth"
        class="form-control"
      />
    </div>
  </div>
</div>

  <div class="col-md-6">
    <div class="form-group">
      <label>Date of Birth</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [style.background-color]="compare(props.currentSelectedModification.NewDateOfBirth,props.currentSelectedModification.OldDateOfBirth) ? '#AEF0B7' : '#fff'"
          [(ngModel)]="props.currentSelectedModification.NewDateOfBirth"
          class="form-control"
        />
      </div>
    </div>
  </div>

  <div class="col-md-4">
    <div class="form-group">
      <label>Gender</label>
      <div class="input-group">
        <input
        [style.background-color]="compare(props.currentSelectedModification.NewGender,props.currentSelectedModification.OldGender) ? '#AEF0B7' : '#fff'"
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedModification.NewGender"
          class="form-control"
        />
      </div>
    </div>
  </div>





  <div class="col-md-4">
  <div class="form-group">
    <label>Passport Number</label>
    <div class="input-group">
      <input
      [style.background-color]="compare(props.currentSelectedModification.NewPassportNumber,props.currentSelectedModification.OldPassportNumber) ? '#AEF0B7' : '#fff'"
        readOnly="readOnly"
        [(ngModel)]="props.currentSelectedModification.NewPassportNumber"
        class="form-control"
      />
    </div>
  </div>
</div>

<div class="col-md-4">
  <div class="form-group">
    <label>Passport Issue Date</label>
    <div class="input-group">
      <input
      [style.background-color]="compare(props.currentSelectedModification.NewPassportIssueDate,props.currentSelectedModification.OldPassportIssueDate) ? '#AEF0B7' : '#fff'"
        readOnly="readOnly"
        [(ngModel)]="props.currentSelectedModification.NewPassportIssueDate"
        class="form-control"
      />
    </div>
  </div>
</div>




  <div class="col-md-6">
  <div class="form-group">
    <label>Passport Expiration Date</label>
    <div class="input-group">
      <input
      [style.background-color]="compare(props.currentSelectedModification.NewPassportExpirationDate,props.currentSelectedModification.OldPassportExpirationDate) ? '#AEF0B7' : '#fff'"
        readOnly="readOnly"
        [(ngModel)]="props.currentSelectedModification.NewPassportExpirationDate"
        class="form-control"
      />
    </div>
  </div>
</div>

<div class="col-md-6">
  <div class="form-group">
    <label>Country Of Nationality</label>
    <div class="input-group">
      <input
      [style.background-color]="compare(props.currentSelectedModification.NewCountryOfNationality,props.currentSelectedModification.OldCountryOfNationality) ? '#AEF0B7' : '#fff'"
        readOnly="readOnly"
        [(ngModel)]="props.currentSelectedModification.NewCountryOfNationality"
        class="form-control"
      />
    </div>
  </div>
</div>

<div class="col-md-6">
<div class="form-group">
  <label>Passport Issue Country</label>
  <div class="input-group">
    <input
    [style.background-color]="compare(props.currentSelectedModification.NewPassportIssueCountryCode,props.currentSelectedModification.OldPassportIssueCountryCode) ? '#AEF0B7' : '#fff'"
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedModification.NewPassportIssueCountryCode"
      class="form-control"
    />
  </div>
</div>
</div>


<div class="col-md-6">
  <div class="form-group">
    <label>Marital Status</label>
    <div class="input-group">
      <input
      [style.background-color]="compare(props.currentSelectedModification.NewMaritalStatus,props.currentSelectedModification.OldMaritalStatus) ? '#AEF0B7' : '#fff'"
        readOnly="readOnly"
        [(ngModel)]="props.currentSelectedModification.NewMaritalStatus"
        class="form-control"
      />
    </div>
  </div>
</div>





</div>



`;
