export default `
<section *ngIf="(owners !==undefined && owners.length>0)">
<h3>Owner Modifications</h3>
<ngb-tabset>
  <ngb-tab title="After">
    <ng-template ngbTabContent>
      <div class="form-group row">
        <div class="col-sm-12">
          <table class="data-table">
            <thead>
              <th>First Name</th>
              <th>Middle Name</th>
              <th>Last Name</th>
              <th>Nationality</th>
              <th>Email</th>
              <th>Telephone Number</th>
              <th>Entity Type</th>
              <th>Share Percentage</th>
              <th>Remove</th>
            </thead>
            <tbody>
              <tr *ngFor="let owner of owners">
                <td [style.background-color]="compare(owner.NewFirstName,owner.OldFirstName) ? '#AEF0B7' : '#fff'">{{ owner.NewFirstName }}</td>
                <td [style.background-color]="compare(owner.NewMiddleName,owner.OldMiddleName) ? '#AEF0B7' : '#fff'">{{ owner.NewMiddleName }}</td>
                <td [style.background-color]="compare(owner.NewLastName,owner.OldLastName) ? '#AEF0B7' : '#fff'">{{ owner.NewLastName }}</td>
                <td [style.background-color]="compare(owner.NewCountryName,owner.OldCountryName) ? '#AEF0B7' : '#fff'">{{ owner.NewCountryName }}</td>
                <td [style.background-color]="compare(owner.NewEmailaddress,owner.OldEmailAddress) ? '#AEF0B7' : '#fff'">{{ owner.NewEmailaddress }}</td>
                <td [style.background-color]="compare(owner.NewTelephoneNumber,owner.OldTelephoneNumber) ? '#AEF0B7' : '#fff'">{{ owner.NewTelephoneNumber }}</td>
                <td [style.background-color]="compare(owner.NewEntityType,owner.OldEntityType) ? '#AEF0B7' : '#fff'">{{ owner.NewEntityType }}</td>
                <td [style.background-color]="compare(owner.NewSharePercentage,owner.OldSharePercentage) ? '#AEF0B7' : '#fff'">{{ owner.NewSharePercentage }}</td>
                <td [style.background-color]="compare(owner.RemoveOwner,'false') ? '#AEF0B7' : '#fff'">{{ owner.RemoveOwner }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ng-template>
  </ngb-tab>

  <ngb-tab title="Before">
    <ng-template ngbTabContent>
      <div class="form-group row">
        <div class="col-sm-12">
          <table class="data-table">
            <thead>
              <th>First Name</th>
              <th>Middle Name</th>
              <th>Last Name</th>
              <th>Nationality</th>
              <th>Email</th>
              <th>Telephone Number</th>
              <th>Entity Type</th>
              <th>Share Percentage</th>
            </thead>
            <tbody>
              <tr *ngFor="let owner of owners">
                <td>{{ owner.OldFirstName }}</td>
                <td>{{ owner.OldMiddleName }}</td>
                <td>{{ owner.OldLastName }}</td>
                <td>{{ owner.OldCountryName }}</td>
                <td>{{ owner.OldEmailAddress }}</td>
                <td>{{ owner.OldTelephoneNumber }}</td>
                <td>{{ owner.OldEntityType }}</td>
                <td>{{ owner.OldSharePercentage }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ng-template>
  </ngb-tab>
</ngb-tabset>
</section>

`