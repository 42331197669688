export const CHANGEPASSWORDADMIN = {
  duplicates: [],
  access: ['admin'],
  track: 'systemuser',
  formtype: 'dataEntry',
  listresources: [
    {
      id: 'ViewAdminListing',
      type: 'SQL'
    }
  ],
  supportsDelete: false,
  supportsAdd: false,
  supportsAudit: false,
  caption: 'Change Admin Password',
  editSearchSelector: {
    resource: 'ViewAdminListing',
    resourceId: 'UserName',
    resourceValue: 'FullName',
    resourceSelectedType: 'SQL',
    resourceSelected: 'GetUserAdmin',
    resourceDisplayFields: ['UserName']
  },
  model: {
    modelid: 'GetUserAdmin',
    autoload: false
  },
  functionactions: {
    createnew: {
      functionname: 'ChangeAdminPassword',
      functiontype: 'SQL',
      validateData: true,
      validationFailedTitle: '',
      validationFailedText: '',
      alertOnComplete: true,
      alertTitle: '',
      alertText: ''
    },
    update: {
      functionname: 'ChangeAdminPassword',
      functiontype: 'SQL',
      validateData: true,
      validationFailedTitle: '',
      validationFailedText: '',
      alertOnComplete: true,
      alertTitle: '',
      alertText: ''
    },
    after: {
      functionname: 'ViewAdminListing',
      functiontype: 'SQL'
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: 'General Information',
            fields: [
              {
                key: 'UserName',
                readonly: true,
                type: 'text',
                label: 'User Name',
                placeholder: 'User Name',
                required: 'true',
                validation: {
                  'data-parsley-required': true,
                  'data-parsley-minlength': 3,
                  'data-parsley-maxlength': 100
                }
              },
              {
                key: 'FirstName',
                readonly: true,
                type: 'text',
                label: 'First Name',
                placeholder: 'First Name',
                required: 'true',
                validation: {
                  'data-parsley-required': true,
                  'data-parsley-minlength': 2,
                  'data-parsley-maxlength': 100
                },
                isPrimary: 'false'
              },

              {
                key: 'Lastname',
                readonly: true,
                type: 'text',
                label: 'Last Name',
                placeholder: 'Last Name',
                required: 'true',
                validation: {
                  'data-parsley-required': true,
                  'data-parsley-minlength': 2,
                  'data-parsley-maxlength': 100
                },
                isPrimary: 'false'
              },

              {
                key: 'Password2',
                type: 'password',
                label: 'Password',
                placeholder: 'Password',
                required: 'true',
                validation: {
                  'data-parsley-required': true,
                  'data-parsley-minlength': 3,
                  'data-parsley-maxlength': 100
                },
                isPrimary: 'false'
              },

              {
                key: 'TelephoneNumber',
                readonly: true,
                type: 'text',
                label: 'Telephone Number',
                placeholder: 'Telephone Number',
                required: 'true',
                validation: {
                  'data-parsley-required': true,
                  'data-parsley-minlength': 3,
                  'data-parsley-maxlength': 100
                },
                isPrimary: 'false'
              },

              {
                key: 'EmailAddress',
                readonly: true,
                type: 'text',
                label: 'Email Address',
                placeholder: 'Email Address',
                required: 'true',
                validation: {
                  'data-parsley-required': true,
                  'data-parsley-minlength': 3,
                  'data-parsley-maxlength': 100,
                  'data-parsley-type': 'email'
                },
                isPrimary: 'false'
              },

              {
                key: 'UserNotes',
                readonly: true,
                type: 'text',
                label: 'Notes',
                placeholder: 'Notes',
                required: 'true',
                validation: {
                  'data-parsley-maxlength': 255
                },
                isPrimary: 'false'
              }
            ]
          }
        ]
      }
    ]
  }
};
