export const TOURMODIFCATION = {
  access: ['admin'],
  formtype: 'tourmodification',
  listresources: [
    {
      id: 'controlids',
      type: 'SQL'
    }
  ],
  caption: 'Tour Operator Modifications'
};
