
import {Component,AfterViewInit, Input, SimpleChanges} from '@angular/core';
import guid from '../../../constants/guid';
import * as moment from 'moment';

@Component({
    selector: 'date-picker',
    template: `
 <div style="width:100%">
<label    style="width:100%" *ngIf="label">
            <span  class="ThemeControlled" >{{config.label}}</span>
                <div class='input-group date' id='{{referenceid}}'>
                    <input value="{{currentDate}}" placeholder="{{config.placeholder}}" parsley [(validation)]="config.validation" [readonly]="editable ? true : null" class="form-control" type='text' class="form-control" />
                    <span class="input-group-addon" [ngStyle]="readonlytag()">
                        <i class="mdi mdi-calendar"></i>
                    </span>
                </div>
          </label>

                <div class='input-group date' id='{{referenceid}}' *ngIf="!label">
                    <input value="{{currentDate}}" placeholder="{{config.placeholder}}" parsley [(validation)]="config.validation" [readonly]="editable ? true : null" class="form-control" type='text' class="form-control" />
                    <span class="input-group-addon" [ngStyle]="readonlytag()">
                        <i class="mdi mdi-calendar"></i>
                    </span>
                </div>



        </div>



`
})
export class DatePicker implements AfterViewInit {

    currentDate:string;

    ngOnChanges(changes: SimpleChanges): void {


        this.currentDate = this.convertdate();

    }

    convertdate()
    {
        let s = moment(this.model[this.config.key]).format("DD-MMM-YYYY");

        return s === "Invalid date" ? "" : s;

    }

    @Input() model: any;

    @Input() config: any;
    @Input() label:boolean = true;

    @Input() editable: boolean=false;

    @Input() addmode: boolean=false;


    referenceid: string = "";

    constructor()
    {
        this.referenceid= guid.create("date-picker");

    }

    readonlytag()
    {
        if(!this.editable)
        {
            return {"background-color":"#fff","border-left":"1px solid #EBEBEB!important"};
        }
        else
        {
            return {"background-color":"#EEEEEE","border-left":"1px solid #EEEEEE!important"};
        }
    }

    ngAfterViewInit(){
        $('#'+this.referenceid)["datepicker"]({
            autoclose:true,

            format:"dd-M-yyyy",
            todayBtn:true,
            todayHighlight:true,
            showOnFocus:true
        }).on("change",(ev)=>{


             this.model[this.config.key] = moment($('#'+this.referenceid)["datepicker"]("getDate")).format("YYYY-MM-DD");

        });
    }



}
