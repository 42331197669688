import { closest } from "@syncfusion/ej2-ng-grids/node_modules/@syncfusion/ej2-base";
import { showSelectedModification } from "./showSelectedModification";
import { fit_modal_body } from "./fitmodalbody";
import { getTourOperatorModificationEmployeeList } from "./getTourOperatorModificationEmployeeList";
import { createTourOperatorModificationEmployeesListGrid } from "./createTourOperatorModificationEmployeesListGrid";
import { createTourOperatorModificationGuideListGrid } from "./createTourOperatorModificationGuideListGrid";
import { createTourOperatorModificationSafetyEquipmentListGrid } from "./createTourOperatorModificationSafetyEquipmentListGrid";
import { createTourOperatorModificationTourListGrid } from "./createTourOperatorModificationTourListGrid";
import { createTourOperatorModificationTransportEquipmentListGrid } from "./createTourOperatorModificationTransportEquipmentListGrid";
import { getTourOperatorModificationGuideList } from "./getTourOperatorModificationGuideList";
import { getTourOperatorModificationSafetyEquipmentList } from "./getTourOperatorModificationSafetyEquipmentList";
import { getTourOperatorModificationTourList } from "./getTourOperatorModificationTourList";
import { getTourOperatorModificationTransportEquipmentList } from "./getTourOperatorModificationTransportEquipmentList";

export const onViewTour = function(args) {
  const rowObj = this.props.grid.getRowObjectFromUID(
    closest(args.target, ".e-row").getAttribute("data-uid")
  );
  const _showSelectedModification = showSelectedModification.bind(this);

  const data: any = rowObj.data;
  this.props.selectedTourOperator = data.OldTourOperatorName;

  _showSelectedModification(
    data.TourOperatorModificationRequestNumber,
    (response: any) => {
      this.props.currentSelectedModification = response;
      setTimeout(() => {
        this.modalService
          .open(this.content, { centered: true, size: "lg" })
          .result.then(
            result => {
              // this.closeResult = `Closed with: ${result}`;
            },
            reason => {
              // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
          );
        setTimeout(() => {
          fit_modal_body($(".modal"));
        }, 100);
      });
    }
  );
};
