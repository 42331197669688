export default `
<section id="general" *ngIf="!isEmpty(currentSelectedModification)">
<h3>General Information</h3>

<ngb-tabset>
  <ngb-tab title="After">
    <ng-template ngbTabContent>
      <div class="form-group row marginal">
        <label
          for="accomodationname"
          class="col-sm-3 col-form-label"
          >Name of Hotel:</label
        >
        <div class="col-sm-9">
          <input
            type="text"
            class="form-control"
            id="accomodationname"
            [(ngModel)]="currentSelectedModification.NewHotelName"
            [style.background-color]="compare(currentSelectedModification.NewHotelName,currentSelectedModification.OldHotelName) ? '#AEF0B7' : '#fff'"
            readonly
          />
        </div>
      </div>
      <div class="form-group row">
        <label for="streetaddress" class="col-sm-3 col-form-label"
          >Address:</label
        >
        <div class="col-sm-4">
          <input
            type="text"
            class="form-control"
            id="streetaddress"
            [(ngModel)]="currentSelectedModification.NewAddress"
            [style.background-color]="compare(currentSelectedModification.NewAddress,currentSelectedModification.OldAddress) ? '#AEF0B7' : '#fff'"
            readonly
          />
        </div>
        <label for="district" class="col-sm-1 col-form-label"
          >District:</label
        >
        <div class="col-sm-4">
          <input
            type="text"
            class="form-control"
            id="district"
            [style.background-color]="compare(currentSelectedModification.NewDistrictAreaName,currentSelectedModification.OldDistrictAreaName) ? '#AEF0B7' : '#fff'"
            [(ngModel)]="
              currentSelectedModification.NewDistrictAreaName
            "
            readonly
          />
        </div>
      </div>
      <div class="form-group row marginal">
        <label
          for="accomodationtype"
          class="col-sm-3 col-form-label"
          >Category of Accommodation:</label
        >
        <div class="col-sm-9">
          <input
            type="text"
            class="form-control"
            id="accomodationtype"
            [style.background-color]="compare(currentSelectedModification.NewHotelCategory,currentSelectedModification.OldHotelCategory) ? '#AEF0B7' : '#fff'"
            [(ngModel)]="
              currentSelectedModification.NewHotelCategory
            "
            readonly
          />
        </div>
      </div>
      <div class="form-group row">
        <label
          for="numberofnewrooms"
          class="col-sm-3 col-form-label"
          >Number of Rooms:</label
        >
        <div class="col-sm-3">
          <input
            type="text"
            class="form-control"
            id="numberofnewrooms"
            [style.background-color]="compare(currentSelectedModification.NewNumberOfRooms,currentSelectedModification.OldNumberOfRooms) ? '#AEF0B7' : '#fff'"
            [(ngModel)]="
              currentSelectedModification.NewNumberOfRooms 
            "
            readonly
          />
        </div>
        <label
          for="numberofregisteredrooms"
          class="col-sm-3 col-form-label"
          >Available Rooms:</label
        >
        <div class="col-sm-3">
          <input
            type="text"
            class="form-control"
            id="numberofregisteredrooms"
            [(ngModel)]="
              currentSelectedModification.NewNumberOfAvailableRooms 
            "
            [style.background-color]="compare(currentSelectedModification.NewNumberOfAvailableRooms,currentSelectedModification.OldNumberOfAvailableRooms) ? '#AEF0B7' : '#fff'"
            readonly
          />
        </div>  
     
      </div>
      
      <div class="form-group row">
        <label
          for="numberofnewunits"
          class="col-sm-3 col-form-label"
          >Available Units:</label
        >
        <div class="col-sm-3">
          <input
            type="text"
            class="form-control"
            id="numberofnewunits"
            [style.background-color]="compare(currentSelectedModification.NewNumberOfAvailableUnits,currentSelectedModification.OldNumberOfAvailableUnits) ? '#AEF0B7' : '#fff'"
            [(ngModel)]="
              currentSelectedModification.NewNumberOfAvailableUnits 
            "
            readonly
          />
        </div>
        <label
          for="numberofnewbeds"
          class="col-sm-3 col-form-label"
          >Available Beds:</label
        >
        <div class="col-sm-3">
          <input
            type="text"
            class="form-control"
            id="numberofnewbeds"
            [(ngModel)]="
              currentSelectedModification.NewNumberOfAvailableBeds 
            "
            [style.background-color]="compare(currentSelectedModification.NewNumberOfAvailableBeds,currentSelectedModification.OldNumberOfAvailableBeds) ? '#AEF0B7' : '#fff'"
            readonly
          />
        </div>  
     
      </div>




    </ng-template>
  </ngb-tab>
  <ngb-tab title="Before">
    <ng-template ngbTabContent>
      <div class="form-group row marginal">
        <label
          for="accomodationname"
          class="col-sm-3 col-form-label"
          >Name of Hotel:</label
        >
        <div class="col-sm-9">
          <input
            type="text"
            class="form-control"
            id="accomodationname"
            [(ngModel)]="currentSelectedModification.OldHotelName"
            readonly
          />
        </div>
      </div>
      <div class="form-group row">
        <label for="streetaddress" class="col-sm-3 col-form-label"
          >Address:</label
        >
        <div class="col-sm-4">
          <input
            type="text"
            class="form-control"
            id="streetaddress"
            [(ngModel)]="currentSelectedModification.OldAddress"
            readonly
          />
        </div>
        <label for="district" class="col-sm-1 col-form-label"
          >District:</label
        >
        <div class="col-sm-4">
          <input
            type="text"
            class="form-control"
            id="district"
            [(ngModel)]="
              currentSelectedModification.OldDistrictAreaName
            "
            readonly
          />
        </div>
      </div>
      <div class="form-group row">
        <label
          for="accomodationtype"
          class="col-sm-3 col-form-label"
          >Category of Accommodation:</label
        >
        <div class="col-sm-9">
          <input
            type="text"
            class="form-control"
            id="accomodationtype"
            [(ngModel)]="
              currentSelectedModification.OldHotelCategory
            "
            readonly
          />
        </div>
      </div>
      <div class="form-group row">     
        <label
          for="totalOldregisteredrooms"
          class="col-sm-3 col-form-label"
          >Number of Rooms:</label
        >
        <div class="col-sm-3">
          <input
            type="text"
            class="form-control"
            id="totalOldregisteredrooms"
            [(ngModel)]="
            currentSelectedModification.OldNumberOfRooms
          "
            readonly
          />
        </div>
        <label
          for="numberofavailablerooms"
          class="col-sm-3 col-form-label"
          >Available Rooms:</label
        >
        <div class="col-sm-3">
          <input
            type="text"
            class="form-control"
            id="numberofavailablerooms"
            [(ngModel)]="
              currentSelectedModification.OldNumberOfAvailableRooms 
            "         
            readonly
          />
        </div>
       
      </div>
      
      <div class="form-group row">     
        <label
          for="oldnumberofavailableunits"
          class="col-sm-3 col-form-label"
          >Available Units:</label
        >
        <div class="col-sm-3">
          <input
            type="text"
            class="form-control"
            id="oldnumberofavailableunits"
            [(ngModel)]="
            currentSelectedModification.OldNumberOfAvailableUnits
          "
            readonly
          />
        </div>
        <label
          for="oldnumberofavailablebeds"
          class="col-sm-3 col-form-label"
          >Available Beds:</label
        >
        <div class="col-sm-3">
          <input
            type="text"
            class="form-control"
            id="oldnumberofavailablebeds"
            [(ngModel)]="
              currentSelectedModification.OldNumberOfAvailableBeds 
            "         
            readonly
          />
        </div>
       
      </div>
    </ng-template>
  </ngb-tab>
</ngb-tabset>
</section>

`