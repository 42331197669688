import { closest } from "@syncfusion/ej2-ng-grids/node_modules/@syncfusion/ej2-base";
import { showSelectedModification } from "./showSelectedModification";
import { fit_modal_body } from "./fitmodalbody";
import { getTourGuideModificationLanguageList } from "./getTourGuideModificationLanguageList";
import { getTourGuideModificationServicesList } from "./getTourGuideModificationServicesList";
import { createTourGuideModificationLanguageListGrid } from "./createTourGuideModificationLanguageListGrid";
import { createTourGuideModificationServicesListGrid } from "./createTourGuideModificationServicesListGrid";


import * as _ from 'underscore';


export const onViewTour = function(args) {
  const rowObj = this.props.grid.getRowObjectFromUID(
    closest(args.target, ".e-row").getAttribute("data-uid")
  );
  const _showSelectedModification = showSelectedModification.bind(this);

  const data: any = rowObj.data;
  this.props.selectedTourGuide = data.OldFirstName + ' ' + data.OldLastName;


  const _createTourGuideApplicationLanguageListGrid = createTourGuideModificationLanguageListGrid.bind(
    this
  );



  const _createTourGuideModificationServicesListGrid = createTourGuideModificationServicesListGrid.bind(
    this
  );




  _showSelectedModification(
    data.TourGuideModificationRequestNumber,
    (response: any) => {
      getTourGuideModificationLanguageList(
        data.TourGuideModificationRequestNumber,
        languageList => {
          getTourGuideModificationServicesList(
            data.TourGuideModificationRequestNumber,
            servicesList => {
      this.props.currentSelectedModification = response;
      setTimeout(() => {

        setTimeout(() => {
          _createTourGuideApplicationLanguageListGrid(
            languageList
          );



          _createTourGuideModificationServicesListGrid(
            servicesList
          );


          },100);


        this.modalService
          .open(this.content, { centered: true, size: "lg" })
          .result.then(
            result => {
              // this.closeResult = `Closed with: ${result}`;
            },
            reason => {
              // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
          );
        setTimeout(() => {
          fit_modal_body($(".modal"));

        }, 100);
      });
    });
  });
    }
  );
};
