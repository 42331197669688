export default `
<ng-template #moddependent let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
          Dependent Current - {{ props.selectedDependent }}
        </h4>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">


      <ngb-tabset>
      <ngb-tab title="After">
        <ng-template ngbTabContent>


        <div class="row">


<h3 style="margin-left: 15px;">General Information</h3>
        <div class="col-md-6">
    <div class="form-group">
      <label>Full Name</label>
      <div class="input-group">
        <input
        [style.background-color]="compare(props.currentSelectedDependent.NewFullName,props.currentSelectedDependent.OldFullName) ? '#AEF0B7' : '#fff'"
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedDependent.NewFullName"
          class="form-control"
        />
      </div>
    </div>
  </div>



<div class="col-md-6">
<div class="form-group">
  <label>Relationship</label>
  <div class="input-group">
    <input
    [style.background-color]="compare(props.currentSelectedDependent.NewRelationship,props.currentSelectedDependent.OldRelationship) ? '#AEF0B7' : '#fff'"
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.NewRelationship"
      class="form-control"
    />
  </div>
</div>
</div>

<div class="col-md-6">
<div class="form-group">
  <label>Gender</label>
  <div class="input-group">
    <input
    [style.background-color]="compare(props.currentSelectedDependent.NewGender,props.currentSelectedDependent.OldGender) ? '#AEF0B7' : '#fff'"
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.NewGender"
      class="form-control"
    />
  </div>
</div>
</div>

<div class="col-md-6">
<div class="form-group">
  <label>Date Of Birth</label>
  <div class="input-group">
    <input
    [style.background-color]="compare(props.currentSelectedDependent.NewDateOfBirth,props.currentSelectedDependent.OldDateOfBirth) ? '#AEF0B7' : '#fff'"
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.NewDateOfBirth"
      class="form-control"
    />
  </div>
</div>
</div>

<div class="col-md-6">
<div class="form-group">
  <label>Country Of Birth</label>
  <div class="input-group">
    <input
    [style.background-color]="compare(props.currentSelectedDependent.NewCountryOfBirth,props.currentSelectedDependent.OldCountryOfBirth) ? '#AEF0B7' : '#fff'"
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.NewCountryOfBirth"
      class="form-control"
    />
  </div>
</div>
</div>



<div class="col-md-6">
<div class="form-group">
  <label>Marital Status</label>
  <div class="input-group">
    <input
    [style.background-color]="compare(props.currentSelectedDependent.NewMaritalStatus,props.currentSelectedDependent.OldMaritalStatus) ? '#AEF0B7' : '#fff'"
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.NewMaritalStatus"
      class="form-control"
    />
  </div>
</div>
</div>

<h3 style="margin-left: 15px;">Contact Information</h3>

<div class="col-md-6">
<div class="form-group">
  <label>Address</label>
  <div class="input-group">
    <input
    [style.background-color]="compare(props.currentSelectedDependent.NewAddress,props.currentSelectedDependent.OldAddress) ? '#AEF0B7' : '#fff'"
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.NewAddress"
      class="form-control"
    />
  </div>
</div>
</div>

<div class="col-md-6">
<div class="form-group">
  <label>City Name</label>
  <div class="input-group">
    <input
    [style.background-color]="compare(props.currentSelectedDependent.NewCityName,props.currentSelectedDependent.OldCityName) ? '#AEF0B7' : '#fff'"
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.NewCityName"
      class="form-control"
    />
  </div>
</div>
</div>

<div class="col-md-6">
<div class="form-group">
  <label>District Area</label>
  <div class="input-group">
    <input
    [style.background-color]="compare(props.currentSelectedDependent.NewDistrictArea,props.currentSelectedDependent.OldDistrictArea) ? '#AEF0B7' : '#fff'"
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.NewDistrictArea"
      class="form-control"
    />
  </div>
</div>
</div>

<div class="col-md-6">
<div class="form-group">
  <label>Overseas Address</label>
  <div class="input-group">
    <input
    [style.background-color]="compare(props.currentSelectedDependent.NewOverseasAddress,props.currentSelectedDependent.OldOverseasAddress) ? '#AEF0B7' : '#fff'"
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.NewOverseasAddress"
      class="form-control"
    />
  </div>
</div>
</div>


<div class="col-md-6">
<div class="form-group">
  <label>Overseas Postal Code</label>
  <div class="input-group">
    <input
    [style.background-color]="compare(props.currentSelectedDependent.NewOverseasPostalCode,props.currentSelectedDependent.OldOverseasPostalCode) ? '#AEF0B7' : '#fff'"
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.NewOverseasPostalCode"
      class="form-control"
    />
  </div>
</div>
</div>

<div class="col-md-6">
<div class="form-group">
  <label>Overseas Country</label>
  <div class="input-group">
    <input
    [style.background-color]="compare(props.currentSelectedDependent.NewOverseasCountry,props.currentSelectedDependent.OldOverseasCountry) ? '#AEF0B7' : '#fff'"
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.NewOverseasCountry"
      class="form-control"
    />
  </div>
</div>
</div>

<div class="col-md-6">
<div class="form-group">
  <label>Cell Phone</label>
  <div class="input-group">
    <input
    [style.background-color]="compare(props.currentSelectedDependent.NewCellPhone,props.currentSelectedDependent.OldCellPhone) ? '#AEF0B7' : '#fff'"
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.NewCellPhone"
      class="form-control"
    />
  </div>
</div>
</div>

<div class="col-md-6">
<div class="form-group">
  <label>Home Phone</label>
  <div class="input-group">
    <input
    [style.background-color]="compare(props.currentSelectedDependent.NewHomePhone,props.currentSelectedDependent.OldHomePhone) ? '#AEF0B7' : '#fff'"
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.NewHomePhone"
      class="form-control"
    />
  </div>
</div>
</div>

<div class="col-md-6">
<div class="form-group">
  <label>Overseas Phone Number</label>
  <div class="input-group">
    <input
    [style.background-color]="compare(props.currentSelectedDependent.NewOverseasPhoneNumber,props.currentSelectedDependent.OldOverseasPhoneNumber) ? '#AEF0B7' : '#fff'"
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.NewOverseasPhoneNumber"
      class="form-control"
    />
  </div>
</div>
</div>

<div class="col-md-6">
<div class="form-group">
  <label>Email Address</label>
  <div class="input-group">
    <input
    [style.background-color]="compare(props.currentSelectedDependent.NewEmailAddress,props.currentSelectedDependent.OldEmailAddress) ? '#AEF0B7' : '#fff'"
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.NewEmailAddress"
      class="form-control"
    />
  </div>
</div>
</div>

<h3 style="margin-left: 15px;">Education</h3>

<div class="col-md-6">
<div class="form-group">
  <label>Primary Education Completion Year</label>
  <div class="input-group">
    <input
    [style.background-color]="compare(props.currentSelectedDependent.NewPrimaryEducationCompletionYear,props.currentSelectedDependent.OldPrimaryEducationCompletionYear) ? '#AEF0B7' : '#fff'"
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.NewPrimaryEducationCompletionYear"
      class="form-control"
    />
  </div>
</div>
</div>

<div class="col-md-6">
<div class="form-group">
  <label>Secondary Education Completion Year</label>
  <div class="input-group">
    <input
    [style.background-color]="compare(props.currentSelectedDependent.NewSecondaryEducationCompletionYear,props.currentSelectedDependent.OldSecondaryEducationCompletionYear) ? '#AEF0B7' : '#fff'"
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.NewSecondaryEducationCompletionYear"
      class="form-control"
    />
  </div>
</div>
</div>

<div class="col-md-6">
<div class="form-group">
  <label>Tertiary Education Completion Year</label>
  <div class="input-group">
    <input
    [style.background-color]="compare(props.currentSelectedDependent.NewTertiaryEducationCompletionYear,props.currentSelectedDependent.OldTertiaryEducationCompletionYear) ? '#AEF0B7' : '#fff'"
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.NewTertiaryEducationCompletionYear"
      class="form-control"
    />
  </div>
</div>
</div>

<div class="col-md-6">
<div class="form-group">
  <label>Other Education Completion Year</label>
  <div class="input-group">
    <input
    [style.background-color]="compare(props.currentSelectedDependent.NewOtherEducationCompletionYear,props.currentSelectedDependent.OldOtherEducationCompletionYear) ? '#AEF0B7' : '#fff'"
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.NewOtherEducationCompletionYear"
      class="form-control"
    />
  </div>
</div>
</div>



<h3 style="margin-left: 15px;">Language</h3>

<div class="col-md-6">
<div class="form-group">
  <label>Primary Language</label>
  <div class="input-group">
    <input
    [style.background-color]="compare(props.currentSelectedDependent.NewPrimaryLanguage,props.currentSelectedDependent.OldPrimaryLanguage) ? '#AEF0B7' : '#fff'"
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.NewPrimaryLanguage"
      class="form-control"
    />
  </div>
</div>
</div>

<div class="col-md-6">
<div class="form-group">
  <label>Secondary Language</label>
  <div class="input-group">
    <input
    [style.background-color]="compare(props.currentSelectedDependent.NewSecondaryLanguage,props.currentSelectedDependent.OldSecondaryLanguage) ? '#AEF0B7' : '#fff'"
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.NewSecondaryLanguage"
      class="form-control"
    />
  </div>
</div>
</div>

<h3 style="margin-left: 15px;">Other Information</h3>


<div class="col-md-4">
<div class="form-group">
  <label>Passport Number</label>
  <div class="input-group">
    <input
    [style.background-color]="compare(props.currentSelectedDependent.NewPassportNumber,props.currentSelectedDependent.OldPassportNumber) ? '#AEF0B7' : '#fff'"
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.NewPassportNumber"
      class="form-control"
    />
  </div>
</div>
</div>

<div class="col-md-4">
<div class="form-group">
  <label>Passport Issue Date</label>
  <div class="input-group">
    <input
    [style.background-color]="compare(props.currentSelectedDependent.NewPassportIssueDate,props.currentSelectedDependent.OldPassportIssueDate) ? '#AEF0B7' : '#fff'"
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.NewPassportIssueDate"
      class="form-control"
    />
  </div>
</div>
</div>

<div class="col-md-4">
<div class="form-group">
  <label>Passport Issue Country</label>
  <div class="input-group">
    <input
    [style.background-color]="compare(props.currentSelectedDependent.NewPassportIssueCountry,props.currentSelectedDependent.OldPassportIssueCountry) ? '#AEF0B7' : '#fff'"
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.NewPassportIssueCountry"
      class="form-control"
    />
  </div>
</div>
</div>

<div class="col-md-6">
<div class="form-group">
  <label>Passport Expiration Date</label>
  <div class="input-group">
    <input
    [style.background-color]="compare(props.currentSelectedDependent.NewPassportExpirationDate,props.currentSelectedDependent.OldPassportExpirationDate) ? '#AEF0B7' : '#fff'"
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.NewPassportExpirationDate"
      class="form-control"
    />
  </div>
</div>
</div>

<div class="col-md-6">
<div class="form-group">
  <label>Country Of Nationality</label>
  <div class="input-group">
    <input
    [style.background-color]="compare(props.currentSelectedDependent.NewCountryOfNationality,props.currentSelectedDependent.OldCountryOfNationality) ? '#AEF0B7' : '#fff'"
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.NewCountryOfNationality"
      class="form-control"
    />
  </div>
</div>
</div>
        </div>


        </ng-template>
        </ngb-tab>

        <ngb-tab title="Before">
          <ng-template ngbTabContent>

          <div class="row">
          <h3 style="margin-left: 15px;">General Information</h3>
                  <div class="col-md-6">
              <div class="form-group">
                <label>Full Name</label>
                <div class="input-group">
                  <input
                    readOnly="readOnly"
                    [(ngModel)]="props.currentSelectedDependent.OldFullName"
                    class="form-control"
                  />
                </div>
              </div>
            </div>



          <div class="col-md-6">
          <div class="form-group">
            <label>Relationship</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="props.currentSelectedDependent.OldRelationship"
                class="form-control"
              />
            </div>
          </div>
          </div>

          <div class="col-md-6">
          <div class="form-group">
            <label>Gender</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="props.currentSelectedDependent.OldGender"
                class="form-control"
              />
            </div>
          </div>
          </div>

          <div class="col-md-6">
          <div class="form-group">
            <label>Date Of Birth</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="props.currentSelectedDependent.OldDateOfBirth"
                class="form-control"
              />
            </div>
          </div>
          </div>

          <div class="col-md-6">
          <div class="form-group">
            <label>Country Of Birth</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="props.currentSelectedDependent.OldCountryOfBirth"
                class="form-control"
              />
            </div>
          </div>
          </div>



          <div class="col-md-6">
          <div class="form-group">
            <label>Marital Status</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="props.currentSelectedDependent.OldMaritalStatus"
                class="form-control"
              />
            </div>
          </div>
          </div>

          <h3 style="margin-left: 15px;">Contact Information</h3>

          <div class="col-md-6">
          <div class="form-group">
            <label>Address</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="props.currentSelectedDependent.OldAddress"
                class="form-control"
              />
            </div>
          </div>
          </div>

          <div class="col-md-6">
          <div class="form-group">
            <label>City Name</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="props.currentSelectedDependent.OldCityName"
                class="form-control"
              />
            </div>
          </div>
          </div>

          <div class="col-md-6">
          <div class="form-group">
            <label>District Area</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="props.currentSelectedDependent.OldDistrictArea"
                class="form-control"
              />
            </div>
          </div>
          </div>

          <div class="col-md-6">
          <div class="form-group">
            <label>Overseas Address</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="props.currentSelectedDependent.OldOverseasAddress"
                class="form-control"
              />
            </div>
          </div>
          </div>


          <div class="col-md-6">
          <div class="form-group">
            <label>Overseas Postal Code</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="props.currentSelectedDependent.OldOverseasPostalCode"
                class="form-control"
              />
            </div>
          </div>
          </div>

          <div class="col-md-6">
          <div class="form-group">
            <label>Overseas Country</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="props.currentSelectedDependent.OldOverseasCountry"
                class="form-control"
              />
            </div>
          </div>
          </div>

          <div class="col-md-6">
          <div class="form-group">
            <label>Cell Phone</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="props.currentSelectedDependent.OldCellPhone"
                class="form-control"
              />
            </div>
          </div>
          </div>

          <div class="col-md-6">
          <div class="form-group">
            <label>Home Phone</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="props.currentSelectedDependent.OldHomePhone"
                class="form-control"
              />
            </div>
          </div>
          </div>

          <div class="col-md-6">
          <div class="form-group">
            <label>Overseas Phone Number</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="props.currentSelectedDependent.OldOverseasPhoneNumber"
                class="form-control"
              />
            </div>
          </div>
          </div>

          <div class="col-md-6">
          <div class="form-group">
            <label>Email Address</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="props.currentSelectedDependent.OldEmailAddress"
                class="form-control"
              />
            </div>
          </div>
          </div>

          <h3 style="margin-left: 15px;">Education</h3>

          <div class="col-md-6">
          <div class="form-group">
            <label>Primary Education Completion Year</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="props.currentSelectedDependent.OldPrimaryEducationCompletionYear"
                class="form-control"
              />
            </div>
          </div>
          </div>

          <div class="col-md-6">
          <div class="form-group">
            <label>Secondary Education Completion Year</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="props.currentSelectedDependent.OldSecondaryEducationCompletionYear"
                class="form-control"
              />
            </div>
          </div>
          </div>

          <div class="col-md-6">
          <div class="form-group">
            <label>Tertiary Education Completion Year</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="props.currentSelectedDependent.OldTertiaryEducationCompletionYear"
                class="form-control"
              />
            </div>
          </div>
          </div>

          <div class="col-md-6">
          <div class="form-group">
            <label>Other Education Completion Year</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="props.currentSelectedDependent.OldOtherEducationCompletionYear"
                class="form-control"
              />
            </div>
          </div>
          </div>



          <h3 style="margin-left: 15px;">Language</h3>

          <div class="col-md-6">
          <div class="form-group">
            <label>Primary Language</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="props.currentSelectedDependent.OldPrimaryLanguage"
                class="form-control"
              />
            </div>
          </div>
          </div>

          <div class="col-md-6">
          <div class="form-group">
            <label>Secondary Language</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="props.currentSelectedDependent.OldSecondaryLanguage"
                class="form-control"
              />
            </div>
          </div>
          </div>

          <h3 style="margin-left: 15px;">Other Information</h3>


          <div class="col-md-4">
          <div class="form-group">
            <label>Passport Number</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="props.currentSelectedDependent.OldPassportNumber"
                class="form-control"
              />
            </div>
          </div>
          </div>

          <div class="col-md-4">
          <div class="form-group">
            <label>Passport Issue Date</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="props.currentSelectedDependent.OldPassportIssueDate"
                class="form-control"
              />
            </div>
          </div>
          </div>

          <div class="col-md-4">
          <div class="form-group">
            <label>Passport Issue Country</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="props.currentSelectedDependent.OldPassportIssueCountry"
                class="form-control"
              />
            </div>
          </div>
          </div>

          <div class="col-md-6">
          <div class="form-group">
            <label>Passport Expiration Date</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="props.currentSelectedDependent.OldPassportExpirationDate"
                class="form-control"
              />
            </div>
          </div>
          </div>

          <div class="col-md-6">
          <div class="form-group">
            <label>Country Of Nationality</label>
            <div class="input-group">
              <input
                readOnly="readOnly"
                [(ngModel)]="props.currentSelectedDependent.OldCountryOfNationality"
                class="form-control"
              />
            </div>
          </div>
          </div>

          </div>

          </ng-template>
        </ngb-tab>
      </ngb-tabset>













      </div>
      <div class="modal-footer">


        <button
          type="button"
          class="btn btn-outline-dark"
          (click)="modal.close()"
        >
          Close
        </button>
      </div>
    </ng-template>

`
