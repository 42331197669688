export default `
<ng-template #dependent let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
          Dependent - {{ props.selectedDependent }}
        </h4>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      
      <div class="modal-body">
        <div class="row">
<h3 style="margin-left: 15px;">General Information</h3>
        <div class="col-md-6">
    <div class="form-group">
      <label>First Name</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedDependent.FirstName"
          class="form-control"
        />
      </div>
    </div>
  </div>

  <div class="col-md-6">
  <div class="form-group">
    <label>Middle Name</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        [(ngModel)]="props.currentSelectedDependent.MiddleName"
        class="form-control"
      />
    </div>
  </div>
</div>

<div class="col-md-6">
<div class="form-group">
  <label>Last Name</label>
  <div class="input-group">
    <input
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.LastName"
      class="form-control"
    />
  </div>
</div>
</div>

<div class="col-md-6">
<div class="form-group">
  <label>Relationship</label>
  <div class="input-group">
    <input
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.Relationship"
      class="form-control"
    />
  </div>
</div>
</div>

<div class="col-md-6">
<div class="form-group">
  <label>Gender</label>
  <div class="input-group">
    <input
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.Gender"
      class="form-control"
    />
  </div>
</div>
</div>

<div class="col-md-6">
<div class="form-group">
  <label>Date Of Birth</label>
  <div class="input-group">
    <input
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.DateOfBirth"
      class="form-control"
    />
  </div>
</div>
</div>

<div class="col-md-6">
<div class="form-group">
  <label>Country Of Birth</label>
  <div class="input-group">
    <input
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.CountryOfBirth"
      class="form-control"
    />
  </div>
</div>
</div>



<div class="col-md-6">
<div class="form-group">
  <label>Marital Status</label>
  <div class="input-group">
    <input
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.MaritalStatus"
      class="form-control"
    />
  </div>
</div>
</div>

<h3 style="margin-left: 15px;">Contact Information</h3>

<div class="col-md-6">
<div class="form-group">
  <label>Address</label>
  <div class="input-group">
    <input
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.Address"
      class="form-control"
    />
  </div>
</div>
</div>

<div class="col-md-6">
<div class="form-group">
  <label>City Name</label>
  <div class="input-group">
    <input
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.CityName"
      class="form-control"
    />
  </div>
</div>
</div>

<div class="col-md-6">
<div class="form-group">
  <label>District Area</label>
  <div class="input-group">
    <input
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.DistrictArea"
      class="form-control"
    />
  </div>
</div>
</div>

<div class="col-md-6">
<div class="form-group">
  <label>Overseas Address</label>
  <div class="input-group">
    <input
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.OverseasAddress"
      class="form-control"
    />
  </div>
</div>
</div>


<div class="col-md-6">
<div class="form-group">
  <label>Overseas Postal Code</label>
  <div class="input-group">
    <input
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.OverseasPostalCode"
      class="form-control"
    />
  </div>
</div>
</div>

<div class="col-md-6">
<div class="form-group">
  <label>Overseas Country</label>
  <div class="input-group">
    <input
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.OverseasCountry"
      class="form-control"
    />
  </div>
</div>
</div>

<div class="col-md-6">
<div class="form-group">
  <label>Cell Phone</label>
  <div class="input-group">
    <input
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.CellPhone"
      class="form-control"
    />
  </div>
</div>
</div>

<div class="col-md-6">
<div class="form-group">
  <label>Home Phone</label>
  <div class="input-group">
    <input
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.HomePhone"
      class="form-control"
    />
  </div>
</div>
</div>

<div class="col-md-6">
<div class="form-group">
  <label>Overseas Phone Number</label>
  <div class="input-group">
    <input
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.OverseasPhoneNumber"
      class="form-control"
    />
  </div>
</div>
</div>

<div class="col-md-6">
<div class="form-group">
  <label>Email Address</label>
  <div class="input-group">
    <input
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.EmailAddress"
      class="form-control"
    />
  </div>
</div>
</div>

<h3 style="margin-left: 15px;">Education</h3>

<div class="col-md-6">
<div class="form-group">
  <label>Primary Education Completion Year</label>
  <div class="input-group">
    <input
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.PrimaryEducationCompletionYear"
      class="form-control"
    />
  </div>
</div>
</div>

<div class="col-md-6">
<div class="form-group">
  <label>Secondary Education Completion Year</label>
  <div class="input-group">
    <input
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.SecondaryEducationCompletionYear"
      class="form-control"
    />
  </div>
</div>
</div>

<div class="col-md-6">
<div class="form-group">
  <label>Tertiary Education Completion Year</label>
  <div class="input-group">
    <input
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.TertiaryEducationCompletionYear"
      class="form-control"
    />
  </div>
</div>
</div>

<div class="col-md-6">
<div class="form-group">
  <label>Other Education Completion Year</label>
  <div class="input-group">
    <input
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.OtherEducationCompletionYear"
      class="form-control"
    />
  </div>
</div>
</div>



<h3 style="margin-left: 15px;">Language</h3>

<div class="col-md-6">
<div class="form-group">
  <label>Primary Language</label>
  <div class="input-group">
    <input
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.PrimaryLanguage"
      class="form-control"
    />
  </div>
</div>
</div>

<div class="col-md-6">
<div class="form-group">
  <label>Secondary Language</label>
  <div class="input-group">
    <input
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.SecondaryLanguage"
      class="form-control"
    />
  </div>
</div>
</div>

<h3 style="margin-left: 15px;">Other Information</h3>


<div class="col-md-4">
<div class="form-group">
  <label>Passport Number</label>
  <div class="input-group">
    <input
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.PassportNumber"
      class="form-control"
    />
  </div>
</div>
</div>

<div class="col-md-4">
<div class="form-group">
  <label>Passport Issue Date</label>
  <div class="input-group">
    <input
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.PassportIssueDate"
      class="form-control"
    />
  </div>
</div>
</div>

<div class="col-md-4">
<div class="form-group">
  <label>Passport Issue Country</label>
  <div class="input-group">
    <input
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.PassportIssueCountry"
      class="form-control"
    />
  </div>
</div>
</div>

<div class="col-md-6">
<div class="form-group">
  <label>Passport Expiration Date</label>
  <div class="input-group">
    <input
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.PassportExpirationDate"
      class="form-control"
    />
  </div>
</div>
</div>

<div class="col-md-6">
<div class="form-group">
  <label>Country Of Nationality</label>
  <div class="input-group">
    <input
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedDependent.CountryOfNationality"
      class="form-control"
    />
  </div>
</div>
</div>















        </div>
      </div>
      <div class="modal-footer">


        <button
          type="button"
          class="btn btn-outline-dark"
          (click)="modal.close()"
        >
          Close
        </button>
      </div>
    </ng-template>

`
