import {
  AfterViewInit,
  Component,
  ViewChild,
  OnInit,
  Input,
  OnChanges,
} from "@angular/core";

import DataAction from "../../../actions/action.data";
import {
  DetailRowService,
  GridModel,
  FilterSettingsModel,
  RowSelectEventArgs,
  GridComponent,
  IRow,
  Column,
} from "@syncfusion/ej2-ng-grids";
import { PageSettingsModel } from "@syncfusion/ej2-ng-grids";
import { ToolbarItems } from "@syncfusion/ej2-ng-grids";
import guid from "../../../constants/guid";
import {
  Grid,
  Group,
  Filter,
  Sort,
  ExcelExport,
  CommandColumn,
  Page,
  Toolbar,
  PdfExport,
  Edit,
} from "@syncfusion/ej2-grids";
import * as moment from "moment";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { closest } from "@syncfusion/ej2-base";
import swal from "sweetalert2";
Grid.Inject(
  Filter,
  Group,
  Sort,
  Page,
  ExcelExport,
  Toolbar,
  PdfExport,
  CommandColumn
);

declare var tinymce: any;

@Component({
  selector: "btb-online-portal-messages",
  template: ` <div
    style="position:absolute;top: 10px;left: 20px;right: 20px;bottom:10px; overflow:auto;"
    class="clientassign"
  >
    <ng-template #content let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
          Online Portal Messages
        </h4>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label for="dateOfBirth">Message Title</label>
          <div class="input-group">
            <input
              id="dateOfBirth"
              [(ngModel)]="postdata.MessageTitle"
              class="form-control"
            />
          </div>
          <label>Message</label>
          <div class="input-group">
            <editor
              [(ngModel)]="postdata.FullMessage"
              [init]="{
                plugins:
                  'image imagetools table link paste contextmenu textpattern autolink',
                insert_toolbar: 'image quicktable',
                selection_toolbar:
                  'fontselect |  fontsizeselect | alignleft aligncenter alignright alignjustify | bold italic underline | quicklink h2 h3 blockquote',
                inline: false,
                paste_data_images: true
              }"
            ></editor>
          </div>
          <date-picker
            [model]="postdata"
            [config]="{ key: 'EffectiveDate', label: 'Effective Date' }"
            [editable]="false"
            [addmode]="false"
          ></date-picker>

          <date-picker
            [model]="postdata"
            [config]="{ key: 'ExpirationDate', label: 'Expiration Date' }"
            [editable]="false"
            [addmode]="false"
          ></date-picker>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-outline-dark"
          (click)="modal.close('Save click')"
        >
          Save
        </button>
      </div>
    </ng-template>

    <div class="form-group" style="width:300px;margin:20px;">
      <button (click)="addNew()" class="btn btn-default">
        Add Online Message
      </button>
    </div>

    <div style="margin:40px;">
      <div id="{{ referenceid }}"></div>
    </div>
  </div>`,
  providers: [DetailRowService],
})
export class BTBOnlinePortalMessages implements AfterViewInit {
  public data: Object[];
  public toolbarOptions: ToolbarItems[];
  public pageSettings: PageSettingsModel;
  public postdata = {
    MessageTitle: "",
    FullMessage: "",
    EffectiveDate: "",
    ExpirationDate: "",
  };
  @ViewChild("content", { static: false }) content;
  public filterOptions: FilterSettingsModel;
  public selectedrecords: any;
  closeResult: string;
  public referenceid = guid.create("syncfusion");
  public grid: Grid = null;
  _onChange = null;

  constructor(private modalService: NgbModal) {
    this.filterOptions = {
      type: "Menu",
    };

    this._onChange = (changed) => {
      //this.postdata.ControlId = changed.ControlId;
      this.submitdata(changed.ControlId);
    };
  }

  addNew() {
    this.postdata = {
      MessageTitle: "",
      FullMessage: "",
      EffectiveDate: "",
      ExpirationDate: "",
    };
    this.modalService.open(this.content, { centered: true }).result.then(
      (result) => {
        DataAction.runData(
          this.postdata,
          "SQL",
          "AddOnlinePortalMessage",
          (data1) => {
            DataAction.runData(
              {},
              "SQL",
              "OnlinePortalMessageListing",
              (data2) => {
                this.selectedrecords = null;
                this.grid.dataSource = JSON.parse(JSON.stringify(data2));
                this.grid.refresh();
              }
            );
          }
        );
        // this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  formatDate(value: any) {
    return moment(value).format("LL");
  }

  rowSelected(args: RowSelectEventArgs) {
    this.selectedrecords = this.grid.getSelectedRecords()[0];
  }

  @Input()
  formdata: any;
  ngOnInit(): void {
    this.pageSettings = { pageSize: 6 };
    this.toolbarOptions = ["Search"];
  }

  controlids = [];

  ngAfterViewInit() {
    const onClick = (args) => {
      const rowObj = this.grid.getRowObjectFromUID(
        closest(args.target, ".e-row").getAttribute("data-uid")
      );
      const data: any = rowObj.data;
      this.postdata = data;
      this.modalService.open(this.content, { centered: true }).result.then(
        (result) => {
          // this.closeResult = `Closed with: ${result}`;
          DataAction.runData(
            this.postdata,
            "SQL",
            "UpdateOnlinePortalMessage",
            (data1) => {
              DataAction.runData(
                {},
                "SQL",
                "OnlinePortalMessageListing",
                (data2) => {
                  this.selectedrecords = null;
                  this.grid.dataSource = JSON.parse(JSON.stringify(data2));
                  this.grid.refresh();
                }
              );
            }
          );
        },
        (reason) => {
          // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );

    };

    const onSend = (args) => {
      const rowObj = this.grid.getRowObjectFromUID(
        closest(args.target, ".e-row").getAttribute("data-uid")
      );
      const data: any = rowObj.data;

      swal
        .fire({
          title: "Confirm Send",
          icon: "question",
          html:
            '<p>You are about send the notice to all active accounts. Type the word <span style="color:red">all</span> below and click continue to confirm send.</p><input id="input-field" class="form-control" style="background:#ffffff!important;color:red!important;"></p>',
          showCancelButton: true,
          confirmButtonColor: "#FCB022",
          confirmButtonText: "Continue",
          cancelButtonText: "Cancel",
        })
        .then(
          (result) => {
            if (result.value) {
              const val = $("#input-field").val();
              if (val === "all") {
                $(".fakeloader").show();

                DataAction.runData(
                  data,
                  "SQL",
                  "SendOnlinePortalMessage",
                  (data1) => {
                    $(".fakeloader").hide();
                  }
                );
              } else {
                alert("please try again you did not type 'all'.");
              }
            }
          },
          () => {}
        );
    };

    const cols = [
      {
        field: "MessageTitle",
        headerText: "Title",
      },
      {
        field: "EffectiveDate",
        headerText: "Effective Date",
        type: "date",
        format: "yMd",
        filter: { type: "Menu" },
      },
      {
        field: "ExpirationDate",
        headerText: "Expiration Date",
        type: "date",
        format: "yMd",
        filter: { type: "Menu" },
      },
      {
        headerText: "Commands",
        width: 200,
        isUnbound: false,
        commands: [
          {
            buttonOption: {
              content: "Edit",
              cssClass: "e-flat",
              click: onClick,
            },
          },
          {
            buttonOption: {
              content: "Send",
              cssClass: "e-flat",
              click: onSend,
            },
          },
        ],
      },
    ];

    this.grid = new Grid({
      dataSource: this.data,
      allowPaging: true,
      allowGrouping: true,
      allowFiltering: true,
      allowSorting: true,
      filterSettings: { type: "Excel" },
      allowPdfExport: true,
      allowExcelExport: true,
      toolbar: ["ExcelExport", "PdfExport"],
      columns: cols,
      rowSelected: this.rowSelected.bind(this),
    });
    this.grid.appendTo(`#${this.referenceid}`);
    this.grid.toolbarClick = (args: Object) => {
      if (args["item"].id === `${this.referenceid}_excelexport`) {
        this.grid.excelExport();
      }
      if (args["item"].id === `${this.referenceid}_pdfexport`) {
        this.grid.pdfExport();
      }
    };

    DataAction.runData({}, "SQL", "OnlinePortalMessageListing", (data) => {
      this.selectedrecords = null;
      this.grid.dataSource = JSON.parse(JSON.stringify(data));
      this.grid.refresh();
    });
  }

  reviver(key, value) {
    if (key === "CheckInDate" || key === "CheckOutDate") {
      return new Date(value);
    }

    return value;
  }

  submitdata(controlid) {}
}
