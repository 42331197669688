export default `
<div class="row">

<div class="col-md-6">
<div class="form-group">
  <label>Primary Language</label>
  <div class="input-group">
    <input
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedModification.OldPrimaryLanguageId"
      class="form-control"
    />
  </div>
</div>
</div>

<div class="col-md-6">
<div class="form-group">
  <label>Secondary Language</label>
  <div class="input-group">
    <input
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedModification.OldSecondaryLanguageId"
      class="form-control"
    />
  </div>
</div>
</div>

</div>
`;
