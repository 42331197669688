import {Component, Input, OnInit} from '@angular/core';
import * as _ from 'underscore';
import {DataStore} from '../../../stores/store.data';
import constants from '../../../constants/constants';
import DataAction from "../../../actions/action.data";
import * as moment from 'moment';
import {TrackingStore} from "../../../stores/store.tracking";
import * as numeral from 'numeral'
@Component({
    selector: 'simple-table-view',
    template: `

        <div class="card-full">


            <table class="tableheader hidden-xs hidden-sm" style="margin-top:10px;">
                <thead>
                <tr style="cursor: pointer;-webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;       ">
                    <th *ngFor="let column of formdata.fields" (click)="setSort(column.key)" [ngStyle]="getcolstyle()">

                        <span style="float:left;font-weight:400;width:80%;font-size:14px;font-weight:400;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;">{{column.label}}</span><i
                            *ngIf="column.type!=='action'"
                            style="float:right;width:30px;font-size: 12px;font-weight:600"
                            [ngClass]="getSortClass(column)"></i>
                    </th>
                </tr>
                </thead>

            </table>
            <table class="tablerows">
                <tbody>
                <tr style="background: #fff!important;">
                    <td *ngFor="let column of formdata.fields" [ngStyle]="getcolstyle()">
                        <div [ngSwitch]="column.type" class="hidden-xs hidden-sm">
                            <input type="hidden" id="{{column.key}}" *ngSwitchCase="'action'"/>


                            <div class="input-group" *ngSwitchDefault>
                                <input type="text" class="form-control" placeholder="Search" id="{{column.key}}"
                                       (keydown)="filterdata()" (blur)="filterdata()" (click)="filterdata()"
                                       aria-describedby="basic-addon2">
                                <span class="input-group-addon" id="basic-addon2"><i class="mdi mdi-magnify"></i></span>
                            </div>


                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
            <div class="hidden-xs hidden-sm scrollersimple"
                 data-mcs-theme="dark"
            >


                <table class="tablerows">
                    <tbody>
                    <tr *ngFor="let data of currentdata">
                        <td *ngFor="let column of formdata.fields" [ngStyle]="getcolstyle()">

<span [ngSwitch]="column.type">

      <span *ngSwitchCase="'text'" class="datadisplay">
      {{data[column.key]}}
      </span>
       <span *ngSwitchCase="'date'" class="datedisplay">
      {{dateformatin(data[column.key])}}
      </span>
      <span *ngSwitchCase="'number'" class="datadisplay">
      {{numeralformatin(data[column.key], '0,0')}}
      </span>
      <span *ngSwitchCase="'html'" [innerHTML]="data[column.key]" class="datadisplay">
      </span>

       <div class="actions" *ngSwitchCase="'action'">
                                        <a href="javascript:void(0);"
                                           (click)="onProcessAction(column.action,data)"
                                           class="btn btn-sm btn-circle btn-success"> {{column.action.caption}} </a>
                                    </div>

</span>


                        </td>
                    </tr>

                    </tbody>
                </table>
            </div>

            <div class="visible-xs visible-sm">

                <div class="row">

                    <div *ngFor="let column of formdata.fields" [ngSwitch]="column.type" class="col-sm-6">
                        <input type="hidden" id="{{column.key}}" *ngSwitchCase="'action'"/>


                        <div class="input-group" style="margin-top:8px;" *ngSwitchDefault>
                            <input type="text" class="form-control" placeholder="Search By {{column.label}}"
                                   id="{{column.key}}" (keydown)="filterdata()" (blur)="filterdata()"
                                   (click)="filterdata()" aria-describedby="basic-addon2">
                            <span class="input-group-addon" id="basic-addon2"><i class="mdi mdi-magnify"></i></span>

                        </div>


                    </div>
                </div>

            </div>

            <div class="mobiletable visible-xs visible-sm">


                <ul *ngFor="let data of currentdata">
                    <li *ngFor="let column of formdata.fields" [ngSwitch]="column.type">
                        <span style="font-weight: 600;display: inline-block;width:250px;text-transform: uppercase;">{{column.label}}</span>
                        <span *ngSwitchCase="'text'">
      {{data[column.key]}}
      </span>
                        <span *ngSwitchCase="'date'">
      {{data[column.key]}}
      </span>
                        <span *ngSwitchCase="'number'">
      {{numeralformatin(data[column.key], '0,0')}}
      </span>
                        <span *ngSwitchCase="'html'" [innerHTML]="data[column.key]">
      </span>

                        <div class="actions" *ngSwitchCase="'action'">
                            <a href="javascript:;"
                               (click)="onProcessAction(column.action,data)"
                               class="btn btn-sm btn-circle btn-success"> {{column.action.caption}} </a>
                        </div>

                    </li>
                </ul>
            </div>


        </div>
    `
})
export class SimpleTableComponent implements OnInit {
    @Input() formdata: any;
    @Input() listresources: any;
    colors: any = ['1D8CF7', '1EB2A4', '6C3AAF', '86582E', 'E00015', '4ACB62', 'FF753A', '1D8CF7', '4ACB62'];
    currentdata: any = [];
    alldata: any = [];
    currentSort: string = null;
    sortOrder: string = "ASC";

    numeralformatin(value: any, format: string) {
        return numeral(value).format(format);
    }

    dateformatin(value: any) {
        return moment(value).format("Do MMM YYYY");
    }

    getSortClass(column) {
        if (column.key === this.currentSort) {
            return this.sortOrder === "ASC" ? "mdi mdi-chevron-down" : "mdi mdi-chevron-up";
        }
        return "mdi mdi-unfold-more";
    }


    ngOnInit() {
        if (this.listresources !== null && this.listresources !== undefined) {
            this.listresources.map((res) => {
                if (res.id === this.formdata.startResourceId) {
                    this.alldata = res[res.id];
                    this.currentdata = this.alldata;
                }
            });
        }
        else {
            this.alldata = [];
            this.currentdata = [];
        }


        DataStore.emitter.subscribe(constants.DataActions.DATA_AVAILABLE, (data) => {

            if (this.formdata.datasource !== undefined) {

                if (data[0].dataid === this.formdata.datasource) {
                    this.alldata = data[0].data;
                    this.filterdata();

                }
            }
        });

    }

    setSort(key) {
        this.currentSort = key;
        if (this.currentSort === key) {
            if (this.sortOrder === "ASC") {
                this.sortOrder = "DSC";
            }
            else {
                this.sortOrder = "ASC";
            }
        }
        else {
            this.sortOrder = "ASC";
        }
        this.filterdata();
    }

    filterdata() {
        let retval: any = [];
        let found: boolean = false;
        this.formdata.fields.map((item) => {


            if ($("#" + item.key).val()["length"] !== 0) {

                found = true;
                let pop = [];
                this.alldata.map((item1) => {
                    if (item1[item.key] !== null && item1[item.key] !== undefined) {
                        if (item1[item.key].toLowerCase().indexOf($("#" + item.key).val()["toLowerCase"]()) > -1) {
                            pop.push(item1);
                        }
                    }

                });
                retval = pop;

            }


        });

        if (!found) {
            retval = this.alldata;
        }

        let me = this;

        if (this.currentSort !== null) {
            if (this.sortOrder === "ASC") {
                retval = _.sortBy(retval, function (val) {
                    if (me.currentSort === "last_publish_date") {
                        if (val[me.currentSort] === "N/A") {
                            return new Date(0);
                        }
                        return moment(val[me.currentSort], "MMMM YYYY").toDate();
                    }
                    else {
                        try {
                            return val[me.currentSort].toString().trim();
                        }
                        catch (ex) {
                            return "";
                        }
                    }
                });
            }
            else {
                retval = _.sortBy(retval, function (val) {
                    if (me.currentSort === "last_publish_date") {
                        if (val[me.currentSort] === "N/A") {
                            return new Date(0);
                        }
                        return moment(val[me.currentSort], "MMMM YYYY").toDate();
                    }
                    else {
                        try {
                            return val[me.currentSort].toString().trim();
                        }
                        catch (ex) {
                            return "";
                        }
                    }
                }).reverse();
            }

        }
        this.currentdata = retval;

    }

    getcolstyle() {

        return {
            'width': (100 / this.formdata.fields.length).toString() + "%"
        };
    }

    onProcessAction(action, data) {
        switch (action.actiontype) {

            case "execute": {
                DataAction.runExecute(data, action.typeid, action.executesource, () => {
                    if (action.completedatasource !== null && action.completedatasource !== undefined) {
                        DataAction.runData(data, action.completetypeid, action.completedatasource, () => {
                        });
                    }
                });
            }
                break;
        }
    }
}
