import { Grid } from "@syncfusion/ej2-ng-grids";

export const createTourOperatorApplicationTransportEquipmentListGrid = function(dataSource){
  const cols = [
    {
      field: "TransportEquipmentType",
      headerText: "Transport Equipment Type"
    },
    {
      field: "OwnershipStatus",
      headerText: "Ownership Status"
    },

  ];
  const ownerGrid = new Grid({
    dataSource,
    width: "100%",
    allowTextWrap: true,
    textWrapSettings: {
      wrapMode: "Content"
    },
    columns: cols
  });
  ownerGrid.appendTo(`#${this.props.referenceid5}`);
}
