export default `
<div class="row">

<div class="col-md-12">
    <div class="form-group">
      <label>Name of Tour Operator</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedModification.OldTourOperatorName"
          class="form-control"
        />
      </div>
    </div>
  </div>



  <div class="col-md-12">
    <div class="form-group">
      <label>Category</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedModification.OldCategory"
          class="form-control"
        />
      </div>
    </div>
  </div>

  <div class="col-md-12">
  <div class="form-group">
    <label>Type</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        [(ngModel)]="props.currentSelectedModification.OldType"
        class="form-control"
      />
    </div>
  </div>
</div>

  </div>

`;
