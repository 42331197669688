import { Component, Input, OnChanges, OnInit } from "@angular/core";
import DataAction from "../../../actions/action.data";
import { DataStore } from "../../../stores/store.data";
import constants from "../../../constants/constants";
import guid from "../../../constants/guid";
import { RESTLoader } from "../../../dispatcher/dispatcher.rest";
import * as _ from "underscore";
@Component({
  selector: "user-controls",
  template: `
    <div class="item callnk">
      <div style="height:34px;">
        <button
          *ngIf="!addmode && !editable && mode === 'PENDING'"
          (click)="addItem()"
          class="btn btn-sm btn-circle btn-success pull-right"
        >
          <i class="mdi mdi-plus" style="font-size:14px;"></i> Add New Control
          Id
        </button>
      </div>
      <table style="width:100%;" cellPadding="0" cellSpacing="0">
        <tbody>
          <tr *ngIf="!editable && mode === 'ADD'">
            <td>
              <span class="ThemeControlled">Control ID Name</span>
              <label style="width: 100%">
                <select-ds
                  [editable]="false"
                  [label]="false"
                  [model]="scratchDisk"
                  [listsources]="listresources"
                  [config]="scratchDiskConfig"
                ></select-ds>
              </label>
            </td>

            <td align="center" style="padding-left:5px;">
              <span class="ThemeControlled">Status</span>
              <label style="width: 100%">
                <input
                  type="checkbox"
                  placeholder="Control Id"
                  class="form-control"
                  [(ngModel)]="scratchDisk.Status"
                />
              </label>
            </td>

            <td style="padding:5px;width:88px;">
              <a
                href="javascript:void(0)"
                (click)="addNew()"
                class="save"
                style="display:inline-block;margin-top:22px"
              >
                <i
                  class="mdi mdi-content-save"
                  style="font-size:20px"
                  style="font-size:20px;"
                ></i>
              </a>
              <a
                href="javascript:void(0)"
                (click)="cancelAdd()"
                class="danger"
                style="display:inline-block;margin-top:22px"
              >
                <i class="mdi mdi-undo-variant" style="font-size:20px"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        style="width: 100%;height:300px;overflow-x: hidden;overflow-y: auto;"
      >
        <table
          style="width:100%;"
          class="tablerows"
          cellPadding="0"
          cellSpacing="0"
        >
          <tbody>
            <tr *ngFor="let item of getCalData()">
              <td *ngIf="item.state !== 'UPDATE'">
                
              
               
                <label style="width: 100%">
                  <select-ds
                    [editable]="true"
                    [label]="true"
                    [model]="item"
                    [listsources]="listresources"
                    [config]="scratchDiskConfig"
                  ></select-ds>
                </label>
              </td>

              <td *ngIf="item.state !== 'UPDATE'">
              <span class="ThemeControlled">Security Level</span>
              <label style="width: 100%">
              
                  <select disabled="true" class="form-control" [(ngModel)]="item.SecurityLevel">
                    <option
                      *ngFor="let security of listresources[2]['securitylist']"
                      [attr.value]="security.SecurityLevel"
                      [attr.selected]="
                        security.SecurityLevel == item.SecurityLevel
                          ? true
                          : null
                      "
                    >
                      {{ security.SecurityLevelLabel }}
                    </option>
                  </select>
                </label>
              </td>

              <td
                align="center"
                style="padding-left:5px;"
                *ngIf="item.state !== 'UPDATE'"
              >
                <span class="ThemeControlled">Status</span>
                <label style="width: 100%">
                  <input
                    type="checkbox"
                    readonly="readonly"
                    placeholder="Control Id"
                    class="form-control"
                    [(ngModel)]="item.Status"
                  />
                </label>
              </td>

              <td
                style="padding:5px;width:88px;"
                *ngIf="item.state !== 'UPDATE'"
              >
                <a
                  *ngIf="!editable && mode === 'PENDING'"
                  href="javascript:void(0)"
                  (click)="editItem(item)"
                  ><i
                    style="color:#FCB022;"
                    class="mdi mdi-pencil"
                    style="font-size:20px"
                  ></i
                ></a>
              </td>

              <td *ngIf="item.state === 'UPDATE'">
                <span class="ThemeControlled">Control ID Name</span>
                <label
                  style="width: 100%;border: 1px solid #EDEDED;padding:5px;"
                >
                  <span style="">{{
                    item.ControlId + "-" + item.ControlIdName
                  }}</span>
                </label>
              </td>

              <td *ngIf="item.state === 'UPDATE'">
                <span class="ThemeControlled">Security Level</span>
                <label style="width: 100%">
                  <select
                    class="form-control"
                    [(ngModel)]="scratchDisk.SecurityLevel"
                  >
                    <option
                      *ngFor="let security of listresources[2]['securitylist']"
                      [attr.value]="security.SecurityLevel"
                      [attr.selected]="
                        security.SecurityLevel == scratchDisk.SecurityLevel
                          ? true
                          : null
                      "
                    >
                      {{ security.SecurityLevelLabel }}
                    </option>
                  </select>
              </label>
              </td>

              <td
                align="center"
                style="padding-left:5px;"
                *ngIf="item.state === 'UPDATE'"
              >
                <span class="ThemeControlled">Status</span>
                <label style="width: 100%">
                  <input
                    type="checkbox"
                    placeholder="Control Id"
                    class="form-control"
                    [(ngModel)]="scratchDisk.Status"
                  />
                </label>
              </td>

              <td
                style="padding:5px;width:88px;"
                *ngIf="item.state === 'UPDATE'"
              >
                <a
                  href="javascript:void(0)"
                  (click)="saveItem(item)"
                  class="save"
                  style="display:inline-block;margin-top:22px"
                >
                  <i
                    class="mdi mdi-content-save"
                    style="font-size:20px"
                    style="font-size:20px;"
                  ></i>
                </a>
                <a
                  href="javascript:void(0)"
                  (click)="cancelItem(item)"
                  class="danger"
                  style="display:inline-block;margin-top:22px"
                >
                  <i class="mdi mdi-undo-variant" style="font-size:20px"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  `
})
export class UserControlComponent implements OnChanges, OnInit {
  @Input() editable: boolean = false;
  @Input() addmode: boolean = false;
  @Input() model: any = null;
  @Input() config: any = null;
  @Input() updatewall: any;
  @Input() linkfield: any;
  @Input() listresources: any = [];

  mode = "PENDING";

  emptyLink = {
    UserId: "",
    ControlId: "",
    ControlIdName: "",
    UserActive: false,
    SecurityLevel:3,
    state: "NEW"
  };
  //editable1 = true;
  scratchDisk = JSON.parse(JSON.stringify(this.emptyLink));
  scratchDiskConfig = {
    key: "ControlId",
    type: "select",
    label: "Control ID Name",
    placeholder: "",
    optionsResource: {
      resource: "controlids",
      resourceId: "ControlId",
      resourceValue: "NameAndControlId"
    }
  };
  caldata = [];

  getCalData() {
    var ret = [];
    this.caldata.map(item => {
      if (item.state !== "DELETE") {
        ret.push(item);
      }
    });
    return ret;
  }

  getControlName(controlid) {
    if (this.listresources.length > 0) {
      var v = _.where(this.listresources[0]["getcontrollist"], {
        ControlId: controlid
      });
      if (v != null && v.length > 0) {
        return v[0]["ControlIdName"];
      }
      return "N/A";
    }
  }

  ngOnInit() {
    DataStore.emitter.subscribe(constants.DataActions.DATA_AVAILABLE, data => {
      if (data[0].dataid === this.config.data.getResource) {
        this.caldata = data[0].data;
      } else {
      }
    });
    if (this.updatewall !== undefined) {
      this.updatewall.updateWall.push(this.updatechanges.bind(this));
    }
  }

  updatechanges(callback) {
    this.mode = "PENDING";
    if (this.caldata.length > 0) {
      this.processUpdate(0, callback);
    }
  }
  ngOnChanges(data) {
    if (data.model !== null && data.model !== undefined) {
      let jdata =
        '{"' +
        this.config.data.linkfield +
        '":"' +
        this.model[this.config.key] +
        '"}';

      let v1 = JSON.parse(jdata);
      DataAction.runData(
        v1,
        this.config.data.getResourceType,
        this.config.data.getResource,
        () => {}
      );
      this.mode = "PENDING";
    }
  }

  addNew() {
    this.caldata.push(JSON.parse(JSON.stringify(this.scratchDisk)));
    this.mode = "PENDING";
  }
  cancelAdd() {
    this.mode = "PENDING";
  }

  addItem() {
    this.scratchDisk = JSON.parse(JSON.stringify(this.emptyLink));
    this.scratchDisk.UserId = this.model["UserId"];
    this.scratchDisk.SecurityLevel = 3;
    this.mode = "ADD";
  }

  deleteItem(item) {
    item.state = "DELETE";
  }

  saveItem(item) {
    for (var l in item) {
      item[l] = this.scratchDisk[l];
    }
    if(item.state!="ADD")
    {
      item.state = "EDIT";
    }
    this.mode = "PENDING";
  }

  editItem(item) {
    this.mode = "EDITING";
    item.state = "UPDATE";
    this.scratchDisk = JSON.parse(JSON.stringify(item));
  }

  cancelItem(item) {
    this.mode = "PENDING";
    item.state = "";
  }

  processUpdate(index, callback) {
    let type: string = this.caldata[index]["state"];
    if (type === "NEW")
      DataAction.runData(
        this.caldata[index],
        this.config.data.getResourceType,
        this.config.data.createResource,
        () => {
          this.caldata[index]["state"] = "";
          if (index < this.caldata.length - 1) {
            this.processUpdate(index + 1, callback);
          } else {
            callback();
          }
        }
      );
    else if (type === "EDIT")
      DataAction.runData(
        { ...this.caldata[index], UserId: this.model["UserId"] },
        "SQL",
        "updateusercontrolid",
        () => {
          this.caldata[index]["state"] = "";
          if (index < this.caldata.length - 1) {
            this.processUpdate(index + 1, callback);
          } else {
            callback();
          }
        }
      );
    else if (type === "DELETE")
      DataAction.runData(
        { ...this.caldata[index], UserId: this.model["UserId"] },
        "SQL",
        "updateusercontrolid",
        () => {
          this.caldata[index]["state"] = "";
          if (index < this.caldata.length - 1) {
            this.processUpdate(index + 1, callback);
          } else {
            callback();
          }
        }
      );
    else if (index < this.caldata.length - 1) {
      this.processUpdate(index + 1, callback);
    } else {
      callback();
    }
  }
}
