export default `
<div class="row">

<div class="col-md-10">
  <div class="form-group">
    <label>Physical Address</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedModification.NewAddress"
        [style.background-color]="compare(props.currentSelectedModification.NewAddress,props.currentSelectedModification.OldAddress) ? '#AEF0B7' : '#fff'"
      />
    </div>
  </div>
</div>

<div class="col-md-2">
  <div class="form-group">
    <label>District Area</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedModification.NewDistrictAreaName"
        [style.background-color]="compare(props.currentSelectedModification.NewDistrictAreaName,props.currentSelectedModification.OldDistrictAreaName) ? '#AEF0B7' : '#fff'"
      />
    </div>
  </div>
</div>

<div class="col-md-10">
  <div class="form-group">
    <label>Mailing Address</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedModification.NewMailingAddress"
        [style.background-color]="compare(props.currentSelectedModification.NewMailingAddress,props.currentSelectedModification.OldMailingAddress) ? '#AEF0B7' : '#fff'"
      />
    </div>
  </div>
</div>



<div class="col-md-2">
  <div class="form-group">
    <label>District Area</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedModification.NewMailingDistrictAreaName"
        [style.background-color]="compare(props.currentSelectedModification.NewMailingDistrictAreaName,props.currentSelectedModification.OldMailingDistrictAreaName) ? '#AEF0B7' : '#fff'"
      />
    </div>
  </div>
</div>



<div class="col-md-4">
  <div class="form-group">
    <label>Telephone 1</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedModification.NewTelephone1"
        [style.background-color]="compare(props.currentSelectedModification.NewTelephone1,props.currentSelectedModification.OldTelephone1) ? '#AEF0B7' : '#fff'"
      />
    </div>
  </div>
</div>


<div class="col-md-4">
  <div class="form-group">
    <label>Telephone 2</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedModification.NewTelephone2"
        [style.background-color]="compare(props.currentSelectedModification.NewTelephone2,props.currentSelectedModification.OldTelephone2) ? '#AEF0B7' : '#fff'"
      />
    </div>
  </div>
</div>


<div class="col-md-4">
<div class="form-group">
<label>Fax</label>
<div class="input-group">
  <input
    readOnly="readOnly"
    class="form-control"
    [(ngModel)]="props.currentSelectedModification.NewFaxNumber"
    [style.background-color]="compare(props.currentSelectedModification.NewFaxNumber,props.currentSelectedModification.OldFaxNumber) ? '#AEF0B7' : '#fff'"
  />
</div>


</div>
</div>

<div class="col-md-6">
<div class="form-group">
<label>Email Address</label>
<div class="input-group">
<input
readOnly="readOnly"
class="form-control"
[(ngModel)]="props.currentSelectedModification.NewEmailAddress"
[style.background-color]="compare(props.currentSelectedModification.NewEmailAddress,props.currentSelectedModification.OldEmailAddress) ? '#AEF0B7' : '#fff'"
/>
</div>
</div>

</div>






<div class="col-md-6">
<div class="form-group">
<label>Web Site</label>
<div class="input-group">
<input
readOnly="readOnly"
class="form-control"
[(ngModel)]="props.currentSelectedModification.NewWebSite"
[style.background-color]="compare(props.currentSelectedModification.NewWebSite,props.currentSelectedModification.OldWebSite) ? '#AEF0B7' : '#fff'"
/>
</div>
</div>
</div>
</div>
`;
