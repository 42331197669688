export const AUTHENTICATION = {
    formtype: "authentication",
    listresources: [],
    caption: "Login",
    functionactions: {       
    },
    formDefinition: {
      rows: [
        {
          columns: [
            {
              fields: [
                {
                  key: "auth",
                  type: "auth",
                  onComplete: "move next scene",
                  title: "Web Service User Management"
                }
              ]
            }
          ]
        }
      ]
    }
  }