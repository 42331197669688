export default `
<ng-template #approvecomplete let-modal>
<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    Checkpoints
  </h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="col-md-12">
    <date-picker
      [model]="props.postDataComplete"
      [config]="{ key: 'CheckpointDate', label: 'Checkpoint Date' }"
      [editable]="false"
      [addmode]="false"
    ></date-picker>
  </div>

  <div class="col-md-12">
    <div class="form-group">
      <label>Comments</label>
      <div class="input-group">
        <input
          class="form-control"
          [(ngModel)]="props.postDataComplete.CheckpointComments"
        />
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="modal.close('Save click')"
  >
    Save
  </button>
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="modal.close('Close click')"
  >
    Close
  </button>
</div>
</ng-template>

`
