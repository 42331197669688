export default `
<div class="row">

<div class="col-md-12">
    <div class="form-group">
      <label>Name of Tour Operator</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedModification.NewTourOperatorName"
          class="form-control"
          [style.background-color]="compare(props.currentSelectedModification.NewTourOperatorName,props.currentSelectedModification.OldTourOperatorName) ? '#AEF0B7' : '#fff'"
        />
      </div>
    </div>
  </div>



  <div class="col-md-12">
    <div class="form-group">
      <label>Category</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedModification.NewCategory"
          [style.background-color]="compare(props.currentSelectedModification.NewCategory,props.currentSelectedModification.OldCategory) ? '#AEF0B7' : '#fff'"
          class="form-control"
        />
      </div>
    </div>
  </div>


  <div class="col-md-12">
    <div class="form-group">
      <label>Type</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedModification.NewType"
          [style.background-color]="compare(props.currentSelectedModification.NewType,props.currentSelectedModification.OldType) ? '#AEF0B7' : '#fff'"
          class="form-control"
        />
      </div>
    </div>
  </div>

  </div>

`;
