import { UserStore } from "src/app/stores/store.user";
import axios from "axios";
export const showSelectedModification = function(ModificationId: number, callback) {
  const showPostData = {
    TourGuideModificationRequestNumber: ModificationId
  };
  axios
    .post("/GetTourGuideModificationRequestDetail", showPostData, {
      headers: {
        "user-token": UserStore.getUserDetails().token,
        "site-token": "7pdvAXwKchuAQGw7QBLnSHS",
        "user-id": 1,
        "control-id": 1
      }
    })
    .then((response: any) => {
      callback(response.data.TourGuideModificationRequestDetail);
    });
};
