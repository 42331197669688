export const GUESTREGISTRATION = {
  access: ['admin'],
  formtype: 'guestregistration',
  listresources: [
    {
      id: 'controlids',
      type: 'SQL'
    }
  ],
  caption: 'View Guest Registration'
};
