import general from "./general";
import contactinformation from "./contactinformation";
import tours from "./tours";
import otherinformation from "./otherinformation";
import guides from "./guides";
import safety from "./safety";
import transport from "./transport";
import employees from "./employees";
import guid from "src/app/constants/guid";

export default `
<ng-template #content let-modal>
<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    Application Form - {{ props.selectedTourOperator }}
  </h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <h3>General Information</h3>
  ${general}
  <h3>Contact Information</h3>
  ${contactinformation}
  <h3>Employee(s)</h3>
  ${employees}
  <h3>Guide(s)</h3>
  ${guides}
  <h3>Tour(s)</h3>
  ${tours}
  <h3>Safety Equipment(s)</h3>
  ${safety}
  <h3>Transport Equipment(s)</h3>
  ${transport}
  <h3>Other Details</h3>
  ${otherinformation}
</div>

<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="modal.close('Save click')"
  >
    Close
  </button>
</div>
</ng-template>

`
