export default `
<div class="row">
<div class="col-md-6">
    <div class="form-group">
      <label>Tour Guide Association</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedModification.OldTourGuideAssociationName"
          class="form-control"
        />
      </div>
    </div>
  </div>

  <div class="col-md-6">
    <div class="form-group">
      <label>Tour Guide Association District</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedModification.OldTourGuideAssociationDistrictName"
          class="form-control"
        />
      </div>
    </div>
  </div>

  <div class="col-md-12">
    <div class="form-group">
      <label>Education Level</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedModification.OldEducationLevel"
          class="form-control"
        />
      </div>
    </div>
  </div>








  </div>
`;
