import { closest } from "@syncfusion/ej2-ng-grids/node_modules/@syncfusion/ej2-base";
import axios from 'axios';
import { UserStore } from "src/app/stores/store.user";
import { fit_modal_body } from "./fitmodalbody";
import { showComplete } from "./showComplete";

export const checkpointComplete = function(args) {
  const rowObj = this.props.completeGridTrack.getRowObjectFromUID(
    closest(args.target, ".e-row").getAttribute("data-uid")
  );
  const data: any = rowObj.data;
  const _showComplete=showComplete.bind(this);
  this.props.postDataComplete = {
    TourOperatorModificationRequestNumber:
      data.TourOperatorModificationRequestNumber,
      TourOperatorModificationRequestCheckpointRecordNumber:
      data.TourOperatorModificationRequestCheckpointRecordNumber,
    CheckpointDate: "",
    CheckpointName: data.CheckpointName,
    CompletedBy: UserStore.getUserName(),
    CheckpointComments: ""
  };

  this.modalService
    .open(this.approvecomplete, { centered: true, size: "lg" })
    .result.then(
      result => {
        // this.closeResult = `Closed with: ${result}`;
        if (result === "Save click") {

          axios
            .post(
              "/UpdateTourOperatorModificationRequestCheckpoint",
              this.props.postDataComplete,
              {
                headers: {
                  "user-token": UserStore.getUserDetails().token,
                  "site-token": "7pdvAXwKchuAQGw7QBLnSHS",
                  "user-id": 1,
                  "control-id": 1
                }
              }
            )
            .then((response: any) => {
              _showComplete(
                parseInt(
                  data.TourOperatorModificationRequestNumber.toString()
                ),
                response => {
                  this.props.completeGridTrack.dataSource = response;
                }
              );
            });
        }
      },
      reason => {
        // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  setTimeout(() => {
    fit_modal_body($(".modal"));
  }, 100);


};
