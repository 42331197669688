export default `
<div class="row">
<div class="col-md-6">
    <div class="form-group">
      <label>Foreign Source Of Funds</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedModification.OldForeignSourceOfFunds"
          class="form-control"
        />
      </div>
    </div>
  </div>

  <div class="col-md-6">
    <div class="form-group">
      <label>Local Financial Institution</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedModification.OldLocalfinancialInstitution"
          class="form-control"
        />
      </div>
    </div>
  </div>



  </div>
`;
