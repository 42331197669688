export default `
<section id="temporaryClosure" *ngIf="!isEmpty(temporaryClosure)">
<h3>Temporary Closure</h3>
<br />
<div class="form-group row">
  <label
    for="temporaryclosurecommencementdate"
    class="col-sm-3 col-form-label"
    >Date to commence Temporary Closure: (dd/mm/yy):</label
  >
  <div class="col-sm-9">
    <input
      [(ngModel)]="temporaryClosure.ClosureDate"
      type="text"
      class="form-control"
      id="temporaryclosurecommencementdate"
      placeholder="Date to commence Temporary Closure: (dd/mm/yy)"
      readonly
    />
  </div>
</div>
<div class="form-group row">
  <label for="reopeningdate" class="col-sm-3 col-form-label"
    >Date of reopening: (dd/mm/yy):</label
  >
  <div class="col-sm-9">
    <input
      [(ngModel)]="temporaryClosure.ReOpenningDate"
      type="text"
      class="form-control"
      id="reopeningdate"
      placeholder="Date of reopening: (dd/mm/yy)"
      readonly
    />
  </div>
</div>
<div class="form-group row">
  <label
    for="temporaryclosurereason"
    class="col-sm-3 col-form-label"
    >Reason for Temporary Closure:</label
  >
  <div class="col-sm-9">
    <input
      type="text"
      class="form-control"
      [(ngModel)]="temporaryClosure.ReasonOfClosure"
      id="temporaryclosurereason"
      placeholder="Reason for Temporary Closure"
      readonly
    />
  </div>
</div>
</section>

`