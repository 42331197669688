export const BLANK = {
    access: ['admin'],
    formtype: 'dataEntry',
    listresources: [],
    supportsDelete: false,
    supportsAdd: false,
    supportsAudit: false,
    caption: 'Welcome',
    formDefinition: {
      rows: [
        {
          columns: []
        }
      ]
    }
};
