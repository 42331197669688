export const MANAGEADMIN = {
  duplicates: [],
  access: ['admin'],
  track: 'systemuser',
  formtype: 'dataEntry',
  listresources: [
    {
      id: 'ViewAdminListing',
      type: 'SQL'
    },
    {
      id: 'GetPermissions',
      type: 'SQL'
    },

    {
      id: 'groups',
      type: 'SQL',
      groups: [
        { GroupName: 'Administrator', Description: 'Administrator' },
        { GroupName: 'Online', Description: 'Online User Management Only' },
        {
          GroupName: 'CalcInterest',
          Description: 'Calculate Interest Only'
        },
        {
          GroupName: 'OnlineCalc',
          Description: 'Online User and Calculate Interest'
        },
        { GroupName: 'UploadOnly', Description: 'Upload Bank File Only' }
      ]
    }
  ],
  caption: 'Manage Administrative Users',
  editSearchSelector: {
    resource: 'ViewAdminListing',
    resourceId: 'UserName',
    resourceValue: 'FullName',
    resourceSelectedType: 'SQL',
    resourceSelected: 'GetUserAdmin',
    resourceDisplayFields: ['UserName']
  },
  model: {
    modelid: 'GetUserAdmin',
    autoload: false
  },
  functionactions: {
    createnew: {
      functionname: 'AddAdmin',
      functiontype: 'SQL',
      validateData: true,
      validationFailedTitle: '',
      validationFailedText: '',
      alertOnComplete: true,
      alertTitle: '',
      alertText: ''
    },
    
    update: {
      functionname: 'UpdateAdminInformation',
      functiontype: 'SQL',
      validateData: true,
      validationFailedTitle: '',
      validationFailedText: '',
      alertOnComplete: true,
      alertTitle: '',
      alertText: ''
    },
    after: {
      functionname: 'ViewAdminListing',
      functiontype: 'SQL'
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: 'General Information',
            fields: [
              {
                key: 'UserName',
                type: 'text',
                label: 'User Name',
                placeholder: 'User Name',
                required: 'true',
                validation: {
                  'data-parsley-required': true,
                  'data-parsley-minlength': 3,
                  'data-parsley-maxlength': 100
                }
              },
              {
                key: 'FirstName',
                type: 'text',
                label: 'First Name',
                placeholder: 'First Name',
                required: 'true',
                validation: {
                  'data-parsley-required': true,
                  'data-parsley-minlength': 2,
                  'data-parsley-maxlength': 100
                },
                isPrimary: 'false'
              },

              {
                key: 'Lastname',
                type: 'text',
                label: 'Last Name',
                placeholder: 'Last Name',
                required: 'true',
                validation: {
                  'data-parsley-required': true,
                  'data-parsley-minlength': 2,
                  'data-parsley-maxlength': 100
                },
                isPrimary: 'false'
              },

              {
                key: 'Password',
                type: 'password',
                label: 'Password',
                placeholder: 'Password',
                required: 'true',
                hideOnEdit: 'true',
                validation: {
                  'data-parsley-required': true,
                  'data-parsley-minlength': 3,
                  'data-parsley-maxlength': 100
                },
                isPrimary: 'false'
              },

              {
                key: 'TelephoneNumber',
                type: 'text',
                label: 'Telephone Number',
                placeholder: 'Telephone Number',
                required: 'true',
                validation: {
                  'data-parsley-required': true,
                  'data-parsley-minlength': 3,
                  'data-parsley-maxlength': 15
                },
                isPrimary: 'false'
              },

              {
                key: 'EmailAddress',
                type: 'text',
                label: 'Email Address',
                placeholder: 'Email Address',
                required: 'true',
                validation: {
                  'data-parsley-required': true,
                  'data-parsley-minlength': 3,
                  'data-parsley-maxlength': 100,
                  'data-parsley-type': 'email'
                },
                isPrimary: 'false'
              },

              {
                key: 'UserNotes',
                type: 'text',
                label: 'Notes',
                placeholder: 'Notes',
                required: 'true',
                validation: {
                  'data-parsley-maxlength': 255
                },
                isPrimary: 'false'
              },
              {
                key: 'UserActive',
                type: 'checkbox',
                label: 'Active',
                placeholder: 'Active',
                required: 'true',
                validation: {
                  'data-parsley-maxlength': 1
                },
                isPrimary: 'false'
              },
              {
                key: 'GroupName',
                type: 'hidden',
                label: 'Group Name',
                placeholder: ''
              }
            ]
          },

          {
            title: 'Permissions',
            hideOnAdd: 'true',
            fields: [
              {
                type: 'editabletable',
                datasource: 'GetUserPermissions',
                key: 'UserName',
                keylink: 'UserName',
                data: {
                  getResource: {
                    resourceType: 'SQL',
                    id: 'GetUserPermissions'
                  },
                  createResource: {
                    resourceType: 'SQL',
                    id: 'CreateSystemRole'
                  },
                  updateResource: {
                    resourceType: 'SQL',
                    id: 'UpdateSystemRole'
                  },
                  deleteResource: {
                    resourceType: 'SQL',
                    id: 'DeleteSystemRole'
                  }
                },
                fields: [
                  {
                    key: 'SystemRole',
                    type: 'select',
                    label: 'Permission',
                    placeholder: 'Permission',
                    optionsResource: {
                      resource: 'GetPermissions',
                      resourceId: 'SystemRole',
                      resourceValue: 'Description'
                    },
                    validation: {
                      'data-parsley-required': true,
                      'data-parsley-minlength': 1
                    },
                    larger: 'false'
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
