export default `
<div class="row">

<div class="col-md-12">
    <div class="form-group">
      <label>Name of Tour Operator</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedApplication.TourOperatorName"
          class="form-control"
        />
      </div>
    </div>
  </div>

  <div class="col-md-12">
    <div class="form-group">
      <label>Previous Name (if any)</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedApplication.PreviousTourOperatorName"
          class="form-control"
        />
      </div>
    </div>
  </div>

  <div class="col-md-12">
    <div class="form-group">
      <label>Category</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedApplication.TourOperatorCategory"
          class="form-control"
        />
      </div>
    </div>
  </div>

  </div>

`;
