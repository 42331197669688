import { AfterViewInit, Component, Input, OnChanges } from "@angular/core";
import DataAction from "../../../actions/action.data";
import * as _ from "underscore";
import * as toastr from "toastr";
import { FormStore } from "../../../stores/store.form";
import * as moment from "moment";
import { UserStore } from "../../../stores/store.user";
import swal from "sweetalert2";

@Component({
  selector: "btb-send-mail",
  template: ` <div class="editorActions" style="right:10px;"></div>
    <div
      style="position:absolute;top: 10px;left: 20px;right: 20px;bottom:10px;"
      class="clientassign"
    >
      <div class="form-group" style="width:300px;margin:20px;">
        <label for="sel1">Control Id:</label>

        <searchable
          [placeholder]="'Control Id'"
          [editable]="true"
          [onChange]="_onChange"
          [model]="postdata"
          name="controlidcol"
          [(data)]="formdata.listresources[0]['controlids']"
          value="ControlId"
          text="Name"
          [displays]="['ControlIdName']"
          theme="formSearch"
        ></searchable>
      </div>

      <div style="display:inline-block;margin-left:5px;">
        <div class="form-group" style="width:300px;margin:20px;">
          <div style="width:150px;display:inline-block;">
            <select class="form-control" [(ngModel)]="postdata.month1">
              <option></option>
              <option value="0">JANUARY</option>
              <option value="1">FEBRUARY</option>
              <option value="2">MARCH</option>
              <option value="3">APRIL</option>
              <option value="4">MAY</option>
              <option value="5">JUNE</option>
              <option value="6">JULY</option>
              <option value="7">AUGUST</option>
              <option value="8">SEPTEMBER</option>
              <option value="9">OCTOBER</option>
              <option value="10">NOVEMBER</option>
              <option value="11">DECEMBER</option>
            </select>
          </div>
          <div style="width:15px;display:inline-block;margin-left:10px;">
            <strong>/</strong>
          </div>
          <div style="width:90px;display:inline-block;margin-left:5px;">
            <select class="form-control" [(ngModel)]="postdata.year1">
              <option></option>
              <option *ngFor="let y of years" value="{{ y }}">{{ y }}</option>
            </select>
          </div>
        </div>
      </div>

      <div style="width:15px;display:inline-block;margin-left:10px;">
        <strong> To </strong>
      </div>
      <div style="display:inline-block;margin-left:5px;">
        <div class="form-group" style="width:300px;margin:20px;">
          <div style="width:150px;display:inline-block;">
            <select class="form-control" [(ngModel)]="postdata.month2">
              <option></option>
              <option value="0">JANUARY</option>
              <option value="1">FEBRUARY</option>
              <option value="2">MARCH</option>
              <option value="3">APRIL</option>
              <option value="4">MAY</option>
              <option value="5">JUNE</option>
              <option value="6">JULY</option>
              <option value="7">AUGUST</option>
              <option value="8">SEPTEMBER</option>
              <option value="9">OCTOBER</option>
              <option value="10">NOVEMBER</option>
              <option value="11">DECEMBER</option>
            </select>
          </div>
          <div style="width:15px;display:inline-block;margin-left:10px;">
            <strong>/</strong>
          </div>
          <div style="width:90px;display:inline-block;margin-left:5px;">
            <select class="form-control" [(ngModel)]="postdata.year2">
              <option></option>
              <option *ngFor="let y of years" value="{{ y }}">{{ y }}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="form-group" style="width:300px;margin:20px;">
        <a
          href="javascript:;"
          (click)="calculate()"
          class="btn btn-sm btn-circle btn-success"
        >
          Send Statement
        </a>
      </div>

      <div class="form-group" style="width:300px;margin:20px;">
        <span>{{ currentMessages }}</span>
      </div>
    </div>`,
})
export class BTBSendStatement implements AfterViewInit {
  _onChange = null;
  years = [];
  months = [];
  constructor() {
    this._onChange = (changed) => {
      this.postdata.ControlId = changed.ControlId;
    };
  }
  controlids = [];
  @Input() formdata: any;
  postdata = {
    month1: "",
    year1: "",
    month2: "",
    year2: "",
    ControlId: "",
    startdate: "",
    enddate: "",
    userid: "",
  };
  me = null;
  calculate() {
    /* let realdate = moment(
      this.postdata.year1 + "-" + this.postdata.month1 + "-1"
    ).toDate();    */
    let realdate = new Date(
      parseInt(this.postdata.year1.toString(), 10),
      parseInt(this.postdata.month1.toString(), 10),
      1
    );

    this.postdata.startdate = moment(realdate).format("YYYY-MM-DD");

    let realdate1 = new Date(
      parseInt(this.postdata.year2, 10),
      parseInt(this.postdata.month2, 10) + 1,
      0
    );
    this.postdata.enddate = moment(realdate1).format("YYYY-MM-DD");

    if (
      this.postdata.ControlId !== "" &&
      this.postdata.ControlId !== null &&
      this.postdata.ControlId !== undefined
    ) {
      $(".fakeloader").show();
      let fakeobj = JSON.parse(JSON.stringify(this.postdata));
      fakeobj.userid = UserStore.getUserName();

      this.submitdata(fakeobj, true, () => {});
    } else {
      swal
        .fire({
          title: "Confirm Send",
          icon: "question",
          html:
            '<p>You are about send statements for the selected period to all accounts. Type the word <span style="color:red">all</span> below and click continue to confirm send.</p><input id="input-field" class="form-control" style="background:#ffffff!important;color:red!important;"></p>',
          showCancelButton: true,
          confirmButtonColor: "#FCB022",
          confirmButtonText: "Continue",
          cancelButtonText: "Cancel",
        })
        .then(
          (result) => {
            if (result.value) {
              $(".fakeloader").show();
              let val = $("#input-field").val();
              if (val === "all") {
                let countsent = 0;
                let fakeobj = JSON.parse(JSON.stringify(this.postdata));
                let founderror = false;
                let ErrorString = "";
                this.formdata.listresources[0]["controlids"].map((controls) => {
                  fakeobj.userid = UserStore.getUserName();
                  fakeobj.ControlId = controls.ControlId;

                  this.submitdata(
                    JSON.parse(JSON.stringify(fakeobj)),
                    false,
                    (data) => {
                      if (data.ErrorString !== undefined) {
                        ErrorString = data.ErrorString;
                        founderror = true;
                      }
                      countsent++;
                      if (
                        countsent >=
                        this.formdata.listresources[0]["controlids"].length
                      ) {
                        $(".fakeloader").hide();
                        if (!founderror) {
                          toastr.success(
                            "Statement Has Been Emailed To Client's Primary Email",
                            "Success",
                            {
                              timeOut: 5000,
                              closeButton: true,
                              newestOnTop: true,
                              positionClass: "toast-bottom-right",
                              showEasing: "swing",
                              hideEasing: "linear",
                              showMethod: "fadeIn",
                              hideMethod: "fadeOut",
                            }
                          );
                        } else {
                          toastr.error(ErrorString, "Error", {
                            timeOut: 5000,
                            closeButton: true,
                            newestOnTop: true,
                            positionClass: "toast-bottom-right",
                            showEasing: "swing",
                            hideEasing: "linear",
                            showMethod: "fadeIn",
                            hideMethod: "fadeOut",
                          });
                        }
                      }
                    }
                  );
                });
              } else {
                alert("please try again you did not type 'all'.");
              }
            }
          },
          () => {}
        );
    }
  }

  submitdata(senddata, alert, done) {
    DataAction.runData(senddata, "SQL", "SendStatement", (data) => {
      done(data);

      if (alert) {
        $(".fakeloader").hide();
        if (data.ErrorString === undefined) {
          toastr.success(
            "Statement Has Been Emailed To Client's Primary Email",
            "Success",
            {
              timeOut: 5000,
              closeButton: true,
              newestOnTop: true,
              positionClass: "toast-bottom-right",
              showEasing: "swing",
              hideEasing: "linear",
              showMethod: "fadeIn",
              hideMethod: "fadeOut",
            }
          );
        } else {
          toastr.error(data.ErrorString, "Error", {
            timeOut: 5000,
            closeButton: true,
            newestOnTop: true,
            positionClass: "toast-bottom-right",
            showEasing: "swing",
            hideEasing: "linear",
            showMethod: "fadeIn",
            hideMethod: "fadeOut",
          });
        }
      }
    });
  }

  config2 = {
    key: "penaltydate",
    type: "date",
    label: "Penalty Period Date",
    placeholder: "Penalty Period Date",
    required: "true",
    validation: {
      "data-parsley-required": true,
    },
    isPrimary: "false",
    default: "TODAY",
    readonly: true,
  };

  currentMessages = "";
  ngAfterViewInit() {
    let yearstart = new Date().getFullYear() - 1;
    for (let l = 0; l < 3; l++) {
      this.years.push(yearstart);
      yearstart++;
    }

    for (let f = 1; f < 13; f++) {
      this.months.push(f);
    }
  }
}
