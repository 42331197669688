import dispatcher from "../dispatcher/dispatcher.app";
import constants from "../constants/constants";
import { RESTLoaderAuthOnly } from "../dispatcher/dispatcher.rest.noerror";
import * as $ from "jquery";

export default class UserAction {
  static getUserIdentity(userProperties: any) {
    let respdata = {
      id: 0,
      username: userProperties.username,
      name: userProperties.name,
      customer_id: userProperties.clientid,
      user_type: userProperties.user_type,
      last_activity: "",
      token: userProperties.token
    };
    setTimeout(() => {
    dispatcher.dispatch({
      action: constants.UserActions.GET_USER_DETAILS,
      data: respdata
    });},10);
  }

  static authenticateUser(username: string, password: string) {
    let authenticationData = {
      UserName: username,
      Password: password
    };

    RESTLoaderAuthOnly.processRequest(
      false,
      "Authenticate",
      authenticationData,
      "POST",
      function(response1) {
        if (response1[0].UserRole !== "failed") {
            setTimeout(() => {
          dispatcher.dispatch({
            action: constants.UserActions.USER_DETAILS_AVAILABLE,
            data: {
              details: response1[0],
              UserRole: response1[0].UserRole,
              Roles:response1[0].Roles,
              UserName:response1[0].UserName,
              TokenValue: response1[0].TokenValue,
              GroupName: response1[0].GroupName,
              menuSystem: []
            }
          });},10);
        } else {
            setTimeout(() => {
          dispatcher.dispatch({
            action: constants.UserActions.AUTHENTICATION_FAILED,
            data: {
              details: response1[0],
              UserRole: response1[0].UserRole,
              Roles:response1[0].Roles,
              TokenValue: null,
              menuSystem: []
            }
          });},10);
        }
      }
    );
  }
}
