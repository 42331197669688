import viewapplications from "./viewapplication";
import completeSection from "./complete";
import processSection from "./process";
import approvalSection from "./approval";
import documentSection from "./documents";
import dependent from "./dependent";


export default `
${viewapplications}
${completeSection}
${processSection}
${approvalSection}
${documentSection}
${dependent}

<div class="editorActions" style="right:10px;"></div>
<div
  style="position:absolute;top: 10px;left: 20px;right: 20px;bottom:10px; overflow:auto;"
  class="clientassign"
>
  <div class="row" style="margin:50px 0px 0px 25px">
    <div class="col-md-3">
      <date-picker
        [model]="props.postdata"
        [config]="{ key: 'BeginDate', label: 'Begin Date' }"
        [editable]="false"
        [addmode]="false"
      ></date-picker>
    </div>
    <div class="col-md-3">
      <date-picker
        [model]="props.postdata"
        [config]="{ key: 'EndDate', label: 'End Date' }"
        [editable]="false"
        [addmode]="false"
      ></date-picker>
    </div>

    <div class="col-md-3" style="margin-top:15px;">
      <button (click)="showApplications()" class="btn btn-default">
        Show Applications
      </button>
    </div>
  </div>

  <div style="margin:40px;"><div id="{{ props.referenceid }}"></div></div>
</div>

`
