import general from "./general";
import contactinformation from "./contactinformation";
import otherinformation from "./otherinformation";
import employer from "./employer";
import language from "./language";
import services from "./services";

export default `
<ng-template #content let-modal>
<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    Application Form - {{ props.selectedTourGuide }}
  </h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <h3>General Information</h3>
  ${general}
  <h3>Contact Information</h3>
  ${contactinformation}
  <h3>Employer</h3>
  ${employer}
  <h3>Language(s)</h3>
  ${language}
  <h3>Service(s)</h3>
  ${services}
  <h3>Other Details</h3>
  ${otherinformation}
</div>

<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="modal.close('Save click')"
  >
    Close
  </button>
</div>
</ng-template>

`
