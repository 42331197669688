export default `
<section *ngIf="(managers !==undefined && managers.length>0)">

<h3>Manager Modifications</h3>
<ngb-tabset>
  <ngb-tab title="After">
    <ng-template ngbTabContent>
      <div class="form-group row">
        <div class="col-sm-12">
          <table class="data-table">
            <thead>
              <th>First Name</th>
              <th>Middle Name</th>
              <th>Last Name</th>
              <th>Nationality</th>
              <th>Email</th>
              <th>Telephone Number</th>
              <th>Entity Type</th>
              <th>Remove</th>
            </thead>
            <tbody>
              <tr *ngFor="let manager of managers">
                <td [style.background-color]="compare(manager.NewFirstName,manager.OldFirstName) ? '#AEF0B7' : '#fff'">{{ manager.NewFirstName }}</td>
                <td [style.background-color]="compare(manager.NewMiddleName,manager.OldMiddleName) ? '#AEF0B7' : '#fff'">{{ manager.NewMiddleName }}</td>
                <td [style.background-color]="compare(manager.NewLastName,manager.OldLastName) ? '#AEF0B7' : '#fff'">{{ manager.NewLastName }}</td>
                <td [style.background-color]="compare(manager.NewCountryName,manager.OldCountryName) ? '#AEF0B7' : '#fff'">{{ manager.NewCountryName }}</td>
                <td [style.background-color]="compare(manager.NewEmailaddress,manager.OldEmailAddress) ? '#AEF0B7' : '#fff'">{{ manager.NewEmailaddress }}</td>
                <td [style.background-color]="compare(manager.NewTelephoneNumber,manager.OldTelephoneNumber) ? '#AEF0B7' : '#fff'">{{ manager.NewTelephoneNumber }}</td>
                <td [style.background-color]="compare(manager.NewBusinessEntityType,manager.OldBusinessEntityType) ? '#AEF0B7' : '#fff'">{{ manager.NewBusinessEntityType }}</td>
                <td [style.background-color]="compare(manager.RemoveManager,'false') ? '#AEF0B7' : '#fff'">{{ manager.RemoveManager }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ng-template>
  </ngb-tab>

  <ngb-tab title="Before">
    <ng-template ngbTabContent>
      <div class="form-group row">
        <div class="col-sm-12">
          <table class="data-table">
            <thead>
              <th>First Name</th>
              <th>Middle Name</th>
              <th>Last Name</th>
              <th>Nationality</th>
              <th>Email</th>
              <th>Telephone Number</th>
              <th>Entity Type</th>
            </thead>
            <tbody>
              <tr *ngFor="let manager of managers">
                <td>{{ manager.OldFirstName }}</td>
                <td>{{ manager.OldMiddleName }}</td>
                <td>{{ manager.OldLastName }}</td>
                <td>{{ manager.OldCountryName }}</td>
                <td>{{ manager.OldEmailAddress }}</td>
                <td>{{ manager.OldTelephoneNumber }}</td>
                <td>{{ manager.OldBusinessEntityType }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ng-template>
  </ngb-tab>
</ngb-tabset>
</section>
`