import { UserStore } from "src/app/stores/store.user";

export const createImage = (url, QualifiedRetiredPersonId, documentId, caption) => {
  var xhr = new XMLHttpRequest();
  xhr.responseType = "blob"; //so you can access the response like a normal URL
  xhr.onreadystatechange = function() {
    if (xhr.readyState == XMLHttpRequest.DONE && xhr.status == 200) {
      $["fancybox"].open({
        padding: 0,
        src: URL.createObjectURL(xhr.response),
        type: "iframe",
        caption
      });
    }
  };
  xhr.open("GET", url, true);
  xhr.setRequestHeader("user-token", UserStore.getUserDetails().token);
  xhr.setRequestHeader("site-token", "7pdvAXwKchuAQGw7QBLnSHS");
  xhr.setRequestHeader("user-id", "1");
  xhr.setRequestHeader("control-id", "1");
  xhr.setRequestHeader(
    "qualified-retired-person-application-id",
    QualifiedRetiredPersonId.toString()
  );
  xhr.setRequestHeader("document-id", documentId.toString());
  xhr.send();
};
