export default `
<ng-template #complete let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
          Checkpoints
        </h4>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div><div id="{{ props.referenceid6 }}"></div></div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-outline-dark"
          (click)="modal.close('Process click')"
        >
          Process
        </button>

        <button
          type="button"
          class="btn btn-outline-dark"
          (click)="modal.close('Save click')"
        >
          Close
        </button>
      </div>
    </ng-template>

`
