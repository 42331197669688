export default `

<ng-template #process let-modal>
<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    Process Application - {{ selectedQualifiedRetiredPerson }}
  </h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="col-md-12">
    <date-picker
      [model]="props.postDataProcess"
      [config]="{ key: 'ExpirationDate', label: 'Expiration Date' }"
      [addmode]="false"
    ></date-picker>
  </div>
  <div class="col-md-12">
    <date-picker
      [model]="props.postDataProcess"
      [config]="{ key: 'LicenseDate', label: 'License Date' }"
      [editable]="false"
      [addmode]="false"
    ></date-picker>
  </div>
  <div class="col-md-12">
    <label>License Number</label>

    <input

            [(ngModel)]="props.postDataProcess.LicenseNumber"
            class="form-control"
    />
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="processDetails(modal)"
  >
    Complete Processing
  </button>

  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="modal.close('Save click')"
  >
    Close
  </button>
</div>
</ng-template>

`
