import {Component, Input, AfterViewInit,OnChanges} from '@angular/core';
import * as CodeMirror from 'codemirror';
import guid from '../../../constants/guid';

@Component({

    selector: 'code-js-ds',
    template: ` 

       
          <label    style="width:100%">
            <span  class="ThemeControlled" >{{config.label}}</span>
            
          </label>

     <div id="{{referenceid}}"></div>
      <input style="display: none;" parsley [validation]="getValidation()" type="text" value="{{model[config.key]}}"/>
 `
})


export class CodeComponent implements  AfterViewInit,OnChanges {
    @Input() editable:boolean;
    @Input() model:any;
    @Input() config:any;
    cm:any=null;
    referenceid = guid.create('rtf');

    ngOnChanges()
    {
        if(this.cm!==null)
            if (this.model[this.config.key] !== null && this.model[this.config.key] !== undefined) {
                this.cm.getDoc().setValue(this.model[this.config.key]);
            }
            else {
                this.cm.getDoc().setValue("");
            }
    }
    ngAfterViewInit() {
        let me=this;
        $("#"+this.referenceid).empty().append("<textarea id='"+this.referenceid+"codeblock'>"+this.model[this.config.key]+"</textarea>");
         this.cm = CodeMirror.fromTextArea(<HTMLTextAreaElement>$("#"+this.referenceid+'codeblock')[0], {
            lineNumbers: true,
             mode:'javascript',
             theme:"material",
             readOnly: false

        });
             this.cm.on('change', function() {


             me.model[me.config.key] = me.cm.getValue();


         });


    }
    getValidation()
    {
        if (this.config === null || this.config === undefined) {
            return null;
        }
        return this.config.validation;
    }

}