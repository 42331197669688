import { closest } from "@syncfusion/ej2-base";
import {
  Grid,
  Group,
  CommandColumn,
  Filter,
  Sort,
  ExcelExport,
  Page,
  Toolbar,
  PdfExport,
  Edit,
  Resize
} from "@syncfusion/ej2-grids";

Grid.Inject(
  Filter,
  Group,
  Sort,
  Page,
  ExcelExport,
  Toolbar,
  PdfExport,
  CommandColumn,
  Resize
);
import { createImage } from "./createImage";

export const createDocumentGrid = function(dataSource: any) {

  const onViewDocument = args => {
    const rowObj = this.props.completeGrid.getRowObjectFromUID(
      closest(args.target, ".e-row").getAttribute("data-uid")
    );
    const data: any = rowObj.data;

    createImage(
      "/api/gettourguideapplicationdocument",
      data.TourGuideApplicationRecordNumber,
      data.TourGuideApplicationDocumentRecordNumber,
      data.DocumentName
    );

  };

  const cols = [
    {
      field: "TourGuideModificationRequestNumber",
      headerText: "Checkpoint Comments",
      visible: false
    },

    {
      field: "TourGuideModificationRequestDocumentRecordNumber",
      headerText: "Checkpoint Comments",
      visible: false
    },
    {
      field: "DocumentName",
      headerText: "Name"
    },
    {
      field: "DocumentTypeDescription",
      headerText: "Document Type"
    },
    {
      field: "IssueDate",
      headerText: "Issue Date"
    },
    {
      field: "ExpirationDate",
      headerText: "Expiration Date"
    },

    {
      headerText: "",
      isUnbound: false,
      commands: [
        {
          buttonOption: {
            content: "View",
            cssClass: "e-flat",
            click: onViewDocument.bind(this)
          }
        }
      ]
    }
  ];

  this.props.completeGrid = new Grid({
    dataSource,
    allowTextWrap: true,
    textWrapSettings: {
      wrapMode: "Content"
    },
    columns: cols
  });
  this.props.completeGrid.appendTo(`#${this.props.referenceid8}`);
};
