export default `
<div class="row">

    <div class="col-md-6">
    <div class="form-group">
    <label>Finance Email</label>
    <div class="input-group">
    <input
    readOnly="readOnly"
    class="form-control"
    [(ngModel)]="props.currentSelectedModification.NewFinanceEmailAddress"
    [style.background-color]="compare(props.currentSelectedModification.NewFinanceEmailAddress,props.currentSelectedModification.OldFinanceEmailAddress) ? '#AEF0B7' : '#fff'"
    />
    </div>
    </div>

    </div>

    <div class="col-md-6">
    <div class="form-group">
    <label>Insurance Comp.</label>
    <div class="input-group">
    <input
    readOnly="readOnly"
    class="form-control"
    [(ngModel)]="props.currentSelectedModification.NewInsuranceCompany"
    [style.background-color]="compare(props.currentSelectedModification.NewInsuranceCompany,props.currentSelectedModification.OldInsuranceCompany) ? '#AEF0B7' : '#fff'"
    />
    </div>
    </div>

    </div>




  </div>
`;
