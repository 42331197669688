import {Component, Input} from '@angular/core';



@Component({

    selector: 'centered-into-page',
    template: `  <div>
                                    <div class="loginholder">
                                       

                                        <form-render [formdata]="formdata"></form-render>


                                    </div>

</div>`
})
export class CenteredIntroPageComponent {
    @Input() formdata:any;


}

