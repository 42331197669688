export default `
<div class="row">



<div class="col-md-6">
    <div class="form-group">
      <label>First Name</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedModification.OldFirstName"
          class="form-control"
        />
      </div>
    </div>
  </div>

  <div class="col-md-6">
    <div class="form-group">
      <label>Last Name</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedModification.OldLastName"
          class="form-control"
        />
      </div>
    </div>
  </div>

  <div class="col-md-4">
    <div class="form-group">
      <label>Date of Birth</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedModification.OldDateOfBirth"
          class="form-control"
        />
      </div>
    </div>
  </div>

  <div class="col-md-4">
    <div class="form-group">
      <label>Gender</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedModification.OldGender"
          class="form-control"
        />
      </div>
    </div>
  </div>

  <div class="col-md-4">
  <div class="form-group">
    <label>Social Security Number</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        [(ngModel)]="props.currentSelectedModification.OldSocialSecurityNumber"
        class="form-control"
      />
    </div>
  </div>
  </div>



  <div class="col-md-4">
  <div class="form-group">
    <label>Tour Guide Category</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        [(ngModel)]="props.currentSelectedModification.OldTourGuideCategory"
        class="form-control"
      />
    </div>
  </div>
</div>



  <div class="col-md-4">
  <div class="form-group">
    <label>District Area Of Operation</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        [(ngModel)]="props.currentSelectedModification.OldDistrictAreaOfOperationName"
        class="form-control"
      />
    </div>
  </div>
  </div>

  <div class="col-md-4">
  <div class="form-group">
    <label>Training Program Completion Date</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        [(ngModel)]="props.currentSelectedModification.OldTrainingProgramCompletionDate"
        class="form-control"
      />
    </div>
  </div>
  </div>



  <div class="col-md-6">
  <div class="form-group">
    <label>Tour Guide Type</label>
    <div class="input-group">
      <input

        readOnly="readOnly"
        [(ngModel)]="props.currentSelectedModification.OldTourGuideType"
        class="form-control"
      />
    </div>
  </div>
</div>

<div class="col-md-6">
  <div class="form-group">
    <label>Tour Guide Site</label>
    <div class="input-group">
      <input

        readOnly="readOnly"
        [(ngModel)]="props.currentSelectedModification.OldTourGuideSite"
        class="form-control"
      />
    </div>
  </div>
</div>





</div>



`;
