export default `
<div class="row">

<div class="col-md-10">
  <div class="form-group">
    <label>Physical Address</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedApplication.Address"
      />
    </div>
  </div>
</div>

<div class="col-md-2">
  <div class="form-group">
    <label>District Area</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedApplication.DistrictAreaName"
      />
    </div>
  </div>
</div>

<div class="col-md-10">
  <div class="form-group">
    <label>Mailing Address</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedApplication.MailingAddress"
      />
    </div>
  </div>
</div>



<div class="col-md-2">
  <div class="form-group">
    <label>District Area</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedApplication.MailingDistrictAreaName"
      />
    </div>
  </div>
</div>



<div class="col-md-4">
  <div class="form-group">
    <label>Telephone 1</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedApplication.Telephone1"
      />
    </div>
  </div>
</div>


<div class="col-md-4">
  <div class="form-group">
    <label>Telephone 2</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedApplication.Telephone2"
      />
    </div>
  </div>
</div>


<div class="col-md-4">
<div class="form-group">
<label>Fax</label>
<div class="input-group">
  <input
    readOnly="readOnly"
    class="form-control"
    [(ngModel)]="props.currentSelectedApplication.FaxNumber"
  />
</div>


</div>
</div>

<div class="col-md-6">
<div class="form-group">
<label>Email Address</label>
<div class="input-group">
<input
readOnly="readOnly"
class="form-control"
[(ngModel)]="props.currentSelectedApplication.EmailAddress"
/>
</div>
</div>

</div>






<div class="col-md-6">
<div class="form-group">
<label>Web Site</label>
<div class="input-group">
<input
readOnly="readOnly"
class="form-control"
[(ngModel)]="props.currentSelectedApplication.WebSite"
/>
</div>
</div>
</div>
</div>
`;
