import guid from "../../../../../..//app/constants/guid";
import { UserStore } from "../../../../../../app/stores/store.user";
import * as moment from 'moment';

export default {
  data: [],
  postdata: {
    TourGuideIdNumber:null,
    BeginDate:"",
    EndDate:""
  },
  postDataComplete: {
    TourGuideModificationRecordNumber: "",
    CheckpointName: "",
    TourGuideModificationCheckpointRecordNumber: "",
    CheckpointDate: "",
    CompletedBy: UserStore.getUserName(),
    CheckpointComments: ""
  },
  postDataProcess: {
    TourGuideModificationRecordNumber: "",
    ExpirationDate: "",
    LicenseDate: "",
    LicenseNumber: "",
    UserName: UserStore.getUserName(),
    UserToken: UserStore.getUserDetails().token
  },
  selectedTourGuide: "",
  currentSelectedModification: {},
  grid: null,
  _onChange: null,
  referenceid1: guid.create("syncfusion1"),
  referenceid8: guid.create("syncfusion8"),
  referenceid: guid.create("syncfusion"),
  referenceid2: guid.create("syncfusion2"),
  referenceid3: guid.create("syncfusion3"),
  referenceid4: guid.create("syncfusion4"),
  referenceid7: guid.create("syncfusion7"),

  referenceid81: guid.create("syncfusion81"),
  referenceid88: guid.create("syncfusion88"),
  //referenceid00: guid.create("syncfusion00"),
  referenceid82: guid.create("syncfusion82"),
  referenceid83: guid.create("syncfusion83"),
  referenceid84: guid.create("syncfusion84"),
  referenceid85: guid.create("syncfusion85"),
  referenceid86: guid.create("syncfusion86"),
  referenceid87: guid.create("syncfusion87"),




  facilities: [],
  managers: {},
  owners: {},
  completed: [],
  currentId: "",
  completeGridTrack: null,
  completeGrid:null
};
