import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  OnChanges
} from '@angular/core';
import { DataStore } from '../../../stores/store.data';
import constants from '../../../constants/constants';
import DataAction from '../../../actions/action.data';
import validate from '../../../constants/validate';
import * as _ from 'underscore';
import * as moment from 'moment';
import * as toastr from 'toastr';
import { TrackingStore } from '../../../stores/store.tracking';
import { FormStore } from '../../../stores/store.form';
import { RESTLoader } from '../../../dispatcher/dispatcher.rest';

import { isNull, isUndefined } from 'util';
import { DomSanitizer } from '@angular/platform-browser';
import swal from 'sweetalert2';

@Component({
  selector: 'form-render',
  template: `
        <div class="print" *ngIf="showPrint">

            <div class="headerCaption">
                <a href="javascript:void(0);" (click)="closePrint()" class="close"
                   aria-label="Close" style="opacity: 1!important;margin-top:5px;">
                                                <span aria-hidden="true"
                                                      style="color:#FFFFFF!important;opacity:1!important;"><i
                                                        style="font-size:18px" class="mdi mdi-close"></i></span>
                </a>
                <table>
                    <tbody>
                    <tr>
                        <td valign="middle" style="cursor:pointer;">
                        <i style="padding-top: 3px;padding-right:15px;font-size: 28px;margin-top: 3px;display: inline-block;" class="mdi mdi-apps"></i>
                        </td>
                        <td valign="middle" style="padding-right:15px;border-left:1px solid #348BCF;"><h5>Print Preview</h5></td>
                        <td valign="middle" style="border-left:1px solid #348BCF;"><h5 style="font-weight: 500!important;">
                            {{formdata.caption}}</h5></td>
                    </tr>
                    </tbody>
                </table>


            </div>
            <div class="printbody">
                <iframe id="actionprint" [src]="getCurrentPrintItem" width="100%" height="100%" style="width: 100%;height:100%;" frameborder="0"></iframe>
            </div>
        </div>
    <div class='row' *ngIf="formdata.formtype === 'authentication'">

                         <form-body [refresh]='refresh' [formdata]='formdata'></form-body>


                    </div>


            <div class='row' *ngIf="formdata.formtype === 'clientAssignment'">
             <client-assignment></client-assignment>
                    </div>
        <div class='row' *ngIf="formdata.formtype === 'multiaction'">
            <action-assignment [formdata] = formdata></action-assignment>
        </div>
        <div class='row' *ngIf="formdata.formtype === 'fileuploader'">
            <btb-file-uploader-assignment [formdata] = formdata></btb-file-uploader-assignment>
        </div>

    <div class='row' *ngIf="formdata.formtype === 'hotelmodification'">
        <view-modification-request [formdata] = formdata></view-modification-request>
    </div>

    <div class='row' *ngIf="formdata.formtype === 'hotelapplication'">
        <view-application-request></view-application-request>
    </div>

    <div class='row' *ngIf="formdata.formtype === 'hotelaccommodationtaxreturn'">
        <hotel-accommodation-tax-return [formdata] = formdata></hotel-accommodation-tax-return>
    </div>

    <div class='row' *ngIf="formdata.formtype === 'tourmodification'">
        <view-touroperator-modification-request [formdata] = formdata></view-touroperator-modification-request>
    </div>

    <div class='row' *ngIf="formdata.formtype === 'tourapplication'">
        <view-touroperator-application-request></view-touroperator-application-request>
    </div>


    <div class='row' *ngIf="formdata.formtype === 'tourguidemodification'">
    <view-tourguide-modification-request [formdata] = formdata></view-tourguide-modification-request>
</div>


<div class='row' *ngIf="formdata.formtype === 'qualifiedretiredpersonapplication'">
    <view-qualified-retired-person-application-request></view-qualified-retired-person-application-request>
</div>

<div class='row' *ngIf="formdata.formtype === 'qualifiedretiredpersonmodification'">
    <view-qualified-retired-person-modification-request [formdata] = formdata></view-qualified-retired-person-modification-request>
</div>



<div class='row' *ngIf="formdata.formtype === 'tourguideapplication'">
    <view-tourguide-application-request></view-tourguide-application-request>
</div>

    <div class='row' *ngIf="formdata.formtype === 'fileuploader'">
    <btb-file-uploader-assignment [formdata] = formdata></btb-file-uploader-assignment>
</div>


        <div class='row' *ngIf="formdata.formtype === 'settings'">
        <system-settings [formdata] = formdata></system-settings>
    </div>

    <div class='row' *ngIf="formdata.formtype === 'excemption'">
    <btb-send-mail [formdata] = formdata></btb-send-mail>
</div>

<div class='row' *ngIf="formdata.formtype === 'getuseractivityrecords'">
    <btb-get-user-activity-records [formdata] = formdata></btb-get-user-activity-records>
</div>


        <div class='row' *ngIf="formdata.formtype === 'sendstatement'">
        <btb-send-mail [formdata] = formdata></btb-send-mail>
    </div>



    <div class='row' *ngIf="formdata.formtype === 'guestregistration'">
            <btb-guest-registration [formdata] = formdata></btb-guest-registration>
        </div>

        <div class='row' *ngIf="formdata.formtype === 'onlineportalmessage'">
            <btb-online-portal-messages [formdata] = formdata></btb-online-portal-messages>
        </div>

        <div class='row' *ngIf="formdata.formtype === 'calculator'">
            <btb-interest-calc [formdata] = formdata></btb-interest-calc>
        </div>



<div class='row' *ngIf="formdata.formtype === 'gridView'">
<table-view  [formdata]="formdata"></table-view>
</div>

  <div class='row' *ngIf="formdata.formtype === 'dataEntry'">

                <div class="editorActions">

                 <div *ngIf="!editMode">

                          <div *ngIf="editable && allowdelete">
                          <a href="javascript:;"  class="danger"
                           (click)="onDelete()">
                           <i class="mdi mdi-delete-circle"></i>
                            <span>Delete</span></a>
                            </div>




                     <div *ngIf="allowPrint()">
                         <a href="javascript:;" (click)="onPrint()" *ngIf="editable"
                            class="printbutton">
                             <i class="mdi mdi-printer"></i>
                             <span>Print</span></a>
                     </div>

                     <div>
                     <a href="javascript:;" (click)="onAdd()" *ngIf="supportsAdd"
                        class="success">
                         <i class="mdi mdi-plus-circle"></i>
                         <span>Add</span></a>
                 </div>
                            <div *ngIf="editable">
                        <a href="javascript:;"  class="warning"
                           (click)="onEdit()">
                           <i class="mdi mdi-pencil-circle"></i>
                            <span>Edit</span></a>
</div>

                    </div>


                    <div  *ngIf="editMode">
                        <div>
                        <a href="javascript:;" (click)="onSave()"
                           class="save">
                            <i class="mdi mdi-check-circle"></i>
                            <span>Save</span></a>
                            </div>
                        <div>
                        <a href="javascript:;" (click)="onCancel()"
                           class="danger">
                            <i class="mdi mdi-close-circle"></i>
                            <span>Cancel</span></a>
</div>
                    </div>




</div>
                   <div class="editorSelector" *ngIf="formdata.editSearchSelector!==undefined">

                            <searchable [placeholder]="'Select Record'" [onChange]="_topLevelSelectChanged" [editable]="allowchange" [model]="model" name="mainSelect" [data]="headingSource" value="{{formdata.editSearchSelector.resourceId}}" text="{{formdata.editSearchSelector.resourceValue}}" [displays]="formdata.editSearchSelector.resourceDisplayFields" theme="editorSearch"></searchable>

</div>
                   <div  class="Contents">
                       <form id="demo-form" parsley-validate>

                      <form-body [updatewall]="innerUpdate" [refresh]="refresh" [editable]="!editMode" [addmode]="isAdding" [(formdata)]="formdata" [(model)]="model"></form-body>


                           </form>
                   </div>

                            </div>







    `
})
export class FormRenderComponent implements OnInit, OnChanges, AfterViewInit {
  @Input()
  formdata: any;
  model = this.initModel();
  headingSource = [];
  allowdelete = false;
  allowaudit = false;
  supportsAdd = true;
  noThingSelected = true;
  isAdding = false;
  allowchange = true;
  editMode = false;
  editable = false;
  showPrint = false;

  getCurrentPrintItem = null;
  isAdd = false;
  refresh: Function = this.refreshCallBack.bind(this);
  _topLevelSelectChanged: Function = this.topLevelSelectChanged.bind(this);

  innerUpdate = {
    updateWall: [],
    recycle: []
  };
  //me = this;

  constructor(private _sanitizer: DomSanitizer) {
    /* FormStore.emitter.subscribe(constants.FormsActions.FORM_AVAILABLE, ()=> {




         });*/

    this.isAdding = false;
    this.editMode = false;
    this.editable = false;
    this.allowchange = true;
  }

  processUpdate(ars, index, callback) {
    if (index < ars.length) {
      ars[index](() => {
        this.processUpdate(ars, index + 1, callback);
      });
    } else {
      callback();
    }
  }

  ngAfterViewInit() {}

  onRecycle() {
    FormStore.showDeleteHistory();
  }

  lastKnown() {
    if (this.model === null || this.model === undefined) {
      return '';
    } else {
      if (this.formdata.editSearchSelector !== undefined) {
        return this.model[this.formdata.editSearchSelector.resourceId];
      } else {
        return '';
      }
    }
  }

  refreshCallBack() {
    // setupSelect2b("mainSelect", this.topLevelSelectChanged.bind(this));
  }

  ngOnChanges(changes) {
    if (changes.formdata !== undefined) {
      this.headingSource = this.getResourceList();
      this.isAdding = false;
      this.editMode = false;
      this.editable = false;
      this.allowchange = true;
      this.model = this.initModel();
      this.allowchange = true;
      this.innerUpdate = {
        updateWall: [],
        recycle: []
      };
    }
  }

  allowPrint() {
    return this.formdata.allowPrint === true;
  }

  getResourceList() {
    let retval = [];
    if (this.formdata !== null && this.formdata !== undefined) {
      this.allowdelete = this.formdata.supportsDelete;
      if (
        this.formdata.supportsAdd !== undefined &&
        this.formdata.supportsAdd !== null
      ) {
        this.supportsAdd = this.formdata.supportsAdd;
      } else {
        this.supportsAdd = true;
      }
      this.allowaudit = this.formdata.supportsAudit;
      if (!isNull(this.formdata.listresources)) {
        this.formdata.listresources.map(item1 => {
          if (
            this.formdata.editSearchSelector !== null &&
            this.formdata.editSearchSelector !== undefined
          ) {
            if (item1.id === this.formdata.editSearchSelector.resource) {
              retval = item1[this.formdata.editSearchSelector.resource];
            }
          }
        });
      }
    }

    return retval;
  }

  initModel() {
    const model = {};

    if (this.formdata !== null && this.formdata !== undefined) {
      if (
        this.formdata.formDefinition !== null &&
        this.formdata.formDefinition !== undefined
      ) {
        if (
          this.formdata.formDefinition.rows !== null &&
          this.formdata.formDefinition.rows !== undefined
        ) {
          this.formdata.formDefinition.rows.map((row: any) => {
            row.columns.map(cols => {
              if (cols.fields !== undefined) {
                cols.fields.map(field => {
                  if (field.default !== null && !isUndefined(field.default)) {
                    model[field.key] = field.default;
                    if (field.default === 'TODAY') {
                      model[field.key] = moment().format('YYYY-MM-DD');
                    }
                  } else {
                    model[field.key] = null;
                  }
                });
              }
            });
          });
        }
      }
    }

    return model;
  }

  onDelete() {
    TrackingStore.confirmDelete(status => {
      if (status === true) {
        this.model = this.initModel();
        this.isAdding = true;
        DataAction.runData(
          {},
          this.formdata.editSearchSelector.resourceSelectedType,
          this.formdata.editSearchSelector.resource,
          () => {
            this.updateSelected();
          }
        );
      }
    }, this.model);
  }

  onSendAfterSave() {
    swal.fire({
      title: 'Send Email?',
      text: 'Would you like to send an email to this user',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Send it!',
      cancelButtonText: 'No'
    }).then(result => {
      if (result.value) {
        DataAction.runData(this.model, 'SQL', 'SendAddAccountMessage', () => {

        });
      }
    });
  }

  onAdd() {
    if (!this.editMode) {
      this.editMode = true;

      this.noThingSelected = true;

      this.model = this.initModel();
      // $(this.refs.selectItem1).select2('val',null);
      //       setSelect2Val("mainSelect",null,null);
      this.isAdding = true;
      this.allowchange = false;
    }
  }

  onEdit() {
    TrackingStore.setOpen();
    this.isAdding = false;
    if (!this.editMode) {
      this.editMode = true;
      this.allowchange = false;
      this.isAdd = false;
    }
  }

  runSave() {
    validate.validator.validate();

    if (!validate.validator.isValid()) {
      /*  RealTimeStore.setCurrentMessageStack([
             {
             message:"Something went wrong with your last save attempted on "+moment(new Date()).format("Do MMMM, YYYY [at] h:m:s a")+". Please ensure that all required fields are filled in, your data has been properly formatted as required and that your status is showing online.",
             icon:"mdi mdi-alert-circle-outline",
             status:"header-error"
             }
             ]);*/

      toastr.error(
        'Something went wrong with your last save attempted on ' +
          moment(new Date()).format('Do MMMM, YYYY [at] h:m:s a') +
          '. Please ensure that all required fields are filled in, your data has been properly formatted as required and that your status is showing online',
        'Error',
        {
          timeOut: 5000,
          closeButton: true,
          newestOnTop: true,
          positionClass: 'toast-bottom-right',
          showEasing: 'swing',
          hideEasing: 'linear',
          showMethod: 'fadeIn',
          hideMethod: 'fadeOut'
        }
      );

      return;
    }

    if (!this.isAdding) {
      this.noThingSelected = false;
      DataAction.runExecute(
        this.model,
        this.formdata.functionactions.update.functiontype,
        this.formdata.functionactions.update.functionname,
        () => {
          DataAction.runData2(
            {},
            this.formdata.editSearchSelector.resourceSelectedType,
            this.formdata.editSearchSelector.resource,
            () => {
              if (this.innerUpdate.updateWall === null) {
                this.processSave();
                this.editable = true;
              } else {
                if (
                  this.innerUpdate.updateWall != undefined &&
                  this.innerUpdate.updateWall != null
                ) {
                  this.processUpdate(this.innerUpdate.updateWall, 0, () => {
                    this.processSave();
                    this.editable = true;
                  });
                } else {
                  this.processSave();
                  this.editable = true;
                }
              }

              // if (this.formdata.functionactions.update.alertOnComplete === true) {

              //  }
            }
          );
        }
      );
    } else {
      if (this.formdata.sendaftersave === true) {
        this.onSendAfterSave();
      }
      DataAction.runExecute(
        this.model,
        this.formdata.functionactions.createnew.functiontype,
        this.formdata.functionactions.createnew.functionname,
        () => {
          DataAction.runData2(
            {},
            this.formdata.editSearchSelector.resourceSelectedType,
            this.formdata.editSearchSelector.resource,
            list => {
              // if (this.formdata.functionactions.createnew.alertOnComplete === true) {

              this.editMode = false;
              this.editable = false;
              this.isAdding = false;
              this.allowchange = true;
              TrackingStore.endEdit();
              toastr.success(
                'Everything checked out properly, your information has been successfully persisted to the database',
                'Saved',
                {
                  timeOut: 5000,
                  closeButton: true,
                  newestOnTop: true,
                  positionClass: 'toast-bottom-right',
                  showEasing: 'swing',
                  hideEasing: 'linear',
                  showMethod: 'fadeIn',
                  hideMethod: 'fadeOut'
                }
              );

              //  }

              const qav1 = {};
              qav1[this.formdata.editSearchSelector.resourceValue] = this.model[
                this.formdata.editSearchSelector.resourceValue
              ];

              const v = _.findWhere(list, qav1);

              const selectedval =
                v[this.formdata.editSearchSelector.resourceId];

              const qav = {};
              qav[this.formdata.editSearchSelector.resourceId] = selectedval;

              DataAction.runData(
                qav,
                this.formdata.editSearchSelector.resourceSelectedType,
                this.formdata.editSearchSelector.resourceSelected,
                () => {
                  this.editMode = false;
                  this.editable = false;
                  this.allowchange = true;
                  this.isAdding = false;

                  //   setTimeout(()=>{
                  //         setSelect2Val("mainSelect",selectedval,null);
                  //   },1000);
                }
              );
            }
          );
        }
      );
    }
  }

  processAjaxValidations(index, total, callback) {
    if (index >= total) {
      return callback(true);
    }
    let parts = [];
    if (!this.isAdding) {
      parts = FormStore.getCurrentForm().duplicates[
        index
      ].checkduplicateupdate.split('|');
    } else {
      parts = FormStore.getCurrentForm().duplicates[
        index
      ].checkduplicateadd.split('|');
    }

    const item = 0;

    const post = {};

    const ar = JSON.parse(parts[1]);

    const curmod = this.model;
    ar.map(items => {
      post[items] = curmod[items];
    });

    const found = false;

    RESTLoader.processRequest(false, parts[0], post, 'POST', resp1 => {
      if (resp1.length > 0) {
        toastr.error(
          FormStore.getCurrentForm().duplicates[index].duplicatemessage,
          'Error',
          {
            timeOut: 5000,
            closeButton: true,
            newestOnTop: true,
            positionClass: 'toast-bottom-right',
            showEasing: 'swing',
            hideEasing: 'linear',
            showMethod: 'fadeIn',
            hideMethod: 'fadeOut'
          }
        );
        callback(false);
      } else {
        //this.runSave();
        this.processAjaxValidations(index + 1, total, callback);
      }
    });
  }

  onSave() {
    //  if (this.editMode) {
    //     this.editMode = false;
    FormStore.setCurrentModel(this.model);

    if (
      FormStore.getCurrentForm().duplicates !== null &&
      FormStore.getCurrentForm().duplicates !== undefined
    ) {
      this.processAjaxValidations(
        0,
        FormStore.getCurrentForm().duplicates.length,
        state => {
          if (state === true) {
            this.runSave();
          }
        }
      );
    } else {
      this.runSave();
    }

    //  }
  }

  processSave() {
    TrackingStore.endEdit();
    toastr.success(
      'Everything checked out properly, your information has been successfully persisted to the database',
      'Saved',
      {
        timeOut: 5000,
        closeButton: true,
        newestOnTop: true,
        positionClass: 'toast-bottom-right',
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut'
      }
    );

    this.editMode = false;
    this.editable = false;
    this.isAdding = false;
    this.allowchange = true;

    this.updateSelected();
  }

  onCancel() {
    TrackingStore.endEdit();
    validate.validator.reset();
    if (!this.isAdding) {
      this.updateSelected();
      this.editable = true;
    } else {
      this.noThingSelected = true;
      this.model = this.initModel();
      this.editable = false;
    }
    this.editMode = false;

    this.isAdding = false;
    this.allowchange = true;
  }

  topLevelSelectChanged(data: any) {
    this.noThingSelected = false;

    this.editable = true;

    if (
      this.formdata.editSearchSelector !== null &&
      this.formdata.editSearchSelector !== undefined
    ) {
      if (
        data[this.formdata.editSearchSelector.resourceId] === null ||
        data[this.formdata.editSearchSelector.resourceId] === undefined
      ) {
        this.model = this.initModel();
        return;
      }

      const qav = {};
      qav[this.formdata.editSearchSelector.resourceId] =
        data[this.formdata.editSearchSelector.resourceId];

      if (
        this.formdata.editSearchSelector.updateResourceOnChange !== null &&
        this.formdata.editSearchSelector.updateResourceOnChange !== undefined
      ) {
        DataAction.runData(
          qav,
          this.formdata.editSearchSelector.updateResourceOnType,
          this.formdata.editSearchSelector.updateResourceOnChange,
          () => {}
        );
      }

      if (!this.isAdding) {
        DataAction.runData(
          qav,
          this.formdata.editSearchSelector.resourceSelectedType,
          this.formdata.editSearchSelector.resourceSelected,
          response => {
            if (this.formdata.editSearchSelector.after !== undefined) {

              this.formdata.editSearchSelector.after.map((it: any) => {
                const qav1 = {};

                qav1[it.fieldid] = response[0][it.fieldid];

                DataAction.runData2(qav1, it.type, it.id, resp => {
                  let fd = false;
                  if (!isNull(this.formdata.listresources)) {
                    this.formdata.listresources.map(item => {
                      if (item.id === it) {
                        item[it] = resp;
                        fd = true;
                      }
                    });
                  }

                  if (!fd) {
                    const g = {
                      id: it,
                      type: 'SQL'
                    };

                    g[it] = resp;

                    this.formdata.listresources.push(g);
                  }
                });
              });
            }
          }
        );
      }
    }
  }

  updateSelected() {
    this.editMode = false;
    this.editable = false;
    this.isAdding = false;
    this.allowchange = true;
    if (
      this.formdata.editSearchSelector !== null &&
      this.formdata.editSearchSelector !== undefined
    ) {
      if (this.isAdding === false) {
        this.processUpdate(this.innerUpdate.recycle, 0, () => {
          const selectedval = this.model[
            this.formdata.editSearchSelector.resourceId
          ];
          const qav = {};
          qav[this.formdata.editSearchSelector.resourceId] = selectedval;
          DataAction.runData(
            qav,
            this.formdata.editSearchSelector.resourceSelectedType,
            this.formdata.editSearchSelector.resourceSelected,
            () => {}
          );
        });
        this.editMode = false;
        this.editable = false;
        this.isAdding = false;
        this.allowchange = true;
      } else {
        this.editMode = false;
        this.editable = false;
        this.isAdding = false;
        this.allowchange = true;
      }

      /*DataAction.runData(qav, this.formdata.editSearchSelector.resourceSelectedType, this.formdata.editSearchSelector.resourceSelected,()=>{
             this.editable=true;
             //DataStore.updateState(false);
             });*/
    }
  }

  ngOnInit() {
    DataStore.emitter.subscribe(constants.DataActions.DATA_AVAILABLE, data => {
      if (
        this.formdata.editSearchSelector !== null &&
        this.formdata.editSearchSelector !== undefined
      ) {
        if (
          data[0].dataid === this.formdata.editSearchSelector.resourceSelected
        ) {
          if (data[0].data.length > 0) {
            this.model = data[0].data[0];
            TrackingStore.setItemTracmed(
              JSON.parse(JSON.stringify(this.model))
            );
          } else {
            this.model = this.initModel();
          }
        }
      }

      if (
        this.formdata.editSearchSelector !== null &&
        this.formdata.editSearchSelector !== undefined
      ) {
        if (data[0].dataid === this.formdata.editSearchSelector.resource) {
          this.headingSource = data[0].data;

          //  setSelect2Val("mainSelect",this.model[this.formdata.editSearchSelector.resourceId], null);
        }
      }
    });
  }
}
