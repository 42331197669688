import { Grid } from "@syncfusion/ej2-ng-grids";

export const createTourOperatorApplicationTourListGrid = function(dataSource){
  const cols = [
    {
      field: "TourName",
      headerText: "Tour Name"
    },
    {
      field: "TourTypeName",
      headerText: "Tour Type Name"
    },
    {
      field: "TourDescription",
      headerText: "Tour Description"
    },
    {
      field: "EmergencyPlan",
      headerText: "Emergency Plan"
    },
    {
      field: "TourPrice",
      headerText: "Tour Price"
    },
    {
      field: "TourDurationInMinutes",
      headerText: "Tour Duration In Minutes"
    },
    {
      field: "MinimumPassengers",
      headerText: "Minimum Passengers"
    },
    {
      field: "MaximumPassengers",
      headerText: "Maximum Passengers"
    }

  ];
  const ownerGrid = new Grid({
    dataSource,
    width: "100%",
    allowTextWrap: true,
    textWrapSettings: {
      wrapMode: "Content"
    },
    columns: cols
  });
  ownerGrid.appendTo(`#${this.props.referenceid4}`);
}
