import { UserStore } from "src/app/stores/store.user";
import { closest } from "@syncfusion/ej2-base";
import { fit_modal_body } from "./fitmodalbody";
import { showDependentDetail } from "./showDependentDetail";

export const onShowDependentDetail = function(args) {
  const rowObj = this.props.dependentGrid.getRowObjectFromUID(
    closest(args.target, ".e-row").getAttribute("data-uid")
  );
  const data: any = rowObj.data;

  this.props.selectedDependent = `${data.FirstName} ${data.LastName}`;

  this.props.postDataProcess = {
    QualifiedRetiredPersonApplicationRecordNumber:
    data.QualifiedRetiredPersonApplicationRecordNumber,
    UserName: UserStore.getUserName(),
    UserToken: UserStore.getUserDetails().token
  };

  showDependentDetail(
    data.QualifiedRetiredPersonApplicationRecordNumber,
    data.QualifiedRetiredPersonApplicationDependentRecordNumber,
    (response: any) => {
     this.props.currentSelectedDependent = response;
      this.completed = response;
      this.modalService
        .open(this.dependent, { centered: true, size: "lg" })
        .result.then(
          result => {

          },
          reason => {
            // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
      setTimeout(() => {
        fit_modal_body($(".modal"));
      }, 100);
    }
  );

};
