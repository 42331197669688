export const GETUSERACTIVITYRECORDS = {
  access: ['admin'],
  formtype: 'getuseractivityrecords',
  listresources: [
    {
      id: 'users',
      type: 'SQL'
    }
  ],
  caption: 'Get User Activity Records'
};
//touched