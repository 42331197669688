import { closest } from "@syncfusion/ej2-ng-grids/node_modules/@syncfusion/ej2-base";
import { showSelectedApplication } from "./showSelectedApplication";
import { fit_modal_body } from "./fitmodalbody";
import { getTourOperatorApplicationEmployeeList } from "./getTourOperatorApplicationEmployeeList";
import { createTourOperatorApplicationEmployeesListGrid } from "./createTourOperatorApplicationEmployeesListGrid";
import { createTourOperatorApplicationGuideListGrid } from "./createTourOperatorApplicationGuideListGrid";
import { createTourOperatorApplicationSafetyEquipmentListGrid } from "./createTourOperatorApplicationSafetyEquipmentListGrid";
import { createTourOperatorApplicationTourListGrid } from "./createTourOperatorApplicationTourListGrid";
import { createTourOperatorApplicationTransportEquipmentListGrid } from "./createTourOperatorApplicationTransportEquipmentListGrid";
import { getTourOperatorApplicationGuideList } from "./getTourOperatorApplicationGuideList";
import { getTourOperatorApplicationSafetyEquipmentList } from "./getTourOperatorApplicationSafetyEquipmentList";
import { getTourOperatorApplicationTourList } from "./getTourOperatorApplicationTourList";
import { getTourOperatorApplicationTransportEquipmentList } from "./getTourOperatorApplicationTransportEquipmentList";

export const onViewTour = function(args) {
  const rowObj = this.props.grid.getRowObjectFromUID(
    closest(args.target, ".e-row").getAttribute("data-uid")
  );
  const _showSelectedApplication = showSelectedApplication.bind(this);
  const _createTourOperatorApplicationEmployeesListGrid = createTourOperatorApplicationEmployeesListGrid.bind(
    this
  );
  const _createTourOperatorApplicationGuideListGrid = createTourOperatorApplicationGuideListGrid.bind(
    this
  );
  const _createTourOperatorApplicationSafetyEquipmentListGrid = createTourOperatorApplicationSafetyEquipmentListGrid.bind(
    this
  );
  const _createTourOperatorApplicationTourListGrid = createTourOperatorApplicationTourListGrid.bind(
    this
  );
  const _createTourOperatorApplicationTransportEquipmentListGrid = createTourOperatorApplicationTransportEquipmentListGrid.bind(
    this
  );
  const data: any = rowObj.data;
  this.props.selectedTourOperator = data.TourOperatorName;



  _showSelectedApplication(
    data.TourOperatorApplicationRecordNumber,
    (response: any) => {
      this.props.currentSelectedApplication = response;
      getTourOperatorApplicationEmployeeList(
        data.TourOperatorApplicationRecordNumber,
        employeesList => {
          getTourOperatorApplicationGuideList(
            data.TourOperatorApplicationRecordNumber,
            guideList => {
              getTourOperatorApplicationSafetyEquipmentList(
                data.TourOperatorApplicationRecordNumber,
                safetyList => {
                  getTourOperatorApplicationTourList(
                    data.TourOperatorApplicationRecordNumber,
                    tourList => {
                      getTourOperatorApplicationTransportEquipmentList(
                        data.TourOperatorApplicationRecordNumber,
                        transportList => {
                          setTimeout(() => {
                            _createTourOperatorApplicationEmployeesListGrid(
                              employeesList
                            );
                            _createTourOperatorApplicationGuideListGrid(
                              guideList
                            );
                            _createTourOperatorApplicationSafetyEquipmentListGrid(
                              safetyList
                            );
                            _createTourOperatorApplicationTourListGrid(
                              tourList
                            );
                            _createTourOperatorApplicationTransportEquipmentListGrid(
                              transportList
                            );},100);

                            this.modalService
                            .open(this.content, { centered: true, size: "lg" })
                            .result.then(
                              result => {
                                // this.closeResult = `Closed with: ${result}`;
                              },
                              reason => {
                                // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                              }
                            );
                          setTimeout(() => {
                            fit_modal_body($(".modal"));
                          }, 100);

                        }
                      );
                    }
                  );
                }
              );
            }
          );
        }
      );
    }
  );
};
