export default `
<div class="row">

<div class="col-md-4">
  <div class="form-group">
    <label>Address</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedModification.OldAddress"
      />
    </div>
  </div>
</div>

<div class="col-md-4">
  <div class="form-group">
    <label>City</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedModification.OldCityName"
      />
    </div>
  </div>
</div>

<div class="col-md-4">
  <div class="form-group">
    <label>District Area</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedModification.OldDistrictArea"
      />
    </div>
  </div>
</div>




<div class="col-md-6">
  <div class="form-group">
    <label>Cell Phone</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedModification.OldCellPhone"
      />
    </div>
  </div>
</div>


<div class="col-md-6">
  <div class="form-group">
    <label>Home Phone</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedModification.OldHomePhone"
      />
    </div>
  </div>
</div>

<div class="col-md-6">
  <div class="form-group">
    <label>Overseas Phone Number</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedModification.OldOverseasPhoneNumber"
      />
    </div>
  </div>
</div>




<div class="col-md-6">
<div class="form-group">
<label>Email Address</label>
<div class="input-group">
<input
readOnly="readOnly"
class="form-control"
[(ngModel)]="props.currentSelectedModification.OldEmailAddress"
/>
</div>
</div>

</div>







</div>
`;
