import {Component, OnInit, Input} from '@angular/core';

import {DataStore} from '../../../stores/store.data';
import constants from '../../../constants/constants';
import DataAction from '../../../actions/action.data';

import guid from '../../../constants/guid';

@Component({

    selector: 'select-ds',
    template: ` 

       <div style="width:100%">
          <label    style="width:100%" *ngIf="label">
            <span  class="ThemeControlled" >{{config.label}}</span>
             <searchable [placeholder]="config.placeholder" [editable]="!editable" [onChange]="_onChange" [config]="config" [model]="model" name="{{referenceid}}" [(data)]="listdata" value="{{config.optionsResource.resourceId}}" text="{{config.optionsResource.resourceValue}}" [displays]="config.optionsResource.resourceDisplay" theme="formSearch"></searchable>
        
          </label>
          <div *ngIf="!label">
          <searchable [placeholder]="config.placeholder" [editable]="!editable" [onChange]="_onChange" [config]="config" [model]="model" name="{{referenceid}}" [(data)]="listdata" value="{{config.optionsResource.resourceId}}" text="{{config.optionsResource.resourceValue}}" [displays]="config.optionsResource.resourceDisplay" theme="formSearch"></searchable>
          </div>
        </div>
         
      
 `
})


export class SelectDSComponent implements OnInit {
    @Input() editable: boolean;
    @Input() model: any;
    @Input() config: any;
    @Input() label:boolean = true;
    @Input() listsources: any;
    _onChange = this.onChange.bind(this);
    changes = null;
    isReady: boolean = false;

    listdata: any = [];
    referenceid = guid.create('select-box');


    constructor() {

        DataStore.emitter.subscribe(constants.DataActions.DATA_AVAILABLE, (data) => {
            if (this.config.optionsResource.resource !== undefined) {
                if (data !== undefined && data !== null) {
                    if (data[0] !== undefined && data[0] !== null) {
                        if (data[0].dataid === this.config.optionsResource.resource) {
                            this.listdata = data[0].data;
                        }
                    }
                }
            }
        });

    }

    ngOnInit() {
        if (this.config.optionsResource !== undefined) {
            this.listsources.map((source) => {
                if (source.id === this.config.optionsResource.resource) {
                    this.listdata = source[this.config.optionsResource.resource];
                }
            });
        }
    }

    onChange(data) {
        let value: any = data[this.config.optionsResource.resourceId];
        this.model[this.config.key] = value;
        if (this.config.optionsResource.updateResourceOnChange !== undefined) if (value !== null && value !== undefined) {
            let params = {};

            params[this.config.optionsResource.resourceId] = value;
            DataAction.runData(params, 'SQL', this.config.optionsResource.updateResourceOnChange, null);
        }
        else {
            DataStore.triggerNoData(this.config.optionsResource.updateResourceOnChange);
        }
    }

    onChange1() {
        let value = this.model[this.config.key];
        if (this.config.optionsResource.updateResourceOnChange !== undefined) if (value !== null && value !== undefined) {
            let params = {};

            params[this.config.optionsResource.resourceId] = value;
            DataAction.runData(params, 'SQL', this.config.optionsResource.updateResourceOnChange, null);
        }
        else {
            DataStore.triggerNoData(this.config.optionsResource.updateResourceOnChange);
        }

    }


}