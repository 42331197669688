import { Component, OnInit } from '@angular/core';
import FlowControlAction from './actions/action.flowcontrol';
import * as moment from 'moment';
import { isUndefined } from 'util';

@Component({
  selector: 'my-app',
  template: `
        <theme-management></theme-management>
        <searchable-modal></searchable-modal>
            <div style="display: none;">
                <div id="notimportanteditor"></div>
            </div>`
})
export class AppComponent implements OnInit {
  checkexternalvalid(requirement, item) {
    if (item !== 1) {
      this.checkexternalvalid(requirement, item);
    }

    return status;
  }

  ngOnInit() {
    $('.fakeloader')['fakeLoader']({
      spinner: 'spinner7',
      bgColor: '#121212'
    });
    $('.fakeloader').hide();
    FlowControlAction.startFlowControl();

    window['tinyMCE'].suffix = '.min';
    const tp: any = top;
    const sl: any = self;
    if (sl === tp) {
      const antiClickjack = document.getElementById('antiClickjack');
      antiClickjack.parentNode.removeChild(antiClickjack);
    } else {
      tp.location = sl.location;
    }

    window['ParsleyConfig'] = {
      excluded: 'input[type=button], input[type=submit], input[type=reset]',
      inputs: 'input, textarea, select, input[type=hidden], :hidden'
    };

    window['parsley'].addValidator('greaterthantoday', {
      validateString: (_value, maxSize, parsleyInstance) => {
        if (
          _value != null &&
          _value !== undefined &&
          _value.trim().length > 0
        ) {
          const compare = new Date();
          compare.setHours(0, 0, 0, 0);

          const cur = moment(_value).toDate();
          cur.setHours(0, 0, 0, 0);
          return cur <= compare;
        }

        return true;
      },
      messages: {
        en: 'This date cannot be greater than today'
      }
    });
  }
}
