import { Component, Input, OnChanges, OnInit } from "@angular/core";
import DataAction from "../../../actions/action.data";
import { DataStore } from "../../../stores/store.data";
import constants from "../../../constants/constants";
import guid from "../../../constants/guid";
@Component({
  selector: "cal-links",
  template: `
    <div class="item callnk">
      <div style="height:34px;">
        <button
          *ngIf="!addmode && !editable && mode === 'PENDING'"
          (click)="addItem()"
          class="btn btn-sm btn-circle btn-success pull-right"
        >
          <i class="mdi mdi-plus" style="font-size:14px;"></i> Add New Link
        </button>
        <span
          class="pull-right"
          style="color:#B50B00;font-weight:600;"
          *ngIf="addmode"
          >Calendar Adjustments Will Be Available Once Initial Rep Setup Is
          Complete.</span
        >
      </div>
      <table style="width:100%;" cellPadding="0" cellSpacing="0">
        <tbody>
          <tr *ngIf="!editable && mode === 'ADD'">
            <td>
              <label style="width: 100%">
                <span class="ThemeControlled">Name</span>
                <input
                  placeholder="Name To Be Displayed"
                  type="text"
                  class="form-control"
                  [(ngModel)]="scratchDisk.calendarlinkname"
                />
              </label>
            </td>
            <td style="padding-left:5px;">
              <label style="width: 100%">
                <span class="ThemeControlled">URL</span>
                <input
                  type="text"
                  placeholder="Link To Schedule"
                  class="form-control"
                  [(ngModel)]="scratchDisk.calendarlink"
                />
              </label>
            </td>

            <td style="padding:5px;width:88px;">
              <a
                href="javascript:void(0)"
                (click)="addNew()"
                class="save"
                style="display:inline-block;margin-top:22px"
              >
                <i
                  class="mdi mdi-content-save"
                  style="font-size:20px"
                  style="font-size:20px;"
                ></i>
              </a>
              <a
                href="javascript:void(0)"
                (click)="cancelAdd()"
                class="danger"
                style="display:inline-block;margin-top:22px"
              >
                <i class="mdi mdi-undo-variant" style="font-size:20px"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        style="width: 100%;height:300px;overflow-x: hidden;overflow-y: auto;"
      >
        <table
          style="width:100%;"
          class="tablerows"
          cellPadding="0"
          cellSpacing="0"
        >
          <tbody>
            <tr *ngFor="let item of getCalData()">
              <td colspan="2" *ngIf="item.state !== 'UPDATE'">
                <h4 style="font-weight: 600;line-height:0;">
                  {{ item.calendarlinkname }}
                </h4>
                <span style="font-weight: 300;font-size:12px;">{{
                  item.calendarlink
                }}</span>
              </td>
              <td
                style="padding:5px;width:88px;"
                *ngIf="item.state !== 'UPDATE'"
              >
                <a
                  *ngIf="!editable && mode === 'PENDING'"
                  href="javascript:void(0)"
                  (click)="editItem(item)"
                  ><i
                    style="color:#FCB022;"
                    class="mdi mdi-pencil"
                    style="font-size:20px"
                  ></i
                ></a>
                <a
                  *ngIf="!editable && mode === 'PENDING'"
                  href="javascript:void(0)"
                  (click)="deleteItem(item)"
                  ><i
                    style="color:#FCB022;"
                    class="mdi mdi-delete"
                    style="font-size:20px"
                  ></i
                ></a>
              </td>

              <td *ngIf="item.state === 'UPDATE'">
                <label style="width: 100%">
                  <span class="ThemeControlled">Name</span>
                  <input
                    placeholder="Name To Be Displayed"
                    type="text"
                    class="form-control"
                    [(ngModel)]="scratchDisk.calendarlinkname"
                  />
                </label>
              </td>
              <td style="padding-left:5px;" *ngIf="item.state === 'UPDATE'">
                <label style="width: 100%">
                  <span class="ThemeControlled">URL</span>
                  <input
                    type="text"
                    placeholder="Link To Schedule"
                    class="form-control"
                    [(ngModel)]="scratchDisk.calendarlink"
                  />
                </label>
              </td>

              <td
                style="padding:5px;width:88px;"
                *ngIf="item.state === 'UPDATE'"
              >
                <a
                  href="javascript:void(0)"
                  (click)="saveItem(item)"
                  class="save"
                  style="display:inline-block;margin-top:22px"
                >
                  <i
                    class="mdi mdi-content-save"
                    style="font-size:20px"
                    style="font-size:20px;"
                  ></i>
                </a>
                <a
                  href="javascript:void(0)"
                  (click)="cancelItem(item)"
                  class="danger"
                  style="display:inline-block;margin-top:22px"
                >
                  <i class="mdi mdi-undo-variant" style="font-size:20px"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  `
})
export class CalLinksComponent implements OnChanges, OnInit {
  @Input() editable: boolean = false;
  @Input() addmode: boolean = false;
  @Input() model: any = null;
  @Input() config: any = null;
  @Input() updatewall: any;
  @Input() linkfield: any;
  mode = "PENDING";
  emptyLink = {
    agentid: "",
    calendarlinkid: guid.create(""),
    calendarlink: "",
    calendarlinkname: "",
    state: "NEW"
  };

  scratchDisk = JSON.parse(JSON.stringify(this.emptyLink));

  caldata = [];

  getCalData() {
    var ret = [];
    this.caldata.map(item => {
      if (item.state !== "DELETE") {
        ret.push(item);
      }
    });
    return ret;
  }

  ngOnInit() {
    DataStore.emitter.subscribe(constants.DataActions.DATA_AVAILABLE, data => {
      if (data[0].dataid === this.config.data.getResource) {
        this.caldata = data[0].data;
      }
    });
    if (this.updatewall !== undefined) {
      this.updatewall.updateWall = this.updatechanges.bind(this);
    }
  }

  updatechanges(callback) {
    this.mode = "PENDING";
    if (this.caldata.length > 0) {
      this.processUpdate(0, callback);
    }
  }
  ngOnChanges(data) {
    if (data.model !== null && data.model !== undefined) {
      let jdata =
        '{"' +
        this.config.data.linkfield +
        '":"' +
        this.model[this.config.key] +
        '"}';

      let v1 = JSON.parse(jdata);
      DataAction.runData(
        v1,
        this.config.data.getResourceType,
        this.config.data.getResource,
        () => {}
      );
      this.mode = "PENDING";
    }
  }

  addNew() {
    this.caldata.push(JSON.parse(JSON.stringify(this.scratchDisk)));
    this.mode = "PENDING";
  }
  cancelAdd() {
    this.mode = "PENDING";
  }

  addItem() {
    this.scratchDisk = JSON.parse(JSON.stringify(this.emptyLink));
    this.scratchDisk.agentid = this.linkfield;
    this.mode = "ADD";
  }

  deleteItem(item) {
    item.state = "DELETE";
  }

  saveItem(item) {
    for (var l in item) {
      item[l] = this.scratchDisk[l];
    }
    item.state = "EDIT";
    this.mode = "PENDING";
  }

  editItem(item) {
    this.mode = "EDITING";
    item.state = "UPDATE";
    this.scratchDisk = JSON.parse(JSON.stringify(item));
  }

  cancelItem(item) {
    this.mode = "PENDING";
    item.state = "";
  }

  processUpdate(index, callback) {
    let type: string = this.caldata[index]["state"];

    if (type === "NEW")
      DataAction.runData(
        this.caldata[index],
        this.config.data.getResourceType,
        this.config.data.createResource,
        () => {
          this.caldata[index]["state"] = "";
          if (index < this.caldata.length - 1) {
            this.processUpdate(index + 1, callback);
          } else {
            callback();
          }
        }
      );
    else if (type === "EDIT")
      DataAction.runData(
        this.caldata[index],
        this.config.data.getResourceType,
        this.config.data.cleanUpResource,
        () => {
          this.caldata[index]["state"] = "";
          if (index < this.caldata.length - 1) {
            this.processUpdate(index + 1, callback);
          } else {
            callback();
          }
        }
      );
    else if (type === "DELETE")
      DataAction.runData(
        this.caldata[index],
        this.config.data.getResourceType,
        this.config.data.deleteResource,
        () => {
          this.caldata[index]["state"] = "";
          if (index < this.caldata.length - 1) {
            this.processUpdate(index + 1, callback);
          } else {
            callback();
          }
        }
      );
    else if (index < this.caldata.length - 1) {
      this.processUpdate(index + 1, callback);
    } else {
      callback();
    }
  }
}
