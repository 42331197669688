import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';
import DataAction from '../../../actions/action.data';
import * as _ from 'underscore';
import * as toastr from 'toastr';
import { FormStore } from '../../../stores/store.form';
import * as moment from 'moment';
import { UserStore } from '../../../stores/store.user';
import { RESTLoaderAuthOnly } from '../../../dispatcher/dispatcher.rest.noerror';
import swal from 'sweetalert2';

@Component({
  selector: "system-settings",
  template: `
  <div class="editorActions" style="text-align:left!important;left:0;right:0;height:100%;position:absolute;width:100%;overflow-y:auto;padding:15px 25px;">
    <div class="row">
      <div class="col-md-12">
        <h3 style="text-align:left!important">System Settings</h3><br/>
      </div>
  
      <div class="col-md-12" style="text-align:left!important">
        <h4>Send Statement Schedule</h4>  
      </div>

      <div class="col-md-4" style="text-align:left!important">
        <label style="text-align:left!important">Day Of Month:</label>              
        <input type="text" class="form-control" [(ngModel)]="postdata.dayofmonth">          
      </div>

      <div class="col-md-4" style="text-align:left!important">
        <label style="text-align:left!important">Run Scheduled Tasks At:</label>              
        <input type="text" class="form-control" [(ngModel)]="postdata.runat">          
      </div>

      <div class="col-md-4">
        <div class="checkbox1" style="text-align:left!important">

          <section title=".slideThree">
            <label>
              <span>Enable Schedule</span>
            </label>

            <div class="slideThree">
                <input type="checkbox" id="checkfield" name="checkfield"
                      [(ngModel)]="postdata.enableschedule"
                      (change)="$event.target.checked ? true : false"/>
                <label for="checkfield"></label>
            </div>
          </section>
        </div>
      </div>
      <div class="col-md-12">
        <br/>
        <br/>
        <br/>
      </div>
      <div class="col-md-12" style="text-align:left!important">
        <h4>Notification Schedule</h4>  
      </div>   

      <div class="col-md-4" style="text-align:left!important">
          <label style="text-align:left!important">Time of Day:</label>              
          <input type="text" class="form-control" [(ngModel)]="postdata.licenseNotificationRunat">          
      </div> 

      <div class="col-md-4" style="text-align:left!important">
    
        <div class="checkbox1">

          <section title=".slideThreex">
              <label>
                  <span>Enable Schedule</span>
              </label>
            <div class="slideThree">
                <input type="checkbox" id="checknotificationfield" name="checknotificationfield"
                      [(ngModel)]="postdata.EnableLicenseNotificationSchedule"
                      (change)="$event.target.checked ? true : false"/>
                <label for="checknotificationfield"></label>
            </div>
          </section>
        </div>   
      </div>

      <div class="col-md-12">
        <br/>
        <br/>
        <br/>
      </div>

      <div class="col-md-12" style="text-align:left!important">
        <div style="width:300px;">
          <a href="javascript:;"
            (click)="calculate()"
            class="btn btn-sm btn-circle btn-success"> Apply Settings </a>
        </div>
      </div>
    </div>
  </div>`
})
export class SystemSettings implements AfterViewInit {

  controlids = [];
  @Input() formdata: any;
  postdata = {

  }

  calculate() {
    RESTLoaderAuthOnly.processRequest(
      false,
      "SaveSettings",
      this.postdata,
      "POST",
      () => {
        toastr.success(
          "Everything checked out properly, your information has been successfully persisted to the database",
          "Saved",
          {
            timeOut: 5000,
            closeButton: true,
            newestOnTop: true,
            positionClass: "toast-bottom-right",
            showEasing: "swing",
            hideEasing: "linear",
            showMethod: "fadeIn",
            hideMethod: "fadeOut"
          }
        );
      }
    );
  };


  ngAfterViewInit() {
    RESTLoaderAuthOnly.processRequest(
      false,
      "GetSettings",
      null,
      "GET",
      (response1) => {
        this.postdata = response1;
      }
    );


  }
}
