import { Grid } from "@syncfusion/ej2-ng-grids";

export const createTourGuideApplicationServicesListGrid = function(
  dataSource: any
)  {
  const cols = [
    {
      field: "TourGuideServiceName",
      headerText: "Service Name"
    }
  ];
  const managerGrid = new Grid({
    dataSource,
    width: "100%",
    allowTextWrap: true,
    textWrapSettings: {
      wrapMode: "Content"
    },
    columns: cols
  });
  managerGrid.appendTo(`#${this.props.referenceid3}`);
};
