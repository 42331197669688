export default `
<div class="row">



<div class="col-md-6">
    <div class="form-group">
      <label>First Name</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedApplication.FirstName"
          class="form-control"
        />
      </div>
    </div>
  </div>

  <div class="col-md-6">
    <div class="form-group">
      <label>Last Name</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedApplication.LastName"
          class="form-control"
        />
      </div>
    </div>
  </div>

  <div class="col-md-6">
  <div class="form-group">
    <label>Country Of Birth</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        [(ngModel)]="props.currentSelectedApplication.CountryNameOfBirth"
        class="form-control"
      />
    </div>
  </div>
</div>

  <div class="col-md-6">
    <div class="form-group">
      <label>Date of Birth</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedApplication.DateOfBirth"
          class="form-control"
        />
      </div>
    </div>
  </div>

  <div class="col-md-4">
    <div class="form-group">
      <label>Gender</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedApplication.Gender"
          class="form-control"
        />
      </div>
    </div>
  </div>





  <div class="col-md-4">
  <div class="form-group">
    <label>Passport Number</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        [(ngModel)]="props.currentSelectedApplication.PassportNumber"
        class="form-control"
      />
    </div>
  </div>
</div>

<div class="col-md-4">
  <div class="form-group">
    <label>Passport IssueDate</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        [(ngModel)]="props.currentSelectedApplication.PassportIssueDate"
        class="form-control"
      />
    </div>
  </div>
</div>




  <div class="col-md-6">
  <div class="form-group">
    <label>Passport Expiration Date</label>
    <div class="input-group">
      <input

        readOnly="readOnly"
        [(ngModel)]="props.currentSelectedApplication.PassportExpirationDate"
        class="form-control"
      />
    </div>
  </div>
</div>

<div class="col-md-6">
  <div class="form-group">
    <label>Country Of Nationality</label>
    <div class="input-group">
      <input

        readOnly="readOnly"
        [(ngModel)]="props.currentSelectedApplication.CountryNameOfNationality"
        class="form-control"
      />
    </div>
  </div>
</div>

<div class="col-md-6">
<div class="form-group">
  <label>Passport Issue Country</label>
  <div class="input-group">
    <input
      readOnly="readOnly"
      [(ngModel)]="props.currentSelectedApplication.PassportIssueCountryName"
      class="form-control"
    />
  </div>
</div>
</div>


<div class="col-md-6">
  <div class="form-group">
    <label>Marital Status</label>
    <div class="input-group">
      <input

        readOnly="readOnly"
        [(ngModel)]="props.currentSelectedApplication.MaritalStatus"
        class="form-control"
      />
    </div>
  </div>
</div>





</div>



`;
