import { BLANK } from './formdefinitions/blank';
import { AUTHENTICATION } from './formdefinitions/authentication';
import { UPLOAD } from './formdefinitions/upload';
import { GUESTREGISTRATION } from './formdefinitions/guessregistration';
import { SENDSTATEMENT } from './formdefinitions/sendstatement';
import { SETTINGS } from './formdefinitions/settings';
import { CALCULATOR } from './formdefinitions/calculator';
import { CHANGEPASSWORD } from './formdefinitions/changepassword';
import { ROLEMANAGEMENT } from './formdefinitions/rolemanagement';
import { CHANGEPASSWORDADMIN } from './formdefinitions/changepasswordadmin';
import { SETSTATUS } from './formdefinitions/setstatus';
import { STARTUP } from './formdefinitions/startup';
import { PENALTY } from './formdefinitions/penalty';
import { MANAGEADMIN } from './formdefinitions/manageadmin';
import { ONLINEPORTALMESSAGE } from './formdefinitions/onlineportalmessage';
import { GETUSERACTIVITYRECORDS } from './formdefinitions/getuseractivityrecords';
import { HOTELAPPLICATION } from './formdefinitions/hotelApplication';
import { HOTELMODIFCATION } from './formdefinitions/hotelModification';
import { TOURAPPLICATION } from './formdefinitions/tourOperatorApplication';
import { TOURMODIFCATION } from './formdefinitions/tourOperatorModification';
import { TOURGUIDEAPPLICATION } from './formdefinitions/tourGuideApplication';
import { TOURGUIDEMODIFCATION } from './formdefinitions/tourGuideModification';

import { QUALIFIEDRETIREDPERSONAPPLICATION } from './formdefinitions/qualifiedRetiredPersonApplication';
import { QUALIFIEDRETIREDPERSONMODIFCATION } from './formdefinitions/qualifiedRetiredPersonModification';
import { HOTELACCOMMODATIONTAXRETURN } from './formdefinitions/hotelaccomodationtaxreturn';


export default {
  FORMDEFS: {
    BLANK,
    AUTHENTICATION,
    UPLOAD,
    GUESTREGISTRATION,
    SENDSTATEMENT,
    SETTINGS,
    CALCULATOR,
    CHANGEPASSWORD,
    ROLEMANAGEMENT,
    CHANGEPASSWORDADMIN,
    SETSTATUS,
    STARTUP,
    PENALTY,
    MANAGEADMIN,
    ONLINEPORTALMESSAGE,
    GETUSERACTIVITYRECORDS,
    HOTELAPPLICATION,
    HOTELMODIFCATION,
    TOURAPPLICATION,
    TOURMODIFCATION,
    TOURGUIDEAPPLICATION,
    TOURGUIDEMODIFCATION,
    QUALIFIEDRETIREDPERSONAPPLICATION,
    QUALIFIEDRETIREDPERSONMODIFCATION,
    HOTELACCOMMODATIONTAXRETURN
  }
};
//test
