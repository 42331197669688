export default `
<div class="row">

<div class="col-md-12">
    <div class="form-group">
      <label>Name Of Applicant</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedApplication.NameOfApplicant"
          class="form-control"
        />
      </div>
    </div>
  </div>

<div class="col-md-6">
    <div class="form-group">
      <label>First Name</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedApplication.FirstName"
          class="form-control"
        />
      </div>
    </div>
  </div>

  <div class="col-md-6">
    <div class="form-group">
      <label>Last Name</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedApplication.LastName"
          class="form-control"
        />
      </div>
    </div>
  </div>

  <div class="col-md-4">
    <div class="form-group">
      <label>Date of Birth</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedApplication.DateOfBirth"
          class="form-control"
        />
      </div>
    </div>
  </div>

  <div class="col-md-4">
    <div class="form-group">
      <label>Gender</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedApplication.Gender"
          class="form-control"
        />
      </div>
    </div>
  </div>

  <div class="col-md-4">
  <div class="form-group">
    <label>Social Security Number</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        [(ngModel)]="props.currentSelectedApplication.SocialSecurityNumber"
        class="form-control"
      />
    </div>
  </div>
  </div>



  <div class="col-md-4">
  <div class="form-group">
    <label>Tour Guide Category</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        [(ngModel)]="props.currentSelectedApplication.TourGuideCategory"
        class="form-control"
      />
    </div>
  </div>
</div>



  <div class="col-md-4">
  <div class="form-group">
    <label>District Area Of Operation</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        [(ngModel)]="props.currentSelectedApplication.DistrictAreaOfOperationName"
        class="form-control"
      />
    </div>
  </div>
  </div>

  <div class="col-md-4">
  <div class="form-group">
    <label>Training Program Completion Date</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        [(ngModel)]="props.currentSelectedApplication.TrainingProgramCompletionDate"
        class="form-control"
      />
    </div>
  </div>
  </div>



  <div class="col-md-6">
  <div class="form-group">
    <label>Tour Guide Type</label>
    <div class="input-group">
      <input

        readOnly="readOnly"
        [(ngModel)]="props.currentSelectedApplication.TourGuideType"
        class="form-control"
      />
    </div>
  </div>
</div>

<div class="col-md-6">
  <div class="form-group">
    <label>Tour Guide Site</label>
    <div class="input-group">
      <input

        readOnly="readOnly"
        [(ngModel)]="props.currentSelectedApplication.TourGuideSite"
        class="form-control"
      />
    </div>
  </div>
</div>





</div>



`;
