import dispatcher from '../dispatcher/dispatcher.app';
import constants from '../constants/constants';
import {RESTLoader} from '../dispatcher/dispatcher.rest';

export default class DataAction {

    static runData(model,typeid,dataid,callback) {


        RESTLoader.processRequest(false,dataid,model,'POST',function(response){

            dispatcher.dispatch({
                    action: constants.DataActions.DATA_AVAILABLE,
                    data: {
                        'dataid': dataid,
                        'data': response
                    }
                }
            );

            if(callback !== undefined && callback !== null) {
                callback(response);
            }

        });



    }
    static runData2(model,typeid,dataid,callback) {


        RESTLoader.processRequest(false,dataid,null,'GET',function(response){

            dispatcher.dispatch({
                    action: constants.DataActions.DATA_AVAILABLE,
                    data: {
                        'dataid': dataid,
                        'data': response
                    }
                }
            );

            if(callback !== undefined && callback !== null) {
                callback(response);
            }

        });



    }

    static runExecute(model:any,typeid:string,dataid:string,callback:Function) {

        RESTLoader.processRequest(false,dataid,model,'POST',function(response){

            dispatcher.dispatch({
                    action: constants.DataActions.DATA_AVAILABLE,
                    data: {'dataid':dataid, 'data':response}
                }
            );

            if(callback!==undefined) {
                callback(response);
            }
        });
    }

};

