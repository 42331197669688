import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import DataAction from '../../../actions/action.data';
import * as _ from 'underscore';
import * as toastr from 'toastr';
import {FormStore} from "../../../stores/store.form";

import {
  DetailRowService,
  GridModel,
  FilterSettingsModel,
  RowSelectEventArgs,
  GridComponent,
  IRow,
  Column
} from '@syncfusion/ej2-ng-grids';
import { closest } from '@syncfusion/ej2-base';
import { PageSettingsModel } from '@syncfusion/ej2-ng-grids';
import { ToolbarItems } from '@syncfusion/ej2-ng-grids';
import guid from '../../../constants/guid';
import {
  Grid,
  Group,
  Filter,
  Sort,
  ExcelExport,
  CommandColumn,
  Page,
  Toolbar,
  PdfExport,
  Edit
} from '@syncfusion/ej2-grids';

Grid.Inject(
  Filter,
  Group,
  Sort,
  Page,
  ExcelExport,
  Toolbar,
  PdfExport,
  CommandColumn
);
@Component({
  selector: 'action-assignment',
  template: `
  <div class="editorActions" style="right:10px;">

  <div *ngIf="allowAssign()">

      <div>
          <a href="javascript:;"  class="danger"
             (click)="assignClients()">
              <i class="mdi mdi-arrow-right-bold-hexagon-outline"></i>
              <span style="white-space: initial;
              font-size: 8px;">{{formdata.actionLabel}}</span></a>
      </div>
  </div>
</div>

                  <div id='{{referenceid}}'>
                  </div>









    `
})
export class ActionAssignmentComponent implements OnInit, AfterViewInit {
  @Input()
  formdata: any;

  public referenceid = guid.create('syncfusion');
  public grid: Grid = null;


  ngOnInit() {
    if (this.formdata.listresources !== null) {
      this.formdata.listresources.map(res => {
        if (res.id === this.formdata.startResourceId) {
         this.grid.dataSource = res[res.id];
         this.grid.refresh();
        }
      });
    }
  }

  refreshData() {

    DataAction.runData2({}, "SQL", this.formdata.datasource, (d) => {
      this.grid.dataSource = d;
      this.grid.refresh();
    });


}


  ngAfterViewInit() {
    const cols = [];
    cols.push({ type: 'checkbox', width: 50 });
    this.formdata.fields.map(column => {
      if (column.type !== 'action') {
        cols.push({
          field: column.key,
          headerText: column.label,
          type: column.type === 'text' ? 'string' : column.type
        });
      }
    });

    let actionCol = {
        headerText: 'Actions',
        width: 200,
        isUnbound: false,
        commands: []
      };


    this.formdata.fields.map(column => {
      if (column.type === 'action') {
        actionCol.commands.push(


              {
                buttonOption: {
                  content: 'Edit',
                  cssClass: 'e-flat',
                  click: (args) => {

                    const rowObj = this.grid.getRowObjectFromUID(
                      closest(args.target, '.e-row').getAttribute('data-uid')
                    );
                    const data: any = rowObj.data;

                    switch (column.action.actiontype) {
                      case 'execute':
                        {
                          DataAction.runData(data, column.action.typeid, column.action.model, () => {});
                        }
                        break;
                    }
                  }
                }
              }



        );
      }
    });

    if(actionCol.commands.length>0)
    {
      cols.push(actionCol);
    }



    this.grid = new Grid({
      dataSource: this.formdata.listresources[0][this.formdata.datasource],
      allowPaging: true,
      allowGrouping: true,
      allowFiltering: true,
      selectionSettings: { type: 'Multiple' },
      allowSorting: true,
      filterSettings: { type: 'Excel' },
      allowPdfExport: true,
      allowExcelExport: true,
      toolbar: ['ExcelExport', 'PdfExport'],
      columns: cols
    });
    this.grid.appendTo(`#${this.referenceid}`);
    this.grid.toolbarClick = (args: Object) => {
      if (args['item'].id === `${this.referenceid}_excelexport`) {
        this.grid.excelExport();
      }
      if (args['item'].id === `${this.referenceid}_pdfexport`) {
        this.grid.pdfExport();
      }
    };
  }

  assignClients() {

    let selectedrowindex: number[] = this.grid.getSelectedRowIndexes();

    const postable = this.grid.getSelectedRecords();
    let counter = postable.length;

    if (counter === 0) {
        toastr.error("There was nothing to assign as no clients have been selected", "Error", {
            timeOut: 5000,
            closeButton: true,
            "newestOnTop": true,
            'positionClass': 'toast-bottom-right',
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        });
        return;
    }




    this.processAssignGroup(postable, 0, () => {
        this.refreshData();
        toastr.success("All clients have been assigned to the selected criteria. Please confirm that the procedure completed as you intended.", "Saved", {
            timeOut: 5000,
            closeButton: true,
            "newestOnTop": true,
            'positionClass': 'toast-bottom-right',
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        });
    });




}

processAssignGroup(portable: any, index: number, callback: any) {
  if (index === portable.length) {
      callback();
      return;
  }

  DataAction.runData(portable[index], this.formdata.action.type, this.formdata.action.id, () => {


      this.processAssignGroup(portable, index + 1, callback);


  });
}


  allowAssign(): boolean {

  /*  this.clientData.map((item) => {
        if (item.checked === true) {
            assign = true;
        }
    });*/
    if(this.grid!==null)
    {
    return this.grid.getSelectedRowIndexes().length > 0;
    }
    else
    {
      return false;
    }
}


  onProcessAction(action, data) {

  }
}
