import {Component, OnInit, NgZone, AfterViewInit} from '@angular/core';
import {FlowControlStore} from '../../../stores/store.flowcontrol';
import constants from '../../../constants/constants';
import FormAction from '../../../actions/action.form';
import {UserStore} from "../../../stores/store.user";
import DataAction from "../../../actions/action.data";


@Component({
    selector: 'theme-management',


    template: `<div class="container-slider demo-1">
            <div id="slider" class="sl-slider-wrapper">
                    <div class="sl-slider">

                        <div  class="sl-slide bg-1" data-orientation="horizontal" data-slice1-rotation="-25" data-slice2-rotation="-25" data-slice1-scale="2" data-slice2-scale="2">
                             <div id="scene_0" class="sl-slide-inner">                            
                                <theme-render [(flowShow)]="flowShow" [inx]="0"></theme-render>                        
                             </div>
                      </div>
                      
                       <div  class="sl-slide bg-2" data-orientation="horizontal" data-slice1-rotation="-25" data-slice2-rotation="-25" data-slice1-scale="2" data-slice2-scale="2">
                             <div id="scene_1" class="sl-slide-inner">                            
                                <theme-render [(flowShow)]="flowShow" [inx]="1"></theme-render>                        
                             </div>
                      </div>
                      
                      
                       <div  class="sl-slide bg-3" data-orientation="horizontal" data-slice1-rotation="-25" data-slice2-rotation="-25" data-slice1-scale="2" data-slice2-scale="2">
                             <div id="scene_2" class="sl-slide-inner">                            
                                <theme-render [(flowShow)]="flowShow" [inx]="2"></theme-render>                        
                             </div>
                      </div>
                      
                      
                       <div  class="sl-slide bg-4" data-orientation="horizontal" data-slice1-rotation="-25" data-slice2-rotation="-25" data-slice1-scale="2" data-slice2-scale="2">
                             <div id="scene_3" class="sl-slide-inner">                            
                                <theme-render [(flowShow)]="flowShow" [inx]="3"></theme-render>                        
                             </div>
                      </div>                     
                    </div>
           </div>
           </div>`
})


export class ThemeManagementComponent implements OnInit, AfterViewInit {
    private Page: any = null;
    private slitslider: any = null;
    private zone: NgZone = new NgZone({enableLongStackTrace: false});
    private flowDetails = [];
    private flowShow = [];
    private slitSlider: any = null;

    constructor() {
        this.Page = function () {

            let $navArrows = $('#nav-arrows'),


                moveNext = () => {
                    this.slitslider.next();
                },

                init = function () {

                };

            return {init: init, moveNext: moveNext};

        };
    }

    ngAfterViewInit() {


        this.slitslider = $("#slider")["slitslider"]({
            onBeforeChange: (slide, pos) => {

                $nav.removeClass('nav-dot-current');
                $nav.eq(pos).addClass('nav-dot-current');

            }
        });

        let $nav = $('#nav-dots > span');


        this.Page().init();
    }


    ngOnInit() {


        FlowControlStore.emitter.subscribe(constants.FlowControl.FLOW_STATE_CHANGED, () => {
            let currentIndex = FlowControlStore.getCurrentFlowIndex();
            this.flowShow[currentIndex] = FlowControlStore.getCurrentFlowDefinition();
            this.flowShow[currentIndex]['Data'] = FlowControlStore.getCurrentFlowResource();

            if (currentIndex !== 0) {
                this.Page().moveNext();

                    FormAction.loadForm(FlowControlStore.getCurrentFlowDefinition().form);


            }

        });

        FlowControlStore.emitter.subscribe(constants.FlowControl.FLOW_STATE_INITIATED, () => {

            this.flowDetails = FlowControlStore.getRouteDetails();

            this.flowDetails.map(() => {
                this.flowShow.push({
                    template: 'none'
                })
            })


        });
    }


}

