import { Grid } from "@syncfusion/ej2-ng-grids";

export const createTourOperatorApplicationGuideListGrid = function(dataSource){
  const cols = [
    {
      field: "FirstName",
      headerText: "First Name"
    },
    {
      field: "LastName",
      headerText: "Last Name"
    },
    {
      field: "DateOfBirth",
      headerText: "Date Of Birth"
    },
    {
      field: "EmploymentType",
      headerText: "Employment Type"
    },
    {
      field: "LicenseNumber",
      headerText: "License Number"
    },
    {
      field: "EmploymentDate",
      headerText: "Employment Date"
    }
  ];
  const ownerGrid = new Grid({
    dataSource,
    width: "100%",
    allowTextWrap: true,
    textWrapSettings: {
      wrapMode: "Content"
    },
    columns: cols
  });
  ownerGrid.appendTo(`#${this.props.referenceid1}`);
}
