import { closest } from "@syncfusion/ej2-ng-grids/node_modules/@syncfusion/ej2-base";
import { showSelectedApplication } from "./showSelectedApplication";
import { fit_modal_body } from "./fitmodalbody";
import { getQualifiedRetiredPersonApplicationDependentList } from "./getQualifiedRetiredPersonApplicationDependentList";
import { createQualifiedRetiredPersonApplicationDependentListGrid } from "./createQualifiedRetiredPersonApplicationDependentListGrid";


export const onViewTour = function(args) {
  const rowObj = this.props.grid.getRowObjectFromUID(
    closest(args.target, ".e-row").getAttribute("data-uid")
  );
  const _showSelectedApplication = showSelectedApplication.bind(this);

  const data: any = rowObj.data;
  this.props.selectedQualifiedRetiredPerson = data.FirstName + ' ' + data.LastName;

  const _createQualifiedRetiredPersonApplicationDependentListGrid = createQualifiedRetiredPersonApplicationDependentListGrid.bind(
    this
  );

  _showSelectedApplication(
    data.QualifiedRetiredPersonApplicationRecordNumber,
    (response: any) => {
      this.props.currentSelectedApplication = response;

          getQualifiedRetiredPersonApplicationDependentList(
            data.QualifiedRetiredPersonApplicationRecordNumber,
            servicesList => {
                          setTimeout(() => {
                            _createQualifiedRetiredPersonApplicationDependentListGrid(
                              servicesList
                            );

                            },100);

                            this.modalService
                            .open(this.content, { centered: true, size: "lg" })
                            .result.then(
                              result => {
                                // this.closeResult = `Closed with: ${result}`;
                              },
                              reason => {
                                // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                              }
                            );
                          setTimeout(() => {
                            fit_modal_body($(".modal"));
                          }, 100);



                        })

                    }
  );
};
