export default `
<div class="row">


<div class="col-md-6">
  <div class="form-group">
    <label>Employer</label>
    <div class="input-group">
      <input
      [style.background-color]="compare(props.currentSelectedModification.NewEmployer,props.currentSelectedModification.OldEmployer) ? '#AEF0B7' : '#fff'"
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedModification.NewEmployer"
      />
    </div>
  </div>
</div>


<div class="col-md-6">
  <div class="form-group">
    <label>Employer Address</label>
    <div class="input-group">
      <input
      [style.background-color]="compare(props.currentSelectedModification.NewEmployerAddress,props.currentSelectedModification.OldEmployerAddress) ? '#AEF0B7' : '#fff'"
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedModification.NewEmployerAddress"
      />
    </div>
  </div>
</div>

<div class="col-md-4">
  <div class="form-group">
    <label>Employer Phone 1</label>
    <div class="input-group">
      <input
      [style.background-color]="compare(props.currentSelectedModification.NewEmployerPhoneNumber1,props.currentSelectedModification.OldEmployerPhoneNumber1) ? '#AEF0B7' : '#fff'"
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedModification.NewEmployerPhoneNumber1"
      />
    </div>
  </div>
</div>


<div class="col-md-4">
  <div class="form-group">
    <label>Employer Phone 2</label>
    <div class="input-group">
      <input
      [style.background-color]="compare(props.currentSelectedModification.NewEmployerPhoneNumber2,props.currentSelectedModification.OldEmployerPhoneNumber2) ? '#AEF0B7' : '#fff'"
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedModification.NewEmployerPhoneNumber2"
      />
    </div>
  </div>
  </div>

  <div class="col-md-4">
  <div class="form-group">
    <label>Employment Date</label>
    <div class="input-group">
      <input
      [style.background-color]="compare(props.currentSelectedModification.NewEmploymentDate,props.currentSelectedModification.OldEmploymentDate) ? '#AEF0B7' : '#fff'"
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedModification.NewEmploymentDate"
      />
    </div>
  </div>
</div>











</div>
`;
