export default `
<div class="row">

<div class="col-md-12">
    <div class="form-group">
      <label>Name</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedModification.NewManagerName"
          class="form-control"
          [style.background-color]="compare(props.currentSelectedModification.NewManagerName,props.currentSelectedModification.OldManagerName) ? '#AEF0B7' : '#fff'"
        />
      </div>
    </div>
  </div>



  <div class="col-md-12">
    <div class="form-group">
      <label>Telephone</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedModification.NewManagerTelephoneNumber"
          [style.background-color]="compare(props.currentSelectedModification.NewManagerTelephoneNumber,props.currentSelectedModification.OldManagerTelephoneNumber) ? '#AEF0B7' : '#fff'"
          class="form-control"
        />
      </div>
    </div>
  </div>


  <div class="col-md-12">
    <div class="form-group">
      <label>Email Address</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedModification.NewManagerEmailAddress"
          [style.background-color]="compare(props.currentSelectedModification.NewManagerEmailAddress,props.currentSelectedModification.OldManagerEmailAddress) ? '#AEF0B7' : '#fff'"
          class="form-control"
        />
      </div>
    </div>
  </div>

  <div class="col-md-12">
  <div class="form-group">
    <label>Nationality</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        [(ngModel)]="props.currentSelectedModification.NewManagerCountryCodeOfNationality"
        [style.background-color]="compare(props.currentSelectedModification.NewManagerCountryCodeOfNationality,props.currentSelectedModification.OldManagerCountryCodeOfNationality) ? '#AEF0B7' : '#fff'"
        class="form-control"
      />
    </div>
  </div>
</div>

  </div>

`;
