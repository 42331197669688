import axios from "axios";
import { UserStore } from "src/app/stores/store.user";
export const  showComplete = (ModificationId: number, callback) => {
  const showPostData = {
    TourGuideModificationRequestNumber: ModificationId
  };
  axios
    .post("/GetTourGuideModificationRequestCheckpointList", showPostData, {
      headers: {
        "user-token": UserStore.getUserDetails().token,
        "site-token": "7pdvAXwKchuAQGw7QBLnSHS",
        "user-id": 1,
        "control-id": 1
      }
    })
    .then((response: any) => {
      callback(response.data.TourGuideModificationRequestCheckpoints);
    });
}
