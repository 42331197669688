import axios from "axios";
import { UserStore } from "src/app/stores/store.user";
import { closest } from "@syncfusion/ej2-base";

import { checkpointComplete } from "./checkpointComplete";
import {
  Grid,
  Group,
  CommandColumn,
  Filter,
  Sort,
  ExcelExport,
  Page,
  Toolbar,
  PdfExport,
  Edit,
  Resize
} from "@syncfusion/ej2-grids";

Grid.Inject(
  Filter,
  Group,
  Sort,
  Page,
  ExcelExport,
  Toolbar,
  PdfExport,
  CommandColumn,
  Resize
);
export const createCompleteGrid = function (dataSource: any) {
  const cols = [
    {
      field: "TourOperatorApplicationCheckpointRecordNumber",
      headerText: "TourOperatorApplicationCheckpointRecordNumber",
      visible: false,
    },
    {
      field: "TourOperatorApplicationRecordNumber",
      headerText: "TourOperatorApplicationRecordNumber",
      visible: false,
    },
    {
      field: "CheckpointName",
      headerText: "Checkpoint Name",
    },
    {
      field: "CheckpointDate",
      headerText: "Checkpoint Date",
    },
    {
      field: "CompletedBy",
      headerText: "Completed By",
    },
    {
      field: "CheckpointComments",
      headerText: "Checkpoint Comments",
    },
    {
      headerText: "",
      isUnbound: false,
      width: 100,
      commands: [
        {
          buttonOption: {
            content: "Modify",
            cssClass: "e-flat",
            click: checkpointComplete.bind(this),
          },
        },
      ],
    },
  ];
  this.props.completeGridTrack = new Grid({
    dataSource,
    allowTextWrap: true,
    textWrapSettings: {
      wrapMode: "Content",
    },
    columns: cols,
  });
  console.log("checkpoint");
  this.props.completeGridTrack.appendTo(`#${this.props.referenceid6}`);
};
