import axios from "axios";
import { UserStore } from "src/app/stores/store.user";

export const showModificationsList = function() {
  axios
    .post("/api/GetQualifiedRetiredPersonModificationRequestListByDate", this.props.postdata, {
      headers: {
        "user-token": UserStore.getUserDetails().token,
        "site-token": "7pdvAXwKchuAQGw7QBLnSHS",
        "user-id": 1,
        "control-id": 1
      }
    })
    .then(response => {
      this.props.grid.dataSource = response.data.QualifiedRetiredPersonModificationRequests;
    });
};
