import {Component, OnInit} from '@angular/core';
import DataAction from '../../../actions/action.data';
import * as _ from 'underscore';
import * as toastr from 'toastr';

@Component({
    selector: 'client-assignment',
    template: ` 
 
 <div style="position:absolute;top: 10px;left: 20px;right: 310px;bottom:10px;" class="clientassign">
         
                            <table class="tableheader hidden-xs hidden-sm">
                                <thead>
                                <tr style="cursor: pointer;">
                                      <th style="width:4%;text-align:center">

                           <input type="checkbox" [checked]="checkAllState" (click)="checkAll()"/>


                       </th>
                   <th style="width:20%;" (click)="setSort('clientname')">
                                     
<span style="float:left;font-weight:400;width:80%;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;">Client Name</span><i style="float:right;font-weight:600;width:30px;font-size: 12px;" [ngClass]="getSortClass('clientname')"></i>
                     
                   </th>
                    <th style="width:25%;" (click)="setSort('domainname')">
                                     
<span style="float:left;font-weight:400;width:80%;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;">Domain Name</span><i style="float:right;font-weight:600;width:30px;font-size: 12px;" [ngClass]="getSortClass('domainname')"></i>
                    </th>
                   <th style="width:25%;" (click)="setSort('agentname')">
                                     
<span style="float:left;font-weight:400;width:80%;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;">SEO Rep</span><i style="float:right;font-weight:600;width:30px;font-size: 12px;" [ngClass]="getSortClass('agentname')"></i>
                   </th>
                   <th style="width:22%;" (click)="setSort('dealerspikegroup')">
                                     
<span style="float:left;font-weight:400;width:80%;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;">Client Group</span><i style="float:right;font-weight:600;width:30px;font-size: 12px;" [ngClass]="getSortClass('dealerspikegroup')"></i>
                   </th>
                                </tr>
                                </thead>
                                
                                </table>
                                <table class="tablerows">
                                 <tbody>
                                 <tr style="background: #fff!important;">
 <td style="width:4%;text-align:center;">

                                </td>
                            <td style="width:20%">
                                
                                
                                
                                <div class="input-group">
  <input type="text" class="form-control" placeholder="Search" id="clientName"  (keydown)="filterdata()" (blur)="filterdata()" (click)="filterdata()" aria-describedby="basic-addon2">
  <span class="input-group-addon" id="basic-addon2"><i class="mdi mdi-magnify"></i></span>
</div>
                                
                                
                                
                            </td>
                            <td style="width:25%">
                            
                               <div class="input-group">
  <input type="text" class="form-control" placeholder="Search" id="domainName"  (keydown)="filterdata()" (blur)="filterdata()" (click)="filterdata()" aria-describedby="basic-addon2">
  <span class="input-group-addon" id="basic-addon2"><i class="mdi mdi-magnify"></i></span>
</div>
                               
                            </td>
                            <td  style="width:25%">
                            
                               <div class="input-group">
  <input type="text" class="form-control" placeholder="Search" id="agentName"  (keydown)="filterdata()" (blur)="filterdata()" (click)="filterdata()" aria-describedby="basic-addon2">
  <span class="input-group-addon" id="basic-addon2"><i class="mdi mdi-magnify"></i></span>
</div>
                                
                            </td>
                            <td style="width:22%">
                            
                               <div class="input-group">
  <input type="text" class="form-control" placeholder="Search" id="dealerGroup"  (keydown)="filterdata()" (blur)="filterdata()" (click)="filterdata()" aria-describedby="basic-addon2">
  <span class="input-group-addon" id="basic-addon2"><i class="mdi mdi-magnify"></i></span>
</div>
                               
                            </td>
</tr>
                                 </tbody>
</table>
                                 <div class="hidden-xs hidden-sm scrollersimple" style="top:88px;"
                     data-mcs-theme="dark"
                >
                               


 <table class="tablerows">
                                <tbody>
  <tr *ngFor="let item of filterdata()">
                    <td style="width:4%;text-align:center;"><input type="checkbox" [checked]="item.checked" (click)="checkUpdated(item)"/></td>
                    <td  style="width:20%"><span class="datadisplay">{{item.clientname}}</span></td>
                    <td style="width:25%"><span class="datadisplay">{{item.domainname}}</span></td>
                    <td style="width:25%"><span class="datadisplay">{{item.agentname}}</span></td>
                    <td style="width:22%"><span class="datadisplay">{{item.dealerspikegroup}}</span></td>
                </tr>
                                
                                </tbody>
                            </table>
                            </div>
</div>


 <div style="position:absolute;top: 50px;width: 290px;right: 10px;bottom:0;">
 
 
  <div style="width:100%;">
                           <label  style="width:100%;">
                               Selected Representative
                               
                                   
                                        <searchable [editable]="true"  [onChange]="_onChange1" [model]="seldata" name="agentrepsel" [(data)]="agentData" value="agentid" text="agentname" [displays]="" theme="formSearch"></searchable>
        

                           </label>
                       </div>
                       <div style="width:100%;">
                           <label  style="width:100%;">
                               Selected Client Group
                              
                               
                                <tagsearch [editable]="true" [reference] = "'dealerGroupSelect1'"   [onChange]="_onChange" [model]="seldata" name="dealerGroupSelect" [(data)]="groupData" value="dealerspikegroupid" text="dealerspikegroup" [displays]="" theme="formSearch"></tagsearch>
        
                           </label>
                       </div>
                       <br/>
                       <br/>
                       <button [disabled]="!allowAssign()" (click)="assignClients()" class="pull-right btn btn-primary">Assign</button>
                   </div>
               
 
  `
})
export class ClientAssignmentComponent implements OnInit {
    colors:any = ['1D8CF7', '1EB2A4', '6C3AAF', '86582E', 'E00015', '4ACB62', 'FF753A', '1D8CF7', '4ACB62'];
    clientData:any = [];
    checkAllState:boolean = false;
    agentData:any = [];
    groupData:any = [];
    seldata:any = {
        dealerspikegroupid: "",
        agentid: ""
    };
    currentSort:string = null;
    sortOrder:string = "ASC";

    _onChange = this.onChange.bind(this);
    _onChange1 = this.onChange1.bind(this);

    onChange(data) {
        this.seldata.dealerspikegroupid = data.dealerspikegroupid;
    }

    onChange1(data) {
        this.seldata.agentid = data.agentid;
    }

    allowAssign(): boolean {
        let assign: boolean = false;
        this.clientData.map((item) => {
            if (item.checked === true) {
                assign = true;
            }
        });

        let dealer = $("#dealerGroupSelect1");
        if (dealer !== null && dealer !== undefined) {
            if (dealer["tagging"] !== null && dealer["tagging"] !== undefined) {
                if (dealer["tagging"]('getTags') !== null && dealer["tagging"]('getTags') !== undefined) {
                    if (dealer["tagging"]('getTags').length == 0 && this.seldata.agentid === '') {
                        assign = false;
                    }
                }
            }
        }
        return assign
    }


    refreshData() {
        this.checkAllState = false;
        DataAction.runData({}, "SQL", "clientassignment/clientassignment", (d) => {
            this.clientData = d;
        });


    }

    ngOnInit() {
        this.refreshData();
        DataAction.runData({}, "SQL", "agents/getAgentList", (d) => {
            this.agentData = d;
        });


        DataAction.runData({}, "SQL", "dealergroups/getGroupList", (d) => {
            this.groupData = d;
        });
    }

    setSort(key) {
        this.currentSort = key;
        if (this.currentSort === key) {
            if (this.sortOrder === "ASC") {
                this.sortOrder = "DSC";
            }
            else {
                this.sortOrder = "ASC";
            }
        }
        else {
            this.sortOrder = "ASC";
        }
        this.filterdata();
    }

    filterdata() {

        let retval = this.clientData;

       
        if ($("#domainName").val()["length"] > 0) {
            let pop = [];
            retval.map((item) => {
                if (item.domainname !== null && item.domainname !== undefined) {
                    if (item.domainname.toLowerCase().indexOf($("#domainName").val()["toLowerCase"]()) > -1) {

                        pop.push(item)
                    }
                }

            });
            retval = pop;
        }
      
        if ($("#agentName").val()["length"] > 0) {
            let pop = [];
            retval.map((item) => {
                if (item.agentname !== null && item.agentname !== undefined) {
                    if (item.agentname.toLowerCase().indexOf($("#agentName").val()["toLowerCase"]()) > -1) {

                        pop.push(item);
                    }
                }

            });

            retval = pop;
        }


        if (this.currentSort !== null) {
            if (this.sortOrder === "ASC") {
                retval = _.sortBy(retval, this.currentSort);
            }
            else {
                retval = _.sortBy(retval, this.currentSort).reverse();
            }

        }


        return retval;

    }

    getSortClass(column) {
        if (column === this.currentSort) {
            if (this.sortOrder === "ASC") {
                return "mdi mdi-chevron-down";
            }
            else {
                return "mdi mdi-chevron-up";
            }
        }
        else {
            return "mdi mdi-unfold-more";
        }
    }

    checkUpdated(item) {
        item.checked = item.checked === undefined ? true : !item.checked;
    }

    checkAll() {
        this.checkAllState = !this.checkAllState;
        this.clientData.map((item) => {
            item.checked = this.checkAllState;
        });

    }

    assignClients() {
        let postable = [];
        this.clientData.map((item) => {
            if (item.checked === true) {
                postable.push({
                    clientid: item.clientid,
                    agentid: this.seldata.agentid,
                    dealerspikegroupid: this.seldata.dealerspikegroupid
                });
            }
        });


        let groups = [];

        let counter = 0;
        this.clientData.map((itemc) => {
            if (itemc.checked === true) {
                counter++;
                $("#dealerGroupSelect1")["tagging"]("getTags").map((grp: string) => {
                    this.groupData.map((item) => {

                        if (item["dealerspikegroup"].toLowerCase() === grp.toLowerCase()) {
                            groups.push({
                                clientid: itemc.clientid,
                                dealerspikegroupid: item.dealerspikegroupid
                            });
                        }

                    });
                });
            }
        });


        if (counter === 0) {
            toastr.error("There was nothing to assign as no clients have been selected", "Error", {
                timeOut: 5000,
                closeButton: true,
                "newestOnTop": true,
                'positionClass': 'toast-bottom-right',
                "showEasing": "swing",
                "hideEasing": "linear",
                "showMethod": "fadeIn",
                "hideMethod": "fadeOut"
            });
            return;
        }


        this.cleanallGroups(groups, 0, () => {

            this.processAssignGroup(groups, 0, () => {
                this.refreshData();
            })

        });

        this.processAssignAgent(postable, 0, () => {
            this.refreshData();
            toastr.success("All clients have been assigned to the selected criteria. Please confirm that the procedure completed as you intended.", "Saved", {
                timeOut: 5000,
                closeButton: true,
                "newestOnTop": true,
                'positionClass': 'toast-bottom-right',
                "showEasing": "swing",
                "hideEasing": "linear",
                "showMethod": "fadeIn",
                "hideMethod": "fadeOut"
            });
        });


    }

    processAssignGroup(portable: any, index: number, callback: any) {
        if (index === portable.length) {
            callback();
            return;
        }

        DataAction.runData(portable[index], "SQL", "clientassignment/createGroup", () => {


            this.processAssignGroup(portable, index + 1, callback);


        });
    }

    processAssignAgent(portable: any, index: number, callback: any) {
        if (index === portable.length) {
            DataAction.runData({}, "SQL", "clientassignment/clientassignment", null);
            callback();
            return;
        }

        if (this.seldata.agentid !== "") {
            DataAction.runData(portable[index], "SQL", "clientassignment/assignclientagent", () => {
                this.processAssignAgent(portable, index + 1, callback);
            });
        }
    }

    cleanallGroups(portable: any, index: number, callback: any) {
        if (index === portable.length) {
            callback();
            return;
        }

        DataAction.runData(portable[index], "SQL", "clientassignment/cleanGroup", () => {


            this.cleanallGroups(portable, index + 1, callback);


        });
    }


}