import axios from "axios";
import { UserStore } from "src/app/stores/store.user";
import swal from "sweetalert2";

export const processDetails = function(modal) {
  var allowComplete = true;
  if (this.completed !== undefined) {
    this.completed.forEach(appcomplete => {
      if (
        appcomplete.CompletedBy === null ||
        appcomplete.CompletedBy === undefined ||
        appcomplete.CompletedBy === ""
      ) {
        allowComplete = false;
      }
    });
  }

  if (!allowComplete) {
    if (
      confirm(
        "Not all checkpoints were completed. Would you like to process anyway?"
      )
    ) {
      axios
        .post(
          "/api/processtourguidemodificationrequest",
          {
            TourGuideModificationRequestNumber: this.props.completeId
          },
          {
            headers: {
              "user-token": UserStore.getUserDetails().token,
              "site-token": "7pdvAXwKchuAQGw7QBLnSHS",
              "user-id": 1,
              "control-id": 1
            }
          }
        )
        .then((response: any) => {
          modal.close();
          if (response.data.Error) {
            swal.fire({
              title: "Process Error",
              text: `${response.data.Message}. ${response.data.Error}`,
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: "#DD6B55",
              confirmButtonText: "Close"
            });
          } else {
            swal.fire({
              title: "Process Complete",
              text: `${response.data.Message}.`,
              icon: 'info',
              showCancelButton: false,
              confirmButtonColor: "#DD6B55",
              confirmButtonText: "Close"
            });
          }
        });
    } else {
      return;
    }
  } else {
    axios
      .post(
        "/api/processhotelmodificationrequest",
        {
          TourGuideModificationRequestNumber: this.props.completeId
        },
        {
          headers: {
            "user-token": UserStore.getUserDetails().token,
            "site-token": "7pdvAXwKchuAQGw7QBLnSHS",
            "user-id": 1,
            "control-id": 1
          }
        }
      )
      .then((response: any) => {
        modal.close();
        swal.fire({
          title: "Process Complete",
          text: "Process completed successfully",
          icon: 'info',
          showCancelButton: false,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Close"
        });
      });
  }
}
