import {
  Grid,
  Group,
  CommandColumn,
  Filter,
  Sort,
  ExcelExport,
  Page,
  Toolbar,
  PdfExport,
  Resize
} from "@syncfusion/ej2-grids";
import * as moment from "moment";
import {fit_modal_body} from './fitmodalbody';
Grid.Inject(
  Filter,
  Group,
  Sort,
  Page,
  ExcelExport,
  Toolbar,
  PdfExport,
  CommandColumn,
  Resize
);

import { closest } from "@syncfusion/ej2-base";
import { onViewTour } from "./onViewQualifiedRetiredPerson";
import { onComplete } from "./onComplete";
import { onDocument } from "./onDocument";

export const init = function() {
  $(window).resize(() => fit_modal_body($(".modal")));

  const cols = [
    {
      field: "QualifiedRetiredPersonIdNumber",
      headerText: "Qualified Retired Person Id Number",
      visible: false
    },
    {
      field: "RetireeName",
      headerText: "Retiree"
    },

    {
      field: "ControlId",
      headerText: "Control Id"
    },
    {
      field: "RequestDate",
      headerText: "Request Date",
      type: "date",
      format: "yMd",
      filter: { type: "Menu" }
    },
    {
      field: "RequestNotes",
      headerText: "Request Notes"
    },
    {
      field: "RequestStatus",
      headerText: "Request Status"
    },
    {
      field: "ApprovalExecutedBy",
      headerText: "Approval Executed By"
    },
    {
      field: "ApprovalExecutionDate",
      headerText: "Approval Execution Date",
      type: "date",
      format: "yMd",
      filter: { type: "Menu" }
    },
    {
      field: "QualifiedRetiredPersonModificationRequestNumber",
      headerText: "Tour Guide Modification Request Number",
      visible: false
    },
    {
      headerText: "",
      width: 350,
      isUnbound: false,
      commands: [
        {
          buttonOption: {
            content: "View",
            cssClass: "e-flat",
            click: onViewTour.bind(this)
          }
        },
        {
          buttonOption: {
            content: "Attachments",
            cssClass: "e-flat",
            click: onDocument.bind(this)
          }
        },
        {
          buttonOption: {
            content: "Checkpoints",
            cssClass: "e-flat",
            click: onComplete.bind(this)
          }
        }
      ]
    }
  ];

  this.props.grid = new Grid({
    dataSource: [],
    allowPaging: true,
    allowGrouping: true,
    allowFiltering: true,
    allowSorting: true,
    width: "100%",
    allowTextWrap: true,
    textWrapSettings: {
      wrapMode: "Content"
    },
    filterSettings: { type: "Excel" },
    allowPdfExport: true,
    allowExcelExport: true,
    toolbar: ["ExcelExport", "PdfExport"],
    columns: cols
  });
  this.props.grid.appendTo(`#${this.props.referenceid}`);
  this.props.grid.toolbarClick = (args: Object) => {
    if (args["item"].id === `${this.props.referenceid}_excelexport`) {
      this.props.grid.excelExport();
    }
    if (args["item"].id === `${this.props.referenceid}_pdfexport`) {
      this.props.grid.pdfExport();
    }
  };
};
