import {AfterViewInit, Component, Input, OnChanges} from '@angular/core';
import DataAction from '../../../actions/action.data';
import * as _ from 'underscore';
import * as toastr from 'toastr';
import {FormStore} from "../../../stores/store.form";
import { UserStore } from "../../../stores/store.user";
import guid from "../../../constants/guid";

@Component({
    selector: 'btb-file-uploader-assignment',
    template: `
        <div class="editorActions" style="right:10px;">

          
        </div>
        <div style="position:absolute;top: 10px;left: 20px;right: 20px;bottom:10px;" class="clientassign">

            <div class="form-group" style="width:300px;margin:20px;">
                <label for="sel1">Select Bank:</label>              

                <select class="form-control" [(ngModel)] = "Bank" placeHolder="Please Select A Bank">                
                    <option *ngFor="let c of BankTypes" [ngValue]="c"> {{c}} </option>
                </select>
            </div>
            
            <br/>
            <br/>

          
            
            <div id="{{currentId}}" class="dropzone">
                <div class="fallback">
                    <input name="file" type="file" multiple />
                </div>
            </div>
            
            <br/>
            <br/>
            <table class="tableheader hidden-xs hidden-sm">
                <thead>
                <tr style="cursor: pointer;-webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;       ">
                    <th>
                        Type
                    </th>
                    <th>
                        Description
                    </th>
                </tr>
                </thead>

            </table>
            <table class="tablerows">
                <tbody>
                <tr style="background: #fff!important;" *ngFor="let column of currentMessages">
                    <td>{{column.MessageType}}</td>
                    <td>{{column.MessageDescription}}</td>
                </tr>
                </tbody>
            </table>
            
            
         
        </div>`
})
export class BTBFileUploader implements AfterViewInit {

    @Input() formdata: any;
    currentMessages = [];
    currentId = guid.create("btb");

    Bank:string = "";
    BankTypes: string[] = [
        "Atlantic Bank",
        "Belize Bank",
        "Heritage Bank",
        "Scotia Bank"
    ];

    ngAfterViewInit() {
        const me = this;

        const myDropzone = new window["Dropzone"]("#" + this.currentId, { url: "/api/uploadbankfile",
            dictDefaultMessage: "Click Here To Select File To Upload."
        });

        myDropzone.on("sending", function(file, xhr, formData) {
            xhr.setRequestHeader("BANK", me.Bank);
            xhr.setRequestHeader("site-token", '7pdvAXwKchuAQGw7QBLnSHS');            
            xhr.setRequestHeader('user-token', UserStore.getUserDetails().token);

            this.currentMessages = [];
            // add headers with xhr.setRequestHeader() or
            // form data with formData.append(name, value);
        });

        myDropzone.on("complete", (file) => {
            const data = JSON.parse(file.xhr.response);
            this.currentMessages = data;
            myDropzone.removeAllFiles(file)
        });


        myDropzone.on("addedfile", function (file) {
            if(me.Bank === ""){
                alert("Please Select a Valid Bank.");
                this.removeFile(file);
                return false;
            }
            
            if (file.type !== "text/plain" && file.type !== "application/vnd.ms-excel" && file.type !== "text/csv" ) {
                alert("Error! Files of this type are not accepted");
                this.removeFile(file);
                return false;
            }

            if(!confirm("Do you want to process the file?")){
                this.removeFile(file);
                return false;
            }
        });


    }




}
