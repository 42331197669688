export default `
<div class="row">

<div class="col-md-12">
    <div class="form-group">
      <label>Name</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedModification.OldOwnerName"
          class="form-control"

        />
      </div>
    </div>
  </div>



  <div class="col-md-12">
    <div class="form-group">
      <label>Telephone</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedModification.OldOwnerTelephoneNumber"

          class="form-control"
        />
      </div>
    </div>
  </div>


  <div class="col-md-12">
    <div class="form-group">
      <label>Email Address</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedModification.OldOwnerEmailAddress"

          class="form-control"
        />
      </div>
    </div>
  </div>

  <div class="col-md-12">
  <div class="form-group">
    <label>Nationality</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        [(ngModel)]="props.currentSelectedModification.OldOwnerCountryCodeOfNationality"

        class="form-control"
      />
    </div>
  </div>
</div>

  </div>

`;
