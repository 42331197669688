import dispatcher from '../dispatcher/dispatcher.app';
import constants from '../constants/constants';
import EventEmitter from 'eventemitter-rx';

class RealTimeStoreClass {

    public emitter:EventEmitter = new EventEmitter();
    private websocket:any = null;
    private _isOnline:boolean = false;
    private _currentMessageStack = [];
    constructor() {

        dispatcher.register((payload:any) => {
            this.__invokeOnDispatch(payload);
        });

    }

    public __invokeOnDispatch(payload:any) {



    }

    public init():any {

            this.emitter.next(constants.RealTimeActionsActions.CONNECTION_OPEN, null);

    }

    public getOnlineStatus():boolean
    {
        return true;
    }

    public getCurrentMessageStack()
    {
        return this._currentMessageStack;
    }

    public setCurrentMessageStack(_value)
    {
        this._currentMessageStack=_value;
    }




}

export var RealTimeStore = new RealTimeStoreClass();