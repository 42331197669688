import dispatcher from '../dispatcher/dispatcher.app';
import constants from '../constants/constants';
import FlowControlAction from '../actions/action.flowcontrol';
import {UserStore} from './store.user';
import EventEmitter from 'eventemitter-rx';
class FlowControlStoreStoreClass {

    public emitter:EventEmitter = new EventEmitter();

    private flow = {routeDetails: [], routeDefinitions: []};
    private currentFlow:number = 0;
    private flowForm = {};

   

    constructor() {

        dispatcher.register((payload:any) => {
            this.__invokeOnDispatch(payload);
        });

    }
  
    public getCurrentFlowIndex() {
        return this.currentFlow;
    }

    public getRouteDetails() {
        return this.flow.routeDetails;
    }

    public loadFlowResource(resourceURL:string,type:string) {
        FlowControlAction.loadFlowResource(resourceURL,type);
    }

    public determineRouteTemplate(name:string) {
        this.flow.routeDefinitions.map((item:any)=> {
            if (item.name === name) {
                this.loadFlowResource(item.definition['form'],item.definition['formtype']);

                return;

            }
        });
    }

    public setCurrentFlow(index:number) {
        switch (this.flow.routeDetails[index].kind) {
            case 'point':
            {
                this.determineRouteTemplate(this.flow.routeDetails[index].route);
            }
                break;
            case 'choice':
            {
                if (this.flow.routeDetails[index].chooseBy === 'usertype') {
                    this.flow.routeDetails[index].routes.map((route)=> {
                        if (route.value.toUpperCase() === UserStore.getUserRole().toUpperCase()) {
                            this.determineRouteTemplate(route.route);
                            return;
                        }
                    });
                }
            }
                break;

        }
    }

    public moveNextScene() {
        this.currentFlow++;
        this.setCurrentFlow(this.currentFlow);


    }

    public getCurrentFlowDefinition() {

        let routeName = '';

        switch (this.flow.routeDetails[this.currentFlow].kind) {
            case 'point':
            {
                routeName = this.flow.routeDetails[this.currentFlow].route;
            }
                break;
            case 'choice':
            {
                if (this.flow.routeDetails[this.currentFlow].chooseBy === 'usertype') {
                    this.flow.routeDetails[this.currentFlow].routes.map((route)=> {
                        if (route.value.toUpperCase() === UserStore.getUserRole().toUpperCase()) {
                            routeName = route.route;
                            return;
                        }
                    });
                }
            }
                break;

        }


        let ret = null;
        this.flow.routeDefinitions.map((item)=> {
            if (item.name === routeName) {

                ret = item.definition;


            }
        });
        return ret;
    }

    public getCurrentFlowResource() {
        return this.flowForm;
    }

    private __invokeOnDispatch(payload:any) {

        switch (payload.action) {

            case constants.FlowControl.FLOW_STATE_INITIATED:
            {
                this.flow = payload.data;
                this.emitter.next(constants.FlowControl.FLOW_STATE_INITIATED, null);
                this.currentFlow = 0;
                this.setCurrentFlow(0);
            }
                break;

            case constants.FlowControl.FLOW_STATE_CHANGED:
            {
                this.flowForm = payload.data;
                this.emitter.next(constants.FlowControl.FLOW_STATE_CHANGED, null);
            }
                break;

        }
    }

    


}

export var FlowControlStore = new FlowControlStoreStoreClass();