export default `
<div class="row">


<div class="col-md-6">
    <div class="form-group">
      <label>Primary Education Completion Year</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedApplication.PrimaryEducationCompletionYear"
          class="form-control"
        />
      </div>
    </div>
  </div>

  <div class="col-md-6">
    <div class="form-group">
      <label>Secondary Education Completion Year</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedApplication.SecondaryEducationCompletionYear"
          class="form-control"
        />
      </div>
    </div>
  </div>

  <div class="col-md-6">
    <div class="form-group">
      <label>Tertiary Education Completion Year</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedApplication.TertiaryEducationCompletionYear"
          class="form-control"
        />
      </div>
    </div>
  </div>

    <div class="col-md-6">
      <div class="form-group">
        <label>Other Education Completion Year</label>
        <div class="input-group">
          <input
            readOnly="readOnly"
            [(ngModel)]="props.currentSelectedApplication.OtherEducationCompletionYear"
            class="form-control"
          />
        </div>
      </div>
    </div>





</div>
`;
