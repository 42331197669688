export default `
<div class="row">

<div class="col-md-4">
  <div class="form-group">
    <label>Address</label>
    <div class="input-group">
      <input
      [style.background-color]="compare(props.currentSelectedModification.NewAddress,props.currentSelectedModification.OldAddress) ? '#AEF0B7' : '#fff'"
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedModification.NewAddress"
      />
    </div>
  </div>
</div>

<div class="col-md-4">
  <div class="form-group">
    <label>City</label>
    <div class="input-group">
      <input
      [style.background-color]="compare(props.currentSelectedModification.NewCityName,props.currentSelectedModification.OldCityName) ? '#AEF0B7' : '#fff'"
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedModification.NewCityName"
      />
    </div>
  </div>
</div>

<div class="col-md-4">
  <div class="form-group">
    <label>District Area</label>
    <div class="input-group">
      <input
      [style.background-color]="compare(props.currentSelectedModification.NewDistrictArea,props.currentSelectedModification.OldDistrictArea) ? '#AEF0B7' : '#fff'"
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedModification.NewDistrictArea"
      />
    </div>
  </div>
</div>



<div class="col-md-6">
  <div class="form-group">
    <label>Cell Phone</label>
    <div class="input-group">
      <input
      [style.background-color]="compare(props.currentSelectedModification.NewCellPhone,props.currentSelectedModification.OldCellPhone) ? '#AEF0B7' : '#fff'"
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedModification.NewCellPhone"
      />
    </div>
  </div>
</div>


<div class="col-md-6">
  <div class="form-group">
    <label>Home Phone</label>
    <div class="input-group">
      <input
      [style.background-color]="compare(props.currentSelectedModification.NewHomePhone,props.currentSelectedModification.OldHomePhone) ? '#AEF0B7' : '#fff'"
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedModification.NewHomePhone"
      />
    </div>
  </div>
</div>

<div class="col-md-6">
  <div class="form-group">
    <label>Overseas Phone Number</label>
    <div class="input-group">
      <input
      [style.background-color]="compare(props.currentSelectedModification.NewOverseasPhoneNumber,props.currentSelectedModification.OldOverseasPhoneNumber) ? '#AEF0B7' : '#fff'"
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedModification.NewOverseasPhoneNumber"
      />
    </div>
  </div>
</div>




<div class="col-md-6">
<div class="form-group">
<label>Email Address</label>
<div class="input-group">
<input
[style.background-color]="compare(props.currentSelectedModification.NewEmailAddress,props.currentSelectedModification.OldEmailAddress) ? '#AEF0B7' : '#fff'"
readOnly="readOnly"
class="form-control"
[(ngModel)]="props.currentSelectedModification.NewEmailAddress"
/>
</div>
</div>

</div>







</div>
`;
