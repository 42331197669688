export const HOTELMODIFCATION = {
  access: ['admin'],
  formtype: 'hotelmodification',
  listresources: [
    {
      id: 'controlids',
      type: 'SQL'
    }
  ],
  caption: 'Hotel Modifications'
};
