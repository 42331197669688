export default `
<div class="row">
<div class="col-md-6">
    <div class="form-group">
      <label>Foreign Source Of Funds</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedApplication.ForeignSourceOfFunds"
          class="form-control"
        />
      </div>
    </div>
  </div>

  <div class="col-md-6">
    <div class="form-group">
      <label>Local Financial Institution</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedApplication.LocalfinancialInstitution"
          class="form-control"
        />
      </div>
    </div>
  </div>

  <div class="col-md-6">
    <div class="form-group">
      <label>Name of Agent</label>
      <div class="input-group">
        <input
          readOnly="readOnly"
          [(ngModel)]="props.currentSelectedApplication.NameofAgent"
          class="form-control"
        />
      </div>
    </div>
  </div>


  <div class="col-md-6">
  <div class="form-group">
    <label>Agent Email Address</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        [(ngModel)]="props.currentSelectedApplication.AgentEmailAddress"
        class="form-control"
      />
    </div>
  </div>
</div>

<div class="col-md-4">
  <div class="form-group">
    <label>Name Of Applicants</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        [(ngModel)]="props.currentSelectedApplication.NameOfApplicant"
        class="form-control"
      />
    </div>
  </div>
</div>

    <div class="col-md-4">
      <div class="form-group">
        <label>Application Fee Paid</label>
        <div class="input-group">
          <input
            readOnly="readOnly"
            [(ngModel)]="props.currentSelectedApplication.ApplicationFeeAmount"
            class="form-control"
          />
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label>Date Paid</label>
        <div class="input-group">
          <input
            readOnly="readOnly"
            [(ngModel)]="
              props.currentSelectedApplication.ApplicationFeePaymentDate
            "
            class="form-control"
          />
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label>Receipt No.</label>
        <div class="input-group">
          <input
            readOnly="readOnly"
            [(ngModel)]="
              props.currentSelectedApplication.ApplicationFeeReceiptNumber
            "
            class="form-control"
          />
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label>License Fee Paid</label>
        <div class="input-group">
          <input
            readOnly="readOnly"
            [(ngModel)]="props.currentSelectedApplication.LicenseFeeAmount"
            class="form-control"
          />
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label>Date Paid</label>
        <div class="input-group">
          <input
            readOnly="readOnly"
            [(ngModel)]="props.currentSelectedApplication.LicenseFeePaymentDate"
            class="form-control"
          />
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label>Receipt No.</label>
        <div class="input-group">
          <input
            readOnly="readOnly"
            [(ngModel)]="props.currentSelectedApplication.LicenseFeeReceiptNumber"
            class="form-control"
          />
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label>License Date</label>
        <div class="input-group">
          <input
            readOnly="readOnly"
            [(ngModel)]="props.currentSelectedApplication.LicenseDate"
            class="form-control"
          />
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label>License #</label>
        <div class="input-group">
          <input
            readOnly="readOnly"
            [(ngModel)]="props.currentSelectedApplication.LicenseNumber"
            class="form-control"
          />
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label>License Year</label>
        <div class="input-group">
          <input
            readOnly="readOnly"
            [(ngModel)]="props.currentSelectedApplication.LicenseYear"
            class="form-control"
          />
        </div>
      </div>
    </div>





    <div class="col-md-4">
    <div class="form-group">
    <label>Application Status</label>
    <div class="input-group">
    <input
    readOnly="readOnly"
    class="form-control"
    [(ngModel)]="props.currentSelectedApplication.ApplicationStatus"
    />
    </div>
    </div>
    </div>



    <div class="col-md-4">
    <div class="form-group">
    <label>User Id</label>
    <div class="input-group">
    <input
    readOnly="readOnly"
    class="form-control"
    [(ngModel)]="props.currentSelectedApplication.UserId"
    />
    </div>
    </div>
    </div>






  </div>
`;
