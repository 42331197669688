import {Component, Input, AfterViewInit} from '@angular/core';
import {FormStore} from '../../../stores/store.form'
import * as _ from 'underscore';

@Component({

    selector: 'tagsearch',
    template: `<div id="{{name}}" [ngClass]="getTheme()">
<div class="searchable tagsearch" [ngStyle]="getErrorStyle()" >
<table style="width:100%" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td>
<div class="mainspan" id="{{reference}}"></div>
</td>
<td style="width:24px;" (click)="showsearch()">
<i class="mdi mdi-plus" style="margin-top:5px;"></i>
</td>
</tr>
</tbody>
</table>
        </div>   
            </div>
            <div id="{{name+'valid'}}">
             <input style="display: none;" parsley [validation]="getValidation()" type="text" value="{{getModelFromData1()}}"/>
</div>
`
})
export class TagSearchComponent implements AfterViewInit {
    @Input() config: any;
    @Input() data: any = [];
    @Input() model: any = "";
    @Input() theme: string = "";
    @Input() name: string = "";
    @Input() value: string = "";
    @Input() text: string = "";
    @Input() onChange: any;
    @Input() displays: any;
    @Input() editable: boolean = true;
    @Input() placeholder: string = "";

    tag = null;
    @Input() reference: string = "";

    ngAfterViewInit() {
        this.tag = $("#" + this.reference);

        this.tag["tagging"]({
            "no-duplicate": true,
            "no-duplicate-callback": window.alert,
            "no-duplicate-text": "Duplicate tags",
            "edit-on-delete": false
        });
    }


    getErrorStyle(): any {

        return $("#" + this.name + 'valid').find("input").hasClass("parsley-error") ? {
            "border": "1px solid #E41600",
            "border-left": "10px solid #E41600"
        } : {};
    }

    getValidation() {
        if (this.config === null || this.config === undefined) {
            return null;
        }
        return this.config.validation;
    }

    getTheme() {
        return !this.editable ? this.theme == "formSearch" ? this.theme + " readonly" : this.theme + " readonly1" : this.theme;
    }


    showsearch() {

        let passdata = {
            config: this.config,
            data: this.data,
            model: this.model,
            theme: this.theme,
            name: this.name,
            value: this.value,
            text: this.text,
            onChange: this.onChange,
            displays: this.displays,
            editable: this.editable,
            tagger: (item) => {
                this.tag.tagging("add", item[this.text]);
            }
        };

        if (!this.editable) {
            return;
        }

        FormStore.ShowSearchableModal(passdata);

    }


    getModelFromData() {
        if (this.model === null || this.model === undefined) {
            return this.placeholder;
        }
        let query = {};
        query[this.value] = this.config === null || this.config === undefined ? this.model[this.value] : this.model[this.config.key];
        let found = _.findWhere(this.data, query);
        return found !== undefined && found !== null ? found[this.text] : this.placeholder;
    }

    getModelFromData1() {
        if (this.model === null || this.model === undefined) {
            return "";
        }
        let query = {};
        query[this.value] = this.config === null || this.config === undefined ? this.model[this.value] : this.model[this.config.key];
        let found = _.findWhere(this.data, query);
        return found !== undefined && found !== null ? found[this.text] : "";
    }


}
