import axios from "axios";
import { UserStore } from "src/app/stores/store.user";
export const showDocuments = function(applicationId: number, callback) {
  const showPostData = {
    TourOperatorApplicationRecordNumber: applicationId
  };
  axios
    .post("/api/getTourOperatorapplicationdocumentlist", showPostData, {
      headers: {
        "user-token": UserStore.getUserDetails().token,
        "site-token": "7pdvAXwKchuAQGw7QBLnSHS",
        "user-id": 1,
        "control-id": 1
      }
    })
    .then((response: any) => {
      callback(response.data.TourOperatorApplicationDocuments);
    });
};
