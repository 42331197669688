export const STARTUP = {
  duplicates: [],
  sendaftersave: false,
  access: ['admin'],
  track: 'systemuser',
  formtype: 'dataEntry',
  listresources: [
    {
      id: 'ViewUserListing',
      type: 'SQL'
    },
    {
      id: 'controlids',
      type: 'SQL'
    },
    {
      id: 'securitylist',
      type: 'SQL'
    },
  ],
  caption: 'Manage Online Users',
  editSearchSelector: {
    resource: 'ViewUserListing',
    resourceId: 'Identity',
    resourceValue: 'FullName',
    resourceSelectedType: 'SQL',
    resourceSelected: 'getuser',
    resourceDisplayFields: ['ControlId']
  },
  model: {
    modelid: 'GetUser',
    autoload: false
  },
  functionactions: {
    createnew: {
      functionname: 'AddUser',
      functiontype: 'SQL',
      validateData: true,
      validationFailedTitle: '',
      validationFailedText: '',
      alertOnComplete: true,
      alertTitle: '',
      alertText: ''
    },
    update: {
      functionname: 'UpdateUserInformation',
      functiontype: 'SQL',
      validateData: true,
      validationFailedTitle: '',
      validationFailedText: '',
      alertOnComplete: true,
      alertTitle: '',
      alertText: ''
    },
    after: {
      functionname: 'ViewUserListing',
      functiontype: 'SQL'
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: 'General Information',
            fields: [
              {
                key: 'ControlId',
                type: 'select',
                label: 'Primary Control Id',
                placeholder: 'Primary Control Id',
                required: 'true',
                isPrimary: 'false',                
                hideOnEdit: 'true',
                optionsResource: {
                  resource: 'controlids',
                  resourceId: 'ControlId',
                  resourceValue: 'NameAndControlId',
                  resourceDisplay: ['ControlId']
                },
              },   

              {
                key: 'FirstName',
                type: 'text',
                label: 'First Name',
                placeholder: 'First Name',
                required: 'true',
                validation: {
                  'data-parsley-required': true,
                  'data-parsley-minlength': 2,
                  'data-parsley-maxlength': 100
                },
                isPrimary: 'false'
              },

              {
                key: 'LastName',
                type: 'text',
                label: 'Last Name',
                placeholder: 'Last Name',
                required: 'true',
                validation: {
                  'data-parsley-required': true,
                  'data-parsley-minlength': 2,
                  'data-parsley-maxlength': 100
                },
                isPrimary: 'false'
              },
              {
                key: 'TelephoneNumber',
                type: 'text',
                label: 'Telephone Number',
                placeholder: 'Telephone Number',
                required: 'true',
                validation: {
                  'data-parsley-required': true,
                  'data-parsley-minlength': 3,
                  'data-parsley-maxlength': 100
                },
                isPrimary: 'false'
              },

              {
                key: 'EmailAddress',
                type: 'text',
                label: 'Email Address',
                placeholder: 'Email Address',
                required: 'true',
                validation: {
                  'data-parsley-required': true,
                  'data-parsley-minlength': 3,
                  'data-parsley-maxlength': 100,
                  'data-parsley-type': 'email'
                },
                isPrimary: 'false'
              },
             
             
              {
                key: 'UserNotes',
                type: 'text',
                label: 'Notes',
                placeholder: 'Notes',
                required: 'true',
                validation: {
                  'data-parsley-maxlength': 255
                },
                isPrimary: 'false'
              },
              
            ]
          }
        ]
      },
      {
        columns: [
          {
            title: 'Control Id List',
            fields: [
              {                
                key:  'UserId',
                type: 'usercontrol',
                data:{
                  linkfield:"UserId",
                  getResource:"getcontrollistid",
                  getResourceType:"SQL",
                  createResource:"addusercontrolid",
                  deleteResource:"deleteResource",
                  cleanUpResource:"deleteResource",
                }
              }
            ]
          }
        ]
      }
    ]
  }
};
