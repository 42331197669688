export const SENDSTATEMENT = {
  access: ['admin'],
  formtype: 'sendstatement',
  listresources: [
    {
      id: 'controlids',
      type: 'SQL'
    }
  ],
  caption: 'Email Statement'
};
