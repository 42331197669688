export const HOTELACCOMMODATIONTAXRETURN = {
  access: ['admin'],
  formtype: 'hotelaccommodationtaxreturn',
  listresources: [
    {
      id: 'controlids',
      type: 'SQL'
    }
  ],
  caption: 'View Hotel Accommodation Tax Returns'
};
