import {Component, OnInit, Input} from '@angular/core';
import {FormStore} from '../../../stores/store.form';
import constants from '../../../constants/constants';
import * as ReactDOM from 'react-dom';
import * as React from 'react';
import {UserStore} from '../../../stores/store.user';


@Component({

    selector: 'side-bar-template',
    template: `
        <div class="sidebar-templating">


            <div id="currentloadedform" class="content" *ngIf="currentForm !== null && getCurrentItem() === 'form'">

                <dashboard-admin *ngIf="showform" [formdata]="currentForm"></dashboard-admin>
            </div>

            <div id="dashboardoverlay"></div>
            <header-component></header-component>
        </div>




    `
})
export class SideBarTemplateComponent implements OnInit {
    @Input() formdata: any;
    currentForm: any = null;
    showInlet = false;
    showform = true;
    outsidedata: any = null;



    getCurrentItem(): any {


        let ret = "form";

        UserStore.getMenuSystem().map((tab: any) => {

            tab.groups.map((group) => {

                group.menuItems.map((item) => {


                    if (item.submenus !== null && item.submenus !== undefined) {
                        item.submenus.map((item1: any) => {
                            if (item1.resource === UserStore.getCurrentItem()) {
                                ret = item1.type;
                            }
                        })
                    }
                    if (item.resource === UserStore.getCurrentItem()) {
                        ret = item.type;

                    }

                });

            });
        });
        return ret;
    }


    ngOnInit() {

        const dash = $("#mainloader");

        FormStore.emitter.subscribe(constants.FormsActions.FORM_AVAILABLE, () => {
            this.currentForm = FormStore.getCurrentForm();
            dash.css("display", "none");
        });
    }
}

