export default `
<section *ngIf="newOwners">
            <h3>New Owners</h3>

            <div class="form-group row">
              <label class="col-sm-12 col-form-label"></label>
              <div class="col-sm-12">
                <div><div id="{{ referenceid2 }}"></div></div>
              </div>
            </div>
            </section>
`