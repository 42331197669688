export default `
<section id="contact" *ngIf="!isEmpty(currentSelectedModification)">
<h3>Contact Information</h3>

<ngb-tabset>
  <ngb-tab title="After">
    <ng-template ngbTabContent>
      <div class="form-group row marginal">
        <label for="new-mailing-address" class="col-sm-2 col-form-label"
          >Mailing Address:</label>
        <div class="col-sm-4">
          <input
            type="text"
            class="form-control"
            id="new-mailing-address"
            [(ngModel)]="currentSelectedModification.NewMailingAddress"
            [style.background-color]="compare(currentSelectedModification.NewMailingAddress,currentSelectedModification.OldMailingAddress) ? '#AEF0B7' : '#fff'"
            readonly
          />
        </div>
        
        <label for="new-pob" class="col-sm-1 col-form-label"
          >P.O Box:</label>
        <div class="col-sm-2">
          <input
            type="text"
            class="form-control"
            id="new-pob"
            [(ngModel)]="
              currentSelectedModification.NewPostOfficeBox
            "
            [style.background-color]="compare(currentSelectedModification.NewPostOfficeBox,currentSelectedModification.OldPostOfficeBox) ? '#AEF0B7' : '#fff'"
            readonly
          />
        </div>
        
        <label for="district2" class="col-sm-1 col-form-label"
          >District:</label>
        <div class="col-sm-2">
          <input
            type="text"
            class="form-control"
            id="district2"
            [(ngModel)]="
              currentSelectedModification.NewMailingDistrictAreaName
            "
            [style.background-color]="compare(currentSelectedModification.NewMailingDistrictAreaName,currentSelectedModification.OldMailingDistrictAreaName) ? '#AEF0B7' : '#fff'"
            readonly
          />
        </div>
      </div>

      <div class="form-group row">
        <label
          for="accomodationofficenumber"
          class="col-sm-2 col-form-label"
          >Telephone No.:</label>
        <div class="col-sm-4">
          <input
            type="text"
            class="form-control"
            id="accomodationofficenumber"
            [(ngModel)]="currentSelectedModification.NewTelephone1"
            [style.background-color]="compare(currentSelectedModification.NewTelephone1,currentSelectedModification.OldTelephone1) ? '#AEF0B7' : '#fff'"
            readonly
          />
        </div>
        <label for="mobilenumber" class="col-sm-2 col-form-label"
          >Telephone No. 2:</label>
        <div class="col-sm-4">
          <input
            type="text"
            class="form-control"
            id="mobilenumber"
            placeholder="Telephone 2"
            [(ngModel)]="currentSelectedModification.NewTelephone2"
            [style.background-color]="compare(currentSelectedModification.NewTelephone2,currentSelectedModification.OldTelephone2) ? '#AEF0B7' : '#fff'"  
            readonly
          />
        </div>        
      </div>

      <div class="form-group row">
        <label for="faxnumber" class="col-sm-2 col-form-label"
        >Fax:</label>
        <div class="col-sm-4">
          <input
            type="text"
            class="form-control"
            id="faxnumber"
            placeholder="Fax Number"
            [(ngModel)]="currentSelectedModification.NewFaxNumber"
            [style.background-color]="compare(currentSelectedModification.NewFaxNumber,currentSelectedModification.OldFaxNumber) ? '#AEF0B7' : '#fff'"  
            readonly
          />
        </div>        
      </div>

      <div class="form-group row">
        <label
          for="accomodationemail"
          class="col-sm-2 col-form-label"
          >Email:</label>
        <div class="col-sm-4">
          <input
            type="text"
            class="form-control"
            id="accomodationemail"
            [style.background-color]="compare(currentSelectedModification.NewEmailAddress,currentSelectedModification.OldEmailAddress) ? '#AEF0B7' : '#fff'"
            [(ngModel)]="
              currentSelectedModification.NewEmailAddress
            "
            readonly
          />
        </div>
        <label
          for="financeemail"
          class="col-sm-2 col-form-label"
          >Finance Email:</label>
        <div class="col-sm-4">
          <input
            type="text"
            class="form-control"
            id="financeemail"
            [style.background-color]="compare(currentSelectedModification.NewFinanceEmailAddress,currentSelectedModification.OldFinanceEmailAddress) ? '#AEF0B7' : '#fff'"
            [(ngModel)]="currentSelectedModification.NewFinanceEmailAddress"
            readonly
          />
        </div>
      </div>
      
      <div class="form-group row">
        <label
          for="accomodationwebsite"
          class="col-sm-2 col-form-label"
          >Website:</label>
        <div class="col-sm-6">
          <input
            type="text"
            class="form-control"
            id="accomodationwebsite"
            [style.background-color]="compare(currentSelectedModification.NewWebsite,currentSelectedModification.OldWebsite) ? '#AEF0B7' : '#fff'"
            [(ngModel)]="currentSelectedModification.NewWebsite"
            readonly
          />
        </div>
        
      </div>
     
      
    </ng-template>
  </ngb-tab>

  <ngb-tab title="Before">
    <ng-template ngbTabContent>
      <div class="form-group row marginal">
        <label for="old-mailing-address" class="col-sm-2 col-form-label"
          >Mailing Address:</label>
        <div class="col-sm-4">
          <input
            type="text"
            class="form-control"
            id="old-mailing-address"
            [(ngModel)]="currentSelectedModification.OldMailingAddress"
            readonly
          />
        </div>

        <label for="old-pob" class="col-sm-1 col-form-label"
          >P.O Box:</label>
        <div class="col-sm-2">
          <input
            type="text"
            class="form-control"
            id="old-pob"
            [(ngModel)]="currentSelectedModification.OldPostOfficeBox"
            readonly
          />
        </div>
        <label for="district2" class="col-sm-1 col-form-label"
          >District:</label>
        <div class="col-sm-2">
          <input
            type="text"
            class="form-control"
            id="district2"
            [(ngModel)]="currentSelectedModification.OldMailingDistrictAreaName"
            readonly
          />
        </div>
      </div>
      <div class="form-group row">
        <label
          for="accomodationofficenumber"
          class="col-sm-2 col-form-label"
          >Telephone No.:</label>
        <div class="col-sm-4">
          <input
            type="text"
            class="form-control"
            id="accomodationofficenumber"
            [(ngModel)]="currentSelectedModification.OldTelephone1"
            readonly
          />
        </div>
        <label for="telephone-e" class="col-sm-2 col-form-label"
          >Telephone No. 2:</label>
        <div class="col-sm-4">
          <input
            type="text"
            class="form-control"
            id="telephone-t"
            [(ngModel)]="currentSelectedModification.OldTelephone2"
            readonly
          />
        </div>
      </div>

      <div class="form-group row">
        <label for="faxnumber" class="col-sm-2 col-form-label"
        >Fax:</label>
        <div class="col-sm-4">
          <input
            type="text"
            class="form-control"
            id="faxnumber"
            placeholder="Fax Number"
            [(ngModel)]="currentSelectedModification.OldFaxNumber"
            readonly
          />
        </div>        
      </div>      

      <div class="form-group row">
        <label
          for="accomodationemail"
          class="col-sm-2 col-form-label"
          >Email:</label>
        <div class="col-sm-4">
          <input
            type="text"
            class="form-control"
            id="accomodationemail"
            [(ngModel)]="currentSelectedModification.OldEmailAddress"
            readonly
          />
        </div>
        <label
          for="financeemail"
          class="col-sm-2 col-form-label"
          >Finance Email:</label>
        <div class="col-sm-4">
          <input
            type="text"
            class="form-control"
            id="financeemail"
            [(ngModel)]="currentSelectedModification.OldFinanceEmailAddress"
            readonly
          />
        </div>
      </div>

      <div class="form-group row">
        <label
          for="accomodationwebsite"
          class="col-sm-2 col-form-label"
          >Website:</label>
        <div class="col-sm-6">
          <input
            type="text"
            class="form-control"
            id="accomodationwebsite"
            [(ngModel)]="currentSelectedModification.OldWebsite"
            readonly
          />
        </div>
      </div>
     
     
    </ng-template>
  </ngb-tab>
</ngb-tabset>
</section>

`