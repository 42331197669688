import dispatcher from '../dispatcher/dispatcher.app';
import constants from '../constants/constants';
import EventEmitter from 'eventemitter-rx';
import {RealTimeStore} from "./store.realtime";

class UserStoreClass {

    public emitter: EventEmitter = new EventEmitter();

    private UserDetails = {
        UserRole: '',
        token: '',
        Roles: '',
        menuSystem: [],
        user_type: '',
        username: '',
        customer_id: '',
        clientname: '',
        Group:''
    };

    private currentItem = {
        icon: "",
        name: ""
    };


    constructor() {

        dispatcher.register((payload: any) => {
            this.__invokeOnDispatch(payload);
        });

    }

    public __invokeOnDispatch(payload: any) {

        switch (payload.action) {
            case constants.UserActions.USER_DETAILS_AVAILABLE: {
                this.UserDetails = payload.data;


                        this.UserDetails.user_type = 'super';
                        this.UserDetails.username = payload.data.UserName;
                        this.UserDetails.token = payload.data.TokenValue;
                        this.UserDetails.Roles = payload.data.Roles;
                this.UserDetails.Group = payload.data.GroupName;



                RealTimeStore.init();

                this.emitter.next(constants.UserActions.USER_DETAILS_AVAILABLE, null);
            }
                break;
            case constants.UserActions.AUTHENTICATION_FAILED: {
                this.emitter.next(constants.UserActions.AUTHENTICATION_FAILED, null);

            }
                break;


            case constants.UserActions.GET_USER_DETAILS: {
                this.emitter.next(constants.UserActions.GET_USER_DETAILS, null);

            }
                break;
        }

    }

    public getUserDetails(): any {
        return this.UserDetails;
    }

    public setCurrentItem(item: any) {
        this.currentItem = item;
    }

    public getCurrentItem() {
        return this.currentItem;
    }

    public getUserName() {
        return this.UserDetails.username;
    }


    public getUserRole() {
        return this.UserDetails.UserRole;
    }

    public getMenuSystem() {
        return this.UserDetails.menuSystem;
    }


}

export let UserStore = new UserStoreClass();
