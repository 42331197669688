import {Component} from '@angular/core';
import {FormStore} from '../../../stores/store.form';
import constants from '../../../constants/constants';
import * as ReactDOM from 'react-dom';
import * as React from 'react';
import advanceSearchReact from '../../react/component.react.advancesearch';

@Component({

    selector: 'searchable-modal',
    template: `<div id="modaldomframe">
            </div>
`
})
export class SearchableComponentModal {
    config: any;
    data: any = [];
    model: any = "";
    theme: string = "";
    name: string = null;
    value: string = "";
    text: string = "";
    onChange: any;
    displays: any;
    editable: boolean = true;



    closesearch() {
        ReactDOM.unmountComponentAtNode($("#modaldomframe")[0]);
    }

    constructor() {

        FormStore.emitter.subscribe(constants.FormsActions.TRIGGER_SEARCHABLE, (data) => {
            ReactDOM.render(React.createElement(advanceSearchReact, {
                fulldata: data,
                onClose: this.closesearch
            }), $("#modaldomframe")[0]);
        });
    }

}
