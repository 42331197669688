import dispatcher from "../dispatcher/dispatcher.app";
import constants from "../constants/constants";
import formdefs from "../constants/formdefs";
import { RESTLoaderAuthOnly } from "../dispatcher/dispatcher.rest.noerror";

export default class FormAction {
  static loadForm(formid: string) {
    switch (formid) {
      case "util/guestregistration": {
        setTimeout(() => {
          RESTLoaderAuthOnly.processRequest(
            false,
            "GetStatementControlList",
            null,
            "GET",
            function(response1) {
              const dat = JSON.parse(
                JSON.stringify(formdefs.FORMDEFS.GUESTREGISTRATION)
              );
              dat.listresources[0]["controlids"] = response1;

              dispatcher.dispatch({
                action: constants.FormsActions.FORM_AVAILABLE,
                data: dat
              });
            }
          );
        }, 10);
        break;
      }
      case "admin/getuseractivityrecords": {
        setTimeout(() => {
          RESTLoaderAuthOnly.processRequest(
            false,
            "ViewAdminListing",
            null,
            "GET",
            function(response1) {
              const dat = JSON.parse(
                JSON.stringify(formdefs.FORMDEFS.GETUSERACTIVITYRECORDS)
              );
              dat.listresources[0]["users"] = response1;

              dispatcher.dispatch({
                action: constants.FormsActions.FORM_AVAILABLE,
                data: dat
              });
            }
          );
        }, 10);
        break;
      }
      case "util/onlineportalmessage": {
        setTimeout(() => {
          const dat = JSON.parse(
            JSON.stringify(formdefs.FORMDEFS.ONLINEPORTALMESSAGE)
          );
          dispatcher.dispatch({
            action: constants.FormsActions.FORM_AVAILABLE,
            data: dat
          });
        }, 10);
        break;
      }

      case "hotelapplication": {
        setTimeout(() => {
          const dat = JSON.parse(
            JSON.stringify(formdefs.FORMDEFS.HOTELAPPLICATION)
          );
          dispatcher.dispatch({
            action: constants.FormsActions.FORM_AVAILABLE,
            data: dat
          });
        }, 10);
        break;
      }

      case "hotelmodification": {
        setTimeout(() => {
          RESTLoaderAuthOnly.processRequest(
            false,
            "GetStatementControlList",
            null,
            "GET",
            function(response1) {
              const dat = JSON.parse(
                JSON.stringify(formdefs.FORMDEFS.HOTELMODIFCATION)
              );
              dat.listresources[0]["controlids"] = response1;

              dispatcher.dispatch({
                action: constants.FormsActions.FORM_AVAILABLE,
                data: dat
              });
            }
          );
        }, 10);

        break;
      }

      
      case "hotelaccommodationtaxreturn": {
        setTimeout(() => {
          RESTLoaderAuthOnly.processRequest(
            false,
            "GetStatementControlList",
            null,
            "GET",
            function(response1) {
              const dat = JSON.parse(
                JSON.stringify(formdefs.FORMDEFS.HOTELACCOMMODATIONTAXRETURN)
              );
              dat.listresources[0]["controlids"] = response1;

              dispatcher.dispatch({
                action: constants.FormsActions.FORM_AVAILABLE,
                data: dat
              });
            }
          );
        }, 10);
        break;
      }

      case "touroperatorapplication": {
        setTimeout(() => {
          const dat = JSON.parse(
            JSON.stringify(formdefs.FORMDEFS.TOURAPPLICATION)
          );
          dispatcher.dispatch({
            action: constants.FormsActions.FORM_AVAILABLE,
            data: dat
          });
        }, 10);
        break;
      }

      case "touroperatormodification": {
        setTimeout(() => {
          RESTLoaderAuthOnly.processRequest(
            false,
            "gettouroperatorlist",
            null,
            "GET",
            function(response1) {
              const dat = JSON.parse(
                JSON.stringify(formdefs.FORMDEFS.TOURMODIFCATION)
              );
              dat.listresources[0]["controlids"] = response1;

              dispatcher.dispatch({
                action: constants.FormsActions.FORM_AVAILABLE,
                data: dat
              });
            }
          );
        }, 10);

        break;
      }

      case "qualifiedRetiredPersonApplication": {
        setTimeout(() => {
          const dat = JSON.parse(
            JSON.stringify(formdefs.FORMDEFS.QUALIFIEDRETIREDPERSONAPPLICATION)
          );
          dispatcher.dispatch({
            action: constants.FormsActions.FORM_AVAILABLE,
            data: dat
          });
        }, 10);
        break;
      }

      case "qualifiedRetiredPersonModification": {
        setTimeout(() => {
          RESTLoaderAuthOnly.processRequest(
            false,
            "getqualifiedretiredpersonlist",
            null,
            "GET",
            function(response1) {
              const dat = JSON.parse(
                JSON.stringify(
                  formdefs.FORMDEFS.QUALIFIEDRETIREDPERSONMODIFCATION
                )
              );
              dat.listresources[0]["controlids"] = response1;

              dispatcher.dispatch({
                action: constants.FormsActions.FORM_AVAILABLE,
                data: dat
              });
            }
          );
        }, 10);

        break;
      }

      case "tourguideapplication": {
        setTimeout(() => {
          const dat = JSON.parse(
            JSON.stringify(formdefs.FORMDEFS.TOURGUIDEAPPLICATION)
          );
          dispatcher.dispatch({
            action: constants.FormsActions.FORM_AVAILABLE,
            data: dat
          });
        }, 10);
        break;
      }

      case "tourguidemodification": {
        setTimeout(() => {
          RESTLoaderAuthOnly.processRequest(
            false,
            "gettourguideonlinelist",
            null,
            "GET",
            function(response1) {
              const dat = JSON.parse(
                JSON.stringify(formdefs.FORMDEFS.TOURGUIDEMODIFCATION)
              );
              dat.listresources[0]["controlids"] = response1;

              dispatcher.dispatch({
                action: constants.FormsActions.FORM_AVAILABLE,
                data: dat
              });
            }
          );
        }, 10);

        break;
      }

      case "settings": {
        setTimeout(() => {
          dispatcher.dispatch({
            action: constants.FormsActions.FORM_AVAILABLE,
            data: formdefs.FORMDEFS.SETTINGS
          });
        }, 10);
        break;
      }
      case "startup/uploadfile": {
        setTimeout(() => {
          dispatcher.dispatch({
            action: constants.FormsActions.FORM_AVAILABLE,
            data: formdefs.FORMDEFS.UPLOAD
          });
        }, 10);
        break;
      }
      case "util/penalty": {
        setTimeout(() => {
          const dat = JSON.parse(JSON.stringify(formdefs.FORMDEFS.PENALTY));
          RESTLoaderAuthOnly.processRequest(
            false,
            "PenaltyExemptionsListing",
            null,
            "GET",
            function(response1) {
              dat.listresources[0]["PenaltyExemptionsListing"] = response1;

              RESTLoaderAuthOnly.processRequest(
                false,
                "GetControlList",
                null,
                "GET",
                function(response2) {
                  dat.listresources[1]["GetControlList"] = response2;

                  dispatcher.dispatch({
                    action: constants.FormsActions.FORM_AVAILABLE,
                    data: dat
                  });
                }
              );
            }
          );
        }, 10);
        break;
      }
      case "startup/changepassword": {
        setTimeout(() => {
          RESTLoaderAuthOnly.processRequest(
            false,
            "ViewUserListing",
            null,
            "GET",
            function(response1) {
              const dat = JSON.parse(
                JSON.stringify(formdefs.FORMDEFS.CHANGEPASSWORD)
              );
              dat.listresources.map(item => {
                item[item.id] = response1;
              });
              dispatcher.dispatch({
                action: constants.FormsActions.FORM_AVAILABLE,
                data: dat
              });
            }
          );
        }, 10);
        break;
      }
      case "startup/setstatus": {
        setTimeout(() => {
          RESTLoaderAuthOnly.processRequest(
            false,
            "ViewUserListing",
            null,
            "GET",
            function(response1) {
              const dat = JSON.parse(
                JSON.stringify(formdefs.FORMDEFS.SETSTATUS)
              );
              dat.listresources.map(item => {
                item[item.id] = response1;
              });

              dispatcher.dispatch({
                action: constants.FormsActions.FORM_AVAILABLE,
                data: dat
              });
            }
          );
        }, 10);
        break;
      }

      case "startup/blank": {
        const dat = JSON.parse(JSON.stringify(formdefs.FORMDEFS.BLANK));
        setTimeout(() => {
          dispatcher.dispatch({
            action: constants.FormsActions.FORM_AVAILABLE,
            data: dat
          });
        }, 10);
        break;
      }

      case "startup/startuppage": {
        setTimeout(() => {
          RESTLoaderAuthOnly.processRequest(
            false,
            "getsecuritylevellist",
            null,
            "GET",
            response3 => {
              RESTLoaderAuthOnly.processRequest(
                false,
                "ViewUserListing",
                null,
                "GET",
                function(response1) {
                  RESTLoaderAuthOnly.processRequest(
                    false,
                    "GetControlList",
                    null,
                    "GET",
                    function(response2) {
                      const dat = JSON.parse(
                        JSON.stringify(formdefs.FORMDEFS.STARTUP)
                      );
                      //  .map((item)=>{
                      (dat.listresources[0]["ViewUserListing"] = response1),
                        (dat.listresources[2]["securitylist"] = response3),
                        (dat.listresources[1]["controlids"] = response2);

                      dispatcher.dispatch({
                        action: constants.FormsActions.FORM_AVAILABLE,
                        data: dat
                      });
                    }
                  );
                }
              );
            }
          );
        }, 10);
        break;
      }

      case "admin/manageusers": {
        setTimeout(() => {
          RESTLoaderAuthOnly.processRequest(
            false,
            "ViewAdminListing",
            null,
            "GET",
            function(response1) {
              RESTLoaderAuthOnly.processRequest(
                false,
                "GetPermissions",
                null,
                "GET",
                function(response2) {
                  const dat = JSON.parse(
                    JSON.stringify(formdefs.FORMDEFS.MANAGEADMIN)
                  );
                  // dat.listresources.map((item)=>{
                  dat.listresources[0]["ViewAdminListing"] = response1;
                  dat.listresources[1]["GetPermissions"] = response2;
                  dispatcher.dispatch({
                    action: constants.FormsActions.FORM_AVAILABLE,
                    data: dat
                  });
                }
              );
            }
          );
        }, 10);
        break;
      }

      case "admin/changepassword": {
        setTimeout(() => {
          RESTLoaderAuthOnly.processRequest(
            false,
            "ViewAdminListing",
            null,
            "GET",
            function(response1) {
              const dat = JSON.parse(
                JSON.stringify(formdefs.FORMDEFS.CHANGEPASSWORDADMIN)
              );
              dat.listresources.map(item => {
                item[item.id] = response1;
              });
              dispatcher.dispatch({
                action: constants.FormsActions.FORM_AVAILABLE,
                data: dat
              });
            }
          );
        }, 10);
        break;
      }

      case "util/sendstatement": {
        setTimeout(() => {
          RESTLoaderAuthOnly.processRequest(
            false,
            "GetStatementControlList",
            null,
            "GET",
            function(response1) {
              const dat = JSON.parse(
                JSON.stringify(formdefs.FORMDEFS.SENDSTATEMENT)
              );
              dat.listresources[0]["controlids"] = response1;

              dispatcher.dispatch({
                action: constants.FormsActions.FORM_AVAILABLE,
                data: dat
              });
            }
          );
        }, 10);
        break;
      }
      case "util/calcinterest": {
        setTimeout(() => {
          RESTLoaderAuthOnly.processRequest(
            false,
            "ViewAdminListing",
            null,
            "GET",
            function(response1) {
              const dat = JSON.parse(
                JSON.stringify(formdefs.FORMDEFS.CALCULATOR)
              );
              dat.listresources.map(item => {
                item[item.id] = response1;
              });
              dispatcher.dispatch({
                action: constants.FormsActions.FORM_AVAILABLE,
                data: dat
              });
            }
          );
        }, 10);
        break;
      }
    }
  }
}
