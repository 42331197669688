export const ONLINEPORTALMESSAGE = {
  access: ['admin'],
  formtype: 'onlineportalmessage',
  listresources: [
    {
      id: 'controlids',
      type: 'SQL'
    }
  ],
  caption: 'Online Portal Message'
};
