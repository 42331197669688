import {Component} from '@angular/core';
import FormAction from '../../../actions/action.form';
import {FlowControlStore} from '../../../stores/store.flowcontrol';

@Component({
    selector: 'logo-component',
    template: `<a style="cursor: pointer;" (click)="gotoStart()"><img style="width:40px;margin: 0 25px 0 25px;" src="img/logo.png"/></a>`
})
export class LogoComponent {

    gotoStart() {
        FormAction.loadForm(FlowControlStore.getCurrentFlowDefinition().form);
    }


}

