import { Grid } from "@syncfusion/ej2-ng-grids";

export const createTourGuideModificationLanguageListGrid = function(dataSource){


  const queryCell = (args)=>{
    if(args.data.Status === "New")
    {
      $(args.cell).css("background","#AEF0B7");
    }
    else if(args.data.Status === "Removed")
    {
      $(args.cell).css("background","#F0B1AE");
    }
    else
    {

    }
  }

  const cols = [
    {
      field: "Language",
      headerText: "Language"
    },
    {
      field: "Status",
      headerText: "Status"
    }
  ];

  const ownerGrid = new Grid({
    dataSource,
    width: "100%",
    allowTextWrap: true,
    textWrapSettings: {
      wrapMode: "Content"
    },
    queryCellInfo:queryCell.bind(this),
    columns: cols
  });
  ownerGrid.appendTo(`#${this.props.referenceid2}`);
}
