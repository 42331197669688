import { Component, AfterViewInit, OnInit } from '@angular/core';
import { UserStore } from '../../../stores/store.user';
import { RealTimeStore } from '../../../stores/store.realtime';
import { RESTLoaderAuthOnly } from '../../../dispatcher/dispatcher.rest.noerror';
import { TrackingStore } from '../../../stores/store.tracking';
import FormAction from '../../../actions/action.form';
import { RESTLoader } from '../../../dispatcher/dispatcher.rest';
import * as moment from 'moment';
import * as lockr from 'lockr';
import * as toastr from 'toastr';
import { FormStore } from '../../../stores/store.form';
import MenuSystem from '../../../constants/menusystem';
@Component({
  selector: "header-component",
  template: `


        <div class="modal fade" [ngStyle]="showModal()">
            <div class="modal-dialog" role="document" style="margin: 180px auto;min-width:40%;">
                <div class="modal-content">
                    <div class="modal-header">
                        <a href="javascript:void(0);" (click)="hideChangePassword()" class="close"
                           aria-label="Close" style="opacity: 1!important;">
                                                   <span aria-hidden="true"
                                                         style="color:#121212!important;opacity:1!important;"><i
                                                           style="font-size:18px" class="mdi mdi-close"></i></span>
                        </a>
                        <h4 class="modal-title" id="myModalLabel">Account</h4>
                    </div>
                    <div class="modal-body">

                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="row">
                                        <h3>Password Notice</h3>
                                    </div>
                                    Password must contain<br/>
                                    <ul>
                                        <li>
                                            At least 6 characters
                                        </li>
                                        <li>
                                            At least 1 upper case letter (A-Z)
                                        </li>
                                        <li>
                                            At least 1 lower case letter (a-z)
                                        </li>
                                        <li>
                                            At least 1 number (0-9)
                                        </li>
                                    </ul>
                                    <button class="btn btn-danger" (click)="onLogout()">Logout</button>
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <h3>Change Password</h3>
                                    </div>
                                    <div class="row">
                                        <label style="width:100%">
                                            <span class="ThemeControlled">New Password</span>
                                            <input type="password"
                                                   class="form-control"
                                                   name="chnewpassword"
                                                   id="chnewpassword"
                                                   placeholder="New Password"/>
                                        </label>
                                    </div>

                                    <div class="row">
                                        <label style="width:100%">
                                            <span class="ThemeControlled">Confirm New Password</span>
                                            <input type="password"
                                                   class="form-control"
                                                   name="chnewconfirmpassword"
                                                   id="chnewconfirmpassword"
                                                   placeholder="Confirm New Password"/>
                                        </label>
                                    </div>

                                    <button class="btn btn-primary" (click)="changePass()">Change Password</button>


                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="headerCaption">
            <table>
                <tbody>
                <tr>
                    <td valign="middle" (click)="showChangePassword()" style="cursor:pointer;"><i style="padding-top: -3px;padding-right:15px;font-size: 28px;margin-top: 3px;display: inline-block;"
                                           class="mdi mdi-account-circle"></i></td>
                    <td valign="middle" style="padding-right:15px;border-left:1px solid #348BCF;"><h5>BTB</h5></td>
                    <td valign="middle" style="border-left:1px solid #348BCF;padding-right:15px;"><h5 style="font-weight: 500!important;">
                        {{getCaption()}}</h5></td>
                        <td *ngIf="hasSettingspermission()" valign="middle" (click)="showSettings()" style="border-left:1px solid #348BCF;"><i style="padding-top: -3px;padding-right:15px;font-size: 28px;margin-top: 3px;display: inline-block;"
                        class="mdi mdi-settings"></i></td>



        </tr>
                </tbody>
            </table>
        </div>
        <div class="headerTabs">
            <ul>
                <li class="{{getactiveTab(tab)}} {{isActiveTab(tab)}}" (click)="setCurrentTab(tab)" *ngFor="let tab of MenuSystem">
                    {{tab.name}}


                </li>
            </ul>
        </div>


        <div class="headerGroups" *ngIf="currentTab !== null">
            <ul>
                <li class="activeGroup" *ngFor="let group of currentTab.groups">
                    <table style="height:99px;">
                        <tbody>
                        <tr>
                            <td>

                                <div class="headerItems">
                                    <ul>
                                        <li *ngFor="let menu of group.menuItems" class="menuitem"
                                            (click)="launchMenuItem(menu)" class="{{isActiveMenu(menu)}}">
                                            <table style="width:100%;height:100%;">
                                                <tbody>
                                                <tr>
                                                    <td align="center"><i class="{{menu.icon}}"
                                                                          style="font-size:18px;padding-right:3px;height: 32px;"></i>
                                                    </td>


                                                </tr>
                                                <tr>
                                                    <td align="center" style="padding-right:3px;">
                                                        <p>{{menu.name}}</p></td>
                                                </tr>

                                                </tbody>
                                            </table>
                                        </li>
                                    </ul>


                                </div>


                            </td>
                        </tr>
                        <tr>
                            <td align="center" class="groupitem" style="height:22px;">{{group.name}}</td>
                        </tr>
                        </tbody>
                    </table>


                </li>
            </ul>
        </div>






    `
})
export class HeaderComponent implements OnInit, AfterViewInit {
  currentItem: any = {};
  MenuSystem = [];
  currentName: any = null;

  currentTab: any = null;
  selectedTab: any = null;

  getLic() {
    return window["getLic"]();
  }
  setCurrentTab(tab) {
    this.currentTab = tab;
  }

  isActiveMenu(item) {
    if (item.resource === UserStore.getCurrentItem()) {
      return "activeItem";
    } else {
      return "";
    }
  }

  isActiveTab(item) {
    if (item === this.selectedTab) {
      return "selectedTab";
    } else {
      return "";
    }
  }

  changePassword = false;

  showModal() {
    if (this.changePassword) {
      return { display: "inherit", opacity: "1" };
    }

    return { display: "none", opacity: "0" };
  }

  getCaption() {
    return FormStore.getCurrentForm().caption;
  }

  getactiveTab(item) {
    if (item === this.currentTab) {
      return "active";
    } else {
      return "";
    }
  }

  changePass() {
    if ($("#chnewpassword").val() !== $("#chnewconfirmpassword").val()) {
      toastr.error(
        "Passwords do not match. Please insure that your new password and confirmation password matches",
        "Error",
        {
          timeOut: 5000,
          closeButton: true,
          newestOnTop: true,
          positionClass: "toast-bottom-right",
          showEasing: "swing",
          hideEasing: "linear",
          showMethod: "fadeIn",
          hideMethod: "fadeOut"
        }
      );

      return;
    }

    if (
      !$("#chnewpassword").filter(function() {
        return this["value"].match(
          /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9]{6})$/
        );
      })
    ) {
      toastr.error(
        "Your password does not meet the minimum requirements.",
        "Error",
        {
          timeOut: 5000,
          closeButton: true,
          newestOnTop: true,
          positionClass: "toast-bottom-right",
          showEasing: "swing",
          hideEasing: "linear",
          showMethod: "fadeIn",
          hideMethod: "fadeOut"
        }
      );

      return;
    }

    const mod = {
      password: $("#chnewpassword").val()
    };

    RESTLoader.processRequest(
      false,
      "changepassword",
      mod,
      "POST",
      response => {
        if (response.status === "success") {
          this.changePassword = false;

          toastr.success(
            "Everything checked out properly, your information has been successfully persisted to the database",
            "Saved",
            {
              timeOut: 5000,
              closeButton: true,
              newestOnTop: true,
              positionClass: "toast-bottom-right",
              showEasing: "swing",
              hideEasing: "linear",
              showMethod: "fadeIn",
              hideMethod: "fadeOut"
            }
          );
        } else {
          toastr.error(
            "Something went wrong with your last save attempted on " +
              moment(new Date()).format("Do MMMM, YYYY [at] h:m:s a") +
              ". Please ensure that all required fields are filled in, your data has been properly formatted as required and that your status is showing online",
            "Error",
            {
              timeOut: 5000,
              closeButton: true,
              newestOnTop: true,
              positionClass: "toast-bottom-right",
              showEasing: "swing",
              hideEasing: "linear",
              showMethod: "fadeIn",
              hideMethod: "fadeOut"
            }
          );
        }
      }
    );
  }

  showChangePassword() {
    this.changePassword = true;
  }

  hideChangePassword() {
    this.changePassword = false;
  }

  ngAfterViewInit() {}

  launchMenuItem(item) {
    if (item["type"] === "none") {
      return;
    }

    this.currentName = item;
    this.selectedTab = this.currentTab;
    TrackingStore.closeRecord(state => {
      if (state === true) {
        if (item.resource === UserStore.getCurrentItem()) {
          return;
        }

        RealTimeStore.setCurrentMessageStack([]);

        UserStore.setCurrentItem(item.resource);
        $("#mainloader").css("display", "block");

        FormAction.loadForm(item.resource);
      }
    }, true);

    //   this.lastDashboard=item.resource;
  }

  onLogout() {
    location.reload();
  }

  getMessages() {
    return RealTimeStore.getCurrentMessageStack();
  }

  isOnline() {
    if (RealTimeStore.getOnlineStatus() === true) {
      return "Online";
    }
    return "Offline";
  }

  hasSettingspermission() {
    return (
      UserStore.getUserDetails()
        .Roles.split(",")
        .indexOf("settings") > -1
    );
  }

  isOnlineIcon() {
    if (RealTimeStore.getOnlineStatus() === true) {
      return "mdi mdi-server-network";
    }
    return "mdi mdi-server-network-off";
  }

  showSettings() {
    FormAction.loadForm("settings");
    UserStore.setCurrentItem("settings");
  }

  ngOnInit() {
    let defaults = MenuSystem.MENUS;

    let roles = UserStore.getUserDetails().Roles.split(",");

    let modified = [];

    defaults.map(mn => {
      let top = {
        name: mn.name,
        groups: []
      };

      mn.groups.map(grp => {
        let group = {
          name: grp.name,
          menuItems: []
        };
        grp.menuItems.map(sub => {
          if (roles.indexOf(sub.resource) >= 0) {
            group.menuItems.push(sub);
          }
        });

        if (group.menuItems.length > 0) {
          top.groups.push(group);
        }
      });
      if (top.groups.length > 0) {
        modified.push(top);
      }
    });

    this.MenuSystem = modified;
    /*  if (this.MenuSystem.length > 0) {

         }*/

    switch (UserStore.getUserDetails().Group.toLowerCase()) {
    }

    if (this.MenuSystem.length > 0) {
      if (this.MenuSystem[0].groups.length > 0) {
        this.currentTab = this.MenuSystem[0];
        this.selectedTab = this.currentTab;
        UserStore.setCurrentItem("startup/blank");
        /*    if (this.MenuSystem[0].groups[0].menuItems.length > 0) {
                        this.currentName = this.MenuSystem[0].groups[0].menuItems[0];
                    }*/
      }
    }
  }

  getCurrentItem(): any {
    if (this.currentName !== null) {
      return this.currentName;
    }

    UserStore.getMenuSystem().map((item: any) => {
      if (item.resource === UserStore.getCurrentItem()) {
        this.currentItem = item;
      }
    });
    return this.currentItem;
  }
}
