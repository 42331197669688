import { UserStore } from "src/app/stores/store.user";
import { closest } from "@syncfusion/ej2-base";
import { fit_modal_body } from "./fitmodalbody";
import { createCompleteGrid } from "./createcompletegrid";
import { showComplete } from "./showComplete";

export const onComplete = function(args) {
  const rowObj = this.props.grid.getRowObjectFromUID(
    closest(args.target, ".e-row").getAttribute("data-uid")
  );
  const data: any = rowObj.data;
  const _createCompleteGrid = createCompleteGrid.bind(this);
  this.props.selectedQualifiedRetiredPerson = data.OldFirstName + ' ' + data.OldLastName;

  this.props.postDataProcess = {
    QualifiedRetiredPersonModificationRecordNumber:
      data.QualifiedRetiredPersonModificationRequestNumber,
    ExpirationDate: "",
    LicenseDate: "",
    OpeningDate: "",
    UserName: UserStore.getUserName(),
    UserToken: UserStore.getUserDetails().token
  };
  this.props.completeId= data.QualifiedRetiredPersonModificationRequestNumber;
  showComplete(
    data.QualifiedRetiredPersonModificationRequestNumber,
    (response: any) => {
      // this.currentSelectedModification = response;
      this.completed = response;
      this.modalService
        .open(this.complete, { centered: true, size: "lg" })
        .result.then(
          result => {
            // this.closeResult = `Closed with: ${result}`;
            if (result === "Process click") {
              this.modalService
                .open(this.process, { centered: true, size: "lg" })
                .result.then(
                  result => {
                    // this.closeResult = `Closed with: ${result}`;

                    if (result === "Process click") {

                    }
                  },
                  reason => {
                    // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                  }
                );
              setTimeout(() => {
                fit_modal_body($(".modal"));
              }, 100);
            }
          },
          reason => {
            // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
      setTimeout(() => {
        _createCompleteGrid(response);
      }, 100);
      setTimeout(() => {
        fit_modal_body($(".modal"));
      }, 100);
    }
  );
};
