export default `
<div class="row">

<div class="col-md-10">
  <div class="form-group">
    <label>Physical Address</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedModification.OldAddress"
      />
    </div>
  </div>
</div>

<div class="col-md-2">
  <div class="form-group">
    <label>District Area</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedModification.OldDistrictAreaName"
      />
    </div>
  </div>
</div>

<div class="col-md-10">
  <div class="form-group">
    <label>Mailing Address</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedModification.OldMailingAddress"
      />
    </div>
  </div>
</div>



<div class="col-md-2">
  <div class="form-group">
    <label>District Area</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedModification.OldMailingDistrictAreaName"
      />
    </div>
  </div>
</div>



<div class="col-md-4">
  <div class="form-group">
    <label>Telephone 1</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedModification.OldTelephone1"
      />
    </div>
  </div>
</div>


<div class="col-md-4">
  <div class="form-group">
    <label>Telephone 2</label>
    <div class="input-group">
      <input
        readOnly="readOnly"
        class="form-control"
        [(ngModel)]="props.currentSelectedModification.OldTelephone2"
      />
    </div>
  </div>
</div>


<div class="col-md-4">
<div class="form-group">
<label>Fax</label>
<div class="input-group">
  <input
    readOnly="readOnly"
    class="form-control"
    [(ngModel)]="props.currentSelectedModification.OldFaxNumber"
  />
</div>


</div>
</div>

<div class="col-md-6">
<div class="form-group">
<label>Email Address</label>
<div class="input-group">
<input
readOnly="readOnly"
class="form-control"
[(ngModel)]="props.currentSelectedModification.OldEmailAddress"
/>
</div>
</div>

</div>






<div class="col-md-6">
<div class="form-group">
<label>Web Site</label>
<div class="input-group">
<input
readOnly="readOnly"
class="form-control"
[(ngModel)]="props.currentSelectedModification.OldWebSite"
/>
</div>
</div>
</div>
</div>
`;
