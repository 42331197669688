export const  PENALTY = {
    duplicates: [
      {
        duplicatemessage: 'PLEASE NOTE: A similar record already exists.',
        checkduplicateupdate:
          'PenaltyUpdateDuplicate|["SourceType","SourceNumber","PenaltyExemptionRecordNumber"]',
        checkduplicateadd: 'PenaltyAddDuplicate|["SourceType","SourceNumber"]'
      }
    ],
    access: ['admin'],
    track: 'systemuser',
    formtype: 'dataEntry',
    listresources: [
      {
        id: 'PenaltyExemptionsListing',
        type: 'SQL'
      },
      {
        id: 'GetControlList',
        type: 'SQL'
      },
      {
        id: 'sourcetypes',
        type: 'NONE',
        sourcetypes: [
          { id: 'HOT', name: 'Hotels' },
          { id: 'CRU', name: 'Cruise' },
          { id: 'TSH', name: 'Timeshare' },
          { id: 'ABD', name: 'Live aboards' }
        ]
      }
    ],
    caption: 'Penalty Exemptions',
    editSearchSelector: {
      resource: 'PenaltyExemptionsListing',
      resourceId: 'PenaltyExemptionRecordNumber',
      resourceValue: 'ControlIdName',
      resourceSelectedType: 'SQL',
      resourceSelected: 'GetPenaltyExemptions',
      resourceDisplayFields: ['ControlId']
    },
    model: {
      modelid: 'GetPenaltyExemptions',
      autoload: false
    },
    functionactions: {
      createnew: {
        functionname: 'AddPenaltyExemptions',
        functiontype: 'SQL',
        validateData: true,
        validationFailedTitle: '',
        validationFailedText: '',
        alertOnComplete: true,
        alertTitle: '',
        alertText: ''
      },
      update: {
        functionname: 'UpdatePenaltyExemptions',
        functiontype: 'SQL',
        validateData: true,
        validationFailedTitle: '',
        validationFailedText: '',
        alertOnComplete: true,
        alertTitle: '',
        alertText: ''
      },
      after: {
        functionname: 'PenaltyExemptionsListing',
        functiontype: 'SQL'
      }
    },
    formDefinition: {
      rows: [
        {
          columns: [
            {
              title: 'General Information',
              fields: [
                {
                  key: 'SourceType',
                  type: 'select',
                  label: 'Source Type',
                  placeholder: '',
                  optionsResource: {
                    resource: 'sourcetypes',
                    resourceId: 'id',
                    resourceValue: 'name',

                    resourceDisplay: []
                  },
                  validation: {
                    'data-parsley-required': true,
                    'data-parsley-minlength': 3
                  },
                  isPrimary: 'false'
                },
                {
                  key: 'SourceNumber',
                  dependentKey: 'SourceType',
                  dependencyLookup: 'ControlId',
                  type: 'select',
                  label: 'Source',
                  placeholder: '',
                  optionsResource: {
                    resource: 'GetControlList',
                    resourceId: 'IdNumber',
                    resourceValue: 'ControlIdName',

                    resourceDisplay: ['ControlId']
                  },
                  validation: {
                    'data-parsley-required': true
                  },
                  isPrimary: 'false'
                },

                {
                  key: 'BeginExemptionDate',
                  type: 'date',
                  label: 'Begin Exemption Date',
                  placeholder: 'Begin Exemption Date',
                  required: 'true',
                  validation: {
                    'data-parsley-required': true
                  },
                  isPrimary: 'false'
                },

                {
                  key: 'EndExemptionDate',
                  type: 'date',
                  label: 'End Exemption Date',
                  placeholder: 'End Exemption Date',
                  required: 'true',
                  validation: {
                    'data-parsley-required': true
                  },
                  isPrimary: 'false'
                }
              ]
            }
          ]
        }
      ]
    }
  };