import dispatcher from '../dispatcher/dispatcher.app';
import constants from '../constants/constants';
import EventEmitter from 'eventemitter-rx';
class FormStoreClass {

    public emitter:EventEmitter = new EventEmitter();
    
    private currentForm = {};    

    private currentModel:any = {};

    constructor() {

        dispatcher.register((payload:any) => {
            this.__invokeOnDispatch(payload);
        });

    }    
    
    private __invokeOnDispatch(payload:any) {

        switch (payload.action) {
            case constants.FormsActions.FORM_AVAILABLE:
            {
                this.currentForm = payload.data;
                this.emitter.next(constants.FormsActions.FORM_AVAILABLE, null);
            }
                break;

        }
    }

    public ShowSearchableModal(data:any)
    {
       
        this.emitter.next(constants.FormsActions.TRIGGER_SEARCHABLE, data);
    }

    public ShowSettingsModal(data:any,currentData:any)
    {

        this.emitter.next(constants.FormsActions.TRIGGER_SETTINGS, {data:data,currentData:currentData});
    }

    public showDeleteHistory()
    {
        this.emitter.next(constants.FormsActions.TRIGGER_DELETE_HISTORY, {currentformdata:this.currentForm});
    }

    public showUpdateHistory(identifier:string,text:string)
    {
        this.emitter.next(constants.FormsActions.TRIGGER_UPDATE_HISTORY, {currentformdata:this.currentForm,id:identifier,text:text});
    }


    public setCurrentModel(model)
    {
        this.currentModel=model;
    }

    public getCurrentModel()
    {
        return this.currentModel;
    }

    public getCurrentForm():any
    {
        return this.currentForm;
    }


}

export var FormStore = new FormStoreClass();