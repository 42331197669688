import {Component, Input} from '@angular/core';
import {FormStore} from '../../../stores/store.form'
import * as _ from 'underscore';

@Component({

    selector: 'searchable',
    template: `<div id="{{name}}" [ngClass]="getTheme()">
<div class="searchable" [ngStyle]="getErrorStyle()" >
<table style="width:100%" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td (click)="showsearch()">
<span class="mainspan" [ngStyle]="getPlaceHolderText()" >{{getModelFromData1()}}</span>
</td>
<td style="width:24px;" (click)="showsearch()">
<i [ngClass]="chevron"></i>
</td>
<td *ngIf="getAllowClear()" style="width:24px;" data-balloon-length="small" data-balloon-pos="left" data-balloon="Clear Contents"><i class="mdi mdi-close" (click)="clearcontents()"></i></td>
</tr>
</tbody>
</table>
        </div>
            </div>
            <div id="{{name+'valid'}}">
             <input style="display: none;" parsley [validation]="getValidation()" type="text" value="{{getModelFromData1()}}"/>
</div>
`
})
export class SearchableComponent {
    @Input() config: any;
    @Input() data: any = [];
    @Input() model: any = "";
    @Input() theme: string = "";
    @Input() name: string = "";
    @Input() value: string = "";
    @Input() text: string = "";
    @Input() onChange: any;
    @Input() displays: any;
    @Input() editable: boolean = true;
    @Input() placeholder: string = "";
    lastlen = -1;
    currentIndex = 0;
    currentSearch: string = "";

    lastdata = null;
    chevron: string = "mdi mdi-magnify mainsearchicon";


    getAllowClear() {
        return this.theme !== "editorSearch" && this.theme !== "dashboardSearch" && this.editable;
    }


    getPlaceHolderText(): any {
        if (this.model === null || this.model === undefined) {
            if (this.theme === "editorSearch") {
                return {"color": "#8A8A8A"};
            }
            if (this.theme === "dashboardSearch") {
                return {"color": "#818181"};
            }
            return {"color": "#eee"};
        }
        let query = {};
        if (this.config === null || this.config === undefined) {
            query[this.value] = this.model[this.value];
        }
        else {
            if(this.config.dependentKey!==undefined)
            {

                if(this.model[this.config.key]!==null && this.model[this.config.key]!==undefined)
                {

                query[this.config.dependencyLookup] = this.model[this.config.dependentKey]+this.model[this.config.key].toString().padStart(5,"0");

                }
                else
                {
                    query[this.value] = this.model[this.config.key];
                }
            }
            else
            {
                query[this.value] = this.model[this.config.key];
            }

        }


        let found = _.findWhere(this.data, query);


        if (found !== undefined && found !== null) {
            return {};
        }
        if (this.theme === "editorSearch") {
            return {"color": "#8A8A8A"};
        }
        if (this.theme === "dashboardSearch") {
            return {"color": "#818181"};
        }
        return {"color": "#eee"};

    }

    getErrorStyle(): any {

        if ($("#" + this.name + 'valid').find("input").hasClass("parsley-error")) {
            return {"border": "1px solid #E41600", "border-left": "10px solid #E41600"};
        }
        return {};
    }

    getValidation() {
        if (this.config === null || this.config === undefined) {
            return null;
        }
        return this.config.validation;
    }

    getTheme() {
        if (!this.editable) if (this.theme == "formSearch") {
            return this.theme + " readonly";
        }
        else {
            return this.theme + " readonly1";
        }
        else {
            return this.theme;
        }
    }

    clearcontents() {
        if (this.config !== null && this.config !== undefined) {
            this.model[this.config.key] = "";
        }
        else {
            this.model[this.value] = "";
        }
    }


    showsearch() {

        let realdata = [];
        if(this.config!==undefined)
        {
        if(this.config.dependentKey!==undefined)
        {
            if(this.model[this.config.dependentKey]!==undefined)
            {
                this.data.map((item)=>{
                    if(item[this.config.dependencyLookup].startsWith(this.model[this.config.dependentKey].toString()))
                    {
                        realdata.push(item);
                    }
                });
            }
            else
            {
            realdata =  this.data;
            }

        }
        else
        {
            realdata =  this.data;
        }
    }
    else
        {
            realdata =  this.data;
        }



        let passdata: any = {
            config: this.config,
            data: realdata,
            model: this.model,
            theme: this.theme,
            name: this.name,
            value: this.value,
            text: this.text,
            onChange: this.onChange,
            displays: this.displays,
            editable: this.editable
        };



        if (!this.editable) {
            return;
        }


        FormStore.ShowSearchableModal(passdata);

    }


    getModelFromData1() {


        if (this.model === null || this.model === undefined) {
            return this.placeholder;
        }

        let found: string = this.placeholder;

        if (this.config !== null && this.config !== undefined) {


            if (this.model[this.config.key] === undefined || this.model[this.config.key] === null) {
                return this.placeholder;
            }
            let positionElement = -1;
            if(this.config!==undefined && this.config!==null)
            {
            if(this.config.dependentKey!==undefined)
                {
                    let query = {};
                    query[this.config.dependencyLookup] = this.model[this.config.dependentKey]+this.model[this.config.key].toString().padStart(5,"0");
                    let found = _.findWhere(this.data, query);
                    positionElement=this.data.indexOf(found);


                }
                else
                {
                    positionElement = this.data.map((x) => {

                    return x[this.value].toString().trim();

            }).indexOf(this.model[this.config.key].toString().trim());
        }
    }
    else
    {

        positionElement = this.data.map((x) => {

            return x[this.value].toString().trim();

    }).indexOf(this.model[this.config.key].toString().trim());
    }
            if (positionElement > -1) {
                if(this.data[positionElement][this.text]===undefined)
                {
                    return "";
                }
                if(this.config!==undefined)
                {
                    if(this.config.dependencyLookup!==undefined)
                    {
                        return this.data[positionElement][this.text].toString()+" - "+this.data[positionElement][this.config.dependencyLookup].toString();
                    }
                }

                return this.data[positionElement][this.text].toString();
            }

            return this.placeholder;

        }
        else {

            let positionElement = -1;
            if (this.model[this.value] === undefined || this.model[this.value] === null) {
                return this.placeholder;
            }
            if(this.config!==undefined && this.config!==null)
        {
            if(this.config.dependentKey!==undefined)
            {

                let query = {};
                query[this.config.dependencyLookup] = this.model[this.config.dependentKey]+this.model[this.config.key].toString().padStart(5,"0");
                let found = _.findWhere(this.data, query);
                positionElement = this.data.indexOf(found);

            }
            else
            {

                positionElement = this.data.map((x) => {
                    return x[this.value].toString().trim();
                }).indexOf(this.model[this.value].toString().trim());
            }
        }
        else
        {

            positionElement = this.data.map((x) => {
                return x[this.value].toString().trim();
            }).indexOf(this.model[this.value].toString().trim());

        }




            if (positionElement > -1) {


                if(this.data[positionElement][this.text]===undefined)
                {
                    return "";
                }

                return this.data[positionElement][this.text].toString();
            }
            return this.placeholder;
        }


    }


}
